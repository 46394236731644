import gql from 'graphql-tag';

export const DELETE_USER = gql`
  mutation DeleteUser($uuid: ID!) {
    deleteUser(uuid: $uuid) {
      success
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUser(
    $uuid: ID!
    $bio: String
    $firstName: String
    $lastName: String
    $username: String
    $email: String
    $cover: Upload
    $avatar: Upload
    $adminRole: AdminRoleEnum
    $userType: AppRoleEnum
    $categories: [ID!]
    $subcategories: [ID!]
  ) {
    updateUser(
      uuid: $uuid
      bio: $bio
      firstName: $firstName
      lastName: $lastName
      username: $username
      email: $email
      coverPhoto: $cover
      avatar: $avatar
      adminRole: $adminRole
      appRole: $userType
      followedCategoryUuids: $categories
      subcategoryUuids: $subcategories
    ) {
      user {
        firstName
        lastName
        username
        email
        appRole
        adminRole
        bio
        banned
        followingCount
        avatar {
          url
        }
        coverPhoto {
          url
        }
        followedCategories {
          uuid
          name
        }
        subcategories {
          uuid
          name
        }
      }
    }
  }
`;

export const UPDATE_ADMIN_PRIVILEGES = gql`
  mutation UpdateAdminPrivileges($uuid: ID!, $adminRole: AdminRoleEnum) {
    updateUser(uuid: $uuid, adminRole: $adminRole) {
      user {
        uuid
        adminRole
      }
    }
  }
`;

export const UPDATE_FLAG_BAN_HOLR = gql`
  mutation updateHolrFlagAuthorStatus($uuid: ID!, $flagged: Boolean) {
    updateHolrFlagAuthorBanStatus(uuid: $uuid, flagged: $flagged) {
      holr {
        uuid
      }
    }
  }
`;

export const UPDATE_BAN_HOLR = gql`
  mutation UpdateHolrBanStatus($uuid: ID!, $authorBanned: Boolean) {
    updateHolrFlagAuthorBanStatus(uuid: $uuid, authorBanned: $authorBanned) {
      holr {
        uuid
      }
    }
  }
`;

export const UPDATE_USER_TYPE = gql`
  mutation UpdateUserType($uuid: ID!, $appRole: AppRoleEnum) {
    updateUser(uuid: $uuid, appRole: $appRole) {
      user {
        uuid
        appRole
      }
    }
  }
`;

export const UPDATE_UNBAN_USER = gql`
  mutation UpdateUnbanUser($uuid: ID!) {
    unbanUser(uuid: $uuid) {
      user {
        uuid
      }
    }
  }
`;

export const UPDATE_BAN_USER = gql`
  mutation UpdateBanUser(
    $uuid: ID!
    $notes: String
    $reason: BanReasonEnum!
    $timeSpanType: TimeSpanEnum!
    $endDate: IsoTime
  ) {
    createBanHistory(
      reportedUserUuid: $uuid
      reason: $reason
      notes: $notes
      timeSpanType: $timeSpanType
      endDate: $endDate
    ) {
      banHistory {
        reason
      }
    }
  }
`;

export const UPDATE_HOLR = gql`
  mutation UpdateHolr(
    $uuid: ID!, 
    $title: String, 
    $caption: String, 
    $subcategoryUuids: [ID!],
    $googlePlaceId: String,
    $media: [MediaAttributesUpdate!]
    ) {
    updateHolr(
      uuid: $uuid, 
      title: $title, 
      caption: $caption, 
      subcategoryUuids: $subcategoryUuids,
      googlePlaceId: $googlePlaceId,
      media: $media) {
      holr {
        uuid
        title
      }
    }
  }
`;

export const UPDATE_REPORTED_PROBLEM = gql`
  mutation UpdateReportedProblem($uuid: ID!, $feedback: String!) {
    updateReportedProblem(uuid: $uuid, feedback: $feedback) {
      reportedProblem {
        uuid
        feedback
      }
    }
  }
`;

export const FETCH_USER = gql`
  query FetchUser(
    $uuid: ID!,
    $first: Int,
    $after: String
  ) {
    fullUser(uuid: $uuid) {
      uuid
      firstName
      lastName
      fullName
      username
      email
      appRole
      adminRole
      bio
      banned
      followingCount
      followerCount
      avatar {
        url
      }
      coverPhoto {
        url
      }

      followedCategories {
        uuid
        name
        subcategories{
          nodes{
            uuid
            name
          }
        }
      }
      
      subcategories{
        name
        uuid
      }

      holrs {
        edges {
          node {
            uuid
            title
            caption
            type
            likeCount
            viewCount
            holrbackCount
            createdAt
            mediumType
            thumbnails {
              contentType
              lowQualityUrl
              originalType
              url
            }
            videoStreamingUrl 
          }
        }
      }

      followers(first: $first, after: $after){
        edges {
          node {
            uuid
            fullName
            username
            email
            appRole
            adminRole
          }
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }

      following {
        edges {
          node {
            uuid
            username
            email
            appRole
            adminRole
          }
        }
        totalCount
        pageInfo {
          startCursor
          endCursor
          hasPreviousPage
          hasNextPage
        }
      }

      reportedProblems {
        uuid
        feedback
        response
        state
        createdAt
        user {
          fullName
        }
      }

      userPayments {
        edges {
          node {
            uuid
            amount
            createdAt
            updatedAt
          }
        }
        totalCount
      }
    }
  }
`;

export const FETCH_CATEGORIES = gql`
  query FetchCategories {
    categories {
      nodes {
        uuid
        name
        subcategories{
          nodes{
            uuid
            name
          }
        }
      }
    }
  }
`;

export default {
  DELETE_USER,
  UPDATE_USER,
  UPDATE_USER_TYPE,
  UPDATE_ADMIN_PRIVILEGES,
  UPDATE_FLAG_BAN_HOLR,
  UPDATE_BAN_HOLR,
  FETCH_CATEGORIES,
  FETCH_USER,
  UPDATE_BAN_USER,
  UPDATE_HOLR,
  UPDATE_REPORTED_PROBLEM,
  UPDATE_UNBAN_USER,
};
