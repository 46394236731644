import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Button, RadioGroup, FormControlLabel, Radio, Table, TableRow, TableBody, TableCell, TableHead,
} from '@material-ui/core';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';
import CollapsableCard from '../../widgets/CollapsableCard';
import DateRangePicker from '../../forms/ReportDateRangePicker';
import NoDataPlaceholder from '../UsersReportCard/NoDataPlaceholder';

const dataKeys = ['Paid', 'Unpaid'];
const dataTypes = ['CUMULATIVE', 'MONTHLY', 'DAILY'];
const dataTypeLabels = {
  CUMULATIVE: 'Cumulative Data',
  MONTHLY: 'Monthly Data',
  DAILY: 'Daily Data',
};

const colors = {
  Paid: '#FDCD69',
  Unpaid: '#E64F00',
};

const TopInfluencersReportCard = ({
  classes, onDownloadCsv, data, onChange, topInfluencers = [],
}) => {
  const [dateRange, setDateRange] = useState({});
  const [dataType, setDataType] = useState('CUMULATIVE');
  const [influencerUuid, setInfluencerUuid] = useState(null);

  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
    if (influencerUuid) onChange(influencerUuid, { startDate, endDate }, dataType);
  };

  const handleDataType = (type) => {
    setDataType(type);
    if (influencerUuid) onChange(influencerUuid, dateRange, type);
  };

  const handleSelectInfluencer = (uuid) => {
    setInfluencerUuid(uuid);
    onChange(uuid, dateRange, dataType);
  };

  useEffect(() => {
    if (topInfluencers.length > 0) {
      setInfluencerUuid(topInfluencers[0].uuid);
    }
  }, [topInfluencers]);

  const renderTable = () => (
    <Table className={classes.table}>
      <TableHead>
        <TableRow>
          <TableCell classes={{ root: `${classes.tableHeader}` }}>Influencer</TableCell>
          <TableCell classes={{ root: `${classes.tableHeader} ${classes.followersColumn}` }}>Total Followers</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {topInfluencers.map((influencer, index) => (
          <TableRow
            key={influencer.username}
            selected={influencerUuid === influencer.uuid}
            classes={{
              root: classes.tableRow,
              selected: classes.tableRowSelected,
            }}
            onClick={() => handleSelectInfluencer(influencer.uuid)}
          >
            <TableCell classes={{ root: `${classes.tableCell}` }}>
              <InfluencerWrapper>
                <InfluencerIndex>{`${index + 1}.`}</InfluencerIndex>
                <InfluencerUsername>{`@${influencer.username}`}</InfluencerUsername>
              </InfluencerWrapper>
            </TableCell>
            <TableCell classes={{ root: `${classes.tableCell} ${classes.followersColumn}` }}>
              <FollowersWrapper>{influencer.followerCount}</FollowersWrapper>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <CollapsableCard
      header={(
        <HeaderWrapper>
          <HeaderText>Top Influencers</HeaderText>
          <DateRangePicker
            onDateChange={handleDateChange}
          />
        </HeaderWrapper>
      )}
    >
      <ContentWrapper>
        <RadioGroupWrapper>
          <RadioGroup
            row
            value={dataType}
            onChange={(_, value) => handleDataType(value)}
          >
            {dataTypes.map(type => (
              <FormControlLabel
                value={type}
                control={<Radio color="primary" />}
                label={dataTypeLabels[type]}
                classes={{
                  root: classes.radioRoot,
                  label: classes.radioLabel,
                }}
              />
            ))}
          </RadioGroup>
        </RadioGroupWrapper>
        <DataWrapper>
          {renderTable()}
          {data.length ? (
            <ChartWrapper>
              <OverflowWrapper>
                <ChartContentWrapper style={{ minWidth: data.length * 39 }}>
                  <ResponsiveBar
                    margin={{
                      top: 20, right: 20, bottom: 60, left: 80,
                    }}
                    axisBottom={{
                      format: value => (dataType === 'MONTHLY' ? moment(value).format('MMM') : value),
                      tickRotation: dataType === 'DAILY' ? 35 : 0,
                    }}
                    keys={dataKeys}
                    indexBy="date"
                    animate
                    motionStiffness={120}
                    data={data}
                    colors={value => colors[value.id]}
                    axisLeft={{
                      format: e => Math.floor(e) === e && e,
                    }}
                    theme={{
                      axis: {
                        ticks: {
                          text: {
                            fontSize: dataType === 'DAILY' ? 14 : 18,
                            fontFamily: 'ArchivoNarrow-Bold',
                            fill: 'rgb(113,113,113)',
                          },
                        },
                      },
                      tooltip: {
                        container: {
                          fontSize: 14,
                          fontFamily: 'Raleway-Regular',
                        },
                      },
                    }}
                    enableLabel={false}
                  />
                </ChartContentWrapper>
              </OverflowWrapper>
              <LegendWrapper>
                {dataKeys.map(key => (
                  <LegendSection>
                    <LegendIcon style={{ backgroundColor: colors[key] }} />
                    <LegendText>{key}</LegendText>
                  </LegendSection>
                ))}
              </LegendWrapper>
            </ChartWrapper>
          ) : (
            <PlaceholderWrapper>
              <NoDataPlaceholder />
            </PlaceholderWrapper>
          )}
        </DataWrapper>
        <BottomWrapper>
          <Button
            color="primary"
            variant="contained"
            onClick={() => onDownloadCsv(influencerUuid)}
            className={classes.downloadCsvButton}
            disabled={data.length === 0}
          >
            Download Csv
          </Button>
        </BottomWrapper>
      </ContentWrapper>
    </CollapsableCard>
  );
};

const ContentWrapper = styled.div`
  padding-bottom: 13px;

  & > div > svg {
    overflow: visible !important;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 42px;
`;

const HeaderText = styled.h1`
  font-size: 31px;
  font-family: 'Raleway-Regular';
  margin: 0;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 67px 0 51px;
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 20px;
`;

const LegendSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;

const LegendIcon = styled.div`
  height: 14px;
  width: 21px;
`;

const LegendText = styled.div`
  font-size: 14px;
  font-family: 'Raleway-Regular';
  color: rgb(0,0,0,0.38);
  margin-left: 7px;
`;

const RadioGroupWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 23px;
`;

const ChartWrapper = styled.div`
  flex: 1;
  margin: 44px 80px 16px 0;
  overflow: hidden;
`;

const DataWrapper = styled.div`
  display: flex;
  padding-bottom: 35px;
`;

const InfluencerWrapper = styled.div`
  display: flex;
`;

const InfluencerIndex = styled.div`
  font-family: 'ArchivoNarrow-Bold';
  font-size: 14px;
  margin-right: 13px;
  color: #4A4A4A;
`;

const InfluencerUsername = styled.div`
  font-size: 14px;
`;

const FollowersWrapper = styled.div`
  margin-left: 20px;
  color: #4A4A4A;
`;

const PlaceholderWrapper = styled.div`
  flex: 1;
`;

const OverflowWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  flex: 1;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const ChartContentWrapper = styled.div`
  height: 400px;
`;

TopInfluencersReportCard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array,
  topInfluencers: PropTypes.array,
  onDownloadCsv: PropTypes.func,
  onChange: PropTypes.func,
};

TopInfluencersReportCard.defaultProps = {
  data: [],
  topInfluencers: [],
  onDownloadCsv: () => {},
  onChange: () => {},
};


export default withStyles(theme => ({
  radioLabel: {
    fontFamily: 'Raleway-Regular',
    fontSize: '14px',
    color: 'rgb(0,0,0,0.38)',
  },
  radioRoot: {
    margin: '0 0 0 23px',
  },
  downloadCsvButton: {
    height: '36px',
    width: '220px',
    borderRadius: '2px',
    fontFamily: 'Raleway-SemiBold',
  },
  table: {
    maxWidth: '340px',
  },
  tableHeader: {
    fontSize: '12px',
    fontFamily: 'ArchivoNarrow-Bold',
    color: theme.palette.primary.main,
  },
  tableCell: {
    borderBottom: 'none',
  },
  tableRow: {
    cursor: 'pointer',
    '&&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableRowSelected: {
    backgroundColor: `${theme.palette.action.hover} !important`,
  },
  followersColumn: {
    width: '100px',
    whiteSpace: 'nowrap',
  },
}))(TopInfluencersReportCard);
