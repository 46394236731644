import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import moment from 'moment';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core';
import MediaBox from '../../widgets/MediaBox';

const mediumType = {
  PHOTO: 'PHOTO',
  PHOTOSET: 'PHOTOSET',
  VIDEO: 'VIDEO',
  NO_MEDIA: 'NO_MEDIA',
};

class HolrDetailsModal extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onFlag: PropTypes.func,
    onBan: PropTypes.func,
    open: PropTypes.bool,
    holr: PropTypes.shape({
      uuid: PropTypes.string,
      username: PropTypes.string,
      name: PropTypes.string,
      type: PropTypes.string,
      body: PropTypes.string,
      media: PropTypes.string,
      postDate: PropTypes.any,
      likes: PropTypes.number,
      views: PropTypes.number,
      holrbacks: PropTypes.number,
      mediumType: PropTypes.string,
      thumbnails: PropTypes.arrayOf(PropTypes.shape({
        contentType: PropTypes.string,
        lowQualityUrl: PropTypes.string,
        originalType: PropTypes.string,
        url: PropTypes.string,
      })),
      videoStreamingUrl: PropTypes.string,
    }),
  };

  static defaultProps = {
    onSave: () => {},
    onCancel: () => {},
    onFlag: () => {},
    onBan: () => {},
    open: false,
    holr: {
      uuid: '',
      username: '',
      media: '',
      name: '',
      type: '',
      body: '',
      postDate: moment(new Date(0)).format('YYYY-MM-DD'),
      likes: 0,
      views: 0,
      holrbacks: 0,
      mediumType: '',
      thumbnails: [{
        contentType: '',
        lowQualityUrl: '',
        originalType: '',
        url: '',
      }],
      videoStreamingUrl: '',
    },
  };

  state = {
    formFields: {},
  }

  componentDidMount() {
    const { holr } = this.props;
    this.setState({ formFields: holr });
  }

  isValid = values => values && values.name && values.postDate
   && values.type && values.body && values.likes !== '' && values.views !== '' && values.holrbacks !== ''

  validation = (values) => {
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (!values.postDate) {
      errors.postDate = 'Required';
    }

    if (!values.type) {
      errors.type = 'Required';
    }

    if (!values.body) {
      errors.body = 'Required';
    }

    if (values.likes === '') {
      errors.likes = 'Required';
    }

    if (values.views === '') {
      errors.views = 'Required';
    }

    if (values.holrbacks === '') {
      errors.holrbacks = 'Required';
    }

    return errors;
  };

  handleOnChange = (e, handleChange, values) => {
    handleChange(e);
    const auxValues = values;
    auxValues[e.target.name] = e.target.value;
    this.setState({ formFields: auxValues });
  }

  onSave = async (data) => {
    const { onSave } = this.props;

    const variables = {
      uuid: data.uuid,
      title: data.name,
      caption: data.body,
    };

    await onSave({ variables });
  }

  onFlagClick = async () => {
    const { onFlag, holr } = this.props;

    const variables = {
      uuid: holr.uuid,
      flagged: true,
    };

    await onFlag({ variables });
  }

  onBanClick = async () => {
    const { onBan, holr } = this.props;

    const variables = {
      uuid: holr.uuid,
      authorBanned: true,
    };

    await onBan({ variables });
  }

  render() {
    const {
      open,
      onCancel,
      classes,
      holr,
    } = this.props;
    const { formFields } = this.state;

    return (
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={onCancel}
      >
        <DialogTitle className={classes.modalTitle}>
          {`${holr.username}'s holr`}
        </DialogTitle>
        <DialogContent>
          <Container>
            <Formik
              initialValues={{ ...holr }}
              onSubmit={this.handleSubmit}
              validate={this.validation}
              ref={(ref) => {
                this.form = ref;
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
              }) => (
                <Form>
                  <Grid container spacing={24}>
                    <Grid item xs={6}>
                      <FormControl
                        fullWidth
                        error={touched.name && !!errors.name}
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="name">
                         holr Title
                        </InputLabel>
                        <Input
                          placeholder="Enter your name here"
                          testid="name"
                          id="name"
                          name="name"
                          value={values.name}
                          onChange={e => this.handleOnChange(e, handleChange, values)}
                          onBlur={handleBlur}
                          autoComplete="name"
                        />
                        <FormHelperText>{touched.name && errors.name}</FormHelperText>
                      </FormControl>
                      <FormControl
                        fullWidth
                        error={touched.postDate && !!errors.postDate}
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="postDate">
                         Post postDate
                        </InputLabel>
                        <Input
                          testid="postDate"
                          id="postDate"
                          name="postDate"
                          type="postDate"
                          value={values.postDate}
                          onChange={e => this.handleOnChange(e, handleChange, values)}
                          onBlur={handleBlur}
                          autoComplete="postDate"
                          disabled
                        />
                        <FormHelperText>{touched.postDate && errors.postDate}</FormHelperText>
                      </FormControl>
                      <MediaBox
                        style={{ width: '15rem', height: '15rem' }}
                        media={
                        holr.mediumType === mediumType.PHOTO || mediumType.PHOTOSET
                          ? holr.thumbnails.map(thumbnail => ({
                            uuid: holr.uuid,
                            url: thumbnail.url,
                            type: thumbnail.originalType,
                          })) : {
                            uuid: holr.uuid,
                            url: holr.videoStreamingUrl,
                            type: 'VIDEO',
                          }
                        }
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <FormControl
                        error={touched.type && !!errors.type}
                        fullWidth
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="type">
                          Type of holr
                        </InputLabel>
                        <Select
                          name="type"
                          id="type"
                          value={values.mediumType}
                          disabled
                          onChange={e => this.handleOnChange(e, handleChange, values)}
                        >
                          <MenuItem value={mediumType.PHOTO}>Photo</MenuItem>
                          <MenuItem value={mediumType.VIDEO}>Video</MenuItem>
                          <MenuItem value={mediumType.PHOTOSET}>Photoset</MenuItem>
                          <MenuItem value={mediumType.NO_MEDIA}>No Media</MenuItem>
                        </Select>
                      </FormControl>
                      <FormControl
                        error={touched.likes && !!errors.likes}
                        fullWidth
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="likes">
                         Likes
                        </InputLabel>
                        <Input
                          testid="likes"
                          id="likes"
                          name="likes"
                          type="text"
                          value={values.likes}
                          onChange={e => this.handleOnChange(e, handleChange, values)}
                          onBlur={handleBlur}
                          autoComplete="likes"
                          disabled
                        />
                        <FormHelperText>{touched.likes && errors.likes}</FormHelperText>
                      </FormControl>
                      <FormControl
                        error={touched.views && !!errors.views}
                        fullWidth
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="views">
                         Views
                        </InputLabel>
                        <Input
                          testid="views"
                          id="views"
                          name="views"
                          type="text"
                          value={values.views}
                          onChange={e => this.handleOnChange(e, handleChange, values)}
                          onBlur={handleBlur}
                          autoComplete="views"
                          disabled
                        />
                        <FormHelperText>{touched.views && errors.views}</FormHelperText>
                      </FormControl>
                      <FormControl
                        error={touched.holrbacks && !!errors.holrbacks}
                        fullWidth
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="holrbacks">
                         holrbacks
                        </InputLabel>
                        <Input
                          testid="holrbacks"
                          id="holrbacks"
                          type="text"
                          name="holrbacks"
                          value={values.holrbacks}
                          onChange={e => this.handleOnChange(e, handleChange, values)}
                          onBlur={handleBlur}
                          autoComplete="holrbacks"
                          disabled
                        />
                        <FormHelperText>{touched.holrbacks && errors.holrbacks}</FormHelperText>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControl
                        error={touched.body && !!errors.body}
                        fullWidth
                        className={classes.formControl}
                      >
                        <InputLabel htmlFor="body">
                          Body Text*
                        </InputLabel>
                        <Input
                          testid="body"
                          id="body"
                          name="body"
                          value={values.body}
                          onChange={e => this.handleOnChange(e, handleChange, values)}
                          onBlur={handleBlur}
                          autoComplete="body"
                          multiline
                          rows={4}
                        />
                        <FormHelperText>{touched.body && errors.body}</FormHelperText>
                      </FormControl>
                      <Button
                        onClick={this.onFlagClick}
                        variant="contained"
                        color="secondary"
                        style={{ marginRight: '0.8rem' }}
                      >
                        Flag Holr
                      </Button>
                      <Button
                        onClick={this.onBanClick}
                        variant="contained"
                        color="secondary"
                        style={{ marginLeft: '0.8rem' }}
                      >
                        Ban
                      </Button>
                    </Grid>
                  </Grid>
                </Form>
              )}
            </Formik>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={onCancel}
            color="primary"
          >
            Back
          </Button>
          <Button
            onClick={() => this.onSave(formFields)}
            color="primary"
            variant="contained"
            disabled={!this.isValid(formFields)}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const Container = styled.div`
  width: 40rem;
  margin: 40px auto;
`;


export default withStyles(theme => ({
  name: {
    marginBottom: theme.spacing.unit * 3,
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  modalTitle: {
    borderBottom: '1px solid #949494',
  },
}))(HolrDetailsModal);
