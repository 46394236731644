import gql from 'graphql-tag';

export const FETCH_REPORTED_HOLRS = gql`
  query FetchReportedHolrs($first: Int, $after: String, $startDate: IsoTime, $endDate: IsoTime) {
    reportedHolrs(first: $first, after: $after, type: HOLR, startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          uuid
          holr {
            uuid
            user {
              uuid
              username
              banned
            }
            title
            caption
            mediumType
            media {
              uuid
              original {
                url
                contentType
              }
            }
            flagged
          }
          reason
          state
          createdAt
          user {
            uuid
            username
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const FETCH_REPORTED_HOLRBACKS = gql`
  query FetchReportedHolrbacks($first: Int, $after: String, $startDate: IsoTime, $endDate: IsoTime) {
    reportedHolrs(first: $first, after: $after, type: HOLRBACK, startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          uuid
          holr {
            uuid
            user {
              uuid
              username
            }
            title
            caption
            mediumType
            media {
              uuid
              original {
                url
                contentType
              }
            }
            holr {
              uuid
              user {
                uuid
                username
              }
            }
            flagged
          }
          reason
          state
          createdAt
          user {
            uuid
            username
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const FETCH_REPORTED_USERS = gql`
  query FetchReportedUsers($first: Int, $after: String, $startDate: IsoTime, $endDate: IsoTime) {
    reportedUsers(first: $first, after: $after, startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          uuid
          reporter {
            uuid
            username
          }
          state
          reason
          createdAt
          user {
            uuid
            username
            banned
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const FETCH_REPORTED_PROBLEMS = gql`
  query FetchReportedProblems($first: Int, $after: String, $startDate: IsoTime, $endDate: IsoTime) {
    reportedProblems(first: $first, after: $after, startDate: $startDate, endDate: $endDate) {
      edges {
        node {
          uuid
          feedback
          response
          state
          createdAt
          user {
            uuid
            username
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const RESOLVE_REPORTED_HOLR = gql`
  mutation ResolveReportedHolr($uuid: ID!, $flagged: Boolean) {
    resolveReportedHolr(uuid: $uuid, flagged: $flagged) {
      reportedHolr {
        uuid
        holr {
          uuid
          user {
            uuid
            username
            banned
          }
          title
          caption
          mediumType
          media {
            original {
              url
              contentType
            }
          }
          flagged
        }
        reason
        state
        createdAt
        user {
          uuid
          username
        }
      }
    }
  }
`;

export const RESOLVE_REPORTED_PROBLEM = gql`
  mutation ResolveReportedProblem($uuid: ID!, $response: String!) {
    resolveReportedProblem(uuid: $uuid, response: $response) {
      reportedProblem {
        uuid
        feedback
        response
        state
        createdAt
        user {
          uuid
          username
        }
      }
    }
  }
`;

export const RESOLVE_REPORTED_USER = gql`
  mutation ResolveReportedUser($uuid: ID!) {
    resolveReportedUser(uuid: $uuid) {
      reportedUser {
        uuid
        reason
        state
        createdAt
        user {
          uuid
          username
        }
      }
    }
  }
`;

export const UPDATE_BAN_USER = gql`
  mutation UpdateBanUser(
    $uuid: ID!
    $notes: String
    $reason: BanReasonEnum!
    $timeSpanType: TimeSpanEnum!
    $endDate: IsoTime
  ) {
    createBanHistory(
      reportedUserUuid: $uuid
      reason: $reason
      notes: $notes
      timeSpanType: $timeSpanType
      endDate: $endDate
    ) {
      banHistory {
        reason
      }
    }
  }
`;

export const UPDATE_UNBAN_USER = gql`
  mutation UpdateUnbanUser($uuid: ID!) {
    unbanUser(uuid: $uuid) {
      user {
        uuid
      }
    }
  }
`;

export default {
  FETCH_REPORTED_HOLRS,
  FETCH_REPORTED_USERS,
  FETCH_REPORTED_PROBLEMS,
  FETCH_REPORTED_HOLRBACKS,
  RESOLVE_REPORTED_HOLR,
  RESOLVE_REPORTED_USER,
  RESOLVE_REPORTED_PROBLEM,
  UPDATE_BAN_USER,
  UPDATE_UNBAN_USER,
};
