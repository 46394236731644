import gql from 'graphql-tag';

export const UPDATE_IMPORTED_MEDIUM = gql`
  mutation UpdateImportedMedium($uuid: ID!, $name: String!, $tags: [String!]!) {
    updateImportedMedium(uuid: $uuid, name: $name, tags: $tags) {
      importedMedium {
        uuid
      }
    }
  }
`;

export default {
  UPDATE_IMPORTED_MEDIUM,
};
