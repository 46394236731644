import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Grid, Button, Typography } from '@material-ui/core';
import PlaceholderSmall from '../../../assets/placeholder/img-placeholder-squared.svg';
import PlaceholderLarge from '../../../assets/placeholder/img-placeholder-wide.svg';
import { validate } from '../../../utils';

class ImagePicker extends Component {
  static propTypes = {
    large: PropTypes.bool,
    title: PropTypes.string,
    subtitle: PropTypes.string,
    imageSrc: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.object,
    ]),
    onChange: PropTypes.func,
  };

  static defaultProps = {
    large: false,
    title: '',
    subtitle: '',
    imageSrc: '',
    onChange: () => {},
  };

  handleChange = (event) => {
    const { onChange } = this.props;

    onChange(event.target.files[0]);
  };

  getImage = imgSrc => (validate.url(imgSrc)
    ? imgSrc : window.URL && imgSrc && window.URL.createObjectURL(imgSrc))

  render() {
    const {
      large,
      imageSrc,
      title,
      subtitle,
    } = this.props;

    const placeholder = large ? PlaceholderLarge : PlaceholderSmall;
    const img = this.getImage(imageSrc);

    return (
      <Grid container spacing={16}>
        <Grid item container spacing={8}>
          <Grid item>
            <Typography variant="subheading">{title}</Typography>
          </Grid>
          <Grid item>
            <Typography variant="caption">{subtitle}</Typography>
          </Grid>
        </Grid>
        <Grid item container spacing={32}>
          <Grid item>
            <Image
              src={img || placeholder}
              large={large}
            />
          </Grid>
          <Grid item>
            <input
              accept="image/*"
              type="file"
              ref={(ref) => {
                this.fileButton = ref;
              }}
              onChange={e => this.handleChange(e)}
              hidden
            />
            <Button
              variant="contained"
              color="primary"
              onClick={() => this.fileButton.click()}
            >
              { imageSrc ? 'Change' : 'Upload' }
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }
}

const Image = styled.img`
  width: ${({ large }) => (large ? '375px' : '150px')};
`;

export default ImagePicker;
