import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Menu,
  MenuItem,
} from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';

class TopPanel extends Component {
  static propTypes = {
    title: PropTypes.string,
    userName: PropTypes.string,
    onToggleClick: PropTypes.func,
    onProfileClick: PropTypes.func,
    onLogoutClick: PropTypes.func,
    classes: PropTypes.object.isRequired,
    isAdmin: PropTypes.bool,
  };

  static defaultProps = {
    title: '',
    userName: '',
    isAdmin: false,
    onToggleClick: () => { },
    onProfileClick: () => { },
    onLogoutClick: () => { },
  };

  state = {
    anchorEl: null,
  };

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const {
      title,
      userName,
      onToggleClick,
      onProfileClick,
      onLogoutClick,
      classes,
      isAdmin,
    } = this.props;

    const { anchorEl } = this.state;
    const open = Boolean(anchorEl);

    return (
      <AppBar color="secondary" position="sticky" className={classes.appBar}>
        <Toolbar>
          {isAdmin && (
            <IconButton
              color="inherit"
              className={classes.navigationButton}
              onClick={onToggleClick}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="title" className={classes.title}>
            {title}
          </Typography>
          <div>
            <UsernameContainer onClick={this.handleMenu}>
              <Typography>{`Hi, ${userName}`}</Typography>
              <ArrowDropDownIcon />
            </UsernameContainer>
            <Menu
              anchorEl={anchorEl}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
              getContentAnchorEl={null}
              open={open}
              onClose={this.handleClose}
            >
              {isAdmin && <MenuItem onClick={onProfileClick}>Profile</MenuItem>}
              <MenuItem onClick={onLogoutClick}>Logout</MenuItem>
            </Menu>
          </div>

        </Toolbar>
      </AppBar>
    );
  }
}

const UsernameContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

export default withStyles(theme => ({
  appBar: {
    boxShadow: '0px 1px 10px -1px rgba(0, 0, 0, 0.2), 0px 1px 4px 0px rgba(0, 0, 0, 0.14)',
  },
  navigationButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  title: {
    flexGrow: 1,
    color: theme.palette.primary.main,
  },
}))(TopPanel);
