import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import branch from 'branch-sdk';
import qs from 'qs';
import auth from '../../services/auth';
import { headers } from '../../utils';
import PasswordResetScreen from '../../screens/PasswordResetScreen';

export const PasswordResetScreenConnector = ({ history }) => {
  const [branchParams, setBranchParams] = useState(null);

  useEffect(() => {
    branch.init(process.env.REACT_APP_BRANCH_IO, (err, data) => {
      if (!err) {
        setBranchParams(data.data_parsed);
      }
    });
  }, []);

  function getParams() {
    if (!branchParams || !branchParams['+clicked_branch_link']) {
      return {
        headers: qs.parse(history.location.search, { ignoreQueryPrefix: true }),
      };
    }

    const {
      uid,
      client: { client, token },
    } = branchParams;

    return {
      headers: { 'access-token': token, client, uid },
    };
  }

  async function resetPassword({ password, confirmPassword }) {
    try {
      const params = getParams();

      headers.store(params);

      const response = await auth.update({
        password,
        password_confirmation: confirmPassword,
      });

      headers.clear();

      if (response.data.cms) {
        history.push('/login', {
          message: response.data.message,
        });
      } else {
        // eslint-disable-next-line
        alert(response.data.message);

        window.location.replace('https://holrback.com/');
      }

      return Promise.resolve();
    } catch (error) {
      headers.clear();

      history.push('/login', {
        message: error.response.data.errors[0],
      });

      return Promise.reject();
    }
  }

  return (
    <PasswordResetScreen
      formProps={{
        onCancelClick: () => history.push('/login'),
        onSubmit: resetPassword,
      }}
    />
  );
};

PasswordResetScreenConnector.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(PasswordResetScreenConnector);
