import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Input,
  InputLabel,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';

const PaymentDetailsModal = ({
  open,
  onClose,
  classes,
  paymentDetail,
  onCancel,
}) => {
  const [payment, setPayment] = useState({ ...paymentDetail, paymentType: 'check' });
  const [errors, setErrors] = useState({});

  const updateField = (fieldName, event) => {
    event.preventDefault();

    const { value } = event.target;
    delete errors[fieldName];
    setErrors(errors);
    setPayment({
      ...payment,
      [fieldName]: value,
    });
  };

  const handleModalClose = () => {
    setErrors({});
    onClose();
  };

  const handleSave = () => {
    if (!payment.cancelReason) {
      setErrors({ cancelReason: 'Please enter cancellation reason' });
    } else {
      setErrors({});
      onCancel({ uuid: payment.uuid, cancelReason: payment.cancelReason });
      handleModalClose();
    }
  };

  useEffect(() => {
    setPayment({ ...paymentDetail, paymentType: 'check' });
  }, [paymentDetail]);

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <Grid container>
        <Grid item xs={12}>
          <Grid container>
            <Grid item xs={12}>
              <DialogTitle className={classes.modalTitle}>
                {`Amount: ${payment.amount}`}
              </DialogTitle>
            </Grid>
          </Grid>
          <DialogContent>
            <Container>
              <Form>
                <Grid container spacing={24}>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="influencer">Influencer</InputLabel>
                      <Input
                        disabled
                        testid="influencer"
                        id="influencer"
                        name="influencer"
                        value={`@${payment.username}` || ''}
                        autoComplete="influencer"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="payee">Payee</InputLabel>
                      <Input
                        disabled
                        testid="payee"
                        id="payee"
                        name="payee"
                        value={`@${payment.payee}` || ''}
                        autoComplete="payee"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <Input
                        disabled
                        testid="email"
                        id="email"
                        name="email"
                        value={payment.email || ''}
                        autoComplete="email"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="paymentDate">Payment Date</InputLabel>
                      <Input
                        disabled
                        testid="paymentDate"
                        id="paymentDate"
                        name="paymentDate"
                        value={payment.paymentDate || ''}
                        autoComplete="paymentDate"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="paymentType">Payment Type</InputLabel>
                      <Input
                        disabled
                        testid="paymentType"
                        id="paymentType"
                        name="paymentType"
                        value={payment.paymentType || ''}
                        autoComplete="paymentType"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="checkNumber">Check Number</InputLabel>
                      <Input
                        disabled
                        testid="checkNumber"
                        id="checkNumber"
                        name="checkNumber"
                        value={payment.checkNumber || ''}
                        autoComplete="checkNumber"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <CustomLabel>Status:</CustomLabel>
                    <Select
                      required
                      testid="status"
                      id="status"
                      name="status"
                      value={payment.status}
                      onChange={e => updateField('status', e)}
                      className={classes.customSelect}
                      disabled={payment.status === 'CANCELED'}
                    >
                      <MenuItem value="PAID">PAID</MenuItem>
                      <MenuItem value="CANCELED">CANCEL</MenuItem>
                    </Select>
                  </Grid>
                  {payment.status === 'CANCELED' && (
                    <Grid item xs={8}>
                      <FormControl fullWidth className={classes.formControl}>
                        <InputLabel htmlFor="cancelReason">Cancellation Reason</InputLabel>
                        <Input
                          required
                          testid="cancelReason"
                          id="cancelReason"
                          name="cancelReason"
                          onChange={e => updateField('cancelReason', e)}
                          value={payment.cancelReason || ''}
                          multiline
                          rows={4}
                          error={errors.cancelReason}
                        />
                        {errors.cancelReason && (
                          <InputError>{errors.cancelReason}</InputError>
                        )}
                      </FormControl>
                    </Grid>
                  )}
                </Grid>
              </Form>
            </Container>
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <Button
              onClick={() => handleModalClose()}
              color="primary"
              variant="text"
            >
              Close
            </Button>
            {payment.status === 'CANCELED' && paymentDetail.status === 'PAID' && (
              <Button
                onClick={() => handleSave()}
                color="primary"
                variant="contained"
              >
                Save
              </Button>
            )}
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

PaymentDetailsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onClose: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool,
  paymentDetail: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    amount: PropTypes.string,
    paymentDate: PropTypes.string,
    state: PropTypes.string,
    phoneNumber: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    streetAddress: PropTypes.string,
    apartmentNumber: PropTypes.string,
    poBox: PropTypes.string,
    zipCode: PropTypes.string,
    status: PropTypes.string,
    userPayments: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
};

PaymentDetailsModal.defaultProps = {
  onClose: () => {},
  onCancel: () => {},
  open: false,
  paymentDetail: {
    username: '',
    email: '',
    amount: '',
    paymentDate: '',
    state: '',
    phoneNumber: '',
    country: '',
    city: '',
    streetAddress: '',
    apartmentNumber: '',
    poBox: '',
    zipCode: '',
    firstName: '',
    lastName: '',
    status: '',
  },
};

const Container = styled.div`
  margin-top: 40px;
`;

const CustomLabel = styled.span`
  margin-right: 20px;
  font-size: 20px;
`;

const InputError = styled.div`
  color: #b00020;
  font-size: 12px;
  margin-top: 5px;
`;

export default withStyles(theme => ({
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  modalActions: {
    borderTop: '1px solid #949494',
    paddingTop: '1rem',
  },
  modalTitle: {
    borderBottom: '1px solid #949494',
  },
  customSelect: {
    width: '120px',
  },
}))(PaymentDetailsModal);
