import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import ReactDOM from 'react-dom';
import { withStyles } from '@material-ui/core/styles';
import {
  Menu,
  MenuItem,
  IconButton,
  CircularProgress,
} from '@material-ui/core';
import {
  Editor,
  EditorState,
  RichUtils,
  Modifier,
  convertToRaw,
  convertFromRaw,
} from 'draft-js';
import FormatBold from '@material-ui/icons/FormatBold';
import FormatItalic from '@material-ui/icons/FormatItalic';
import FormatUnderlined from '@material-ui/icons/FormatUnderlined';
import FormatColorText from '@material-ui/icons/FormatColorText';
import FormatAlignLeft from '@material-ui/icons/FormatAlignLeft';
import FormatAlignCenter from '@material-ui/icons/FormatAlignCenter';
import FormatAlignRight from '@material-ui/icons/FormatAlignRight';
import FormatAlignJustify from '@material-ui/icons/FormatAlignJustify';
import FormatLineSpacing from '@material-ui/icons/FormatLineSpacing';
import FormatListBulleted from '@material-ui/icons/FormatListBulleted';
import FormatListNumbered from '@material-ui/icons/FormatListNumbered';
import FormatIndentDecrease from '@material-ui/icons/FormatIndentDecrease';
import FormatIndentIncrease from '@material-ui/icons/FormatIndentIncrease';
import FormatClear from '@material-ui/icons/FormatClear';
import reduce from 'lodash/reduce';
import { BlockPicker } from 'react-color';
import { stateToHTML } from 'draft-js-export-html';
import 'draft-js/dist/Draft.css';
import './style.css';

const inlineStyles = {
  bold: 'BOLD',
  italic: 'ITALIC',
  underline: 'UNDERLINE',
};

const blockTypes = {
  alignLeft: 'alignLeft',
  alignCenter: 'alignCenter',
  alignRight: 'alignRight',
  alignJustify: 'alignJustify',
  lineHeight1: 'lineHeight1',
  lineHeight115: 'lineHeight115',
  lineHeight150: 'lineHeight150',
  lineHeight2: 'lineHeight2',
  unOrderedList: 'unordered-list-item',
  orderedList: 'ordered-list-item',
  addIndent: 'addIndent',
  removeIndent: 'removeIndent',
};

const blockStyles = {
  [blockTypes.alignLeft]: {
    textAlign: 'left',
  },
  [blockTypes.alignCenter]: {
    textAlign: 'center',
  },
  [blockTypes.alignRight]: {
    textAlign: 'right',
  },
  [blockTypes.alignJustify]: {
    textAlign: 'justify',
  },
  [blockTypes.lineHeight1]: {
    lineHeight: '1rem',
  },
  [blockTypes.lineHeight115]: {
    lineHeight: '1.15rem',
  },
  [blockTypes.lineHeight150]: {
    lineHeight: '1.5rem',
  },
  [blockTypes.lineHeight2]: {
    lineHeight: '2rem',
  },
  [blockTypes.addIndent]: {
    textIndent: '4rem',
  },
  [blockTypes.removeIndent]: {
    textIndent: '0',
  },
};

const blockAttributes = {};

const colors = ['#6F6F6F', '#F5A827', '#FCCD68', '#C9891F', '#000000', '#888888'];

export class MaterialTextEditor extends Component {
  static propTypes = {
    editorContent: PropTypes.object,
    timeStamp: PropTypes.number,
    loading: PropTypes.bool,
    onChange: PropTypes.func,
    editorStyle: PropTypes.object,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    editorContent: null,
    timeStamp: null,
    loading: false,
    onChange: () => {},
    editorStyle: {},
  };

  constructor(props) {
    super(props);
    this.state = {
      editorState: EditorState.createEmpty(),
      showColorPicker: false,
      lineHeightAnchor: null,
    };

    this.handleKeyCommand = this.handleKeyCommand.bind(this);
    this.colorPicker = null;
  }

  componentDidMount = () => {
    const { editorContent } = this.props;
    this.loadEditorState(editorContent);
  }

  componentDidUpdate = (prevProps) => {
    const { timeStamp: prevTimeStamp } = prevProps;
    const { editorContent, timeStamp } = this.props;

    if (prevTimeStamp !== timeStamp) {
      this.loadEditorState(editorContent);
    }
  }

  loadEditorState = (editorContent) => {
    const { editorState } = this.state;

    if (editorContent) {
      const currentSelection = editorState.getSelection();
      const stateWithContent = EditorState.createWithContent(convertFromRaw(editorContent));
      const stateWithContentAndSelection = EditorState
        .forceSelection(stateWithContent, currentSelection);

      this.setState({
        editorState: stateWithContentAndSelection,
      });
    }
  };

  clearInlineStyles = (editorState) => {
    const styles = ['BOLD', 'ITALIC', 'UNDERLINE', 'STRIKETHROUGH', 'CODE', ...colors];

    const contentWithoutStyles = reduce(styles, (newContentState, style) => (
      Modifier.removeInlineStyle(
        newContentState,
        editorState.getSelection(),
        style,
      )
    ), editorState.getCurrentContent());

    return EditorState.push(editorState, contentWithoutStyles, 'change-inline-style');
  };

  getColorsStyles = () => {
    const styles = {};
    if (colors && colors.length > 0) {
      colors.forEach((color) => {
        styles[color] = {
          color,
        };
      });
    }
    return styles;
  };

  getStyleMap = () => ({
    ...this.getColorsStyles(),
  });

  getDraftExportHtmlInlineStyle = () => {
    const inlineStyle = {};
    colors.forEach((color) => {
      inlineStyle[color] = {
        style: {
          color,
        },
      };
    });
    return inlineStyle;
  };

  getDraftExportHtmlOptions = () => {
    const inlineStyle = this.getDraftExportHtmlInlineStyle();
    return {
      inlineStyles: {
        ...inlineStyle,
      },
      blockStyleFn: (block) => {
        let style = {};
        let attributes = {};
        const types = block.getType().split(' ');
        if (types && types.length > 0) {
          types.forEach((type) => {
            const typeStyle = blockStyles[type] || {};
            style = {
              ...style,
              ...typeStyle,
            };
            const typeAttributes = blockAttributes[type] || {};
            attributes = {
              ...attributes,
              ...typeAttributes,
            };
          });
        }
        return {
          style,
          attributes,
        };
      },
    };
  };

  getHtml = editorState => (
    stateToHTML(editorState.getCurrentContent(), this.getDraftExportHtmlOptions())
  );

  getSimilarBlockTyes = (currentBlockType) => {
    let similarBlockTypes = [];
    switch (currentBlockType) {
      case blockTypes.alignRight:
      case blockTypes.alignLeft:
      case blockTypes.alignCenter:
      case blockTypes.alignJustify: {
        similarBlockTypes = [
          blockTypes.alignRight,
          blockTypes.alignJustify,
          blockTypes.alignLeft,
          blockTypes.alignCenter,
        ];
        break;
      }
      case blockTypes.lineHeight150:
      case blockTypes.lineHeight1:
      case blockTypes.lineHeight2:
      case blockTypes.lineHeight115: {
        similarBlockTypes = [
          blockTypes.lineHeight150,
          blockTypes.lineHeight1,
          blockTypes.lineHeight2,
          blockTypes.lineHeight115,
        ];
        break;
      }
      case blockTypes.orderedList:
      case blockTypes.unOrderedList: {
        similarBlockTypes = [blockTypes.orderedList, blockTypes.unOrderedList];
        break;
      }
      case blockTypes.addIndent:
      case blockTypes.removeIndent: {
        similarBlockTypes = [blockTypes.addIndent, blockTypes.removeIndent];
        break;
      }
      default: {
        break;
      }
    }
    return similarBlockTypes.filter(blockType => blockType !== currentBlockType);
  };

  resolveBlockType = (prevBlock, currentBlock) => {
    const prevTypes = prevBlock.getType().split(' ');
    const currentTypes = currentBlock.getType().split(' ');
    const similarBlockTypes = this.getSimilarBlockTyes(currentBlock.getType());
    const newBlockTypes = [...new Set([...prevTypes, ...currentTypes])].filter(
      blockType => !similarBlockTypes.includes(blockType),
    );
    return newBlockTypes.join(' ');
  };

  getBlockStyle = (currentBlock) => {
    const { editorState } = this.state;
    const prevEditorState = EditorState.undo(editorState);
    const prevContent = prevEditorState.getCurrentContent();
    const prevBlock = prevContent.getBlockForKey(currentBlock.getKey());

    let blockType = currentBlock.getType();

    if (prevBlock && editorState.getLastChangeType() === 'change-block-type') {
      blockType = this.resolveBlockType(prevBlock, currentBlock);
      const newEditorContent = Modifier.setBlockType(
        editorState.getCurrentContent(),
        editorState.getSelection(),
        blockType,
      );

      if (prevBlock.getType() !== blockType) {
        this.setState({
          editorState: EditorState.push(editorState, newEditorContent, 'change-block-type'),
        });
      }
    }
    return blockType;
  };

  handleChangeColor = (color) => {
    const { editorState } = this.state;
    this.onChange(RichUtils.toggleInlineStyle(editorState, color.hex.toUpperCase()));
  };

  toggleInlineStyle = (style) => {
    const { editorState } = this.state;
    this.onChange(RichUtils.toggleInlineStyle(editorState, style));
  };

  toggleBlockType = (type) => {
    const { editorState } = this.state;
    this.onChange(RichUtils.toggleBlockType(editorState, type));
  };

  onClickColor = () => {
    this.setState(prevState => ({
      showColorPicker: !prevState.showColorPicker,
    }));
  };

  onClickClear = () => {
    const { editorState } = this.state;
    this.setState({
      editorState: this.clearInlineStyles(editorState),
    });
  };

  onChange = (editorState) => {
    const { onChange } = this.props;

    this.setState({ editorState });

    onChange({
      rawState: convertToRaw(editorState.getCurrentContent()),
      html: this.getHtml(editorState),
    });
  };

  handleClickLineHeight = (event) => {
    this.setState({ lineHeightAnchor: event.currentTarget });
  };

  handleCloseLineHeight = () => {
    this.setState({ lineHeightAnchor: null });
  };

  handleKeyCommand(command, editorState) {
    const newState = RichUtils.handleKeyCommand(editorState, command);

    if (newState) {
      this.onChange(newState);
      return 'handled';
    }
    return 'not-handled';
  }

  renderColorPicker = () => {
    const { showColorPicker } = this.state;
    if (!showColorPicker) {
      return null;
    }
    const {
      x,
      y,
      height,
      width,
      // eslint-disable-next-line react/no-find-dom-node
    } = ReactDOM.findDOMNode(this.colorPicker).getBoundingClientRect();
    return (
      <div
        style={{
          position: 'fixed',
          top: y + height,
          left: x - 85 + width / 2,
          zIndex: 99999,
        }}
      >
        <BlockPicker onChange={this.handleChangeColor} colors={colors} color={colors[0]} />
      </div>
    );
  };

  renderSeparator = classes => <div className={classes.separator} />;

  renderLineHeightMenu = () => {
    const { lineHeightAnchor } = this.state;
    return (
      <Menu
        id="simple-menu"
        anchorEl={lineHeightAnchor}
        open={Boolean(lineHeightAnchor)}
        onClose={this.handleCloseLineHeight}
      >
        <MenuItem
          onClick={() => {
            this.toggleBlockType(blockTypes.lineHeight1);
          }}
        >
          Single
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.toggleBlockType(blockTypes.lineHeight115);
          }}
        >
          1.15
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.toggleBlockType(blockTypes.lineHeight150);
          }}
        >
          1.5
        </MenuItem>
        <MenuItem
          onClick={() => {
            this.toggleBlockType(blockTypes.lineHeight2);
          }}
        >
          Double
        </MenuItem>
      </Menu>
    );
  };

  renderActions = () => {
    const { classes } = this.props;

    return (
      <div className={classes.actionsContainer}>
        <div className={classes.actions}>
          <div className={classes.actionsGroup}>
            <IconButton
              className={classes.button}
              aria-label="Bold"
              onClick={() => {
                this.toggleInlineStyle(inlineStyles.bold);
              }}
              testid="formatBoldButton"
            >
              <FormatBold />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Italic"
              onClick={() => {
                this.toggleInlineStyle(inlineStyles.italic);
              }}
              testid="formatItalicButton"
            >
              <FormatItalic />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Underlined"
              onClick={() => {
                this.toggleInlineStyle(inlineStyles.underline);
              }}
              testid="formatUnderlinedButton"
            >
              <FormatUnderlined />
            </IconButton>
            <IconButton
              ref={(colorPicker) => {
                this.colorPicker = colorPicker;
              }}
              className={classes.button}
              aria-label="Color"
              onClick={this.onClickColor}
              testid="formatColorTextButton"
            >
              <FormatColorText />
              {this.renderColorPicker()}
            </IconButton>
            {this.renderSeparator(classes)}
            <IconButton
              className={classes.button}
              aria-label="Align Left"
              onClick={() => {
                this.toggleBlockType(blockTypes.alignLeft);
              }}
              testid="formatAlignLeftButton"
            >
              <FormatAlignLeft />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Align Center"
              onClick={() => {
                this.toggleBlockType(blockTypes.alignCenter);
              }}
              testid="formatAlignCenterButton"
            >
              <FormatAlignCenter />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Align Right"
              onClick={() => {
                this.toggleBlockType(blockTypes.alignRight);
              }}
              testid="formatAlignRightButton"
            >
              <FormatAlignRight />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Justify"
              onClick={() => {
                this.toggleBlockType(blockTypes.alignJustify);
              }}
              testid="formatAlignJustifyButton"
            >
              <FormatAlignJustify />
            </IconButton>
            {this.renderSeparator(classes)}
            <IconButton
              className={classes.button}
              aria-label="Line Spacing"
              onClick={this.handleClickLineHeight}
              testid="formatLineSpacingButton"
            >
              <FormatLineSpacing />
            </IconButton>
            {this.renderLineHeightMenu()}
            {this.renderSeparator(classes)}
            <IconButton
              className={classes.button}
              aria-label="Unordered List"
              onClick={() => {
                this.toggleBlockType(blockTypes.unOrderedList);
              }}
              testid="formatListBulletedButton"
            >
              <FormatListBulleted />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Ordered List"
              onClick={() => {
                this.toggleBlockType(blockTypes.orderedList);
              }}
              testid="formatListNumberedButton"
            >
              <FormatListNumbered />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Remove Indentation"
              onClick={() => {
                this.toggleBlockType(blockTypes.removeIndent);
              }}
              testid="formatIndentDecreaseButton"
            >
              <FormatIndentDecrease />
            </IconButton>
            <IconButton
              className={classes.button}
              aria-label="Add Indentation"
              onClick={() => {
                this.toggleBlockType(blockTypes.addIndent);
              }}
              testid="formatIndentIncreaseButton"
            >
              <FormatIndentIncrease />
            </IconButton>
          </div>
          <div className={classes.actionsGroup}>
            {this.renderSeparator(classes)}
            <IconButton
              className={classes.button}
              aria-label="Clear Style"
              onClick={this.onClickClear}
              testid="formatClearButton"
            >
              <FormatClear />
            </IconButton>
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      loading,
      editorStyle,
      classes,
    } = this.props;
    const { editorState } = this.state;

    if (loading) {
      return (
        <div className={classes.loading}>
          <CircularProgress thickness={5} size={80} />
        </div>
      );
    }

    return (
      <Fragment>
        {this.renderActions(classes)}
        <div className={classes.editor} style={editorStyle}>
          <Editor
            editorState={editorState}
            onChange={this.onChange}
            handleKeyCommand={this.handleKeyCommand}
            customStyleMap={this.getStyleMap()}
            blockStyleFn={this.getBlockStyle}
          />
        </div>
      </Fragment>
    );
  }
}

export default withStyles({
  loading: {
    textAlign: 'center',
    padding: '5%',
  },
  actionsContainer: {
    width: '90%',
    height: '115px',
    display: 'flex',
    marginLeft: '5%',
    marginRight: '5%',
    alignItems: 'center',
  },
  actions: {
    display: 'flex',
    justifyContent: 'space-between',
    flex: 1,
    borderTop: 'solid 1.5px #9B9B9B',
  },
  actionsGroup: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexWrap: 'wrap',
  },
  button: {
    color: '#9B9B9B',
  },
  editor: {
    backgroundColor: '#F4F4F4',
    marginLeft: '5%',
    marginRight: '5%',
    padding: '5%',
    height: '60vh',
    border: 'solid 1px #B8B8B8',
    overflowY: 'auto',
  },
  separator: {
    height: '48px',
    width: '1px',
    backgroundColor: '#9B9B9B',
  },
  footer: {

  },
})(MaterialTextEditor);
