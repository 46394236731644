import React, { Component } from 'react';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import { isEmpty } from 'lodash';
import { withRouter } from 'react-router-dom';
import PropTypes from 'prop-types';
import SupportScreen from '../../screens/SupportScreen/SupportScreen';

export const FETCH_REPORTED = gql`
  {
    reportedHolrs: reportedHolrs(last:1, type:HOLR){
      nodes{
        createdAt,
      }
    }
    reportedHolrsBack: reportedHolrs(last:1, type:HOLRBACK){
      nodes{
        createdAt,
      }
    }
    reportedProblems(last:1){
      nodes{
        createdAt,
      }
    }
    reportedUsers(last:1){
      nodes{
        createdAt,
      }
    }
  }
`;


export class SupportScreenConnector extends Component {
  getLastEditedDate = (data, reportedResource, error, loading) => {
    if (isEmpty(data) || error || loading
      || isEmpty(data[reportedResource]) || isEmpty(data[reportedResource].nodes)) {
      return null;
    }
    return data[reportedResource].nodes[0].createdAt;
  }

  handleClickReport = (tab) => {
    const { history } = this.props;
    const route = '/reported';
    history.push(`${route}?tab=${tab}`);
  }

  render() {
    return (
      <Query
        query={FETCH_REPORTED}
        fetchPolicy="network-only"
      >
        {({
          loading,
          error,
          data,
        }) => (
          <SupportScreen
            lastEditedReportedHolrbacks={this.getLastEditedDate(data, 'reportedHolrsBack', error, loading)}
            lastEditedReportedHolrs={this.getLastEditedDate(data, 'reportedHolrs', error, loading)}
            lastEditedReportedProblems={this.getLastEditedDate(data, 'reportedProblems', error, loading)}
            lastEditedReportedUsers={this.getLastEditedDate(data, 'reportedUsers', error, loading)}
            onClickReportedUsers={() => this.handleClickReport(2)}
            onClickReportedProblems={() => this.handleClickReport(3)}
            onClickReportedHolrs={() => this.handleClickReport(0)}
            onClickReportedHolrbacks={() => this.handleClickReport(1)}
          />
        )}

      </Query>
    );
  }
}

SupportScreenConnector.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(SupportScreenConnector);
