import React from 'react';
import PropTypes from 'prop-types';
import { Form, FieldArray } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Grid,
  Typography,
} from '@material-ui/core';
import ImagePicker from '../ImagePicker';
import ChipsBox from '../ChipsBox';
import { validate } from '../../../utils';


const getSubCategoriesAvailable = (categoriesSelected = []) => {
  try {
    let subcategories = [];
    categoriesSelected.forEach((category) => {
      subcategories = [...subcategories, ...category.subcategories.nodes];
    });
    return subcategories;
  } catch (e) {
    return [];
  }
};

const UserDetailsForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  setFieldValue,
  setFieldTouched,
  setFieldError,
  categories,
  classes,
}) => (
  <Form>
    <Grid container spacing={24}>
      <Grid item xs={4}>
        <FormControl
          fullWidth
          error={touched.avatar && !!errors.avatar}
          className={classes.formControl}
        >
          <ImagePicker
            imageSrc={values.avatar}
            title="Avatar*"
            subtitle="(min 720 x 720 px)"
            onChange={(file) => {
              setFieldValue('avatar', file);
              setFieldTouched('avatar', true);
              validate.imageSize(file, 'avatar', (result) => {
                setFieldError('avatar', result);
              });
            }}
          />
          <FormHelperText>{touched.avatar && errors.avatar}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={touched.username && !!errors.username}
          className={classes.formControl}
        >
          <InputLabel htmlFor="username">Username*</InputLabel>
          <Input
            id="username"
            value={values.username}
            name="username"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="given-name"
          />
          <FormHelperText>{touched.username && errors.username}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={touched.firstName && !!errors.firstName}
          className={classes.formControl}
        >
          <InputLabel htmlFor="firstName">First Name*</InputLabel>
          <Input
            id="firstName"
            name="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="given-name"
          />
          <FormHelperText>{touched.firstName && errors.firstName}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={touched.lastName && !!errors.lastName}
          className={classes.formControl}
        >
          <InputLabel htmlFor="lastName">Last Name*</InputLabel>
          <Input
            id="lastName"
            name="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="given-name"
          />
          <FormHelperText>{touched.lastName && errors.lastName}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={touched.email && !!errors.email}
          className={classes.formControl}
        >
          <InputLabel htmlFor="email">Email*</InputLabel>
          <Input
            id="email"
            name="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="email"
          />
          <FormHelperText>{touched.email && errors.email}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={touched.facebook && !!errors.facebook}
          className={classes.formControl}
        >
          <InputLabel htmlFor="facebook">Facebook</InputLabel>
          <Input
            id="facebook"
            name="facebook"
            value={values.facebook}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="given-name"
          />
          <FormHelperText>{touched.facebook && errors.facebook}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={8}>
        <FormControl
          fullWidth
          error={touched.cover && !!errors.cover}
          className={classes.formControl}
        >
          <ImagePicker
            large
            imageSrc={values.cover}
            title="Cover Photo*"
            subtitle="(min 1200 x 900 px)"
            onChange={(file) => {
              setFieldValue('cover', file);
              setFieldTouched('cover', true);
              validate.imageSize(file, 'cover', (result) => {
                setFieldError('cover', result);
              });
            }}
          />
          <FormHelperText>{touched.cover && errors.cover}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={touched.bio && !!errors.bio}
          className={classes.formControl}
        >
          <InputLabel htmlFor="bio">Bio*</InputLabel>
          <Input
            id="bio"
            name="bio"
            value={values.bio}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="bio"
            multiline
            rows={4}
          />
          <FormHelperText>{touched.bio && errors.bio}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={touched.instagram && !!errors.instagram}
          className={classes.formControl}
        >
          <InputLabel htmlFor="instagram">Instagram</InputLabel>
          <Input
            id="instagram"
            name="instagram"
            value={values.instagram}
            onChange={handleChange}
            onBlur={handleBlur}
          />
          <FormHelperText>{touched.instagram && errors.instagram}</FormHelperText>
        </FormControl>
        <Typography className={classes.categoriesTitle}>Category Tags*</Typography>
        <FieldArray name="categories">
          {({ push, remove }) => (
            <ChipsBox
              optionsItems={categories}
              selectedItems={values.categories}
              onSelect={item => push(item)}
              onRemove={index => remove(index)}
              itemLabel="name"
              itemKey="uuid"
              placeholder="Type to add"
            />
          )}
        </FieldArray>
        <Typography className={classes.categoriesTitle}>Sub-Categories Tags*</Typography>
        <FieldArray name="subcategories">
          {({ push, remove }) => (
            <ChipsBox
              optionsItems={getSubCategoriesAvailable(values.categories)}
              selectedItems={values.subcategories}
              onSelect={item => push(item)}
              onRemove={index => remove(index)}
              itemLabel="name"
              itemKey="uuid"
              placeholder="Type to add"
            />
          )}
        </FieldArray>
      </Grid>
    </Grid>
  </Form>
);

UserDetailsForm.propTypes = {
  classes: PropTypes.object.isRequired,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  setFieldValue: PropTypes.func.isRequired,
  setFieldTouched: PropTypes.func.isRequired,
  setFieldError: PropTypes.func.isRequired,
  categories: PropTypes.arrayOf(PropTypes.object),
};

UserDetailsForm.defaultProps = {
  categories: [],
  values: {
    username: '',
    fullName: '',
    bio: '',
    email: '',
    facebook: '',
    instagram: '',
    avatar: '',
    cover: '',
    categories: [],
    subcategories: [],
  },
  errors: {},
  touched: {},
};

export default withStyles(theme => ({
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  categoriesTitle: {
    fontSize: '1.2rem',
  },
}))(UserDetailsForm);
