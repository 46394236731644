import React from 'react';
import { Chip } from '@material-ui/core';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';

const CustomChip = ({ classes, ...other }) => (
  <Chip
    classes={{
      root: classes.chip,
      label: classes.chipLabel,
    }}
    {...other}
  />
);

CustomChip.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(() => ({
  chip: {
    height: '20px',
    fontSize: '10px',
    margin: '0 7px 8px 0',
    backgroundColor: 'white',
    border: '1px solid rgb(205, 205, 205)',
    '&& svg': {
      width: '12px',
      height: '12px',
    },
  },
  chipLabel: {
    paddingLeft: '5px',
  },
}))(CustomChip);
