import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import moment from 'moment-timezone';
import styled from 'styled-components';
import { CustomChip } from '../../forms';
import addTagIcon from '../../../assets/images/addTagIcon.svg';

export const FETCH_MEDIUM_DATA = gql`
  query GetMedium($uuid: ID!){
    importedMedium(uuid: $uuid){
      createdAt
      name
      duration
      fileSize
      original {
        url,
        contentType
      }
      tags {
        name,
        uuid
      }
      uuid
    }
  }
`;

class MediumDetailsModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onCancel: PropTypes.func,
    classes: PropTypes.object.isRequired,
    uuid: PropTypes.string.isRequired,
    onRemoveTag: PropTypes.func,
    onAddTagPressed: PropTypes.func,
  }

  static defaultProps = {
    title: '',
    open: false,
    onCancel: () => { },
    onRemoveTag: () => { },
    onAddTagPressed: () => { },
  }

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      query: '',
      isSearching: false,
      isSubmitting: false,
    };
  }

  setQuery(query) {
    this.setState(state => ({
      ...state,
      query,
    }));
  }

  mapMedium = ({ error, data, loading }) => {
    if (loading || error) {
      return { data: undefined, loading, error };
    }

    try {
      return {
        data: data.importedMedium,
        loading,
        error,
      };
    } catch (mapError) {
      return { data: undefined, loading, error: mapError };
    }
  }

  renderMedia = (url, contentType) => {
    const type = contentType.split('/')[0];
    switch (type) {
      case 'video':
        return (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <video width="100%" style={{ height: '100%' }} controls>
            <source src={url} type={contentType} />
          </video>
        );
      case 'image':
        return <Thumbnail imageSrc={url} />;

      default:
        return <div>Not Supported</div>;
    }
  }

  render() {
    const {
      title,
      uuid,
      open,
      onCancel,
      classes,
      onRemoveTag,
      onAddTagPressed,
    } = this.props;

    return (
      <Dialog
        maxWidth="xl"
        open={open}
        onClose={onCancel}
      >
        <DialogTitle className={classes.modalTitle} disableTypography>
          {title}
        </DialogTitle>
        <DialogContent className={classes.content}>
          {open && (
            <Query
              query={FETCH_MEDIUM_DATA}
              variables={{ first: 10, uuid }}
              fetchPolicy="network-only"
            >
              {(medium) => {
                const { data } = this.mapMedium(medium);
                if (!data) {
                  return null;
                }
                return (
                  <Grid container>
                    <Grid item classes={{ item: classes.mediaWrapper }} alignItems="center">
                      {this.renderMedia(data.original.url, data.original.contentType)}
                    </Grid>
                    <Grid item>
                      <div className={classes.description}>
                        <InfoTitle>File Name</InfoTitle>
                        <InfoData>{data.name}</InfoData>
                        <InfoTitle>Date</InfoTitle>
                        <InfoData>{moment(data.createdAt).format('MMMM DD, YYYY')}</InfoData>
                        <InfoTitle>File Size</InfoTitle>
                        <InfoData>{`${Math.floor(data.fileSize / 1000)} KB`}</InfoData>
                        <InfoTitle>File Type</InfoTitle>
                        <InfoData>{data.original && data.original.contentType && data.original.contentType.split('/').slice(-1)[0].toUpperCase()}</InfoData>
                        {data.duration && (
                          <DurationWrapper>
                            <InfoTitle>Duration</InfoTitle>
                            <InfoData>{`${`0${Math.floor(data.duration / 60)}`.slice(-2)}:${`0${Math.floor(data.duration % 60)}`.slice(-2)}`}</InfoData>
                          </DurationWrapper>
                        )}
                        <InfoTitle>Tags</InfoTitle>
                        <div className={classes.chipsContent}>
                          {data.tags.map(tag => (
                            <CustomChip
                              key={tag.uuid}
                              label={tag.name}
                              onDelete={async () => {
                                await onRemoveTag({
                                  uuid: data.uuid,
                                  name: data.name,
                                  tags: data.tags,
                                }, tag.uuid);

                                medium.refetch({});
                              }}
                            />
                          ))}
                          <button
                            onClick={() => onAddTagPressed({
                              uuid: data.uuid,
                              name: data.name,
                              tags: data.tags,
                            })}
                            className={`${classes.actionButton} ${classes.primary}`}
                            type="button"
                          >
                            <AddTagButtonIcon src={addTagIcon} />
                            <div className={classes.addTagButtonLabel}>Add Tag</div>
                          </button>
                        </div>
                      </div>
                    </Grid>
                  </Grid>
                );
              }
              }
            </Query>
          )}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            color="primary"
            variant="contained"
            className={classes.closeButton}
            onClick={onCancel}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const Thumbnail = styled.div`
  ${({ imageSrc }) => `background-image: url(${imageSrc});`}
  background-size: contain;
  background-position: top;
  background-repeat: no-repeat;
  height: 100%;
  width: 100%;
`;

const InfoTitle = styled.div`
  font-family: 'Raleway-SemiBold';
  font-size: 15px;
  color: black;
  opacity: 0.87;
  margin-bottom: 7px;
`;

const InfoData = styled.div`
  font-size: 13px;
  color: rgb(156, 156, 156);
  margin-bottom: 17px;
`;

const AddTagButtonIcon = styled.img`
  height: 8px;
  width: 8px;
  margin-right: 3px;
`;

const DurationWrapper = styled.div``;

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  content: {
    paddingTop: '19px',
  },
  formControl: {
    width: 400,
  },
  modalTitle: {
    borderBottom: `1px solid ${theme.palette.common.gray}`,
    fontFamily: 'Raleway-SemiBold',
    fontSize: '20px',
  },
  description: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '30px',
    width: '294px',
  },
  chipsContent: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    height: '20px',
    color: theme.palette.primary.main,
    border: `3px solid ${theme.palette.primary.main}`,
    cursor: 'pointer',
    padding: '3px 14px 5px',
    borderRadius: '20px',
    outline: 'none',
  },
  primary: {
    color: theme.palette.primary.main,
    border: `2px solid ${theme.palette.primary.main}`,

    '&& p': {
      color: theme.palette.primary.main,
    },
  },
  mediaWrapper: {
    height: '386px',
    width: '386px',
    display: 'flex',
  },
  addTagButtonLabel: {
    color: theme.palette.primary.main,
    fontSize: '11px',
    fontFamily: 'ArchivoNarrow-Bold',
  },
  dialogActions: {
    margin: '0',
    padding: '17px 25px',
    boxShadow: '0 -7px 5px -5px rgb(0,0,0, 0.16)',
  },
  closeButton: {
    height: '36px',
    width: '138px',
    borderRadius: '2px',
    fontFamily: 'Raleway-SemiBold',
  },
}))(MediumDetailsModal);
