import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import { sortOrder } from '../../../utils/constants';
import downArrow from '../../../assets/images/downArrow.png';

const SortFilterArrow = ({
  classes, title, onClick, withDefault,
}) => {
  const [isAscending, setIsAscending] = useState(false);

  const handleClick = () => {
    setIsAscending(!isAscending);
    if (withDefault) {
      onClick(title, isAscending ? sortOrder.DESCENDING : sortOrder.ASCENDING);
    } else {
      onClick(title, isAscending ? sortOrder.ASCENDING : sortOrder.DESCENDING);
    }
  };

  return (
    <FilterArrow onClick={() => handleClick()}>
      <img
        src={downArrow}
        className={`${classes.downArrow} ${isAscending && classes.upArrow}`}
        alt="down arrow"
      />
    </FilterArrow>
  );
};

SortFilterArrow.propTypes = {
  classes: PropTypes.object,
  onClick: PropTypes.func,
  title: PropTypes.string,
  withDefault: PropTypes.bool,
};

SortFilterArrow.defaultProps = {
  classes: {},
  onClick: () => {},
  title: '',
  withDefault: false,
};

const FilterArrow = styled.a`
  cursor: pointer;
`;

export default withStyles(() => ({
  downArrow: {
    width: '15px',
    padding: '0 8px',
  },
  upArrow: {
    transform: 'rotate(180deg)',
  },
}))(SortFilterArrow);
