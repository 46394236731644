import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Input,
  InputLabel,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';
import moment from 'moment-timezone';
import isEmpty from 'lodash/isEmpty';

const PaymentsDueModal = ({
  open,
  onClose,
  onPayment,
  classes,
  paymentDetail,
  paymentIndex,
  paymentsLength,
  paymentsDueDate,
  updateUser,
  isPaying,
}) => {
  const validationFields = [
    {
      field: 'checkNumber',
      validations: {
        required: 'Please enter check number',
      },
    },
    {
      field: 'streetAddress',
      validations: {
        required: 'Please enter address',
      },
    },
    {
      field: 'apartmentNumber',
      validations: {
        required: 'Please enter apartment number',
      },
    },
    {
      field: 'poBox',
      validations: {
        required: 'Please enter PO box',
      },
    },
    {
      field: 'city',
      validations: {
        required: 'Please enter city',
      },
    },
    {
      field: 'state',
      validations: {
        required: 'Please enter state',
      },
    },
    {
      field: 'country',
      validations: {
        required: 'Please enter country',
      },
    },
    {
      field: 'zipCode',
      validations: {
        required: 'Please enter zip code',
      },
    },
    {
      field: 'phoneNumber',
      validations: {
        required: 'Please enter phone number',
      },
    },
  ];
  const [payment, setPayment] = useState({ ...paymentDetail, paymentType: 'check' });
  const [errors, setErrors] = useState({});
  const [paymentLoader, setPaymentLoader] = useState(false);

  const updateField = (fieldName, event) => {
    event.preventDefault();

    const { value } = event.target;

    if (value) {
      delete errors[fieldName];
    }

    setPayment({
      ...payment,
      [fieldName]: value,
    });
  };

  const validate = () => {
    const validationErrors = errors;
    validationFields.forEach((validation) => {
      if (validation.validations.required && !payment[validation.field]) {
        validationErrors[validation.field] = validation.validations.required;
      } else {
        delete errors[validation.field];
      }
    });
    setErrors({ ...validationErrors });
    return isEmpty(validationErrors);
  };

  const handlePayment = () => {
    if (validate()) {
      updateUser({ ...payment });
      onPayment({
        userUuid: payment.uuid,
        endDate: moment(paymentsDueDate).format('YYYY-MM-DD'),
        paymentType: payment.paymentType.toUpperCase(),
        checkNumber: payment.checkNumber,
      });
    }
  };

  const handleClose = () => {
    setErrors({});
    onClose();
  };

  useEffect(() => {
    setPayment({ ...paymentDetail, paymentType: 'check' });
  }, [paymentDetail]);

  useEffect(() => {
    setPaymentLoader(isPaying);
  }, [isPaying]);

  const { nodes: paymentsHistory = [] } = (paymentDetail.userPayments || {});

  return (
    <Dialog maxWidth="md" fullWidth open={open} onClose={onClose}>
      <Grid container>
        <Grid item xs={3} className={classes.paymentsHistory}>
          <DialogTitle>
            Previous Payments
          </DialogTitle>
          <PaymentsHistoryContainer>
            <Grid container>
              {paymentsHistory.length > 0
                ? (
                  <>
                    <Grid item xs={6} className={classes.historyTitle}>
                      Amount
                    </Grid>
                    <Grid item xs={6} className={classes.historyTitle}>
                      Date
                    </Grid>
                  </>
                ) : (
                  <Grid item xs={12} className={classes.historyTitle}>
                    No payments made yet.
                  </Grid>
                )}
            </Grid>
            {paymentsHistory.map(paymentHistory => (
              <Grid container>
                <Grid item xs={6}>
                  <HistoryItem>
                    {`$${paymentHistory.amount}`}
                  </HistoryItem>
                </Grid>
                <Grid item xs={6}>
                  <HistoryItem>
                    {moment(paymentHistory.endDate).format('MM/DD/YYYY')}
                  </HistoryItem>
                </Grid>
              </Grid>
            ))}
          </PaymentsHistoryContainer>
        </Grid>
        <Grid item xs={9}>
          <Grid container>
            <Grid item xs={paymentsLength ? 6 : 12}>
              <DialogTitle className={classes.modalTitle}>
                Payment Due
              </DialogTitle>
            </Grid>
            {!!paymentsLength && (
            <Grid item xs={6}>
              <DialogTitle className={classes.indexTitle}>
                {`${paymentIndex}/${paymentsLength}`}
              </DialogTitle>
            </Grid>
            )}
          </Grid>
          <DialogContent>
            <Container>
              <Form>
                <Grid container spacing={24}>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="firstName">First Name</InputLabel>
                      <Input
                        disabled
                        testid="firstName"
                        id="firstName"
                        name="firstName"
                        value={payment.firstName || ''}
                        autoComplete="firstName"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="lastName">Last Name</InputLabel>
                      <Input
                        disabled
                        testid="lastName"
                        id="lastName"
                        name="lastName"
                        value={payment.lastName || ''}
                        autoComplete="lastName"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="influencer">Influencer</InputLabel>
                      <Input
                        disabled
                        testid="influencer"
                        id="username"
                        name="influencer"
                        value={payment.username || ''}
                        autoComplete="influencer"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="email">Email</InputLabel>
                      <Input
                        disabled
                        testid="email"
                        id="email"
                        name="email"
                        value={payment.email || ''}
                        autoComplete="email"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="paymentType">Payment Type</InputLabel>
                      <Select
                        required
                        testid="paymentType"
                        id="paymentType"
                        name="paymentType"
                        value={payment.paymentType}
                        onChange={e => updateField('paymentType', e)}
                      >
                        <MenuItem value="check">Check</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    {payment.paymentType === 'check' && (
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="checkNumber">Check Number</InputLabel>
                      <Input
                        required
                        testid="checkNumber"
                        id="checkNumber"
                        name="checkNumber"
                        onChange={e => updateField('checkNumber', e)}
                        value={payment.checkNumber || ''}
                        error={!!errors.checkNumber}
                      />
                      {errors.checkNumber && (
                        <InputError>{errors.checkNumber}</InputError>
                      )}
                    </FormControl>
                    )}
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="streetAddress">Address</InputLabel>
                      <Input
                        required
                        onChange={e => updateField('streetAddress', e)}
                        testid="streetAddress"
                        id="streetAddress"
                        name="streetAddress"
                        value={payment.streetAddress || ''}
                        autoComplete="streetAddress"
                        error={!!errors.streetAddress}
                      />
                      {errors.streetAddress && (
                        <InputError>{errors.streetAddress}</InputError>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="apartmentNumber">Apartment Number</InputLabel>
                      <Input
                        required
                        testid="apartmentNumber"
                        onChange={e => updateField('apartmentNumber', e)}
                        id="apartmentNumber"
                        name="apartmentNumber"
                        value={payment.apartmentNumber || ''}
                        autoComplete="apartmentNumber"
                        error={!!errors.apartmentNumber}
                      />
                      {errors.apartmentNumber && (
                        <InputError>{errors.apartmentNumber}</InputError>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="poBox">PO Box</InputLabel>
                      <Input
                        required
                        onChange={e => updateField('poBox', e)}
                        testid="poBox"
                        id="poBox"
                        name="poBox"
                        value={payment.poBox || ''}
                        autoComplete="poBox"
                        error={!!errors.poBox}
                      />
                      {errors.poBox && (
                        <InputError>{errors.poBox}</InputError>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="city">City</InputLabel>
                      <Input
                        required
                        testid="city"
                        id="city"
                        name="city"
                        onChange={e => updateField('city', e)}
                        value={payment.city || ''}
                        autoComplete="city"
                        error={!!errors.city}
                      />
                      {errors.city && (
                        <InputError>{errors.city}</InputError>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="state">State</InputLabel>
                      <Input
                        required
                        testid="state"
                        id="state"
                        name="state"
                        onChange={e => updateField('state', e)}
                        value={payment.state || ''}
                        autoComplete="state"
                        error={!!errors.state}
                      />
                      {errors.state && (
                        <InputError>{errors.state}</InputError>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="country">Country</InputLabel>
                      <Input
                        required
                        testid="country"
                        id="country"
                        name="country"
                        onChange={e => updateField('country', e)}
                        value={payment.country || ''}
                        autoComplete="country"
                        error={!!errors.country}
                      />
                      {errors.country && (
                        <InputError>{errors.country}</InputError>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={4}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="zipCode">Zip Code</InputLabel>
                      <Input
                        required
                        testid="zipCode"
                        id="zipCode"
                        name="zipCode"
                        onChange={e => updateField('zipCode', e)}
                        value={payment.zipCode || ''}
                        autoComplete="zipCode"
                        error={!!errors.zipCode}
                      />
                      {errors.zipCode && (
                        <InputError>{errors.zipCode}</InputError>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="phoneNumber">Phone Number</InputLabel>
                      <Input
                        required
                        testid="phoneNumber"
                        id="phoneNumber"
                        name="phoneNumber"
                        onChange={e => updateField('phoneNumber', e)}
                        value={payment.phoneNumber || ''}
                        autoComplete="phoneNumber"
                        error={!!errors.phoneNumber}
                      />
                      {errors.phoneNumber && (
                        <InputError>{errors.phoneNumber}</InputError>
                      )}
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl fullWidth className={classes.formControl}>
                      <InputLabel htmlFor="paymentDate">Payment Date</InputLabel>
                      <Input
                        disabled
                        testid="paymentDate"
                        id="paymentDate"
                        name="paymentDate"
                        value={moment().format('MM/DD/YYYY')}
                        autoComplete="paymentDate"
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </Form>
              <ModalFooter>
                <div>{`Date Range: ${payment.lastPaymentDate ? moment(payment.lastPaymentDate).format('MM/DD/YYYY') : moment(payment.createdAt).format('MM/DD/YYYY')} - ${moment(paymentsDueDate).format('MM/DD/YYYY')}`}</div>
                <div>{`Amount: ${payment.amount}`}</div>
              </ModalFooter>
            </Container>
          </DialogContent>
          <DialogActions className={classes.modalActions}>
            <Button
              onClick={handleClose}
              color="primary"
              variant="text"
            >
              Close
            </Button>
            <Button
              onClick={() => handlePayment()}
              color="primary"
              variant="contained"
              disabled={paymentLoader}
            >
              Pay
            </Button>
          </DialogActions>
        </Grid>
      </Grid>
    </Dialog>
  );
};

PaymentsDueModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onPayment: PropTypes.func,
  onClose: PropTypes.func,
  updateUser: PropTypes.func,
  open: PropTypes.bool,
  paymentIndex: PropTypes.number,
  paymentsLength: PropTypes.number,
  paymentDetail: PropTypes.shape({
    username: PropTypes.string,
    email: PropTypes.string,
    amount: PropTypes.string,
    paymentDate: PropTypes.string,
    state: PropTypes.string,
    phoneNumber: PropTypes.string,
    country: PropTypes.string,
    city: PropTypes.string,
    streetAddress: PropTypes.string,
    apartmentNumber: PropTypes.string,
    poBox: PropTypes.string,
    zipCode: PropTypes.string,
    userPayments: PropTypes.shape({
      nodes: PropTypes.array,
    }),
  }),
  paymentsDueDate: PropTypes.string,
  isPaying: PropTypes.bool,
};

PaymentsDueModal.defaultProps = {
  onPayment: () => {},
  onClose: () => {},
  updateUser: () => {},
  open: false,
  paymentDetail: {
    username: '',
    email: '',
    amount: '',
    paymentDate: '',
    state: '',
    phoneNumber: '',
    country: '',
    city: '',
    streetAddress: '',
    apartmentNumber: '',
    poBox: '',
    zipCode: '',
    firstName: '',
    lastName: '',
  },
  paymentIndex: 0,
  paymentsLength: 0,
  paymentsDueDate: '',
  isPaying: false,
};

const Container = styled.div`
  width: 40rem;
  margin-top: 40px;
`;

const PaymentsHistoryContainer = styled.div`
  padding-left: 20px;
  padding-right: 40px;
  font-family: 'ArchivoNarrow-Bold';
  font-size: 12px;
  margin-top: 30px;
`;

const HistoryItem = styled.div`
  font-family: 'Raleway-Regular';
  color: rgba(0, 0, 0, 0.87);
  text-align: center;
  margin-bottom: 20px;
`;

const ModalFooter = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 60px;
`;

const InputError = styled.div`
  color: #b00020;
  font-size: 12px;
  margin-top: 5px;
`;

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  statusText: {
    margin: '1rem 0rem',
    fontSize: '1.4rem',
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  modalActions: {
    borderTop: '1px solid #949494',
    paddingTop: '1rem',
  },
  modalTitle: {
    borderBottom: '1px solid #949494',
  },
  historyTitle: {
    color: '#f5a623',
    fontSize: '1rem',
    borderBottom: '1px solid #949494',
    textAlign: 'center',
    paddingBottom: '12px',
    marginBottom: '15px',
  },
  indexTitle: {
    textAlign: 'right',
    borderBottom: '1px solid #949494',
  },
  paymentsHistory: {
    backgroundColor: '#ebebeb',
  },
}))(PaymentsDueModal);
