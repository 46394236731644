import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import styled from 'styled-components';
import { TimePicker } from 'material-ui-time-picker';
import { Button } from '@material-ui/core';
import Input from '@material-ui/core/Input';
import InputAdornment from '@material-ui/core/InputAdornment';
import AccessTime from '@material-ui/icons/AccessTime';

class TimePickerInput extends Component {
    state = { open: false, time: null }

    onSavePressed = () => {
      const { onTimeChanged } = this.props;
      const { time } = this.state;
      this.setState({ open: false });
      onTimeChanged(time);
    }

    render() {
      const { open } = this.state;
      const { time, styles } = this.props;
      return (
        <div>
          <Button
            onClick={() => this.setState({ open: !open })}
            style={styles}
          >
            <Input
              id="input-with-icon-adornment"
              value={time && moment(time).format('hh:mm A')}
              disabled
              style={styles}
              endAdornment={(
                <InputAdornment position="end">
                  <AccessTime />
                </InputAdornment>
             )}
            />
          </Button>
          <PickerContainer
            open={open}
          >
            <TimePicker mode="12h" onChange={t => this.setState({ time: t })} />
            <div>
              <div style={{ width: '40%', display: 'inline-block' }} />
              <Button onClick={() => this.setState({ open: false })} color="black">
            Cancel
              </Button>
              <Button onClick={this.onSavePressed} color="primary">
            Ok
              </Button>
            </div>
          </PickerContainer>
        </div>
      );
    }
}

TimePickerInput.propTypes = {
  time: PropTypes.any,
  onTimeChanged: PropTypes.func,
  styles: PropTypes.object,
};

TimePickerInput.defaultProps = {
  time: null,
  onTimeChanged: () => {},
  styles: { color: 'black', width: '18rem' },
};

const PickerContainer = styled.div`
    display: ${props => (props.open ? 'block' : 'none')};
    width: 18rem;
    position: relative;
    box-shadow: 2px 11px 29px 0px rgba(204,200,204,1);
    padding-bottom: 1rem;
`;

export default TimePickerInput;
