import gql from 'graphql-tag';

export const REVENUE_STATS = gql`
  query RevenueStats(
    $startDate: Date!
    $endDate: Date!
    $groupingType: BaseGroupingEnum!
  ) {
    revenueStats(
      startDate: $startDate
      endDate: $endDate
      groupingType: $groupingType
    ) {
      stats {
        date
        holrRevenueApple
        holrRevenueGoogle
        influencerRevenueApple
        influencerRevenueGoogle
        storeRevenueApple
        storeRevenueGoogle
      }
    }
  }
`;

export default {
  REVENUE_STATS,
};
