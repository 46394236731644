import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  TextField,
  RadioGroup,
  Radio,
} from '@material-ui/core';
import { capitalize } from 'lodash';

const STATUS = {
  ACCEPTED: true,
  REJECTED: false,
};

class InfluencerRequestModal extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onCancel: PropTypes.func,
    onResolve: PropTypes.func,
    open: PropTypes.bool,
    data: PropTypes.shape({
      uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      username: PropTypes.string,
      phoneNumber: PropTypes.string,
      email: PropTypes.string,
      acceptanceStatus: PropTypes.bool,
      state: PropTypes.string,
      userState: PropTypes.string,
      country: PropTypes.string,
      city: PropTypes.string,
      streetAddress: PropTypes.string,
      apartmentNumber: PropTypes.string,
      poBox: PropTypes.string,
      zipCode: PropTypes.string,
      reason: PropTypes.string,
    }),
  };

  static defaultProps = {
    onCancel: () => {},
    onResolve: () => {},
    open: false,
    data: {
      uuid: 0,
      username: '',
      phoneNumber: '',
      email: '',
      userState: '',
      country: '',
      city: '',
      streetAddress: '',
      apartmentNumber: '',
      poBox: '',
      zipCode: '',
      reason: '',
    },
  };

  state = {
    acceptanceStatus: 'REJECTED',
  }

  componentDidMount = () => {
    const { data: { state } } = this.props;

    if (state !== 'PENDING') {
      this.setState({ acceptanceStatus: state });
    }
  }

  handleSubmit = () => {
    const {
      onResolve,
      data: { uuid },
    } = this.props;
    const { acceptanceStatus } = this.state;

    const variables = { uuid, acceptanceStatus: STATUS[acceptanceStatus] };

    onResolve({ variables });
  };

  render() {
    const {
      open,
      onCancel,
      classes,
      data,
    } = this.props;

    const { acceptanceStatus } = this.state;

    return (
      <Dialog maxWidth="lg" open={open} onClose={onCancel}>
        <DialogTitle className={classes.modalTitle}>
          Influencer Request
        </DialogTitle>
        <DialogContent>
          <Container>
            <Grid container spacing={24}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled
                  label="Requester"
                  name="requester"
                  value={data.username}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled
                  label="Email"
                  name="email"
                  value={data.email}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled
                  label="Address"
                  name="streetAddress"
                  value={data.streetAddress}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled
                  label="Apartment Number"
                  name="apartmentNumber"
                  value={data.apartmentNumber || ''}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled
                  label="PO Box"
                  name="poBox"
                  value={data.poBox || ''}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled
                  label="City"
                  name="city"
                  value={data.city}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled
                  label="State"
                  name="userState"
                  value={data.userState}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled
                  label="Country"
                  name="country"
                  value={data.country}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled
                  label="Zip Code"
                  name="zipCode"
                  value={data.zipCode || ''}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  fullWidth
                  disabled
                  label="Phone"
                  name="phone"
                  value={data.phoneNumber}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled
                  label="Reason"
                  name="reason"
                  value={data.reason}
                  className={classes.formControl}
                  multiline
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.statusText} variant="subheading">
                  {`Status: ${capitalize(data.state)}`}
                </Typography>
              </Grid>
              <Grid justify="flex-end" container item xs={6}>
                <RadioGroup
                  aria-label="Acceptance Status"
                  name="acceptanceStatus"
                  className={classes.group}
                  value={acceptanceStatus}
                  onChange={(_, value) => this.setState({ acceptanceStatus: value })}
                >
                  <FormControlLabel
                    value="REJECTED"
                    control={<Radio color="primary" />}
                    label="Reject"
                    disabled={data.state !== 'PENDING'}
                  />
                  <FormControlLabel
                    value="ACCEPTED"
                    control={<Radio color="primary" />}
                    label="Accept"
                    disabled={data.state !== 'PENDING'}
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </Container>
        </DialogContent>
        <DialogActions className={classes.modalActions}>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button
            onClick={this.handleSubmit}
            color="primary"
            variant="contained"
            disabled={data.state !== 'PENDING'}
          >
            Resolve
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const Container = styled.div`
  width: 40rem;
  margin: 40px auto;
`;

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  statusText: {
    margin: '1rem 0rem',
    fontSize: '1.4rem',
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  modalActions: {
    borderTop: '1px solid #949494',
    paddingTop: '1rem',
  },
  modalTitle: {
    borderBottom: '1px solid #949494',
  },
  group: {
    flexDirection: 'row',
  },
}))(InfluencerRequestModal);
