import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  TextField,
} from '@material-ui/core';

const ReportedProblemModal = ({
  open,
  onResolve,
  onCancel,
  data,
  classes,
}) => (
  <Formik
    initialValues={{ ...data }}
    enableReinitialize
  >
    {({
      values,
      handleChange,
      handleBlur,
    }) => (
      <Dialog maxWidth="lg" open={open} onClose={onCancel}>
        <DialogTitle className={classes.title}>
          Reported Problem
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Form>
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled
                  label="Reported By"
                  name="reporter"
                  value={`@${data.reporter}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled
                  label="Reported Date"
                  name="createdAt"
                  value={values.createdAt}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled
                  label="General Feedback"
                  name="feedback"
                  multiline
                  rows={4}
                  value={values.feedback}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled={values.state === 'Resolved'}
                  label="Response"
                  name="response"
                  multiline
                  rows={4}
                  value={values.response}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.statusText}>
                  {`Status: ${values.state}`}
                </Typography>
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          {values.state !== 'Resolved' && (
            <Button
              onClick={() => onResolve(values)}
              color="primary"
              variant="contained"
            >
              Resolve
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )}
  </Formik>
);

ReportedProblemModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onResolve: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.shape({
    uuid: PropTypes.string,
    reporter: PropTypes.string,
    createdAt: PropTypes.string,
    feedback: PropTypes.string,
    response: PropTypes.string,
  }),
};

ReportedProblemModal.defaultProps = {
  onResolve: () => {},
  onCancel: () => {},
  open: false,
  data: {},
};

export default withStyles(theme => ({
  title: {
    borderBottom: '1px solid #949494',
  },
  content: {
    width: '40rem',
    marginTop: '1rem',
  },
  actions: {
    borderTop: '1px solid #949494',
    paddingTop: '1rem',
  },
  statusText: {
    fontSize: '1.4rem',
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
}))(ReportedProblemModal);
