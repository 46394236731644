

import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Typography,
  Button,
  Grid,
} from '@material-ui/core';
import moment from 'moment';
import isEqual from 'lodash/isEqual';
import ScreenContainer from '../../components/layout/ScreenContainer';
import PushNotificationsTable from '../../components/tables/PushNotificationsTable';
import PushNotificationDetailsModal from '../../components/modals/PushNotificationDetailsModal';
import SearchBox from '../../components/forms/SearchBox';

class PushNotificationsScreen extends React.Component {
  static propTypes = {
    notificationList: PropTypes.shape({
      notificationsListData: PropTypes.array,
      notificationsListLoading: PropTypes.bool,
    }),
    onUpdateNotification: PropTypes.func,
    onCreateNotification: PropTypes.func,
    onDeleteNotification: PropTypes.func,
  };

  static defaultProps = {
    notificationList: {},
    onUpdateNotification: () => {},
    onCreateNotification: () => {},
    onDeleteNotification: () => {},
  };

  constructor(props) {
    super(props);
    this.state = {
      notifications: props.notificationList.notificationsListData,
      modalProps: {
        isOpen: false,
      },
    };
  }

  componentDidUpdate = ({ notificationList: prevNotificationList }) => {
    const { notificationList } = this.props;

    if (!isEqual(prevNotificationList, notificationList)) {
      this.setState({
        notifications: notificationList.notificationsListData,
      });
    }
  };

  closeModal = () => (
    this.setState({
      modalProps: {
        open: false,
      },
    })
  );

  search = (value, source = this.props) => {
    const { notificationList: { notificationsListData } } = source;
    const matches = notificationsListData.filter(notificiation => (
      Object.values(notificiation).find(item => (
        String(item).toLowerCase().includes(value.toLowerCase())
      ))
    ));
    this.setState({
      notifications: matches,
    });
  };

  openCreateModal = () => {
    const { onCreateNotification } = this.props;
    return this.setState({
      modalProps: {
        open: true,
        title: 'Push Notification',
        onCancel: () => this.closeModal(),
        onSave: (data) => {
          onCreateNotification(data);
          this.closeModal();
        },
        pushNotification: { state: 'Unsent' },
      },
    });
  };

  openEditModal = (notification) => {
    const { onDeleteNotification, onUpdateNotification } = this.props;
    return this.setState({
      modalProps: {
        open: true,
        title: notification.title,
        pushNotification: {
          ...notification,
          deliveredDate: moment(notification.deliverAt),
          deliveredTime: moment(notification.deliverAt),
        },
        onCancel: () => this.closeModal(),
        onSave: (data) => {
          onUpdateNotification(data);
          this.closeModal();
        },
        onDelete: () => {
          onDeleteNotification({ uuid: notification.uuid });
          this.closeModal();
        },
      },
    });
  };

  render = () => {
    const { notificationList } = this.props;
    const { notificationsListLoading, notificationsListData } = notificationList;
    const { modalProps, notifications } = this.state;

    return (
      <ScreenContainer>
        <PushNotificationDetailsModal {...modalProps} />
        <Grid container spacing={24}>
          <Grid item xs={6}>
            <SearchBoxWrapper>
              <SearchBox onSearch={this.search} />
            </SearchBoxWrapper>
          </Grid>
          <Grid item xs={6} style={{ textAlign: 'right' }}>
            <Button
              variant="contained"
              color="primary"
              onClick={this.openCreateModal}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <Headline variant="headline">
          Upcoming
        </Headline>
        <PushNotificationsTable
          data={notificationsListData && notifications.filter(n => n.state.toLowerCase() !== 'sent')}
          onItemClick={this.openEditModal}
          loading={notificationsListLoading}
        />
        <Headline variant="headline">
            Delivered
        </Headline>
        <PushNotificationsTable
          delivered
          data={notificationsListData && notifications.filter(n => n.state.toLowerCase() === 'sent')}
          onItemClick={this.openEditModal}
          loading={notificationsListLoading}
        />
      </ScreenContainer>
    );
  };
}

const SearchBoxWrapper = styled.div`
  margin: 1rem 0 1rem 2rem;
`;

const Headline = styled(Typography)`
  margin-top: 2rem !important;
  margin-bottom: 1rem !important;
`;

export default PushNotificationsScreen;
