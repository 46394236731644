import gql from 'graphql-tag';

export const FETCH_CATEGORIES = gql`
  query FetchCategories(
    $first: Int
    $after: String
    $sortOrder: SortOrderEnum
    $sortColumn: BaseSortColumnsEnum
  ) {
    categories(first: $first, after: $after, sortOrder: $sortOrder, sortColumn: $sortColumn) {
      edges {
        node {
          uuid
          name
          subcategoryCount
          subscriberCount
          holrCount
          updatedAt
        }
        cursor
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const FETCH_SUBCATEGORIES = gql`
  query FetchSubCategories(
    $first: Int
    $after: String
    $sortOrder: SortOrderEnum
    $sortColumn: BaseSortColumnsEnum
  ) {
    subcategories(first: $first, after: $after, sortOrder: $sortOrder, sortColumn: $sortColumn) {
      edges {
        node {
          uuid
          name
          category {
            uuid
            name
          }
          subscriberCount
          holrCount
          updatedAt
        }
        cursor
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const ADD_CATEGORY = gql`
  mutation AddCategory(
    $title: String!
    $imageSrc: Upload!
    $coverImageSrc: Upload!
    $subcategories: [SubcategoriesAttributesCreate!]
  ) {
    createCategory(
      name: $title
      avatar: $imageSrc
      coverPhoto: $coverImageSrc
      subcategories: $subcategories
    ) {
      category {
        uuid
        name
        subcategoryCount
        subscriberCount
        holrCount
        updatedAt
      }
    }
  }
`;

export const ADD_SUBCATEGORY = gql`
  mutation AddSubCategory($title: String!, $parentCategory: ID!, $imageSrc: Upload!) {
    createSubcategory(name: $title, avatar: $imageSrc, categoryUuid: $parentCategory) {
      subcategory {
        uuid
        name
        category {
          uuid
          name
        }
        subscriberCount
        holrCount
        updatedAt
      }
    }
  }
`;

export default {
  FETCH_CATEGORIES,
  FETCH_SUBCATEGORIES,
  ADD_CATEGORY,
  ADD_SUBCATEGORY,
};
