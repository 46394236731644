import React from 'react';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { notify, messages, pagination } from '../../utils';
import { FETCH_INFLUENCERS_REQUEST, RESOLVE_INFLUENCER_REQUEST } from './queries';
import { mapInfluencerRequests } from './mapping';
import InfluencerRequestsScreen from '../../screens/InfluencerRequestsScreen';

const variables = {
  startDate: null,
  endDate: null,
  first: 10,
  state: 'PENDING',
};

const options = {
  fetchPolicy: 'network-only',
  variables,
};

const loadMoreInfluencerRequest = (response, resource, rows) => (
  pagination.fetchNextPage(response, resource, rows)
);

export const InfluencerRequestsScreenConnector = () => {
  const influencerRequests = useQuery(FETCH_INFLUENCERS_REQUEST, options);
  const [resolveInfluencersRequest] = useMutation(RESOLVE_INFLUENCER_REQUEST, {
    onError: () => notify.error({ ...messages.RESOLVE_INFLUENCER_REQUEST.error }),
    onCompleted: () => notify.success({ ...messages.RESOLVE_INFLUENCER_REQUEST.success }),
    refetchQueries: [{ query: FETCH_INFLUENCERS_REQUEST, variables }],
  });

  const { data, totalCount } = mapInfluencerRequests(influencerRequests);

  return (
    <InfluencerRequestsScreen
      influencerRequests={data}
      loading={influencerRequests.loading}
      resolveRequest={resolveInfluencersRequest}
      onDateRangeUpdate={dates => influencerRequests.refetch(dates)}
      onTabChange={state => influencerRequests.refetch({ state })}
      loadMoreInfluencerRequest={values => (
        loadMoreInfluencerRequest(influencerRequests, 'influencerUpgradeRequests', values)
      )}
      totalCount={totalCount}
    />
  );
};

export default withRouter(InfluencerRequestsScreenConnector);
