import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ReactPlayer from 'react-player';
import Slider from 'react-slick';
import isEmpty from 'lodash/isEmpty';
import DefaultImage from '../../../assets/images/defaultImage.svg';
import PlayIcon from '../../../assets/images/play_icon.svg';
import theme from '../../../theme';

const MediaBox = ({ style, media }) => {
  const [play, setPlay] = useState(false);

  function renderImage(medium) {
    return <ImageMedia src={medium.url} />;
  }

  function renderVideo(medium) {
    return (
      <VideoMediaContainer onClick={() => setPlay(!play)}>
        <ReactPlayer
          url={medium.url}
          style={{ backgroundColor: theme.palette.common.lightGray }}
          width="100%"
          height="100%"
          playing={play}
          loop
        />
        {!play && <VideoMediaPlayIcon src={PlayIcon} />}
      </VideoMediaContainer>
    );
  }

  function renderAudio(medium) {
    return (
      <VideoMediaContainer onClick={() => setPlay(!play)}>
        <ReactPlayer
          url={medium.url}
          style={{ backgroundColor: theme.palette.primary.main }}
          width="100%"
          height="100%"
          playing={play}
          loop
        />
        {!play && <VideoMediaPlayIcon src={PlayIcon} />}
      </VideoMediaContainer>
    );
  }

  function renderMedia(medium) {
    switch (true) {
      case medium.type.toLowerCase().includes('image'):
        return renderImage(medium);
      case medium.type.toLowerCase().includes('video'):
        return renderVideo(medium);
      case medium.type.toLowerCase().includes('audio'):
        return renderAudio(medium);
      default:
        return null;
    }
  }

  if (isEmpty(media)) {
    return (
      <Container style={style}>
        <MediaContainer style={style}>
          <ImageMedia src={DefaultImage} />
        </MediaContainer>
      </Container>
    );
  }

  return (
    <Container style={style}>
      <Slider dots infinite slidesToShow={1}>
        {media.map(medium => (
          <MediaContainer key={medium.uuid}>
            {renderMedia(medium)}
          </MediaContainer>
        ))}
      </Slider>
    </Container>
  );
};

MediaBox.propTypes = {
  style: PropTypes.object,
  media: PropTypes.arrayOf(
    PropTypes.shape({
      uuid: PropTypes.string,
      url: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
};

MediaBox.defaultProps = {
  style: {},
  media: [],
};

const Container = styled.div``;

export const MediaContainer = styled.div`
  width: 15rem;
  height: 15rem;
  display: flex !important;
  justify-content: center;
`;

const ImageMedia = styled.img`
  height: 100%;
`;

const VideoMediaContainer = styled.div`
  width: 100%;
  height: 100%;
  cursor: pointer;
  &:hover {
    -webkit-filter: brightness(70%);
    filter: brightness(70%);
  }
`;

const VideoMediaPlayIcon = styled.div`
  position: relative;
  top: -240px;
  width: 100%;
  height: 100%;
  background-image: url(${props => props.src});
  background-repeat: no-repeat;
  background-position: center;
  background-size: 20%;
`;

export default MediaBox;
