export const influencerRequestsStates = [
  'PENDING',
  'ACCEPTED',
  'REJECTED',
];

export const sortOrder = {
  ASCENDING: 'ASCENDING',
  DESCENDING: 'DESCENDING',
};

export const sortColumn = {
  ID: 'ID',
  INFLUENCER_USERNAME: 'INFLUENCER_USERNAME',
  INFLUENCER_EMAIL: 'INFLUENCER_EMAIL',
  PAYEE_USERNAME: 'PAYEE_USERNAME',
  STATUS: 'STATUS',
  AMOUNT: 'AMOUNT',
  LAST_PAYMENT_DATE: 'LAST_PAYMENT_DATE',
  CREATED_AT: 'CREATED_AT',
  UPDATED_AT: 'UPDATED_AT',
};

export default {
  influencerRequestsStates,
  sortOrder,
  sortColumn,
};
