import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Tabs,
  Tab,
  Grid,
  Button,
} from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import { CategoriesTable, SubCategoriesTable } from '../../components/tables';
import { AddCategoryModal, AddSubCategoryModal } from '../../components/modals';
import { ScreenContainer } from '../../components/layout';
import { SearchBox } from '../../components/forms';

class ContentScreen extends Component {
  static propTypes = {
    categories: PropTypes.shape({
      categoriesData: PropTypes.array,
      categoriesLoading: PropTypes.bool,
      categoriesError: PropTypes.object,
    }),
    subCategories: PropTypes.shape({
      subCategoriesData: PropTypes.array,
      subCategoriesLoading: PropTypes.bool,
      subCategoriesError: PropTypes.object,
    }),
    onCategoryClick: PropTypes.func,
    onCategoriesSort: PropTypes.func,
    onSubCategoryClick: PropTypes.func,
    onSubCategoriesSort: PropTypes.func,
    onAddCategorySave: PropTypes.func,
    onAddSubCategorySave: PropTypes.func,
    onAddCategoryUpdate: PropTypes.func,
    onAddSubCategoryUpdate: PropTypes.func,
    classes: PropTypes.object.isRequired,
    loadMoreCategories: PropTypes.func,
    loadMoreSubCategories: PropTypes.func,
    totalCountSubCategories: PropTypes.number,
    totalCountCategories: PropTypes.number,
  };

  static defaultProps = {
    categories: {
      categoriesData: [],
      categoriesLoading: false,
      categoriesError: null,
    },
    subCategories: {
      subCategoriesData: [],
      subCategoriesLoading: false,
      subCategoriesError: null,
    },
    onCategoryClick: () => {},
    onCategoriesSort: () => {},
    onSubCategoryClick: () => {},
    onSubCategoriesSort: () => {},
    onAddCategorySave: () => {},
    onAddSubCategorySave: () => {},
    onAddCategoryUpdate: () => {},
    onAddSubCategoryUpdate: () => {},
    loadMoreCategories: () => {},
    loadMoreSubCategories: () => {},
    totalCountSubCategories: 0,
    totalCountCategories: 0,
  };

  state = {
    activeTab: 0,
    categoryModal: false,
    subCategoryModal: false,
    filteredCategories: [],
    filteredSubCategories: [],
  };

  componentDidMount = () => {
    const {
      categories: { categoriesData },
      subCategories: { subCategoriesData },
    } = this.props;

    this.setState({
      filteredCategories: categoriesData,
      filteredSubCategories: subCategoriesData,
    });
  };

  componentDidUpdate = (prevProps) => {
    const {
      categories: { categoriesData },
      subCategories: { subCategoriesData },
    } = this.props;

    if (!isEqual(categoriesData, prevProps.categories.categoriesData)) {
      this.setState({ filteredCategories: categoriesData });
    }

    if (!isEqual(subCategoriesData, prevProps.subCategories.subCategoriesData)) {
      this.setState({ filteredSubCategories: subCategoriesData });
    }
  };

  filter = (value) => {
    const {
      categories: { categoriesData },
      subCategories: { subCategoriesData },
    } = this.props;

    const { activeTab } = this.state;

    const nameFilter = items => items
      .filter(item => item.name.toLowerCase().startsWith(value.toLowerCase()));

    switch (activeTab) {
      case 0:
        this.setState({
          filteredCategories: nameFilter(categoriesData),
        });
        break;
      case 1:
        this.setState({
          filteredSubCategories: nameFilter(subCategoriesData),
        });
        break;
      default:
        break;
    }
  };

  handleSave = async (func, values) => {
    await func(values);

    this.toggleModal();
  }

  handleTabChange = (event, value) => {
    const {
      categories: { categoriesData },
      subCategories: { subCategoriesData },
    } = this.props;

    this.setState({
      activeTab: value,
      filteredCategories: categoriesData,
      filteredSubCategories: subCategoriesData,
    });
  };

  toggleModal = () => {
    const { activeTab, categoryModal, subCategoryModal } = this.state;

    switch (activeTab) {
      case 0:
        this.setState({ categoryModal: !categoryModal });
        break;
      case 1:
        this.setState({ subCategoryModal: !subCategoryModal });
        break;
      default:
        break;
    }
  };

  render() {
    const {
      categories: { categoriesData, categoriesLoading },
      subCategories: { subCategoriesData, subCategoriesLoading },
      onCategoryClick,
      onCategoriesSort,
      onSubCategoryClick,
      onSubCategoriesSort,
      onAddCategorySave,
      onAddSubCategorySave,
      onAddCategoryUpdate,
      onAddSubCategoryUpdate,
      classes,
      loadMoreCategories,
      loadMoreSubCategories,
      totalCountCategories,
      totalCountSubCategories,
    } = this.props;

    const {
      activeTab,
      categoryModal,
      subCategoryModal,
      filteredCategories,
      filteredSubCategories,
    } = this.state;

    return (
      <ScreenContainer>
        <Grid container>
          <Grid item xs={6}>
            <Tabs indicatorColor="primary" value={activeTab} onChange={this.handleTabChange}>
              <Tab label="Categories" />
              <Tab label="Sub-Categories" />
            </Tabs>
          </Grid>
          <Grid item xs={6} className={classes.search}>
            <SearchBox onSearch={this.filter} />
            <Button
              variant="contained"
              color="primary"
              className={classes.button}
              onClick={this.toggleModal}
            >
              Add
            </Button>
          </Grid>
        </Grid>
        <TableContainer>
          {activeTab === 0 && (
            <CategoriesTable
              data={filteredCategories}
              loading={categoriesLoading}
              onItemClick={onCategoryClick}
              onCategoriesSort={onCategoriesSort}
              loadMore={loadMoreCategories}
              totalCount={totalCountCategories}
            />
          )}
          {activeTab === 1 && (
            <SubCategoriesTable
              data={filteredSubCategories}
              loading={subCategoriesLoading}
              onItemClick={onSubCategoryClick}
              onSubCategoriesSort={onSubCategoriesSort}
              loadMore={loadMoreSubCategories}
              totalCount={totalCountSubCategories}
            />
          )}
        </TableContainer>
        <AddCategoryModal
          open={categoryModal}
          onSave={values => this.handleSave(onAddCategorySave, values)}
          onUpdate={onAddCategoryUpdate}
          onCancel={this.toggleModal}
          subcategories={subCategoriesData}
        />
        <AddSubCategoryModal
          open={subCategoryModal}
          onSave={values => this.handleSave(onAddSubCategorySave, values)}
          onUpdate={onAddSubCategoryUpdate}
          onCancel={this.toggleModal}
          categories={categoriesData}
        />
      </ScreenContainer>
    );
  }
}

const TableContainer = styled.div`
  margin-top: 3rem;
`;

export default withStyles(theme => ({
  search: {
    textAlign: 'end',
  },
  button: {
    marginLeft: theme.spacing.unit * 2,
    width: '100px',
  },
}))(ContentScreen);
