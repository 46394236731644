import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid, Tab, Tabs, Button,
} from '@material-ui/core';
import moment from 'moment-timezone';
import { ScreenContainer } from '../../components/layout';
import { SearchBox, DateRangePicker, DatePicker } from '../../components/forms';
import { PaymentsDueTable, SubscriptionPaymentsTable } from '../../components/tables';
import { PaymentDetailsModal, PaymentsDueModal } from '../../components/modals';
import { date } from '../../utils';

class PaymentsScreen extends Component {
  static propTypes = {
    subscriptions: PropTypes.shape({
      data: PropTypes.array,
      loading: PropTypes.bool,
      totalCount: PropTypes.number,
    }),
    paymentsDue: PropTypes.shape({
      data: PropTypes.array,
      loading: PropTypes.bool,
      totalCount: PropTypes.number,
    }),
    loadMore: PropTypes.shape({
      subscriptions: PropTypes.func,
      paymentsDue: PropTypes.func,
    }),
    onSearch: PropTypes.shape({
      subscriptions: PropTypes.func,
      paymentsDue: PropTypes.func,
    }),
    onDateRangeUpdate: PropTypes.func,
    onPayment: PropTypes.func,
    onCancel: PropTypes.func,
    updateUser: PropTypes.func,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    subscriptions: {
      data: [],
      loading: false,
      totalCount: 0,
    },
    paymentsDue: {
      data: [],
      loading: false,
      totalCount: 0,
    },
    loadMore: {
      subscriptions: () => {},
      paymentsDue: () => {},
    },
    onSearch: {
      subscriptions: () => {},
      paymentsDue: () => {},
    },
    onDateRangeUpdate: () => {},
    onPayment: () => {},
    onCancel: () => {},
    updateUser: () => {},
  };

  state = {
    activeTab: 0,
    startDate: new Date(date.firstDay()),
    endDate: new Date(date.lastDay()),
    detailsModal: false,
    paymentsDueModal: false,
    selectedItem: {},
    selectedItems: [],
    paymentIndex: 0,
    paymentsDueDate: moment().format('MM/DD/YYYY'),
    isPaying: false,
    subscriptionsPageInfo: 10,
  };

  nextPayment = () => {
    const { selectedItems, paymentIndex } = this.state;

    const selectedItem = selectedItems[paymentIndex];
    const paymentsDueModal = !!selectedItem;

    if (paymentsDueModal) {
      this.setState({
        paymentsDueModal,
        selectedItem,
        paymentIndex: paymentIndex + 1,
      });
    } else {
      this.closeModal();
    }
  }

  closeModal = () => {
    this.setState({
      detailsModal: false,
      paymentsDueModal: false,
      selectedItem: undefined,
      paymentIndex: 0,
      selectedItems: [],
    });
  }

  toggleModal = (selectedItem) => {
    const { activeTab, detailsModal, paymentsDueModal } = this.state;

    if (activeTab === 0) {
      this.setState({
        paymentsDueModal: !paymentsDueModal,
        selectedItem,
      });
    }

    if (activeTab === 1) {
      this.setState({
        detailsModal: !detailsModal,
        selectedItem,
      });
    }
  }

  updateSelectedItems = (selectedItems) => {
    this.setState({
      selectedItems,
    });
  }

  handleTabChange = (event, value) => this.setState({
    activeTab: value,
    selectedItems: [],
    paymentsDueDate: moment().format('MM/DD/YYYY'),
  });

  handleDate = (startDate, endDate) => {
    const { onDateRangeUpdate } = this.props;

    onDateRangeUpdate({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });

    this.setState({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  };

  handlePaymentsDueDate = (paymentsDueDate) => {
    const { onSearch } = this.props;

    onSearch.paymentsDue({ payUntil: moment(paymentsDueDate).format('YYYY-MM-DD') });

    this.setState({
      paymentsDueDate: paymentsDueDate.toDate(),
    });
  };

  handleSearch = (term) => {
    const { activeTab } = this.state;
    const { onSearch } = this.props;

    if (activeTab === 0) {
      onSearch.paymentsDue({ term });
    }

    if (activeTab === 1) {
      onSearch.subscriptions({ term });
    }
  };

  handleSort = (sortColumn, sortOrder) => {
    const { activeTab, subscriptionsPageInfo } = this.state;
    const { onSearch } = this.props;

    if (activeTab === 0) {
      onSearch.paymentsDue({ sortColumn, sortOrder });
    }

    if (activeTab === 1) {
      onSearch.subscriptions({ sortColumn, sortOrder, first: subscriptionsPageInfo });
    }
  };

  handlePayment = async (item) => {
    const { onPayment } = this.props;

    this.setState({ isPaying: true });
    await onPayment(item);
    this.setState({ isPaying: false, paymentsDueDate: moment().format('MM/DD/YYYY') });

    this.nextPayment();
  }

  handleCancel = async (variables) => {
    const { onCancel, onSearch } = this.props;
    const { subscriptionsPageInfo } = this.state;
    await onCancel(variables);
    onSearch.subscriptions({ first: subscriptionsPageInfo });
  }

  updateSubscriptionsPageInfo = (pageInfo) => {
    this.setState({ subscriptionsPageInfo: pageInfo });
  }

  render() {
    const {
      activeTab,
      startDate,
      endDate,
      detailsModal,
      paymentsDueModal,
      selectedItem,
      selectedItems,
      paymentIndex,
      paymentsDueDate,
      isPaying,
    } = this.state;

    const {
      subscriptions,
      paymentsDue,
      loadMore,
      classes,
      updateUser,
    } = this.props;

    return (
      <ScreenContainer>
        <Grid container>
          <Grid item xs={6}>
            <Tabs
              value={activeTab}
              onChange={this.handleTabChange}
              indicatorColor="primary"
            >
              <Tab label="Payments Due" />
              <Tab label="Payments Made" />
            </Tabs>
          </Grid>
          <Grid item xs={6} className={classes.filters}>
            <SearchBoxWrapper>
              <SearchBox
                onSearch={this.handleSearch}
                placeholder="Search"
              />
            </SearchBoxWrapper>
            {activeTab === 0 && (
              <DatePicker
                onChange={this.handlePaymentsDueDate}
                date={paymentsDueDate}
                label="PAY UNTIL"
              />
            )}
            {activeTab === 1 && (
              <DateRangePicker
                onUpdate={this.handleDate}
                startDate={startDate}
                endDate={endDate}
                label="DATE RANGE"
              />
            )}
          </Grid>
        </Grid>
        <TableWrapper>
          {activeTab === 0 && (
            <>
              <PaymentsDueTable
                data={paymentsDue.data}
                loading={paymentsDue.loading}
                loadMore={loadMore.paymentsDue}
                totalCount={paymentsDue.totalCount}
                onSelect={this.updateSelectedItems}
                selected={selectedItems}
                onSort={this.handleSort}
              />
              <Grid container>
                <Grid item xs={12} className={classes.action}>
                  <Button
                    disabled={selectedItems.length === 0}
                    variant="contained"
                    onClick={this.nextPayment}
                    color="primary"
                  >
                    Pay All
                  </Button>
                </Grid>

              </Grid>
            </>
          )}
          {activeTab === 1 && (
            <SubscriptionPaymentsTable
              data={subscriptions.data}
              loading={subscriptions.loading}
              onItemClick={this.toggleModal}
              loadMore={loadMore.subscriptions}
              totalCount={subscriptions.totalCount}
              onSort={this.handleSort}
              updatePageInfo={this.updateSubscriptionsPageInfo}
            />
          )}
        </TableWrapper>
        <PaymentDetailsModal
          open={detailsModal}
          paymentDetail={selectedItem}
          onCancel={this.handleCancel}
          onClose={() => this.closeModal()}
        />
        <PaymentsDueModal
          open={paymentsDueModal}
          paymentsLength={selectedItems.length}
          paymentIndex={paymentIndex}
          paymentDetail={selectedItem}
          onPayment={this.handlePayment}
          onClose={() => this.closeModal()}
          paymentsDueDate={paymentsDueDate}
          updateUser={updateUser}
          isPaying={isPaying}
        />
      </ScreenContainer>
    );
  }
}

const SearchBoxWrapper = styled.div`
  margin-top: 6px;
  margin-right: 2rem;
`;

const TableWrapper = styled.div`
  margin-top: 3rem;
`;

export default withStyles({
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
  action: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
    marginTop: 20,
  },
})(PaymentsScreen);
