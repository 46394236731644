import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { notify, messages, pagination } from '../../utils';
import {
  FETCH_CATEGORIES,
  FETCH_SUBCATEGORIES,
  ADD_CATEGORY,
  ADD_SUBCATEGORY,
} from './queries';
import { mapCategories, mapSubCategories } from './mapping';
import ContentScreen from '../../screens/ContentScreen';

/**
 * Checks values provided against data returned from API
 * @param { object } values - Values to be check
 * @param { array } items - Array of items to check against
 * @returns { object } An object containing booleans for errors
 */
function checkValues(values, items) {
  const checks = {};

  items.forEach((item) => {
    if (item.name.toLowerCase() === values.title.toLowerCase()) {
      checks.title = true;
    }
  });

  return checks;
}

export const ContentScreenConnector = ({ history }) => {
  const categories = useQuery(FETCH_CATEGORIES, {
    fetchPolicy: 'network-only',
    onError: () => notify.error({ ...messages.FETCH_CATEGORIES.error }),
  });

  const subCategories = useQuery(FETCH_SUBCATEGORIES, {
    fetchPolicy: 'network-only',
    onError: () => notify.error({ ...messages.FETCH_SUBCATEGORIES.error }),
  });

  const [addCategory] = useMutation(ADD_CATEGORY, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: FETCH_CATEGORIES }],
    onCompleted: () => notify.success({ ...messages.ADD_CATEGORY.success }),
    onError: () => notify.error({ ...messages.ADD_CATEGORY.error }),
  });

  const [addSubCategory] = useMutation(ADD_SUBCATEGORY, {
    awaitRefetchQueries: true,
    refetchQueries: [{ query: FETCH_SUBCATEGORIES }],
    onCompleted: () => notify.success({ ...messages.ADD_SUBCATEGORY.success }),
    onError: () => notify.error({ ...messages.ADD_SUBCATEGORY.error }),
  });

  const mappedCategories = mapCategories(categories);
  const mappedSubCategories = mapSubCategories(subCategories);

  const { totalCountCategories } = mappedCategories;
  const { totalCountSubCategories } = mappedSubCategories;

  return (
    <ContentScreen
      categories={{ ...mappedCategories }}
      loadMoreCategories={values => pagination.fetchNextPage(categories, 'categories', values)}
      onCategoryClick={({ uuid }) => history.push(`/categories/${uuid}`)}
      onCategoriesSort={(column, order) => categories.refetch({
        sortColumn: column,
        sortOrder: order,
      })}
      subCategories={{ ...mappedSubCategories }}
      onSubCategoryClick={({ uuid }) => history.push(`/subcategories/${uuid}`)}
      onSubCategoriesSort={(column, order) => subCategories.refetch({
        sortColumn: column,
        sortOrder: order,
      })}
      loadMoreSubCategories={values => pagination.fetchNextPage(subCategories, 'subcategories', values)}
      onAddCategorySave={addCategory}
      onAddSubCategorySave={addSubCategory}
      onAddCategoryUpdate={values => checkValues(values, mappedCategories.categoriesData)}
      onAddSubCategoryUpdate={values => checkValues(
        values, mappedSubCategories.subCategoriesData,
      )}
      totalCountCategories={totalCountCategories}
      totalCountSubCategories={totalCountSubCategories}
    />
  );
};

ContentScreenConnector.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(ContentScreenConnector);
