const KEYS = {
  action: [13, 32],
};
export const actionKeyPressed = (callback, options = {}) => (ev) => {
  const { stopPropagation } = options;
  ev.preventDefault();

  if (stopPropagation) {
    ev.stopPropagation();
  }

  if (KEYS.action.includes(ev.keyCode)) {
    callback();
  }
};

export default {
  actionKeyPressed,
};
