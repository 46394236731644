import DashboardIcon from '@material-ui/icons/Dashboard';
import ContentIcon from '@material-ui/icons/FormatListBulleted';
import UsersIcon from '@material-ui/icons/Group';
import PaymentsIcon from '@material-ui/icons/CreditCard';
import NotificationsIcon from '@material-ui/icons/NotificationsOutlined';
import SupportIcon from '@material-ui/icons/NewReleases';
import LegalIcon from '@material-ui/icons/WorkOutline';
import ReportsIcon from '@material-ui/icons/InsertChartOutlined';
import PhotoLibraryIcon from '@material-ui/icons/PhotoLibrary';

export default (isAdmin) => {
  if (isAdmin) {
    return [
      {
        label: 'Dashboard',
        key: '/',
        icon: DashboardIcon,
      },
      {
        label: 'Content',
        key: '/content',
        icon: ContentIcon,
      },
      {
        label: 'Users',
        key: '/users',
        icon: UsersIcon,
      },
      {
        label: 'Payments',
        key: '/payments',
        icon: PaymentsIcon,
      },
      {
        label: 'Push Notifications',
        key: '/notifications',
        icon: NotificationsIcon,
      },
      {
        label: 'Support',
        key: '/support',
        icon: SupportIcon,
        items: [
          {
            label: 'Reported',
            key: '/reported',
          },
          {
            label: 'Influencer Requests',
            key: '/requests',
          },
        ],
      },
      {
        label: 'Legal',
        key: '/legal',
        icon: LegalIcon,
      },
      {
        label: 'Reports',
        key: '/reports',
        icon: ReportsIcon,
      },
      {
        label: 'holr Media',
        key: '/holr-media',
        icon: PhotoLibraryIcon,
      },
    ];
  }

  return [];
};
