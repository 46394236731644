import React from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';

// Connectors
import {
  LoginScreenConnector,
  DashboardConnector,
  PasswordRecoveryScreenConnector,
  PasswordResetScreenConnector,
} from './connectors';

export default () => (
  <BrowserRouter>
    <Switch>
      <Route exact path="/password-recovery" component={PasswordRecoveryScreenConnector} />
      <Route exact path="/password-reset" component={PasswordResetScreenConnector} />
      <Route exact path="/login" component={LoginScreenConnector} />
      <Route path="/" component={DashboardConnector} />
    </Switch>
  </BrowserRouter>
);
