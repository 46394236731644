import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Tab, Tabs } from '@material-ui/core';
import { ScreenContainer } from '../../components/layout';
import { InfluencerRequestsTable } from '../../components/tables';
import { InfluencerRequestModal } from '../../components/modals';
import { DateRangePicker } from '../../components/forms';
import { influencerRequestsStates } from '../../utils/constants';
import { date } from '../../utils';

const InfluencerRequestsScreen = ({
  influencerRequests,
  loading,
  onTabChange,
  onDateRangeUpdate,
  resolveRequest,
  loadMoreInfluencerRequest,
  totalCount,
  classes,
}) => {
  const [dateRange, setDateRange] = useState({
    startDate: new Date(date.firstDay()),
    endDate: new Date(date.lastDay()),
  });
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const [tab, setTab] = useState(0);

  function handleDateChange(startDate, endDate) {
    const dates = {
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    };

    onDateRangeUpdate(dates);
    setDateRange(dates);
  }

  function handleTabChange(_, value) {
    setTab(value);
    onTabChange(influencerRequestsStates[value]);
  }

  function handleTableClick(object) {
    setOpen(true);
    setData(object);
  }

  return (
    <ScreenContainer>
      <Grid container spacing={8}>
        <Grid item xs={9}>
          <Tabs value={tab} onChange={handleTabChange} indicatorColor="primary" textColor="primary">
            {influencerRequestsStates.map(state => <Tab label={state} />)}
          </Tabs>
        </Grid>
        <Grid item xs={3} className={classes.filters}>
          <DateRangePicker
            onUpdate={handleDateChange}
            startDate={dateRange.startDate}
            endDate={dateRange.endDate}
            label="Date Range"
          />
        </Grid>
      </Grid>
      <TableWrapper>
        <InfluencerRequestsTable
          data={influencerRequests}
          onItemClick={handleTableClick}
          loading={loading}
          loadMore={loadMoreInfluencerRequest}
          totalCount={totalCount}
        />
      </TableWrapper>
      <InfluencerRequestModal
        onResolve={(request) => {
          setOpen(false);
          resolveRequest(request);
        }}
        onCancel={() => setOpen(false)}
        open={open}
        data={data}
      />
    </ScreenContainer>
  );
};

InfluencerRequestsScreen.propTypes = {
  influencerRequests: PropTypes.array,
  loading: PropTypes.bool,
  resolveRequest: PropTypes.func,
  onTabChange: PropTypes.func,
  onDateRangeUpdate: PropTypes.func,
  loadMoreInfluencerRequest: PropTypes.func,
  totalCount: PropTypes.number,
  classes: PropTypes.object.isRequired,
};

InfluencerRequestsScreen.defaultProps = {
  influencerRequests: [],
  loading: false,
  resolveRequest: () => {},
  onTabChange: () => {},
  onDateRangeUpdate: () => {},
  loadMoreInfluencerRequest: () => {},
  totalCount: 0,
};

const TableWrapper = styled.div`
  padding-top: 2rem;
`;

export default withStyles({
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
})(InfluencerRequestsScreen);
