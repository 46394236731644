import React from 'react';
import styled from 'styled-components';
import graphNoDataImage from '../../../assets/images/graphNoDataImage.svg';
import theme from '../../../theme';

export const renderNoDataPlaceholder = () => (
  <PlaceholderWrapper>
    <PlaceholderText>Sorry, looks like there’s no content to display.</PlaceholderText>
    <PlaceholderText style={{ marginBottom: '44px' }}>Try with another date range!</PlaceholderText>
    <PlaceholderImage src={graphNoDataImage} />
  </PlaceholderWrapper>
);


const PlaceholderWrapper = styled.div`
  padding: 59px 0 56px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PlaceholderText = styled.div`
  color: ${theme.palette.primary.main};
  font-size: 24px;
  font-family: 'ArchivoNarrow-Bold';
`;

const PlaceholderImage = styled.img`
  width: 219px;
  height: 200px;
`;

export default renderNoDataPlaceholder;
