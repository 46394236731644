import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  // Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import theme from '../../../theme';
import { date } from '../../../utils';
import CollapsableCard from '../../widgets/CollapsableCard';
import DateRangePicker from '../../forms/DateRangePicker';

class UserGrowthChart extends React.Component {
  static propTypes = {
    // classes: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        unpaid: PropTypes.number,
        paid: PropTypes.number,
        influencers: PropTypes.number,
      }),
    ),
    loading: PropTypes.bool,
    onDateRangeUpdate: PropTypes.func,
    // onDownloadClick: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    loading: false,
    onDateRangeUpdate: () => {},
    // onDownloadClick: () => {},
  };

  state = {
    unpaid: true,
    paid: true,
    influencers: true,
    startDate: new Date(date.firstDay()),
    endDate: new Date(date.lastDay()),
  };

  handleChange = name => (event) => {
    this.setState({ [name]: event.target.checked });
  };

  handleDateChange = (startDate, endDate) => {
    const { onDateRangeUpdate } = this.props;

    onDateRangeUpdate({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });

    this.setState({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  };

  renderGraph = () => {
    const { data } = this.props;
    const { unpaid, paid, influencers } = this.state;

    return (
      <ResponsiveContainer minWidth={200} minHeight={250} id="user-growth-chart">
        <AreaChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          {influencers && (
            <Area
              type="monotone"
              animationDuration={1000}
              key="influencers"
              dataKey="influencers"
              stroke={theme.palette.common.graphYellow}
              fill={theme.palette.common.graphYellow}
            />
          )}
          {paid && (
            <Area
              type="monotone"
              animationDuration={1000}
              key="paid"
              dataKey="paid"
              stroke={theme.palette.common.graphOrange}
              fill={theme.palette.common.graphOrange}
            />
          )}
          {unpaid && (
            <Area
              type="monotone"
              animationDuration={1000}
              key="unpaid"
              dataKey="unpaid"
              stroke={theme.palette.common.graphRed}
              fill={theme.palette.common.graphRed}
            />
          )}
        </AreaChart>
      </ResponsiveContainer>
    );
  };

  renderContent = () => {
    const {
      data,
      loading,
    } = this.props;

    switch (true) {
      case loading:
        return (
          <CircularProgress thickness={5} size={80} />
        );
      case !data.length:
        return (
          <Typography variant="display1">Sorry, seems there&apos;s no content to display</Typography>
        );
      default:
        return this.renderGraph();
    }
  }

  render = () => {
    // const {
    //   data,
    //   loading,
    //   onDownloadClick,
    //   classes,
    // } = this.props;

    const {
      unpaid,
      paid,
      influencers,
      startDate,
      endDate,
    } = this.state;

    return (
      <CollapsableCard
        header={(
          <TitleContainer>
            <Typography variant="headline">User Growth</Typography>
            <DateRangePicker
              onUpdate={this.handleDateChange}
              startDate={startDate}
              endDate={endDate}
              label="Date Range"
            />
          </TitleContainer>
        )}
      >
        <GraphContainer>{this.renderContent()}</GraphContainer>
        <BottomContainer>
          {/* <Button
            onClick={onDownloadClick}
            color="primary"
            variant="contained"
            className={classes.button}
            disabled={!data.length || loading}
          >
            Download .PDF
          </Button> */}
          <TagContainer>
            <FormControlLabel
              control={(
                <Checkbox
                  checked={unpaid}
                  onChange={this.handleChange('unpaid')}
                  value="unpaid"
                  color="primary"
                />
              )}
              label="Unpaid"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={paid}
                  onChange={this.handleChange('paid')}
                  value="paid"
                  color="primary"
                />
              )}
              label="Paid"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  checked={influencers}
                  onChange={this.handleChange('influencers')}
                  value="influencers"
                  color="primary"
                />
              )}
              label="Influencers"
            />
          </TagContainer>
        </BottomContainer>
      </CollapsableCard>
    );
  };
}

const GraphContainer = styled.div`
  max-height: 237px;
  padding-top: 0.5rem;
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default withStyles({
  button: {
    padding: '8px 44px',
  },
})(UserGrowthChart);
