import { get } from 'lodash';
import { date } from '../../utils';

export function mapInfluencerRequests({ data, loading, error }) {
  if (loading || error) {
    return { data: [], totalCount: 0 };
  }

  try {
    return {
      data: data.influencerUpgradeRequests.edges.map(edge => ({
        ...edge.node,
        ...get(edge, 'node.user', {}),
        createdAt: date.lastUpdated(edge.node.createdAt),
        userState: get(edge, 'node.user.state', ''),
        state: edge.node.state,
        reason: edge.node.reason,
      })),
      totalCount: data.influencerUpgradeRequests.totalCount,
    };
  } catch (mapError) {
    return {
      data: [],
      totalCount: 0,
    };
  }
}

export default {
  mapInfluencerRequests,
};
