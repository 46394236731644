import { date } from '../../utils';

export function mapCategories({ data, loading, error }) {
  if (loading || error) {
    return {
      categoriesData: [],
      categoriesLoading: loading,
      categoriesError: error,
    };
  }

  try {
    return {
      categoriesData: data.categories.edges.map(edge => ({
        uuid: edge.node.uuid,
        name: edge.node.name,
        subcategories: edge.node.subcategoryCount,
        followers: edge.node.subscriberCount,
        holrs: edge.node.holrCount,
        lastUpdated: date.lastUpdated(edge.node.updatedAt),
      })),
      categoriesLoading: loading,
      categoriesError: error,
      totalCountCategories: data.categories.totalCount,
    };
  } catch (mapError) {
    return {
      categoriesData: [],
      categoriesLoading: loading,
      categoriesError: mapError,
    };
  }
}

export function mapSubCategories({ data, loading, error }) {
  if (loading || error) {
    return {
      subCategoriesData: [],
      subCategoriesLoading: loading,
      subCategoriesError: error,
    };
  }

  try {
    return {
      subCategoriesData: data.subcategories.edges.map(edge => ({
        uuid: edge.node.uuid,
        name: edge.node.name,
        parentCategory: edge.node.category.name,
        followers: edge.node.subscriberCount,
        holrs: edge.node.holrCount,
        lastUpdated: date.lastUpdated(edge.node.updatedAt),
      })),
      categoriesLoading: loading,
      categoriesError: error,
      totalCountSubCategories: data.subcategories.totalCount,
    };
  } catch (mapError) {
    return {
      categoriesData: [],
      categoriesLoading: loading,
      categoriesError: mapError,
    };
  }
}

export default {
  mapCategories,
  mapSubCategories,
};
