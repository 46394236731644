
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  FormControl,
  Input,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
  IconButton,
  Grid,
} from '@material-ui/core';
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { Formik, Form } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import ScreenContainer from '../../components/layout/ScreenContainer';
import ImagePicker from '../../components/forms/ImagePicker/ImagePicker';
import { InfoCard, Logo } from '../../components/widgets';
import {
  DiscardChangesConfirmModal,
  ConfirmDeleteSubcategoryModal,
} from '../../components/modals';
import { validate } from '../../utils';

export class SubCategoryScreen extends Component {
  static propTypes = {
    subCategory: PropTypes.shape({
      subCategoryData: PropTypes.shape({
        uuid: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        name: PropTypes.string,
        parentCategory: PropTypes.shape({
          uuid: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
          name: PropTypes.string,
        }),
        avatar: PropTypes.string,
        subscribers: PropTypes.number,
        subscriberStats: PropTypes.number,
        holrs: PropTypes.number,
        subscribersStats: PropTypes.number,
        holrStats: PropTypes.number,
        lastUpdated: PropTypes.string,
      }),
    }),
    categories: PropTypes.shape({
      categoriesData: PropTypes.arrayOf(
        PropTypes.shape({
          uuid: PropTypes.oneOfType([
            PropTypes.string,
            PropTypes.number,
          ]),
          name: PropTypes.string,
        }),
      ),
    }),
    onUpdate: PropTypes.func,
    onDelete: PropTypes.func,
    onReturnClick: PropTypes.func,
    updateLoading: PropTypes.bool,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    subCategory: {
      subCategoryData: {
        uuid: '',
        name: '',
        parentCategory: {
          uuid: '',
          name: '',
        },
        avatar: '',
        coverPhoto: '',
        subscribers: 0,
        holrs: 0,
        subscriberStats: 0,
        holrStats: 0,
        lastUpdated: '',
      },
    },
    categories: {
      categoriesData: [],
    },
    onUpdate: () => {},
    onDelete: () => {},
    onReturnClick: () => {},
    updateLoading: false,
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      isValid: false,
      hasChanged: false,
      deleteModal: false,
      discardModal: false,
    };
  }

  validation = (values) => {
    const { subCategory: { subCategoryData } } = this.props;
    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (!values.avatar) {
      errors.avatar = 'Required';
    }

    if (!values.parentCategory) {
      errors.parentCategory = 'Required';
    }

    const hasChanged = !Object.keys(values).every((key) => {
      if (key === 'parentCategory') {
        return isEqual(values[key], subCategoryData[key].uuid);
      }

      return isEqual(values[key], subCategoryData[key]);
    });

    this.setState({
      isValid: isEmpty(errors),
      hasChanged,
    });

    return errors;
  };

  getStats = (term) => {
    const {
      subCategory: {
        subCategoryData: {
          subscribers,
          subscriberStats,
          holrs,
          holrStats,
        },
      },
    } = this.props;

    const currentValue = term === 'subscribers' ? subscribers : holrs;
    const previousValue = term === 'subscribers' ? subscriberStats : holrStats;
    const value = currentValue - previousValue;

    switch (true) {
      case value > 0 && term === 'subscribers':
        return `${value} more than last month`;
      case value < 0 && term === 'subscribers':
        return `${Math.abs(value)} less than last month`;
      case value > 0 && term === 'holrs':
        return `Increased by ${value} from last month`;
      case value < 0 && term === 'holrs':
        return `Decreased by ${Math.abs(value)} from last month`;
      default:
        return 'Same as last month';
    }
  }

  toggleDeleteModal = () => {
    const { deleteModal } = this.state;

    this.setState({ deleteModal: !deleteModal });
  }

  toggleDiscardChangesModal = () => {
    const { discardModal } = this.state;

    this.setState({ discardModal: !discardModal });
  }

  handleSubmit = (values) => {
    const {
      subCategory: { subCategoryData },
      onUpdate,
    } = this.props;

    const variables = {
      uuid: subCategoryData.uuid,
    };

    Object.keys(values).forEach((key) => {
      if (values[key] !== subCategoryData[key]) {
        variables[key] = values[key];
      }
    });

    onUpdate({ variables });
  }

  handleDiscardChanges = () => {
    this.toggleDiscardChangesModal();
    this.form.current.resetForm();
    this.setState({ isValid: false });
  }

  renderForm = ({
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  }) => {
    const {
      classes,
      categories: {
        categoriesData,
      },
    } = this.props;

    return (
      <Form>
        <FormControl
          className={classes.formControl}
          error={touched.avatar && !!errors.avatar}
          fullWidth
        >
          <ImagePicker
            imageSrc={values.avatar}
            title="Avatar*"
            subtitle="(min 720 x 720 px)"
            onChange={(file) => {
              setFieldValue('avatar', file);
              setFieldTouched('avatar', true);
              validate.imageSize(file, 'avatar', (result) => {
                setFieldError('avatar', result);
              });
            }}
          />
          <FormHelperText>{touched.avatar && errors.avatar}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={touched.name && !!errors.name}
          className={classes.formControl}
        >
          <InputLabel htmlFor="name">Sub-Category Name*</InputLabel>
          <Input
            id="name"
            value={values.name}
            onBlur={handleBlur}
            onChange={handleChange}
            className={classes.input}
          />
          <FormHelperText>{touched.name && errors.name}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={touched.category && !!errors.category}
          className={classes.formControl}
        >
          <InputLabel htmlFor="parentCategory">Parent Category*</InputLabel>
          <Select
            name="parentCategory"
            id="parentCategory"
            value={values.parentCategory}
            onChange={handleChange}
            onBlur={handleBlur}
            className={classes.input}
          >
            {categoriesData.map(category => (
              <MenuItem
                key={category.uuid}
                value={category.uuid}
              >
                {category.name}
              </MenuItem>
            ))}
          </Select>
          <FormHelperText>{touched.category && errors.category}</FormHelperText>
        </FormControl>
      </Form>
    );
  }

  renderDeleteButton = () => {
    const {
      subCategory: {
        subCategoryData: {
          subscribers,
          holrs,
        },
      },
    } = this.props;

    if (subscribers || holrs
      || typeof subscribers === 'undefined'
      || typeof holrs === 'undefined'
    ) {
      return [];
    }

    return [{
      variant: 'contained',
      label: 'Delete',
      color: 'primary',
      onClick: this.toggleDeleteModal,
    }];
  }

  render() {
    const {
      subCategory: {
        subCategoryData,
      },
      onReturnClick,
      onDelete,
      updateLoading,
      classes,
    } = this.props;

    const {
      isValid,
      hasChanged,
      deleteModal,
      discardModal,
    } = this.state;

    return (
      <ScreenContainer
        withFooter
        footerProps={{
          description: {
            label: `Last updated on ${subCategoryData.lastUpdated}`,
          },
          rightButtons: [{
            variant: 'contained',
            label: 'Update',
            color: 'primary',
            onClick: () => this.form.current.submitForm(),
            disabled: !isValid || updateLoading,
          }, {
            variant: 'outlined',
            label: 'Cancel',
            color: 'primary',
            onClick: this.toggleDiscardChangesModal,
            disabled: !hasChanged,
          }],
          leftButtons: this.renderDeleteButton(),
        }}
      >
        <TitleContainer>
          <IconButton onClick={onReturnClick}>
            <NavigateBefore />
          </IconButton>
          <Typography
            align="left"
            variant="headline"
            className={classes.title}
          >
            {subCategoryData.name}
          </Typography>
        </TitleContainer>
        <Paper className={classes.paper}>
          <Container className={classes.form}>
            <Formik
              enableReinitialize
              initialValues={{
                name: subCategoryData.name || '',
                avatar: subCategoryData.avatar || '',
                parentCategory: (subCategoryData.parentCategory && subCategoryData.parentCategory.uuid) || '',
              }}
              onSubmit={this.handleSubmit}
              validate={this.validation}
              ref={this.form}
            >
              {props => this.renderForm(props)}
            </Formik>
            <Grid container className={classes.cards}>
              <Grid item lg={6} className={classes.card}>
                <InfoCard
                  type="Subscribers"
                  title={subCategoryData.subscribers}
                  subtitle={this.getStats('subscribers')}
                  subtitleIcon={<ArrowUpwardIcon />}
                  icon={<Logo style={{ width: 50, fill: '#d8d8d8' }} />}
                />
              </Grid>
              <Grid item lg={6} className={classes.card}>
                <InfoCard
                  type="holrs"
                  title={subCategoryData.holrs}
                  subtitle={this.getStats('holrs')}
                  subtitleIcon={<ArrowUpwardIcon />}
                  icon={<Logo style={{ width: 50, fill: '#d8d8d8' }} />}
                />
              </Grid>
            </Grid>
          </Container>
        </Paper>
        <ConfirmDeleteSubcategoryModal
          open={deleteModal}
          onCancelClick={this.toggleDeleteModal}
          onConfirmClick={onDelete}
        />
        <DiscardChangesConfirmModal
          open={discardModal}
          onCancelClick={this.toggleDiscardChangesModal}
          onConfirmClick={this.handleDiscardChanges}
        />
      </ScreenContainer>
    );
  }
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
`;

const Container = styled.div`
  width: 90%;
  min-width: 20rem;
  margin: 1rem;
`;

export default withStyles(theme => ({
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
  },
  title: {
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    marginTop: '1rem',
  },
  input: {
    width: theme.spacing.unit * 50,
  },
  cards: {
    marginTop: theme.spacing.unit * 2,
  },
  card: {
    marginBottom: theme.spacing.unit * 2,
  },
}))(SubCategoryScreen);
