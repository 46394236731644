import axios from 'axios';
import { headers, users } from '../../utils';
import { config } from '..';

const api = axios.create({
  baseURL: config.API_URL,
});

const authenticationHeader = (apiConfig) => {
  const {
    accessToken,
    client,
    uid,
  } = headers.get();

  /* eslint-disable no-param-reassign */
  apiConfig.headers = {
    /* eslint-disable no-useless-computed-key */
    ['access-token']: accessToken,
    client,
    uid,
  };

  return apiConfig;
};

const errorHandler = (error) => {
  // If there's an authentication error
  // Logout and go back to login
  if (error.request.status === 401 && error.config.method.toUpperCase() !== 'OPTIONS') {
    headers.clear();
    users.remove();
    window.location.reload(true);
  }

  return Promise.reject(error);
};

api.interceptors.request.use(authenticationHeader, errorHandler);

export default api;
