import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Grid,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';

const responseOptions = [
  'Your reason was sufficient, we banned this user',
  'We are looking into this case',
  'This reason is insufficient',
];

const ReportedUserModal = ({
  open,
  onCancel,
  onResolve,
  onBanButtonClick,
  onUnbanButtonClick,
  classes,
  data,
}) => (
  <Formik
    initialValues={{ ...data, response: '' }}
    enableReinitialize
  >
    {({
      values,
      handleChange,
      handleBlur,
    }) => (
      <Dialog maxWidth="lg" open={open} onClose={onCancel}>
        <DialogTitle className={classes.title}>
          Reported User
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Form>
            <Grid container spacing={16}>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled
                  label="Offender"
                  name="offender"
                  value={`@${values.offender}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled
                  label="Reported By"
                  name="reportedBy"
                  value={`@${values.reporter}`}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled
                  label="Reason"
                  name="reason"
                  value={values.reason}
                  onChange={handleChange}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  fullWidth
                  disabled
                  label="Reported Date"
                  name="createdAt"
                  value={values.createdAt}
                  onChange={handleChange}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={12}>
                <FormControl fullWidth className={classes.formControl}>
                  <InputLabel htmlFor="response-select">Response</InputLabel>
                  <Select
                    value={values.response}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    disabled={values.state === 'Resolved'}
                    inputProps={{
                      name: 'response',
                      id: 'response-select',
                    }}
                  >
                    {responseOptions.map(reason => (
                      <MenuItem value={reason} key={reason}>
                        {reason}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Admin Notes"
                  name="adminNotes"
                  value={values.adminNotes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  disabled={values.state === 'Resolved'}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={
                    values.banned
                      ? () => onUnbanButtonClick({ variables: { uuid: data.offenderUuid } })
                      : onBanButtonClick
                  }
                  variant="outlined"
                  color={values.banned ? 'primary' : 'default'}
                  className={classes.button}

                >
                  {values.banned ? 'Unban' : 'Ban'}
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Typography className={classes.statusText} variant="subheading">
                  {`Status: ${values.state}`}
                </Typography>
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          {values.state !== 'Resolved' && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => onResolve(values)}
            >
              Resolve
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )}
  </Formik>
);

ReportedUserModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onBanButtonClick: PropTypes.func,
  onUnbanButtonClick: PropTypes.func,
  onResolve: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.shape({
    offenderUuid: PropTypes.string,
    offender: PropTypes.string,
    reporter: PropTypes.string,
    reason: PropTypes.string,
    createdAt: PropTypes.string,
    response: PropTypes.string,
    adminNotes: PropTypes.string,
    banned: PropTypes.bool,
    state: PropTypes.string,
  }),
};

ReportedUserModal.defaultProps = {
  onBanButtonClick: () => {},
  onUnbanButtonClick: () => {},
  onResolve: () => {},
  onCancel: () => {},
  open: false,
  data: {},
};

export default withStyles(theme => ({
  title: {
    borderBottom: '1px solid #949494',
  },
  content: {
    width: '40rem',
    marginTop: '1rem',
  },
  actions: {
    borderTop: '1px solid #949494',
    paddingTop: '1rem',
  },
  statusText: {
    fontSize: '1.4rem',
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
}))(ReportedUserModal);
