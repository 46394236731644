import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import RevenuesReportCard from './RevenuesReportCard';
import { REVENUE_STATS } from './queries';
import { downloadCsv } from '../../../utils/csv';

const initialDates = { startDate: moment().subtract(1, 'years'), endDate: moment() };

const datasetsBase = [
  {
    key: 'holrRevenueApple',
    label: 'holr Rev - Apple',
    type: 'bar',
    stack: 'Apple',
    backgroundColor: '#FFAE00',
  },
  {
    key: 'storeRevenueApple',
    label: 'App Store Rev - Apple',
    type: 'bar',
    stack: 'Apple',
    backgroundColor: '#F68F00',
  },
  {
    key: 'influencerRevenueApple',
    label: 'Influencers Rev - Apple',
    type: 'bar',
    stack: 'Apple',
    backgroundColor: '#FDCD69',
  },
  {
    key: 'holrRevenueGoogle',
    label: 'holr Rev - Google',
    type: 'bar',
    stack: 'Google',
    backgroundColor: '#BD4000',
  },
  {
    key: 'storeRevenueGoogle',
    label: 'App Store Rev - Google',
    type: 'bar',
    stack: 'Google',
    backgroundColor: '#973300',
  },
  {
    key: 'influencerRevenueGoogle',
    label: 'Influencers Rev - Google',
    type: 'bar',
    stack: 'Google',
    backgroundColor: '#E64F00',
  },
];

export const connect = Component => (props) => {
  // eslint-disable-next-line
  const { data, refetch } = useQuery(REVENUE_STATS, {
    variables: { startDate: initialDates.startDate, endDate: initialDates.endDate, groupingType: 'CUMULATIVE' },
  });

  const handleChange = (dateRange, dataType) => refetch({ ...dateRange, groupingType: dataType });

  const parseData = () => {
    if (!(data && data.revenueStats && data.revenueStats.stats)) return null;
    const labels = data.revenueStats.stats.map(({ date }) => moment(date).format('MMM'));
    const datasets = datasetsBase.map(({
      key, label, type, stack, backgroundColor,
    }) => ({
      label,
      type,
      stack,
      backgroundColor,
      data: data.revenueStats.stats.map(stat => Number(stat[key]).toFixed(2)),
    }));
    return ({
      labels,
      datasets,
    });
  };

  const headers = [{
    date: 'Date',
    holrRevenueApple: 'holr Revenue - Apple',
    holrRevenueGoogle: 'holr Revenue - Google',
    influencerRevenueApple: 'Influencer Revenue - Apple',
    influencerRevenueGoogle: 'Influencer Revenue - Google',
    storeRevenueApple: 'Store Revenue - Apple',
    storeRevenueGoogle: 'Store Revenue - Google',
  }];

  return (
    <Component
      onChange={handleChange}
      data={parseData()}
      onDownloadCsv={() => downloadCsv(data.revenueStats.stats, headers, 'Revenue')}
      {...props}
    />
  );
};

export default connect(RevenuesReportCard);
