import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Grid from '@material-ui/core/Grid';
import {
  DashboardCarouselCard,
  DashboardNotificationCard,
  DashboardSubscribersChart,
  DashboardTableCard,
} from '../../components/widgets';
import ScreenContainer from '../../components/layout/ScreenContainer';

class DashboardScreen extends Component {
  static propTypes = {
    dashboardSummary: PropTypes.shape({
      dashboardSummaryData: PropTypes.shape({
        influencerRequests: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
        reportedHolr: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
        reportedHolrback: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
        reportedUsers: PropTypes.oneOfType([
          PropTypes.number,
          PropTypes.string,
        ]),
      }),
      dashboardSummaryLoading: PropTypes.bool,
      dashboardSummaryError: PropTypes.object,
      onInfluencerRequestsReviewClick: PropTypes.func,
      onReportedHolrReviewClick: PropTypes.func,
      onReportedHolrbackReviewClick: PropTypes.func,
      onReportedUsersReviewClick: PropTypes.func,
    }),
    topInfluencers: PropTypes.shape({
      topInfluencersData: PropTypes.array,
      topInfluencersLoading: PropTypes.bool,
      topInfluencersError: PropTypes.object,
    }),
    topHolrs: PropTypes.shape({
      topHolrsData: PropTypes.array,
      topHolrsLoading: PropTypes.bool,
      topHolrsError: PropTypes.object,
    }),
    topCategories: PropTypes.shape({
      topCategoriesData: PropTypes.array,
      topCategoriesLoading: PropTypes.bool,
      topCategoriesError: PropTypes.object,
    }),
    subscriberStats: PropTypes.shape({
      subscriberStatsData: PropTypes.array,
      subscriberStatsLoading: PropTypes.bool,
      subscriberStatsError: PropTypes.object,
    }),
    onTopInfluencersClick: PropTypes.func,
    onTopHolrsClick: PropTypes.func,
    onTopCategoriesClick: PropTypes.func,
  };

  static defaultProps = {
    dashboardSummary: {
      dashboardSummaryData: {
        influencerRequests: 0,
        reportedHolr: 0,
        reportedHolrback: 0,
        reportedUsers: 0,
      },
      dashboardSummaryLoading: false,
      dashboardSummaryError: null,
      onInfluencerRequestsReviewClick: () => {},
      onReportedHolrReviewClick: () => {},
      onReportedHolrbackReviewClick: () => {},
      onReportedUsersReviewClick: () => {},
    },
    topInfluencers: {
      topInfluencersData: [],
      topInfluencersLoading: false,
      topInfluencersError: null,
    },
    topHolrs: {
      topHolrsData: [],
      topHolrsLoading: false,
      topHolrsError: null,
    },
    topCategories: {
      topCategoriesData: [],
      topCategoriesLoading: false,
      topCategoriesError: null,
    },
    subscriberStats: {
      subscriberStatsData: [],
      subscriberStatsLoading: false,
      subscriberStatsError: null,
    },
    onTopInfluencersClick: () => {},
    onTopHolrsClick: () => {},
    onTopCategoriesClick: () => {},
  }

  renderNotifications = () => {
    const {
      dashboardSummary: {
        dashboardSummaryData: {
          influencerRequests,
          reportedHolr,
          reportedHolrback,
          reportedUsers,
        },
        dashboardSummaryLoading,
        onInfluencerRequestsReviewClick,
        onReportedHolrReviewClick,
        onReportedHolrbackReviewClick,
        onReportedUsersReviewClick,
      },
    } = this.props;

    if (!influencerRequests && !reportedHolr && !reportedHolrback && !reportedUsers) {
      return null;
    }

    return (
      <DashboardCarouselCard
        title="Notifications"
        loading={dashboardSummaryLoading}
      >
        {!!influencerRequests && (
          <DashboardNotificationCard
            type="Requested"
            title={`${influencerRequests} Influencer${influencerRequests > 1 ? 's' : ''}`}
            subtitle="Please review the fans requesting to become influencers"
            onReviewClick={onInfluencerRequestsReviewClick}
          />
        )}
        {!!reportedHolr && (
          <DashboardNotificationCard
            type="Reported"
            title={`${reportedHolr} holr${reportedHolr > 1 ? 's' : ''}`}
            subtitle="Please review the reported holrs"
            onReviewClick={onReportedHolrReviewClick}
          />
        )}
        {!!reportedHolrback && (
          <DashboardNotificationCard
            type="Reported"
            title={`${reportedHolrback} holrback${reportedHolrback > 1 ? 's' : ''}`}
            subtitle="Please review the reported holrbacks"
            onReviewClick={onReportedHolrbackReviewClick}
          />
        )}
        {!!reportedUsers && (
          <DashboardNotificationCard
            type="Reported"
            title={`${reportedUsers} User${reportedUsers > 1 ? 's' : ''}`}
            subtitle="Please review the reported users"
            onReviewClick={onReportedUsersReviewClick}
          />
        )}
      </DashboardCarouselCard>
    );
  }

  render() {
    const {
      topInfluencers: {
        topInfluencersData,
        topInfluencersLoading,
      },
      topHolrs: {
        topHolrsData,
        topHolrsLoading,
      },
      topCategories: {
        topCategoriesData,
        topCategoriesLoading,
      },
      subscriberStats: {
        subscriberStatsData,
        subscriberStatsLoading,
      },
      onTopInfluencersClick,
      onTopHolrsClick,
      onTopCategoriesClick,
    } = this.props;

    return (
      <ScreenContainer>
        <Grid container spacing={24}>
          <Grid item sm={12}>
            {this.renderNotifications()}
          </Grid>
          <Grid item sm={12} md={6}>
            <DashboardTableCard
              type="influencers"
              data={topInfluencersData}
              loading={topInfluencersLoading}
              onItemClick={onTopInfluencersClick}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <DashboardSubscribersChart
              data={subscriberStatsData}
              loading={subscriberStatsLoading}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <DashboardTableCard
              type="holrs"
              data={topHolrsData}
              loading={topHolrsLoading}
              onItemClick={onTopHolrsClick}
            />
          </Grid>
          <Grid item sm={12} md={6}>
            <DashboardTableCard
              type="categories"
              data={topCategoriesData}
              loading={topCategoriesLoading}
              onItemClick={onTopCategoriesClick}
            />
          </Grid>
        </Grid>
      </ScreenContainer>
    );
  }
}

export default DashboardScreen;
