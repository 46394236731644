import React, { useState, useEffect } from 'react';
import { isEmpty } from 'lodash';
import UploadFileModal from './UploadFileModal';
import { CREATE_IMPORTED_MEDIUM } from './mutations';
import { notify, messages } from '../../../utils';
import graphqlClient from '../../../config/api/graphql';
import steps from './steps';

const { FILE_SELECTION, UPLOAD_IN_PROGRESS } = steps;
const compressedFileFormats = ['zip', 'rar', 'gz'];

export const connect = Component => (props) => {
  const {
    // eslint-disable-next-line react/prop-types
    uuid, onClose, onUploadCompletion, onAddTag, droppedFiles,
  } = props;
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isUploadCompleted, setIsUploadCompleted] = useState(false);
  const [currentStep, setCurrentStep] = useState(FILE_SELECTION);

  const handleFileSelection = files => setSelectedFiles(files.map(file => ({
    file,
    progress: 0,
    error: undefined,
    importedMedia: [],
    ignoredFiles: [],
  })));

  useEffect(() => {
    // eslint-disable-next-line react/prop-types
    if (droppedFiles.length) handleFileSelection(droppedFiles);
  }, [droppedFiles]);

  const parseFile = file => ({
    userUuid: uuid,
    file,
    tags: [],
  });

  const parseResponseMedia = importedMedia => importedMedia.map(media => ({
    ...media,
    tags: media.tags.map(tag => tag.name),
  }));

  const handleUpdateData = (index, field, value) => {
    const updatedSelectedFiles = [...selectedFiles];
    updatedSelectedFiles[index][field] = value;
    setSelectedFiles(updatedSelectedFiles);
  };

  const handleUploadCompletion = () => {
    if (selectedFiles.some(({ progress, importedMedia, ignoredFiles }) => (
      progress < 100
      || (isEmpty(ignoredFiles) && importedMedia.length === 0)
    ))) return;
    let allImportedMedia = [];
    selectedFiles.forEach((selectedFile) => {
      allImportedMedia = allImportedMedia.concat(selectedFile.importedMedia);
    });
    onUploadCompletion(parseResponseMedia(allImportedMedia));
    setIsUploadCompleted(true);
    notify.success({ ...messages.CREATE_MEDIUM.success });
  };

  const handleSingleUpload = async (file, index) => {
    try {
      const {
        data: { createImportedMedium: { ignoredFiles, importedMedia } },
      } = await graphqlClient.mutate({
        variables: parseFile(file),
        mutation: CREATE_IMPORTED_MEDIUM,
        context: {
          fetchOptions: {
            onUploadProgress: (p => handleUpdateData(index, 'progress', (p.loaded / p.total) * 100)),
          },
        },
      });
      handleUpdateData(index, 'importedMedia', importedMedia);
      handleUpdateData(index, 'ignoredFiles', ignoredFiles);
      if (ignoredFiles.length && !compressedFileFormats.includes(file.name.split('.').slice(-1)[0])) handleUpdateData(index, 'error', ignoredFiles[0].reason);
      handleUploadCompletion();
    } catch (error) {
      handleUpdateData(index, 'error', 'Upload Failed');
      setIsUploadCompleted(true);
    }
  };

  const handleUpload = async () => {
    setCurrentStep(UPLOAD_IN_PROGRESS);
    selectedFiles.forEach((selectedFile, index) => handleSingleUpload(selectedFile.file, index));
  };

  const handleClose = () => {
    onClose();
    setCurrentStep(FILE_SELECTION);
    setSelectedFiles([]);
    setIsUploadCompleted(false);
  };

  const handleAddTag = () => {
    setCurrentStep(FILE_SELECTION);
    setSelectedFiles([]);
    setIsUploadCompleted(false);
    onAddTag();
  };

  return (
    <Component
      {...props}
      onFileSelection={handleFileSelection}
      selectedFiles={selectedFiles}
      onClickNext={handleUpload}
      currentStep={currentStep}
      isUploadCompleted={isUploadCompleted}
      onAddTag={handleAddTag}
      onClose={handleClose}
    />
  );
};

export default connect(UploadFileModal);
