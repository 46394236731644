import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid, Button } from '@material-ui/core';
import styled from 'styled-components';
import { Query } from '@apollo/react-components';
import { useQuery, useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import { isEmpty } from 'lodash';
import { ScreenContainer } from '../../components/layout';
import { SearchBox } from '../../components/forms';
import { notify, messages, pagination } from '../../utils';
import HolrMediaTable from '../../components/tables/HolrMediaTable';
import withAuth from '../../contexts/AuthContext';
import {
  AddTagModal, MediumDetailsModal, UploadFileModal, AddUploadedTagsModal, DeleteConfirmationModal,
} from '../../components/modals';
import mediaBackIcon from '../../assets/images/mediaBackIcon.svg';
import mediaListIconActive from '../../assets/images/mediaListIconActive.svg';
import mediaListIconInactive from '../../assets/images/mediaListIconInactive.svg';
import mediaGridIconActive from '../../assets/images/mediaGridIconActive.svg';
import mediaGridIconInactive from '../../assets/images/mediaGridIconInactive.svg';

export const FETCH_USER_MEDIUM = gql`
  query UserMedia($uuid: ID!, $first: Int, $after: String, $query: String, $sortColumn: ImportedMediumSortColumnsEnum, $sortOrder: SortOrderEnum = DESCENDING) {
    importedMedia (userUuid: $uuid, query: $query, first:$first, after: $after, sortColumn: $sortColumn, sortOrder: $sortOrder){
      edges{
        node {
          createdAt,
          uuid,
          name,
          original{
            contentType
          },
          lowQualityThumbnail {
            contentType,
            url
          },
          tags (sortOrder: $sortOrder) {
            name,
            uuid
          }
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const UPDATE_USER_MEDIUM = gql`
  mutation UpdateMedium($uuid: ID!, $name: String!,  $tags: [String!]!){
    updateImportedMedium(uuid: $uuid, name: $name, tags: $tags){
      importedMedium {
        name,
        uuid,
        tags{
          name,
          uuid
        }
      }
    }
  }
`;

export const DELETE_USER_MEDIUM = gql`
  mutation DeleteMedium ($uuid: ID!){
    deleteImportedMedium(uuid: $uuid){
      success
    }
  }
`;

export const FETCH_USER = gql`
  query FetchUser ($uuid: ID!){
    user(uuid: $uuid){
      uuid
      fullName
      username
    }
  }
`;

export const FETCH_LATEST_MEDIA_TIME = gql`
  query LatestMediaTime ($userUuid: ID!){
    latestImportedMediaTime(userUuid: $userUuid)
  }
`;

function mapMedia({ error, data, loading }) {
  if (loading || error) {
    return { data: [], loading, error };
  }

  try {
    return {
      data: data.importedMedia.edges.map(edge => ({ ...edge.node })),
      loading,
      error,
      totalCount: data.importedMedia.totalCount,
    };
  } catch (mapError) {
    return { data: [], loading, error: mapError };
  }
}

const SearchBoxWrapper = styled.div`
  margin-top: 6px;
  margin-right: 2rem;
`;

const TableWrapper = styled.div`
  margin-top: 3rem;
`;

const BackButton = styled.button`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: none;
  padding: 0;
  background: transparent;
  cursor: pointer;

  &:hover span {
    color: #000;
  }
`;

const BackButtonText = styled.span`
  color: #adadad;
  font-size: 15px;
  font-weight: 500;
  margin-left: 10px;
  transition: all 300ms ease;
  font-family: 'Raleway-Regular';
`;

const BackIcon = styled.img`
  width: 22px;
  height: 22px;
  cursor: pointer;
`;

const GridIcon = styled.img`
  width: 19px;
  height: 22px;
  cursor: pointer;
  margin-right: 13px;
`;

const ListIcon = styled.img`
  width: 34px;
  height: 22px;
  cursor: pointer;
`;

const HolrMedisList = ({
  history,
  data,
  classes,
  totalCount,
  refetch,
  uuid,
  nextPage,
  loading,
  user,
}) => {
  const [tableMode, setTableMode] = useState('LIST');
  const [search, setSearch] = useState('');
  const [selected, setSelected] = useState([]);
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [detailMedium, setDetailMedium] = useState(undefined);
  const [addTagDialogOpen, setAddTagDialogOpen] = useState(false);
  const [uploadFileDialogOpen, setUploadFileDialogOpen] = useState(false);
  const [addUploadedTagsDialogOpen, setAddUploadedTagsDialogOpen] = useState(false);
  const [deleteConfirmationDialogOpen, setDeleteConfirmationDialogOpen] = useState(false);
  const [deleteUuids, setDeleteUuids] = useState([]);
  const [droppedFiles, setDroppedFiles] = useState([]);
  const [updateMedium] = useMutation(UPDATE_USER_MEDIUM, {
    awaitRefetchQueries: false,
    onCompleted: () => notify.success({ ...messages.UPDATE_MEDIUM.success }),
    onError: () => notify.error({ ...messages.UPDATE_MEDIUM.error }),
  });

  const [deleteMedium] = useMutation(DELETE_USER_MEDIUM, {
    awaitRefetchQueries: false,
    onCompleted: () => notify.success({ ...messages.DELETE_MEDIUM.success }),
    onError: () => notify.error({ ...messages.DELETE_MEDIUM.error }),
  });

  const { data: dataCurrentUser } = useQuery(FETCH_USER, {
    variables: { uuid },
  });

  const {
    data: dataLatestMediaTime,
    refetch: refetchMediaTime,
  } = useQuery(FETCH_LATEST_MEDIA_TIME, {
    variables: { userUuid: uuid },
  });

  const handleDeleteMedium = async (id) => {
    await deleteMedium({ variables: { uuid: id } });
  };

  const handleDelete = (uuids) => {
    uuids.forEach(async (id) => {
      await handleDeleteMedium(id);
      refetch({});
    });
    refetchMediaTime();
  };

  const handleTag = () => {
    setAddTagDialogOpen(true);
  };

  const handleUpdateMedium = async (medium) => {
    await updateMedium({
      variables: { ...medium },
    });
  };

  const handleTagsUpdate = async (mediums, tags) => {
    for (let index = 0; index < mediums.length; index += 1) {
      const medium = mediums[index];
      const mediumTags = [...medium.tags, ...tags].map(({ name }) => name);
      setAddTagDialogOpen(false);

      // eslint-disable-next-line no-await-in-loop
      await handleUpdateMedium({ uuid: medium.uuid, name: medium.name, tags: mediumTags });
    }
    refetchMediaTime();
    refetch();
  };

  const handleOnAddTag = () => {
    setUploadFileDialogOpen(false);
    setAddUploadedTagsDialogOpen(true);
  };

  const handleUploadModalClose = () => {
    setUploadFileDialogOpen(false);
    refetchMediaTime();
    setUploadedMedia([]);
  };

  const handleUploadedTagsModalClose = () => {
    setAddUploadedTagsDialogOpen(false);
    refetch();
    refetchMediaTime();
    setUploadedMedia([]);
  };

  const handleFileDrop = (files) => {
    setDroppedFiles(files);
    setUploadFileDialogOpen(true);
  };

  const handleDeleteUuids = (uuids) => {
    setDeleteUuids(uuids);
    setDeleteConfirmationDialogOpen(true);
  };

  const handleDeleteCancel = () => {
    setDeleteUuids([]);
    setDeleteConfirmationDialogOpen(false);
  };

  const handleDeleteConfirm = () => {
    handleDelete(deleteUuids);
    setDeleteUuids([]);
    setDeleteConfirmationDialogOpen(false);
  };

  const handleRemoveTag = async (medium) => {
    await handleUpdateMedium(medium);
    refetch();
  };

  useEffect(() => {
    const selectedIds = selected.map(item => item.uuid);
    const newSelectedData = data
      .filter(item => selectedIds.includes(item.uuid))
      .map(item => ({ uuid: item.uuid, name: item.name, tags: item.tags }));
    setSelected(newSelectedData);
  }, [data]);

  const isAdmin = !isEmpty(user) && !!user.adminRole;

  return (
    <>
      <ScreenContainer>
        <Grid container>
          <Grid item xs={6}>
            {isAdmin && (
              <BackButton onClick={() => history.push('/holr-media')}>
                <BackIcon src={mediaBackIcon} />
                <BackButtonText>holr Media</BackButtonText>
              </BackButton>
            )}
          </Grid>
        </Grid>
        <Grid container classes={{ container: classes.headerSeparation }}>
          <Grid
            direction="row"
            justify="flex-start"
            alignItems="center"
            container
            item
            xs={1}
          >
            <GridIcon src={tableMode === 'GRID' ? mediaGridIconActive : mediaGridIconInactive} onClick={() => setTableMode('GRID')} />
            <ListIcon src={tableMode === 'LIST' ? mediaListIconActive : mediaListIconInactive} onClick={() => setTableMode('LIST')} />
          </Grid>
          <Grid item xs={6}>
            <SearchBoxWrapper>
              <SearchBox
                onSearch={(query) => { setSearch(query); refetch({ query }); }}
                placeholder="Search"
                label=""
                withBoldPlaceholder
                isClearable
              />
            </SearchBoxWrapper>
          </Grid>
          <Grid
            item
            direction="row"
            justify="flex-end"
            alignItems="center"
            container
            xs={5}
          >
            <Button
              variant="contained"
              color="primary"
              className={classes.uploadFilesButton}
              onClick={() => setUploadFileDialogOpen(true)}
            >
              Upload
            </Button>
          </Grid>
        </Grid>
        <TableWrapper>
          <HolrMediaTable
            totalCount={totalCount}
            tableMode={tableMode}
            data={data}
            selected={selected}
            onSelectChange={setSelected}
            onItemPressed={itemId => setDetailMedium(itemId)}
            onRemoveTag={
              (item, tagId) => handleRemoveTag({
                ...item,
                tags: item.tags
                  .filter(tag => tag.uuid !== tagId)
                  .map(tag => tag.name),
              })
            }
            onItemDeletePressed={itemId => handleDeleteUuids([itemId])}
            onAddTagPressed={() => handleTag()}
            onDeletePressed={() => handleDeleteUuids(selected.map(item => item.uuid))}
            loading={loading}
            loadMore={values => nextPage(values)}
            onRequestSort={(sortColumn, sortOrder) => {
              refetch({ query: search, sortColumn, sortOrder });
            }}
            onFileDrop={handleFileDrop}
            onUploadFileClick={() => setUploadFileDialogOpen(true)}
            user={dataCurrentUser && dataCurrentUser.user}
            latestMediaTime={dataLatestMediaTime && dataLatestMediaTime.latestImportedMediaTime}
            search={search}
          />
        </TableWrapper>
      </ScreenContainer>
      <AddTagModal
        open={addTagDialogOpen}
        title="Add Tag"
        uuid={uuid}
        onSave={tags => handleTagsUpdate(selected, tags)}
        onCancel={() => setAddTagDialogOpen(false)}
      />
      <UploadFileModal
        open={uploadFileDialogOpen}
        onClose={handleUploadModalClose}
        onAddTag={() => handleOnAddTag()}
        onSaveClose={() => refetch({})}
        onUploadCompletion={setUploadedMedia}
        uuid={uuid}
        droppedFiles={droppedFiles}
      />
      <AddUploadedTagsModal
        open={addUploadedTagsDialogOpen}
        uuid={uuid}
        currentUploadedMedia={uploadedMedia}
        onClose={handleUploadedTagsModalClose}
      />
      <DeleteConfirmationModal
        open={deleteConfirmationDialogOpen}
        onConfirm={handleDeleteConfirm}
        onCancel={handleDeleteCancel}
        multiple={deleteUuids.length > 1}
      />
      <MediumDetailsModal
        title="File Details"
        open={!!detailMedium}
        uuid={detailMedium}
        onRemoveTag={
          (item, tagId) => handleUpdateMedium({
            ...item,
            tags: item.tags
              .filter(tag => tag.uuid !== tagId)
              .map(tag => tag.name),
          })
        }
        onAddTagPressed={(medium) => {
          setSelected([medium]);
          handleTag();
        }}
        onCancel={() => {
          setSelected([]);
          setDetailMedium();
          refetch();
        }}
      />
    </>
  );
};

HolrMedisList.propTypes = {
  history: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  totalCount: PropTypes.number.isRequired,
  refetch: PropTypes.func.isRequired,
  uuid: PropTypes.string.isRequired,
  nextPage: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  user: PropTypes.object.isRequired,
};

export const HolrMediaListScreen = ({
  history, match: { params }, classes, user,
}) => (
  <Query
    query={FETCH_USER_MEDIUM}
    variables={{ uuid: params.uuid }}
    fetchPolicy="network-only"
  >
    {(holrs) => {
      const { data, totalCount } = mapMedia(holrs);
      return (
        <HolrMedisList
          classes={classes}
          user={user}
          uuid={params.uuid}
          refetch={holrs.refetch}
          loading={holrs.loading}
          history={history}
          data={data}
          totalCount={totalCount}
          nextPage={value => pagination.fetchNextPage(holrs, 'importedMedia', value)}
        />
      );
    }}
  </Query>
);

HolrMediaListScreen.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withRouter(withAuth(withStyles(theme => ({
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
  button: {
    marginLeft: theme.spacing.unit * 2,
    width: '100px',
  },
  uploadFilesButton: {
    width: '143px',
    height: '36px',
    fontFamily: 'Raleway-SemiBold',
  },
  headerSeparation: {
    marginTop: '15px',
  },
}))(HolrMediaListScreen)));
