import React from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Search from '@material-ui/icons/Search';
import styled from 'styled-components';
import searchRemoveIcon from '../../../assets/images/searchRemoveIcon.svg';

class SearchBox extends React.Component {
  static propTypes = {
    onSearch: PropTypes.func,
    fullWidth: PropTypes.bool,
    withBoldPlaceholder: PropTypes.bool,
    isClearable: PropTypes.bool,
    placeholder: PropTypes.string,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onSearch: () => { },
    fullWidth: false,
    withBoldPlaceholder: false,
    isClearable: false,
    placeholder: 'Search',
  };

  state = { value: '' };

  handleChange = (value) => {
    const { onSearch } = this.props;

    this.setState({ value }, () => onSearch(value.toLowerCase()));
  }

  handleDelete = () => {
    const { onSearch } = this.props;
    this.setState({ value: '' }, () => onSearch(''));
  }

  render() {
    const {
      fullWidth,
      placeholder,
      classes,
      withBoldPlaceholder,
      isClearable,
    } = this.props;
    const { value } = this.state;

    return (
      <SearchBoxWrapper>
        <TextField
          fullWidth={fullWidth}
          value={value}
          onChange={e => this.handleChange(e.target.value)}
          placeholder={placeholder}
          name="search"
          InputProps={{
            className: classes.inputWrapper,
            classes: {
              input: `${classes.input} ${withBoldPlaceholder && classes.boldPlaceholder}`,
            },
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
        />
        {isClearable && value && (
          <SearchDeleteIcon
            src={searchRemoveIcon}
            onClick={this.handleDelete}
          />
        )}
      </SearchBoxWrapper>
    );
  }
}

const SearchBoxWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SearchDeleteIcon = styled.img`
  height: 19px;
  width: 19px;
  margin-left: 11px;
  cursor: pointer;
`;

export default withStyles(() => ({
  inputWrapper: { padding: '5px 0' },
  input: {
    '&::placeholder': {
      opacity: 0.54,
    },
  },
  boldPlaceholder: {
    '&::placeholder': {
      opacity: 1,
      fontFamily: 'Raleway-SemiBold',
      fontSize: '15px',
    },
  },
}))(SearchBox);
