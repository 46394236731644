import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Paper,
} from '@material-ui/core';
import { Logo } from '../../components/widgets';
import { PasswordResetForm } from '../../components/forms';
import theme from '../../theme';

const PasswordResetScreen = ({
  formProps,
  classes,
}) => (
  <Fragment>
    <Grid container direction="column" alignItems="center">
      <Grid item>
        <LogoContainer>
          <Logo
            position="bottom"
            style={{
              fill: theme.palette.primary.main,
              size: 80,
            }}
          />
        </LogoContainer>
      </Grid>
      <Grid item>
        <Paper className={classes.paper}>
          <PasswordResetForm {...formProps} />
        </Paper>
      </Grid>
    </Grid>
  </Fragment>
);

PasswordResetScreen.propTypes = {
  formProps: PropTypes.object,
  classes: PropTypes.object.isRequired,
};

PasswordResetScreen.defaultProps = {
  formProps: {},
};

const LogoContainer = styled.div`
  margin: 20px auto;
`;

export default withStyles({
  paper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 20}px`,
  },
})(PasswordResetScreen);
