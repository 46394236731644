import React from 'react';
import { Query, Mutation } from '@apollo/react-components';
import { Adopt } from 'react-adopt';
import moment from 'moment-timezone';
import {
  FETCH_SUBSCRIPTION_PAYMENTS,
  FETCH_PAYMENTS_DUE,
  CREATE_USER_PAYMENT,
  CANCEL_USER_PAYMENT,
  UPDATE_USER,
} from './queries';
import { mapSubscriptions, mapPaymentsDue } from './mapping';
import { notify, messages, pagination } from '../../utils';
import PaymentsScreen from '../../screens/PaymentsScreen';

const mapper = {
  /* eslint-disable react/prop-types */
  subscriptions: ({ render }) => (
    <Query
      query={FETCH_SUBSCRIPTION_PAYMENTS}
      variables={{ first: 10, sortColumn: 'CREATED_AT', sortOrder: 'DESCENDING' }}
      fetchPolicy="network-only"
    >
      {render}
    </Query>
  ),
  paymentsDue: ({ render }) => (
    <Query
      query={FETCH_PAYMENTS_DUE}
      variables={{ first: 10, payUntil: moment().format('YYYY-MM-DD') }}
      fetchPolicy="network-only"
    >
      {render}
    </Query>
  ),
  createUserPayment: ({ render }) => (
    <Mutation
      mutation={CREATE_USER_PAYMENT}
      awaitRefetchQueries
      refetchQueries={[
        { query: FETCH_PAYMENTS_DUE, variables: { first: 10, payUntil: moment().format('YYYY-MM-DD') } },
      ]}
      onCompleted={() => notify.success({ ...messages.CREATE_USER_PAYMENT.success })}

    >
      {render}
    </Mutation>
  ),
  cancelUserPayment: ({ render }) => (
    <Mutation
      mutation={CANCEL_USER_PAYMENT}
      awaitRefetchQueries
      onCompleted={() => notify.success({ ...messages.CANCEL_USER_PAYMENT.success })}

    >
      {render}
    </Mutation>
  ),
  updateUser: ({ render }) => (
    <Mutation
      mutation={UPDATE_USER}
    >
      {render}
    </Mutation>
  ),
  /* eslint-enable react/prop-types */
};

export const PaymentsScreenConnector = () => (
  <Adopt mapper={mapper}>
    {({
      subscriptions,
      paymentsDue,
      createUserPayment,
      cancelUserPayment,
      updateUser,
    }) => {
      const mappedSubscriptions = mapSubscriptions(subscriptions);
      const mappedPaymentsDue = mapPaymentsDue(paymentsDue);

      return (
        <PaymentsScreen
          subscriptions={mappedSubscriptions}
          paymentsDue={mappedPaymentsDue}
          loadMore={{
            subscriptions: values => pagination.fetchNextPage(subscriptions, 'userPayments', values),
            paymentsDue: values => pagination.fetchNextPage(paymentsDue, 'paymentsDue', values),
          }}
          onSearch={{
            subscriptions: subscriptions.refetch,
            paymentsDue: paymentsDue.refetch,
          }}
          onDateRangeUpdate={subscriptions.refetch}
          onPayment={variables => createUserPayment({ variables })}
          onCancel={variables => cancelUserPayment({ variables })}
          updateUser={variables => updateUser({ variables })}
        />
      );
    }}
  </Adopt>
);

export default PaymentsScreenConnector;
