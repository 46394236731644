import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  // Button,
  Typography,
  CircularProgress,
} from '@material-ui/core';
import { date } from '../../../utils';
import CollapsableCard from '../../widgets/CollapsableCard';
import DateRangePicker from '../../forms/DateRangePicker';

class TopUsersChart extends Component {
  static propTypes = {
    data: PropTypes.shape({
      holrs: PropTypes.arrayOf(
        PropTypes.shape({
          uuid: PropTypes.string,
          username: PropTypes.string,
          value: PropTypes.number,
        }),
      ),
      holrbacks: PropTypes.arrayOf(
        PropTypes.shape({
          uuid: PropTypes.string,
          username: PropTypes.string,
          value: PropTypes.number,
        }),
      ),
      followers: PropTypes.arrayOf(
        PropTypes.shape({
          uuid: PropTypes.string,
          username: PropTypes.string,
          value: PropTypes.number,
        }),
      ),
    }),
    loading: PropTypes.bool,
    onDateRangeUpdate: PropTypes.func,
    // onDownloadClick: PropTypes.func,
    classes: PropTypes.object,
  };

  static defaultProps = {
    data: {
      holrs: [],
      holrbacks: [],
      followers: [],
    },
    loading: false,
    onDateRangeUpdate: () => {},
    // onDownloadClick: () => {},
    classes: {},
  };

  state = {
    startDate: new Date(date.firstDay()),
    endDate: new Date(date.lastDay()),
  };

  handleDateChange = (startDate, endDate) => {
    const { onDateRangeUpdate } = this.props;

    onDateRangeUpdate({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });

    this.setState({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  };

  renderTable = (title, data) => {
    const { classes } = this.props;

    return (
      <TableContainer>
        <Typography variant="display1" className={classes.subtitle}>
          {title}
        </Typography>
        <Table className={classes.table}>
          <TableBody className={classes.tableBody}>
            {data.map((element, index) => (
              <TableRow key={element.uuid} className={index % 2 ? classes.tableRow : ''}>
                <TableCell className={classes.cellId}>{`${index + 1}.`}</TableCell>
                <TableCell className={classes.cellName}>{element.username}</TableCell>
                <TableCell numeric className={classes.cellValue}>{element.value}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  renderContent = () => {
    const {
      data: {
        holrs,
        holrbacks,
        followers,
      },
      loading,
    } = this.props;

    switch (true) {
      case loading:
        return (
          <CircularProgress thickness={5} size={80} />
        );
      case (
        (!holrs || !holrs.length)
        && (!holrbacks || !holrbacks.length)
        && (!followers || !followers.length)
      ):
        return (
          <Typography variant="display1">Sorry, seems there&apos;s no content to display</Typography>
        );
      default:
        return (
          <Fragment>
            {holrs && !!holrs.length && this.renderTable('Most shared holrs', holrs)}
            {holrbacks && !!holrbacks.length && this.renderTable('Most shared holrbacks', holrbacks)}
            {followers && !!followers.length && this.renderTable('Most Followers', followers)}
          </Fragment>
        );
    }
  }

  render() {
    const {
      startDate,
      endDate,
    } = this.state;

    const {
      // data: {
      //   holrs,
      //   holrbacks,
      //   followers,
      // },
      loading,
      // onDownloadClick,
      // classes,
    } = this.props;

    return (
      <CollapsableCard
        header={(
          <TitleContainer>
            <Typography variant="headline">Top Users</Typography>
            <DateRangePicker
              onUpdate={this.handleDateChange}
              startDate={startDate}
              endDate={endDate}
              label="Date Range"
            />
          </TitleContainer>
        )}
      >
        <Container loading={loading} id="top-users-chart">
          {this.renderContent()}
        </Container>
        {/* <Button
          color="primary"
          variant="contained"
          onClick={onDownloadClick}
          className={classes.button}
          disabled={loading || (
            (!holrs || !holrs.length)
            && (!holrbacks || !holrbacks.length)
            && (!followers || !followers.length)
          )}
        >
          DOWNLOAD .PDF
        </Button> */}
      </CollapsableCard>
    );
  }
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const Container = styled.div`
  display: flex;
  justify-content: ${({ loading }) => (loading ? 'center' : 'flex-start')};
  flex-direction: row;
  margin: 1rem;
`;

const TableContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

export default withStyles(theme => ({
  title: {
    fontSize: '30px',
    marginTop: '5px',
  },
  subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    fontSize: '23px',
  },
  button: {
    padding: '8px 44px',
  },
  table: {
    margin: '20px 0px',
    minWidth: '290px',
  },
  tableBody: { margin: '0px 10px' },
  tableRow: {
    background: `${theme.palette.primary.light}55`,
  },
  tableCell: {
    fontSize: '29px',
    fontWeight: 'bold',
  },
  cellId: {
    padding: '0px 18px',
    fontSize: '17px',
    fontWeight: 'bold',
  },
  cellName: {
    paddingLeft: '0px',
    fontSize: '17px',
    textTransform: 'lowercase',
  },
  cellValue: {
    fontSize: '17px',
    fontWeight: 'bold',
    paddingLeft: '0px',
    paddingRight: '14px',
  },
  datePicker: {
    position: 'absolute',
    top: 4,
    right: 24,
  },
}))(TopUsersChart);
