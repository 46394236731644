import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  FormControl,
  Checkbox,
  FormControlLabel,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core';
import DateRangePicker from '../../forms/DateRangePicker';

const validation = (values) => {
  const errors = {};

  if (!values.endDate && !values.permanentBan) {
    errors.endDate = 'Field is required';
  }

  if (!values.reason) {
    errors.reason = 'Please choose a reason';
  }

  return errors;
};

const BanUserModal = ({
  open, onSave, onCancel, user, classes,
}) => (
  <Formik
    onSubmit={values => onSave({
      ...user,
      ...values,
      timeSpanType: values.permanentBan ? 'PERMANENT' : 'DATE_RANGE',
    })}
    validate={validation}
    initialValues={{
      startDate: new Date(),
      endDate: null,
      reason: '',
      permanentBan: false,
      notes: '',
    }}
    enableReinitialize
  >
    {({
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
      setFieldValue,
      setFieldTouched,
      submitForm,
      isValid,
    }) => {
      function handleDateRangeChange(_startDate, endDate) {
        setFieldTouched('endDate', true);
        setFieldValue('endDate', endDate.toDate());
      }

      return (
        <Dialog maxWidth="lg" open={open} onClose={onCancel}>
          <DialogTitle className={classes.modalTitle}>Ban User</DialogTitle>

          <DialogContent className={classes.content}>
            <DialogContentText style={{ marginBottom: '1rem' }}>
              {`Are you sure you want to ban this user @${user.username}?`}
            </DialogContentText>

            <Form>
              <FormControl
                fullWidth
                error={touched.email && !!errors.email}
                className={classes.formControl}
              >
                <DateRangePicker
                  label="Time Span*"
                  minDate={new Date()}
                  startDate={values.startDate}
                  endDate={values.endDate}
                  onUpdate={handleDateRangeChange}
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <FormControlLabel
                  control={(
                    <Checkbox
                      name="permanentBan"
                      checked={values.permanentBan}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      color="primary"
                    />
                  )}
                  label="Permanent Ban"
                />
              </FormControl>

              <FormControl fullWidth className={classes.formControl}>
                <InputLabel htmlFor="reason-label">Reason*</InputLabel>
                <Select
                  name="reason"
                  id="reason"
                  value={values.reason}
                  onChange={handleChange}
                  onBlur={handleBlur}
                >
                  <MenuItem value="SPAM">Spam</MenuItem>
                  <MenuItem value="INAPPROPRIATE">Inappropriate Content</MenuItem>
                </Select>
              </FormControl>

              <FormControl
                fullWidth
                error={touched.notes && !!errors.notes}
                className={classes.formControl}
              >
                <TextField
                  placeholder="Enter your notes here"
                  testid="notes"
                  id="notes"
                  name="notes"
                  value={values.notes}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  autoComplete="notes"
                  multiline
                  rows={5}
                />
                <FormHelperText>{touched.notes && errors.notes}</FormHelperText>
              </FormControl>
            </Form>
          </DialogContent>

          <DialogActions className={classes.actions}>
            <Button onClick={onCancel} color="primary">
              Cancel
            </Button>
            <Button onClick={submitForm} color="primary" variant="contained" disabled={!isValid}>
              Confirm
            </Button>
          </DialogActions>
        </Dialog>
      );
    }}
  </Formik>
);

BanUserModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  onCancel: PropTypes.func,
  user: PropTypes.shape({
    username: '',
  }),
  open: PropTypes.bool,
};

BanUserModal.defaultProps = {
  onSave: () => {},
  onCancel: () => {},
  user: { username: 'username' },
  open: false,
};

export default withStyles(theme => ({
  title: {
    borderBottom: '1px solid #949494',
  },
  content: {
    width: '40rem',
    marginTop: '1rem',
  },
  actions: {
    borderTop: '1px solid #949494',
    paddingTop: '1rem',
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
}))(BanUserModal);
