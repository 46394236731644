import React, { useState } from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import UsersReportCard from './UsersReportCard';
import { USER_STATS } from './queries';
import { downloadCsv } from '../../../utils/csv';

export const connect = Component => (props) => {
  // eslint-disable-next-line
  const [initialDates, setInitialDates] = useState({ startDate: moment().subtract(1, 'years'), endDate: moment() });

  const { data, refetch } = useQuery(USER_STATS, {
    variables: { startDate: initialDates.startDate, endDate: initialDates.endDate },
  });

  const handleDateChange = dates => refetch(dates);

  const parseData = (dataStats) => {
    if (!(dataStats && dataStats.userStats && dataStats.userStats.stats)) return [];
    const dataSet = [
      {
        id: 'Users',
        data: [],
      },
      {
        id: 'Influencers',
        data: [],
      },
      {
        id: 'Fans',
        data: [],
      },
    ];
    dataStats.userStats.stats.forEach((stat) => {
      dataSet.find(dataStatAux => dataStatAux.id === 'Users').data.push({ x: stat.date, y: stat.usersCount });
      dataSet.find(dataStatAux => dataStatAux.id === 'Influencers').data.push({ x: stat.date, y: stat.influencersCount });
      dataSet.find(dataStatAux => dataStatAux.id === 'Fans').data.push({ x: stat.date, y: stat.fansCount });
    });
    return dataSet;
  };

  const headers = [{
    date: 'Date', fansCount: 'Fans', influencersCount: 'Influencers', usersCount: 'Users',
  }];

  return (
    <Component
      onDateChange={handleDateChange}
      data={parseData(data)}
      onDownloadCsv={() => downloadCsv(data.userStats.stats, headers, 'Fans_Influencers_Users')}
      {...props}
    />
  );
};

export default connect(UsersReportCard);
