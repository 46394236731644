import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { pagination } from '../../utils';
import UsersScreen from '../../screens/UsersScreen';

export const FETCH_USERS = gql`
  query FetchUsers(
    $query: String
    $first: Int
    $after: String
  ) {
    fullUsers(
      query: $query
      first: $first
      after: $after
    ) {
      edges {
        node {
          uuid
          username
          firstName
          fullName
          lastName
          email
          banned
          adminRole
          appRole
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

function mapUsers({ error, data, loading }) {
  if (loading || error) {
    return { data: [], loading, error };
  }

  try {
    return {
      data: data.fullUsers.edges.map(edge => ({ ...edge.node })),
      loading,
      error,
      totalCount: data.fullUsers.totalCount,
    };
  } catch (mapError) {
    return { data: [], loading, error: mapError };
  }
}

export const UsersScreenConnector = ({ history }) => (
  <Query
    query={FETCH_USERS}
    variables={{ first: 10 }}
    fetchPolicy="network-only"
  >
    {(users) => {
      const mappedUsers = mapUsers(users);
      const { totalCount } = mappedUsers;

      return (
        <UsersScreen
          users={{ ...mappedUsers }}
          onSearch={query => users.refetch({ query })}
          onUserClick={uuid => history.push(`/users/${uuid}`)}
          loadMoreUsers={values => pagination.fetchNextPage(users, 'fullUsers', values)}
          totalCount={totalCount}
        />
      );
    }}
  </Query>
);

UsersScreenConnector.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(UsersScreenConnector);
