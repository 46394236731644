import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Grid,
  IconButton,
} from '@material-ui/core';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import { Formik, Form, FieldArray } from 'formik';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import difference from 'lodash/difference';
import styled from 'styled-components';
import {
  AddAvatarModal,
  DiscardChangesConfirmModal,
} from '../../components/modals';
import ScreenContainer from '../../components/layout/ScreenContainer';
import { ImagePicker, ChipsBox } from '../../components/forms';
import { InfoCard, Logo } from '../../components/widgets';
import { validate } from '../../utils';

export class CategoryScreen extends Component {
  static propTypes = {
    category: PropTypes.shape({
      categoryData: PropTypes.shape({
        uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
        subcategories: PropTypes.arrayOf(
          PropTypes.shape({
            uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string,
          }),
        ),
        avatar: PropTypes.string,
        coverPhoto: PropTypes.string,
        subscribers: PropTypes.number,
        holrs: PropTypes.number,
        subscriberStats: PropTypes.number,
        holrStats: PropTypes.number,
        lastUpdated: PropTypes.string,
      }),
    }),
    onUpdate: PropTypes.func,
    onReturnClick: PropTypes.func,
    updateLoading: PropTypes.bool,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    category: {
      categoryData: {
        uuid: '',
        name: '',
        subcategories: [],
        avatar: '',
        coverPhoto: '',
        subscribers: 0,
        holrs: 0,
        subscriberStats: 0,
        holrStats: 0,
        lastUpdated: '',
      },
    },
    onUpdate: () => {},
    onReturnClick: () => {},
    updateLoading: false,
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      isValid: false,
      hasChanged: false,
      discardModal: false,
      avatarModal: false,
      subcategory: null,
    };
  }

  validation = (values) => {
    const { category: { categoryData } } = this.props;

    const errors = {};

    if (!values.name) {
      errors.name = 'Required';
    }

    if (!values.avatar) {
      errors.avatar = 'Required';
    }

    if (!values.coverPhoto) {
      errors.coverPhoto = 'Required';
    }

    const hasChanged = !Object.keys(values).every(key => isEqual(values[key], categoryData[key]));

    this.setState({
      isValid: isEmpty(errors),
      hasChanged,
    });

    return errors;
  };

  getStats = (term) => {
    const {
      category: {
        categoryData: {
          subscribers,
          subscriberStats,
          holrs,
          holrStats,
        },
      },
    } = this.props;

    const currentValue = term === 'subscribers' ? subscribers : holrs;
    const previousValue = term === 'subscribers' ? subscriberStats : holrStats;
    const value = currentValue - previousValue;

    switch (true) {
      case value > 0 && term === 'subscribers':
        return `${value} more than last month`;
      case value < 0 && term === 'subscribers':
        return `${Math.abs(value)} less than last month`;
      case value > 0 && term === 'holrs':
        return `Increased by ${value} from last month`;
      case value < 0 && term === 'holrs':
        return `Decreased by ${Math.abs(value)} from last month`;
      default:
        return 'Same as last month';
    }
  };

  toggleDiscardChangesModal = () => {
    const { discardModal } = this.state;

    this.setState({ discardModal: !discardModal });
  };

  handleSubmit = async (values, {
    resetForm,
  }) => {
    const {
      category: { categoryData },
      onUpdate,
    } = this.props;

    const variables = { uuid: categoryData.uuid };

    Object.keys(values).forEach((key) => {
      if (!isEqual(values[key], categoryData[key])) {
        variables[key] = values[key];
      }
    });

    const removedSubcategories = difference(categoryData.subcategories, values.subcategories);

    if (removedSubcategories.length) {
      if (!variables.subcategories) {
        variables.subcategories = [];
      }

      // eslint-disable-next-line
      removedSubcategories.forEach((item) => { item.destroy = true; })

      variables.subcategories = [...removedSubcategories, ...variables.subcategories];
    }

    await onUpdate({ variables });

    resetForm();

    this.setState({ isValid: false });
  };

  handleDiscardChanges = () => {
    this.toggleDiscardChangesModal();
    this.form.current.resetForm();
    this.setState({ isValid: false });
  }

  renderForm = ({
    values,
    touched,
    errors,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  }) => {
    const { classes } = this.props;
    const {
      avatarModal,
      subcategory,
    } = this.state;

    return (
      <Form>
        <Grid container spacing={40}>
          <Grid item md={5}>
            <FormControl
              className={classes.formControl}
              error={touched.avatar && !!errors.avatar}
              fullWidth
            >
              <ImagePicker
                imageSrc={values.avatar}
                title="Avatar*"
                subtitle="(min 720 x 720 px)"
                onChange={(file) => {
                  setFieldValue('avatar', file);
                  setFieldTouched('avatar', true);
                  validate.imageSize(file, 'avatar', (result) => {
                    setFieldError('avatar', result);
                  });
                }}
              />
              <FormHelperText>{touched.avatar && errors.avatar}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              error={touched.name && !!errors.name}
              className={classes.formControl}
            >
              <InputLabel htmlFor="name">Category Name*</InputLabel>
              <Input
                id="name"
                className={classes.input}
                value={values.name}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="current-name"
              />
              <FormHelperText>{touched.name && errors.name}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item md={6}>
            <FormControl
              className={classes.formControl}
              error={touched.coverPhoto && !!errors.coverPhoto}
              fullWidth
            >
              <ImagePicker
                large
                imageSrc={values.coverPhoto}
                title="Cover Photo*"
                subtitle="(min 1200 x 900 px)"
                onChange={(file) => {
                  setFieldValue('coverPhoto', file);
                  setFieldTouched('coverPhoto', true);
                  validate.imageSize(file, 'cover', (result) => {
                    setFieldError('coverPhoto', result);
                  });
                }}
              />
              <FormHelperText>{touched.coverPhoto && errors.coverPhoto}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12} sm={10} md={6} lg={4}>
            <FormControl className={`${classes.formControl} ${classes.inputFormControl}`}>
              <Typography variant="subheading">Sub-Categories</Typography>
              <FieldArray name="subcategories">
                {({ push, remove }) => (
                  <Fragment>
                    <ChipsBox
                      addNew
                      style={{ width: 600 }}
                      selectedItems={values.subcategories}
                      onSelect={item => this.setState({
                        subcategory: item,
                        avatarModal: true,
                      })}
                      onRemove={index => remove(index)}
                      itemLabel="name"
                      itemKey="uuid"
                      placeholder="Add a subcategory"
                    />
                    <AddAvatarModal
                      open={avatarModal}
                      title={`Add "${subcategory && subcategory.name}" avatar`}
                      onSave={({ image: avatar }) => {
                        push({ ...subcategory, avatar });

                        this.setState({
                          subcategory: null,
                          avatarModal: false,
                        });
                      }}
                      onCancel={() => this.setState({ avatarModal: false })}
                    />
                  </Fragment>
                )}
              </FieldArray>
            </FormControl>
          </Grid>
        </Grid>
      </Form>
    );
  };

  render() {
    const {
      category: { categoryData },
      onReturnClick,
      updateLoading,
      classes,
    } = this.props;

    const {
      isValid,
      hasChanged,
      discardModal,
    } = this.state;

    return (
      <ScreenContainer
        withFooter
        footerProps={{
          description: {
            label: `Last updated on ${categoryData.lastUpdated}`,
          },
          rightButtons: [
            {
              variant: 'contained',
              label: 'Update',
              color: 'primary',
              onClick: () => this.form.current.submitForm(),
              disabled: !isValid || updateLoading,
            },
            {
              variant: 'outlined',
              label: 'Cancel',
              color: 'primary',
              onClick: this.toggleDiscardChangesModal,
              disabled: !hasChanged,
            },
          ],
        }}
      >
        <TitleContainer>
          <IconButton onClick={onReturnClick}>
            <NavigateBefore />
          </IconButton>
          <Typography align="left" variant="headline" className={classes.title}>
            {categoryData.name}
          </Typography>
        </TitleContainer>
        <Paper className={classes.paper}>
          <Container className={classes.form}>
            <Formik
              enableReinitialize
              initialValues={{
                name: categoryData.name || '',
                avatar: categoryData.avatar || '',
                coverPhoto: categoryData.coverPhoto || '',
                subcategories: categoryData.subcategories || [],
              }}
              onSubmit={this.handleSubmit}
              validate={this.validation}
              ref={this.form}
            >
              {props => this.renderForm(props)}
            </Formik>
            <Grid container className={classes.cards}>
              <Grid item lg={6} className={classes.card}>
                <InfoCard
                  type="Total Subscribers"
                  title={categoryData.subscribers}
                  subtitle={this.getStats('subscribers')}
                  subtitleIcon={<FavoriteIcon />}
                  icon={<Logo style={{ width: 50, fill: '#d8d8d8' }} />}
                />
              </Grid>
              <Grid item lg={6} className={classes.card}>
                <InfoCard
                  type="holrs"
                  title={categoryData.holrs}
                  subtitle={this.getStats('holrs')}
                  subtitleIcon={<FavoriteIcon />}
                  icon={<Logo style={{ width: 50, fill: '#d8d8d8' }} />}
                />
              </Grid>
            </Grid>
          </Container>
        </Paper>
        <DiscardChangesConfirmModal
          open={discardModal}
          onCancelClick={this.toggleDiscardChangesModal}
          onConfirmClick={this.handleDiscardChanges}
        />
      </ScreenContainer>
    );
  }
}

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 1rem;
`;

const Container = styled.div`
  width: 90%;
  min-width: 20rem;
  margin: 1rem;
`;

export default withStyles(theme => ({
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
  },
  title: {
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    marginTop: '1rem',
  },
  inputFormControl: {
    width: '100%',
  },
  cards: {
    marginTop: theme.spacing.unit * 2,
  },
  card: {
    marginBottom: theme.spacing.unit * 2,
  },
}))(CategoryScreen);
