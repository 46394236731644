import gql from 'graphql-tag';

export const SUBSCRIBER_STATS = gql`
  query SubscriberStats(
    $startDate: Date!
    $endDate: Date!
    $groupingType: SubscriberStatsGroupingEnum!
  ) {
    subscriberStats(
      startDate: $startDate
      endDate: $endDate
      groupingType: $groupingType
    ) {
      stats {
        statsDate
        paidSubscriptions
        unpaidSubscriptions
      }
    }
  }
`;

export default {
  SUBSCRIBER_STATS,
};
