import React, { Component, Fragment } from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { NavigationPanel, TopPanel } from '..';
import Logo from '../../widgets/Logo';
import theme from '../../../theme';

class Dashboard extends Component {
  static propTypes = {
    children: PropTypes.element,
    navigationPanelProps: PropTypes.object,
    topPanelProps: PropTypes.object,
    isAdmin: PropTypes.bool,
  };

  static defaultProps = {
    children: null,
    navigationPanelProps: {},
    topPanelProps: {},
    isAdmin: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      navigationPanelCollapsed: !props.isAdmin,
    };
  }

  toggleCollapsed = () => {
    const { navigationPanelCollapsed } = this.state;

    this.setState({ navigationPanelCollapsed: !navigationPanelCollapsed });
  };

  render() {
    const {
      topPanelProps,
      navigationPanelProps,
      children,
    } = this.props;

    const { navigationPanelCollapsed } = this.state;

    const logoPosition = navigationPanelCollapsed ? 'default' : 'right';

    const logoStyle = {
      fill: theme.palette.primary.main,
      size: navigationPanelCollapsed ? '39' : '120',
    };

    return (
      <Fragment>
        <NavigationPanel
          {...navigationPanelProps}
          logo={<Logo style={logoStyle} position={logoPosition} />}
          collapsed={navigationPanelCollapsed}
        />
        <Container collapsed={navigationPanelCollapsed}>
          <TopPanel
            {...topPanelProps}
            onToggleClick={this.toggleCollapsed}
          />
          <ContentWrapper>{children}</ContentWrapper>
        </Container>
      </Fragment>
    );
  }
}

const Container = styled.div`
  margin-left: ${props => (props.collapsed ? 72 : theme.spacing.drawerWidth)}px;
  height: 100vh;
`;

const ContentWrapper = styled.div`
  min-height: calc(100vh - ${theme.spacing.footerHeight}px);
  display: flex;
  flex-direction: column;
`;

export default Dashboard;
