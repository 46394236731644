import React from 'react';
import { useQuery, useMutation } from '@apollo/react-hooks';
import AddUploadedTagsModal from './AddUploadedTagsModal';
import { USED_TAGS } from './queries';
import { UPDATE_IMPORTED_MEDIUM } from './mutations';
import { notify, messages } from '../../../utils';

export const connect = Component => (props) => {
  // eslint-disable-next-line
  const { uuid, onClose, open } = props;

  const { data: dataUsedTags, refetch } = useQuery(USED_TAGS, {
    variables: { first: 5, uuid },
  });

  const [updateMedium] = useMutation(UPDATE_IMPORTED_MEDIUM);

  const parseUsedTags = (tags = []) => tags.map(tag => ({ value: tag.name, label: tag.name }));
  const parseMedia = ({ uuid: mediaUuid, name, tags }) => ({
    uuid: mediaUuid, name, tags,
  });

  const handleSave = async (uploadedMedia) => {
    try {
      const updateAllMedium = new Promise((resolve) => {
        uploadedMedia.forEach(async (media) => {
          await updateMedium({ variables: parseMedia(media) });
          resolve();
        });
      });

      updateAllMedium.then(() => {
        notify.success({ ...messages.UPDATE_MEDIUM.success });
        onClose();
      });
    } catch (error) {
      notify.error({ ...messages.UPDATE_MEDIUM.error });
    }
  };

  return (
    <Component
      {...props}
      onSave={handleSave}
      usedTags={parseUsedTags(dataUsedTags && dataUsedTags.usedTags.nodes)}
      refetchUsedTags={refetch}
    />
  );
};

export default connect(AddUploadedTagsModal);
