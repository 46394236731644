import React from 'react';
import PropTypes from 'prop-types';
import { useQuery, useMutation } from '@apollo/react-hooks';
import { withRouter } from 'react-router-dom';
import withAuth from '../../contexts/AuthContext';
import { notify, messages, pagination } from '../../utils';
import UserScreen from '../../screens/UserScreen';
import {
  DELETE_USER,
  UPDATE_USER,
  UPDATE_USER_TYPE,
  UPDATE_ADMIN_PRIVILEGES,
  UPDATE_FLAG_BAN_HOLR,
  UPDATE_BAN_HOLR,
  FETCH_CATEGORIES,
  FETCH_USER,
  UPDATE_BAN_USER,
  UPDATE_HOLR,
  UPDATE_REPORTED_PROBLEM,
  UPDATE_UNBAN_USER,
} from './queries';
import { mapUser, mapCategories } from './mapping';

const loadMore = (response, resource, rows) => pagination.fetchNextPage(response, resource, rows);

export const UserScreenConnector = ({ match: { params }, history, user }) => {
  const { uuid } = params;

  const options = {
    fetchPolicy: 'cache-and-network',
    variables: { uuid, first: 10 },
  };

  const refetchQueries = [{ query: FETCH_USER, variables: { uuid } }];

  const fetchUser = useQuery(FETCH_USER, options);

  const fetchCategories = useQuery(FETCH_CATEGORIES, options);

  const [deleteUser] = useMutation(DELETE_USER, {
    onCompleted: () => {
      history.push('/users');
      notify.success({ ...messages.DELETE_USER.success });
    },
    onError: () => notify.error({ ...messages.DELETE_USER.error }),
  });

  const [updateUser, { loading: updateUserLoading }] = useMutation(UPDATE_USER, {
    onCompleted: () => notify.success({ ...messages.UPDATE_USER.success }),
    onError: () => notify.error({ ...messages.UPDATE_USER.error }),
    fetchPolicy: 'network-only',
    refetchQueries,
  });

  const [updateReportedContent] = useMutation(UPDATE_REPORTED_PROBLEM, {
    onCompleted: () => notify.success({ ...messages.UPDATE_REPORTED_PROBLEM.success }),
    onError: () => notify.error({ ...messages.UPDATE_REPORTED_PROBLEM.error }),
    refetchQueries,
  });

  const [updateHolr] = useMutation(UPDATE_HOLR, {
    onCompleted: () => notify.success({ ...messages.UPDATE_HOLR.success }),
    onError: (error) => {
      // eslint-disable-next-line no-console
      console.error(error.toString());
      notify.error({ ...messages.UPDATE_HOLR.error });
    },
    refetchQueries,
  });

  const [updateUserType] = useMutation(UPDATE_USER_TYPE, {
    onCompleted: () => notify.success({ ...messages.UPDATE_USER_TYPE.success }),
    onError: () => notify.error({ ...messages.UPDATE_USER_TYPE.error }),
    refetchQueries,
  });

  const [updateAdminPrivileges] = useMutation(UPDATE_ADMIN_PRIVILEGES, {
    onCompleted: () => notify.success({ ...messages.UPDATE_ADMIN_PRIVILEGES.success }),
    onError: () => notify.error({ ...messages.UPDATE_ADMIN_PRIVILEGES.error }),
    refetchQueries,
  });

  const [updateBanUser] = useMutation(UPDATE_BAN_USER, {
    onCompleted: () => notify.success({ ...messages.UPDATE_BAN_USER.success }),
    onError: () => notify.error({ ...messages.UPDATE_BAN_USER.error }),
    refetchQueries,
  });

  const [updateUnbanUser] = useMutation(UPDATE_UNBAN_USER, {
    onCompleted: () => notify.success({ ...messages.UPDATE_UNBAN_USER.success }),
    onError: () => notify.error({ ...messages.UPDATE_UNBAN_USER.error }),
    refetchQueries,
  });

  const [updateFlagHolr] = useMutation(UPDATE_FLAG_BAN_HOLR, {
    onCompleted: () => notify.success({ ...messages.UPDATE_FLAG_BAN_HOLR.success }),
    onError: () => notify.error({ ...messages.UPDATE_FLAG_BAN_HOLR.error }),
    refetchQueries,
  });

  const [updateBanAuthorHolr] = useMutation(UPDATE_BAN_HOLR, {
    onCompleted: () => notify.success({ ...messages.UPDATE_BAN_HOLR.success }),
    onError: () => notify.error({ ...messages.UPDATE_BAN_HOLR.error }),
    refetchQueries,
  });

  const {
    userData,
    holrsData,
    paymentsData,
    followersData,
    followingData,
    reportedContent,
    error,
    totalCountFollowers,
    totalCountFollowing,
    totalCountPayments,
  } = mapUser(fetchUser);
  const { data: categories } = mapCategories(fetchCategories);

  return (
    <UserScreen
      onReturnClick={() => history.push('/users')}
      isActiveUser={uuid === user.uuid}
      activeUser={user}
      error={error}
      userData={userData}
      categories={categories}
      updateProfileLoading={updateUserLoading}
      holrsData={holrsData}
      followersData={followersData}
      followingData={followingData}
      reportedContentData={reportedContent}
      onCancel={() => history.push('/users')}
      onUpdateReportedContent={updateReportedContent}
      onUpdateHolr={updateHolr}
      onUpdateUserType={updateUserType}
      onUpdateAdminPrivileges={updateAdminPrivileges}
      onUpdateBanUser={variables => updateBanUser({ variables })}
      onUpdateUnbanUser={updateUnbanUser}
      onUpdateUserData={updateUser}
      onUpdateFlagHolr={updateFlagHolr}
      onUpdateBanAuthorHolr={updateBanAuthorHolr}
      onDelete={deleteUser}
      paymentsData={paymentsData}
      onUpdatePayment={() => null}
      loadMoreFollowers={values => loadMore(fetchUser, 'followers', values)}
      loadMoreFollowing={values => loadMore(fetchUser, 'following', values)}
      loadMorePayments={values => loadMore(fetchUser, 'userPayments', values)}
      totalCountFollowers={totalCountFollowers}
      totalCountFollowing={totalCountFollowing}
      totalCountPayments={totalCountPayments}
    />
  );
};

UserScreenConnector.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      uuid: PropTypes.string.isRequired,
    }),
  }).isRequired,
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withRouter(withAuth(UserScreenConnector));
