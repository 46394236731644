import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Select from 'react-select';
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import NoSsr from '@material-ui/core/NoSsr';
import TextField from '@material-ui/core/TextField';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import MenuItem from '@material-ui/core/MenuItem';
import CancelIcon from '@material-ui/icons/Cancel';
import { emphasize } from '@material-ui/core/styles/colorManipulator';

const styles = theme => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0,
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center',
    overflow: 'hidden',
    height: '36px',
  },
  chip: {
    margin: `${theme.spacing.unit / 2}px ${theme.spacing.unit / 4}px`,
  },
  chipFocused: {
    backgroundColor: emphasize(
      theme.palette.type === 'light' ? theme.palette.grey[300] : theme.palette.grey[700],
      0.08,
    ),
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
  },
  singleValue: {
    fontSize: 16,
  },
  placeholder: {
    position: 'absolute',
    left: 2,
    fontSize: 16,
  },
  paper: {
    position: 'absolute',
    zIndex: 1,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
  },
  divider: {
    height: theme.spacing.unit * 2,
  },
  option: {
    '&&:focus': {
      backgroundColor: 'rgb(0,0,0,0.08)',
    },
  },
});

// eslint-disable-next-line
const NoOptionsMessage = ({ selectProps, innerProps, children }) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.noOptionsMessage}
    {...innerProps}
  >
    {children}
  </Typography>
);

NoOptionsMessage.propTypes = {
  selectProps: PropTypes.any.isRequired,
};

// eslint-disable-next-line
const inputComponent = ({ inputRef, ...props }) => <div ref={inputRef} {...props} />;

const Control = ({
  // eslint-disable-next-line
  selectProps, innerRef, children, innerProps,
}) => (
  <TextField
    fullWidth
    InputProps={{
      inputComponent,
      inputProps: {
        className: selectProps.classes.input,
        inputRef: innerRef,
        children,
        ...innerProps,
      },
    }}
    {...selectProps.textFieldProps}
  />
);

Control.propTypes = {
  selectProps: PropTypes.any.isRequired,
};

const StyledMenuItem = withStyles({
  root: {
    backgroundColor: 'white !important',
    '&:hover': {
      backgroundColor: 'rgb(0,0,0,0.08) !important',
    },
    '&:focus': {
      backgroundColor: 'rgb(0,0,0,0.08) !important',
    },
  },
})(MenuItem);

const Option = ({
  // eslint-disable-next-line
  innerRef, isFocused, isSelected, innerProps, children,
}) => (
  <StyledMenuItem
    buttonRef={innerRef}
    selected={isFocused}
    component="div"
    style={{
      fontWeight: isSelected ? 500 : 400,
    }}
    {...innerProps}
  >
    {children}
  </StyledMenuItem>
);

// eslint-disable-next-line
const Placeholder = ({ selectProps, innerProps, children }) => (
  <Typography
    color="textSecondary"
    className={selectProps.classes.placeholder}
    {...innerProps}
  >
    {children}
  </Typography>
);

Placeholder.propTypes = {
  selectProps: PropTypes.any.isRequired,
};

// eslint-disable-next-line
const SingleValue = ({ selectProps, innerProps, children }) => (
  <Typography className={selectProps.classes.singleValue} {...innerProps}>
    {children}
  </Typography>
);

SingleValue.propTypes = {
  selectProps: PropTypes.any.isRequired,
};

// eslint-disable-next-line
const ValueContainer = ({ selectProps, children }) => (
  <div className={selectProps.classes.valueContainer}>{children}</div>);

ValueContainer.propTypes = {
  selectProps: PropTypes.any.isRequired,
};

const MultiValue = ({
  // eslint-disable-next-line
  children, selectProps, isFocused, removeProps,
}) => (
  <Chip
    tabIndex={-1}
    label={children}
    className={classNames(selectProps.classes.chip, {
      [selectProps.classes.chipFocused]: isFocused,
    })}
    onDelete={removeProps.onClick}
    deleteIcon={<CancelIcon {...removeProps} />}
  />
);

MultiValue.propTypes = {
  selectProps: PropTypes.any.isRequired,
  removeProps: PropTypes.any.isRequired,
};

// eslint-disable-next-line
const Menu = ({ selectProps, innerProps, children }) => (
  <Paper square className={selectProps.classes.paper} {...innerProps}>
    {children}
  </Paper>
);

Menu.propTypes = {
  selectProps: PropTypes.any.isRequired,
};

const components = {
  Control,
  Menu,
  MultiValue,
  NoOptionsMessage,
  Option,
  Placeholder,
  SingleValue,
  ValueContainer,
  DropdownIndicator: () => null,
  IndicatorSeparator: () => null,
};

const Autocomplete = ({
  classes, theme, ...other
}) => {
  const selectStyles = {
    input: base => ({
      ...base,
      color: theme.palette.text.primary,
      '& input': {
        font: 'inherit',
      },
    }),
  };
  return (
    <div className={classes.root}>
      <NoSsr>
        <Select
          classes={classes}
          styles={selectStyles}
          components={components}
          {...other}
        />
      </NoSsr>
    </div>
  );
};

Autocomplete.propTypes = {
  classes: PropTypes.object.isRequired,
  theme: PropTypes.object.isRequired,
};

export default withStyles(styles, { withTheme: true })(Autocomplete);
