import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  CardHeader,
  Table,
  TableBody,
  TableRow,
  TableCell,
  CircularProgress,
  Typography,
} from '@material-ui/core';

class DashboardTableCard extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(PropTypes.object),
    type: PropTypes.oneOf(['influencers', 'holrs', 'categories']),
    loading: PropTypes.bool,
    onItemClick: PropTypes.func,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: [],
    type: 'influencers',
    loading: false,
    onItemClick: () => {},
  };

  state = {
    title: '',
    subtitle: '',
  };

  componentDidMount = () => {
    this.renderTitles();
  };

  componentDidUpdate = (prevProps) => {
    const { type } = this.props;

    if (prevProps.type !== type) {
      this.renderTitles();
    }
  };

  renderTitles = () => {
    const { type } = this.props;

    switch (type) {
      case 'influencers':
        this.setState({ title: 'Top Influencers', subtitle: 'Followers' });
        break;
      case 'holrs':
        this.setState({ title: 'Top holrs', subtitle: 'Views' });
        break;
      case 'categories':
        this.setState({ title: 'Top Categories', subtitle: '' });
        break;
      default:
        break;
    }
  };

  renderCellsByType = (row) => {
    const { type, classes } = this.props;

    switch (type) {
      case 'influencers':
        return (
          <Fragment>
            <TableCell className={classes.tableCell}>{row.username}</TableCell>
            <TableCell className={classes.tableCell} numeric>
              {row.followers}
            </TableCell>
          </Fragment>
        );
      case 'holrs':
        return (
          <Fragment>
            <TableCell className={classes.tableCell}>
              {row.user && row.user.username}
            </TableCell>
            <TableCell className={classes.tableCell}>{row.caption}</TableCell>
            <TableCell className={classes.tableCell} numeric>
              {row.views}
            </TableCell>
          </Fragment>
        );
      case 'categories':
        return <TableCell className={classes.tableCell}>{row.category}</TableCell>;
      default:
        return null;
    }
  };

  renderRows = () => {
    const {
      data,
      onItemClick,
      classes,
    } = this.props;

    return data.map((item, index) => (
      <TableRow
        key={item.uuid}
        className={classes.tableRow}
        onClick={() => onItemClick(item)}
        hover
      >
        <TableCell numeric className={`${classes.tableCell} ${classes.tableCellIndex}`}>
          {`${index + 1}.`}
        </TableCell>
        {this.renderCellsByType(item)}
      </TableRow>
    ));
  };

  renderContent = () => {
    const {
      data,
      loading,
      classes,
    } = this.props;

    switch (true) {
      case loading:
        return (
          <TableRow>
            <TableCell colSpan={5} padding="none" className={classes.loading}>
              <CircularProgress thickness={5} size={80} />
            </TableCell>
          </TableRow>
        );
      case !data.length:
        return (
          <TableRow>
            <TableCell colSpan={5} padding="none" className={classes.loading}>
              <Typography variant="display1">Sorry, seems there&apos;s no content to display</Typography>
            </TableCell>
          </TableRow>
        );
      default:
        return this.renderRows();
    }
  }

  render() {
    const { classes } = this.props;
    const { title, subtitle } = this.state;

    return (
      <Card className={classes.card}>
        <CardHeader
          title={title}
          subheader={subtitle}
          classes={{
            content: classes.cardHeader,
            subheader: classes.subheader,
          }}
        />
        <CardContent>
          <Table className={classes.table}>
            <TableBody>{this.renderContent()}</TableBody>
          </Table>
        </CardContent>
      </Card>
    );
  }
}

export default withStyles(theme => ({
  card: {
    padding: `${theme.spacing.unit * 1.5}px ${theme.spacing.unit * 2.5}px`,
  },
  cardHeader: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  subheader: {
    fontSize: '1.25rem',
  },
  table: {
    width: '100%',
    tableLayout: 'auto',
  },
  tableCell: {
    border: 'none',
    padding: theme.spacing.unit,
    fontSize: '1rem',
  },
  tableCellIndex: {
    fontWeight: 'bold',
    width: '5%',
  },
  tableRow: {
    '&&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  loading: {
    textAlign: 'center',
    border: 'none',
  },
}))(DashboardTableCard);
