import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Typography,
  Button,
  Grid,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import MaskedInput from 'react-text-mask';

const ProfileSettingsForm = ({
  values,
  errors,
  touched,
  handleChange,
  handleBlur,
  onPasswordResetButtonClick,
  classes,
}) => (
  <Form>
    <Grid container spacing={24}>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          error={touched.firstName && !!errors.firstName}
          className={classes.formControl}
        >
          <InputLabel htmlFor="firstName">First Name*</InputLabel>
          <Input
            id="firstName"
            value={values.firstName}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="given-name"
          />
          <FormHelperText>{touched.firstName && errors.firstName}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          error={touched.lastName && !!errors.lastName}
          className={classes.formControl}
        >
          <InputLabel htmlFor="lastName">Last Name*</InputLabel>
          <Input
            id="lastName"
            value={values.lastName}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="family-name"
          />
          <FormHelperText>{touched.lastName && errors.lastName}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          error={touched.email && !!errors.email}
          className={classes.formControl}
        >
          <InputLabel htmlFor="email">Email*</InputLabel>
          <Input
            id="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="email"
          />
          <FormHelperText>{touched.email && errors.email}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          error={touched.phone && !!errors.phone}
          className={classes.formControl}
        >
          <InputLabel htmlFor="phone">Phone Number*</InputLabel>
          <Input
            id="phone"
            value={values.phone}
            onChange={handleChange}
            onBlur={handleBlur}
            inputComponent={({ inputRef, ...props }) => (
              <MaskedInput
                {...props}
                ref={inputRef}
                mask={['+', '1', ' ', '(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/]}
                placeholderChar={'\u2000'}
                showMask
                guide={false}
              />
            )}
          />
          <FormHelperText>{touched.phone && errors.phone}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <FormControl
          fullWidth
          error={touched.role && !!errors.role}
          className={classes.formControl}
        >
          <InputLabel htmlFor="role">Role</InputLabel>
          <Input
            id="role"
            type="role"
            value={values.role || ' '}
            onChange={handleChange}
            onBlur={handleBlur}
            disabled
            autoComplete="current-role"
          />
          <FormHelperText>{touched.role && errors.role}</FormHelperText>
        </FormControl>
      </Grid>
      <Grid item xs={6}>
        <Button
          variant="contained"
          color="primary"
          fullWidth
          onClick={onPasswordResetButtonClick}
          className={classes.submitButton}
        >
          Reset Password
        </Button>
      </Grid>
      <Grid item xs={6} />
      <Grid item container xs={12}>
        <Grid item xs={12}>
          <Typography variant="title">Notifications</Typography>
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={(
              <Checkbox
                id="emailNotification"
                checked={values.emailNotification}
                onChange={handleChange}
                onBlur={handleBlur}
                color="primary"
              />
          )}
            label="Email"
          />
        </Grid>
        <Grid item xs={6}>
          <FormControlLabel
            control={(
              <Checkbox
                id="textMessageNotification"
                checked={values.textMessageNotification}
                onChange={handleChange}
                onBlur={handleBlur}
                color="primary"
              />
            )}
            label="Text Messages"
          />
        </Grid>
      </Grid>
    </Grid>
  </Form>
);

ProfileSettingsForm.propTypes = {
  onPasswordResetButtonClick: PropTypes.func,
  values: PropTypes.object.isRequired,
  errors: PropTypes.object.isRequired,
  touched: PropTypes.object.isRequired,
  handleChange: PropTypes.func.isRequired,
  handleBlur: PropTypes.func.isRequired,
  classes: PropTypes.object.isRequired,
};

ProfileSettingsForm.defaultProps = {
  onPasswordResetButtonClick: () => {},
};

export default withStyles(theme => ({
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
}))(ProfileSettingsForm);
