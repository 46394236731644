import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Formik,
  Form,
} from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import { capitalize } from 'lodash';
import MediaBox from '../../widgets/MediaBox';

class ReportedHolrbackModal extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onResolve: PropTypes.func,
    onCancel: PropTypes.func,
    open: PropTypes.bool,
    data: PropTypes.shape({
      uuid: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      reporter: PropTypes.string,
      offender: PropTypes.string,
      originalAuthor: PropTypes.string,
      response: PropTypes.string,
      holrback: PropTypes.string,
      notes: PropTypes.string,
      media: PropTypes.string,
      reportedDate: PropTypes.any,
      banned: PropTypes.bool,
      flagged: PropTypes.bool,
    }),
  };

  static defaultProps = {
    onResolve: () => { },
    onCancel: () => { },
    open: false,
    data: {
      uuid: 0,
      media: '',
      offender: '',
      reporter: '',
      reportedDate: '',
      originalAuthor: '',
      holrback: '',
      notes: '',
      response: '',
      flagged: false,
      banned: false,
    },
  };

  isValid = values => values && values.response;

  parseInitialValues = (data) => {
    const initialValues = {
      uuid: data.uuid,
      offender: data.offender ? `@${data.offender}` : '',
      reporter: data.reporter ? `@${data.reporter}` : '',
      originalAuthor: data.originalAuthor,
      reportedDate: data.createdAt,
      body: data.body,
      reason: data.reason,
      response: data.response,
      holr: data.holr,
      flagged: data.flagged ? data.flagged : false,
      status: capitalize(data.state),
    };

    return initialValues;
  }

  sentValues = ({ uuid, flagged }) => {
    const variables = { uuid, flagged };
    return { variables };
  }

  render() {
    const {
      open,
      onCancel,
      onResolve,
      classes,
      data,
    } = this.props;

    return (
      <Formik
        initialValues={this.parseInitialValues(data)}
        enableReinitialize
      >
        {({
          values,
          handleChange,
          setFieldValue,
        }) => (
          <Dialog
            maxWidth="lg"
            open={open}
            onClose={onCancel}
          >
            <DialogTitle className={classes.modalTitle}>
              Reported holrback
            </DialogTitle>
            <DialogContent>
              <Container>
                <Form>
                  <Grid container spacing={24}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        disabled
                        label="holr Author (offender)"
                        name="offender"
                        value={values.offender}
                        onChange={handleChange}
                        className={classes.formControl}
                      />
                      <MediaBox style={{ width: '100%', height: '18vw' }} mediaUrl={values.media} />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        disabled
                        label="Reported By"
                        name="reporter"
                        value={values.reporter}
                        onChange={handleChange}
                        className={classes.formControl}
                      />
                      <TextField
                        fullWidth
                        disabled
                        label="Reported on"
                        name="reportedDate"
                        value={values.reportedDate}
                        onChange={handleChange}
                        className={classes.formControl}
                        style={{ marginTop: '1rem' }}
                      />
                      <TextField
                        fullWidth
                        disabled
                        label="Original holr author"
                        name="originalAuthor"
                        value={values.originalAuthor}
                        onChange={handleChange}
                        className={classes.formControl}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <TextField
                        fullWidth
                        disabled
                        label="holrback"
                        name="holrback"
                        value={values.holrback}
                        onChange={handleChange}
                        className={classes.formControl}
                      />
                      <FormControl
                        fullWidth
                        className={classes.formControl}
                        style={{ marginTop: '1rem' }}
                      >
                        <InputLabel htmlFor="response">
                          Response*
                        </InputLabel>
                        <Select
                          name="response"
                          id="response"
                          placeholder="Choose"
                          value={values.response}
                          onChange={handleChange}
                        >
                          <MenuItem value="Your reason was sufficient, we banned this user.">Your reason was sufficient, we banned this user.</MenuItem>
                          <MenuItem value="We are looking into this case">We are looking into this case</MenuItem>
                          <MenuItem value="This reason is insufficient">This reason is insufficient</MenuItem>
                        </Select>
                      </FormControl>
                      <TextField
                        fullWidth
                        disabled
                        label="Notes"
                        name="notes"
                        value={values.notes}
                        onChange={handleChange}
                        className={classes.formControl}
                        multiline
                        rows={4}
                      />
                      <Button onClick={() => setFieldValue('flagged', !values.flagged)} color="primary" className={values.flagged ? classes.orangeButton : classes.whiteButton}>
                        {values.flagged ? 'Un-Flag Holr' : 'Flag Holr'}
                      </Button>
                      <Button onClick={() => setFieldValue('banned', !values.banned)} color="primary" className={values.banned ? classes.orangeButton : classes.whiteButton}>
                        {values.banned ? 'Un-Ban' : 'Ban'}
                      </Button>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography className={classes.statusText} variant="subheading">{`Status: ${values.status}`}</Typography>
                    </Grid>
                  </Grid>
                </Form>
              </Container>
            </DialogContent>
            <DialogActions className={classes.modalActions}>
              <Button onClick={onCancel} color="primary">
                Back
              </Button>
              <Button onClick={() => onResolve(this.sentValues(values))} color="primary" variant="contained" disabled={!this.isValid(values)}>
                Resolve
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    );
  }
}

const Container = styled.div`
  width: 40rem;
  margin: 40px auto;
`;


export default withStyles(theme => ({
  reporter: {
    marginBottom: theme.spacing.unit * 3,
  },
  statusText: {
    margin: '1rem 0rem',
    fontSize: '1.4rem',
  },
  orangeButton: {
    backgroundColor: '#FFF8E6',
    color: 'black',
    border: '1px solid #D6CEBE',
    marginRight: '0.8rem',
  },
  whiteButton: {
    backgroundColor: 'white',
    color: 'black',
    border: '1px solid #D5D5D5',
    marginRight: '0.8rem',
  },
  modalActions: {
    borderTop: '1px solid #949494',
    paddingTop: '1rem',
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  modalTitle: {
    borderBottom: '1px solid #949494',
  },
}))(ReportedHolrbackModal);
