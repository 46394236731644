import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { ResponsiveLine } from '@nivo/line';
import {
  Checkbox, FormControlLabel, Button,
} from '@material-ui/core';
import moment from 'moment';
import CollapsableCard from '../../widgets/CollapsableCard';
import DateRangePicker from '../../forms/ReportDateRangePicker';
import fansLegendIcon from '../../../assets/images/fansLegendIcon.svg';
import influencersLegendIcon from '../../../assets/images/influencersLegendIcon.svg';
import usersLegendIcon from '../../../assets/images/usersLegendIcon.svg';
import NoDataPlaceholder from './NoDataPlaceholder';

const checkboxesTypes = ['Users', 'Influencers', 'Fans'];
const colors = {
  Users: '#FF7D38',
  Influencers: '#FDCD69',
  Fans: '#E40000',
};

const icons = {
  Users: usersLegendIcon,
  Influencers: influencersLegendIcon,
  Fans: fansLegendIcon,
};

const UsersReportCard = ({
  classes, onDownloadCsv, data, onDateChange,
}) => {
  const [dataTypes, setDataTypes] = useState(checkboxesTypes);

  const handleDateChange = ({ startDate, endDate }) => onDateChange({ startDate, endDate });

  const handleCheckboxSelection = (type) => {
    if (dataTypes.some(dataType => dataType === type)) {
      setDataTypes(dataTypes.filter(dataType => dataType !== type));
    } else setDataTypes([...dataTypes, type]);
  };

  const isSelected = type => dataTypes.some(dataType => dataType === type);

  const filterData = () => data.filter(dataSet => dataTypes.some(type => type === dataSet.id));

  const hasData = () => data.some(dataSet => dataSet.data.length);

  return (
    <CollapsableCard
      header={(
        <HeaderWrapper>
          <HeaderText>Users/Influencers/Fans</HeaderText>
          <DateRangePicker
            onDateChange={handleDateChange}
          />
        </HeaderWrapper>
      )}
    >
      <CheckboxContainer>
        {checkboxesTypes.map(type => (
          <FormControlLabel
            control={(
              <Checkbox
                color="primary"
                onChange={() => handleCheckboxSelection(type)}
                checked={isSelected(type)}
              />
            )}
            label={type}
            classes={{
              root: classes.checkboxLabelRoot,
              label: classes.checkboxLabel,
            }}
          />
        ))}
      </CheckboxContainer>
      <ContentWrapper>
        {hasData() ? (
          <ChartWrapper>
            <ResponsiveLine
              margin={{
                top: 20, right: 20, bottom: 60, left: 80,
              }}
              colors={value => colors[value.id]}
              data={filterData()}
              yScale={{
                type: 'linear',
              }}
              axisBottom={{
                format: value => moment(value).format('MMM'),
              }}
              curve="cardinal"
              animate
              motionStiffness={120}
              enableSlices="x"
              pointSize={6}
              pointColor="white"
              pointBorderWidth={1}
              pointBorderColor={{ from: 'serieColor', modifiers: [] }}
              axisLeft={{
                format: e => Math.floor(e) === e && e,
              }}
              theme={{
                axis: {
                  ticks: {
                    text: {
                      fontSize: 18,
                      fontFamily: 'ArchivoNarrow-Bold',
                      fill: 'rgb(113,113,113)',
                    },
                  },
                },
                tooltip: {
                  tableCell: {
                    fontSize: 14,
                    fontFamily: 'Raleway-Regular',
                  },
                },
              }}
            />
          </ChartWrapper>
        ) : (
          <NoDataPlaceholder />
        )}
        <BottomWrapper>
          <Button
            color="primary"
            variant="contained"
            onClick={onDownloadCsv}
            className={classes.downloadCsvButton}
            disabled={!hasData()}
          >
            Download Csv
          </Button>
          {hasData() && (
            <LegendWrapper>
              {checkboxesTypes.map(type => (
                <LegendSection>
                  <LegendIcon src={icons[type]} />
                  <LegendText>{type}</LegendText>
                </LegendSection>
              ))}
            </LegendWrapper>
          )}
        </BottomWrapper>
      </ContentWrapper>
    </CollapsableCard>
  );
};

const ContentWrapper = styled.div`
  padding-bottom: 13px;

  & > div > svg {
    overflow: visible !important;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 42px;
`;

const HeaderText = styled.h1`
  font-size: 31px;
  font-family: 'Raleway-Regular';
  margin: 0;
`;

const CheckboxContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 34px;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 67px 0 51px;
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LegendSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;

const LegendIcon = styled.img`
  height: 7px;
  width: 21px;
`;

const LegendText = styled.div`
  font-size: 14px;
  font-family: 'Raleway-Regular';
  color: rgb(0,0,0,0.38);
  margin-left: 7px;
`;

const ChartWrapper = styled.div`
  height: 400px;
  padding-right: 100px;
`;


UsersReportCard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array,
  onDownloadCsv: PropTypes.func,
  onDateChange: PropTypes.func,
};

UsersReportCard.defaultProps = {
  data: [],
  onDownloadCsv: () => {},
  onDateChange: () => {},
};


export default withStyles(() => ({
  checkboxLabel: {
    fontFamily: 'Raleway-Regular',
    fontSize: '14px',
    color: 'rgb(0,0,0,0.38)',
  },
  checkboxLabelRoot: {
    margin: '0 0 0 23px',
  },
  downloadCsvButton: {
    height: '36px',
    width: '220px',
    borderRadius: '2px',
    fontFamily: 'Raleway-SemiBold',
  },
}))(UsersReportCard);
