import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { DateRangePicker } from 'materialui-daterange-picker';
import { TextField, InputAdornment } from '@material-ui/core';
import styled from 'styled-components';
import $ from 'jquery';
import moment from 'moment';
import Calendar from '@material-ui/icons/Event';
import { withStyles } from '@material-ui/core/styles';
import theme from '../../../theme';

const definedRanges = [
  {
    label: 'Year to date',
    startDate: new Date().setFullYear(new Date().getFullYear() - 1),
    endDate: new Date(),
  },
  {
    label: 'Past 30 days',
    startDate: new Date().setDate(new Date().getDate() - 30),
    endDate: new Date(),
  },
  {
    label: 'Past 10 days',
    startDate: new Date().setDate(new Date().getDate() - 10),
    endDate: new Date(),
  },
];

const ReportDateRangePicker = ({ onDateChange, classes, placeholder }) => {
  const [open, setOpen] = useState(false);
  const [dateRange, setDateRange] = useState(null);
  const refInput = useRef();

  // eslint-disable-next-line
  const waitUntilElementExists = (selector, callback) => {
    const el = document.querySelector(selector);
    if (el) {
      return callback(el);
    }
    setTimeout(() => waitUntilElementExists(selector, callback), 10);
  };

  const handleFocus = () => {
    setOpen(true);
    document.activeElement.blur();
    waitUntilElementExists('button[class*=materialui-daterange-picker-makeStyles-outlined-]', () => {
      const el = $(document).find('button[class*=materialui-daterange-picker-makeStyles-outlined-]');
      const classOutlined = el.attr('class').split(/\s+/).find(className => className.includes('materialui-daterange-picker-makeStyles-outlined'));
      $('head').append(`<style>
      .${classOutlined} {
        transition: none !important;
        border: 1px solid ${theme.palette.primary.main} !important;
      }
      </style>`);
    });
    waitUntilElementExists('button[class*=materialui-daterange-picker-makeStyles-filled-]', () => {
      const el = $(document).find('button[class*=materialui-daterange-picker-makeStyles-filled-]');
      const classFilled = el.attr('class').split(/\s+/).find(className => className.includes('materialui-daterange-picker-makeStyles-filled'));
      $('head').append(`<style>
      .${classFilled} {
        transition: none !important;
        background-color: ${theme.palette.primary.main} !important;
      }
      </style>`);

      const text = $(document).find('p[class*=materialui-daterange-picker-makeStyles-contrast-]');
      const classContrast = text.attr('class').split(/\s+/).find(className => className.includes('materialui-daterange-picker-makeStyles-contrast'));
      $('head').append(`<style>
      .${classContrast} {
        transition: none !important;
        color: black !important;
      }
      </style>`);
    });
  };

  const getValue = () => {
    if (!dateRange) return '';
    return `${moment(dateRange.startDate).format('MM/DD/YYYY')} - ${moment(dateRange.endDate).format('MM/DD/YYYY')}`;
  };

  const handleDateChange = (range) => {
    setDateRange(range);
    onDateChange(range);
  };

  return (
    <MainWrapper>
      <TextField
        value={getValue()}
        onFocus={handleFocus}
        inputRef={refInput}
        InputProps={{
          placeholder,
          endAdornment: (
            <InputAdornment
              classes={{ root: classes.inputAdornment }}
              onClick={() => setOpen(true)}
            >
              <Calendar />
            </InputAdornment>
          ),
        }}
        // eslint-disable-next-line
        inputProps={{
          style: {
            width: '185px',
            cursor: 'pointer',
          },
          className: classes.input,
        }}
      />
      <DatePickerWrapper>
        <DateRangePicker
          open={open}
          toggle={() => setOpen(!open)}
          onChange={handleDateChange}
          definedRanges={definedRanges}
        />
      </DatePickerWrapper>
    </MainWrapper>
  );
};

ReportDateRangePicker.propTypes = {
  classes: PropTypes.object.isRequired,
  onDateChange: PropTypes.func,
  placeholder: PropTypes.string,
};

ReportDateRangePicker.defaultProps = {
  onDateChange: () => {},
  placeholder: 'DATE RANGE',
};

const MainWrapper = styled.div`
  position: relative;
  cursor: pointer;
`;

const DatePickerWrapper = styled.div`
  position: absolute;
  top: 40px;
  right: 0;
  width: 700px;
`;

export default withStyles(() => ({
  inputAdornment: {
    cursor: 'pointer',
  },
  input: {
    '&::placeholder': {
      fontSize: 14,
      fontFamily: 'Raleway-Regular',
      color: 'rgb(0,0,0,0.87)',
    },
  },
}))(ReportDateRangePicker);
