import React from 'react';
import PropTypes from 'prop-types';

import LogoImage from '../../../assets/images/Logo/Logo';
import LogoRight from '../../../assets/images/Logo/LogoRight';
import LogoBottom from '../../../assets/images/Logo/LogoBottom';

const chooseImage = (position, style) => {
  let Image;

  switch (position) {
    case 'default': Image = LogoImage; break;
    case 'right': Image = LogoRight; break;
    case 'bottom': Image = LogoBottom; break;
    default: break;
  }

  return <Image {...style} />;
};

const Logo = ({ style, position }) => chooseImage(position, style);

Logo.propTypes = {
  style: PropTypes.shape({
    size: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]),
    fill: PropTypes.string,
  }),
  position: PropTypes.oneOf(['default', 'bottom', 'right']),
};

Logo.defaultProps = {
  style: {
    size: '120',
    fill: 'black',
  },
  position: 'default',
};

export default Logo;
