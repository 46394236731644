import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';

class PaymentsListModal extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        billingStart: PropTypes.string,
        billingEnd: PropTypes.string,
        amount: PropTypes.string,
        admin: PropTypes.string,
        transactionDate: PropTypes.string,
      }),
    ),
    onClose: PropTypes.func,
    onItemClick: PropTypes.func,
    user: PropTypes.object,
    open: PropTypes.bool,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    onClose: () => { },
    onItemClick: () => { },
    user: {},
    open: false,
    loading: false,
  };

  renderRows = () => {
    const {
      data,
      onItemClick,
      classes,
    } = this.props;

    return data.map((payment, index) => (
      <TableRow
        hover
        key={payment.uuid}
        onClick={() => onItemClick(payment)}
        classes={{ hover: classes.tableRow }}
      >
        <TableCell className={classes.tableCell}>{index + 1}</TableCell>
        <TableCell className={classes.tableCell}>{payment.amount}</TableCell>
        <TableCell className={classes.tableCell}>{payment.createdAt}</TableCell>
        <TableCell className={classes.tableCell}>{payment.lastUpdated}</TableCell>
      </TableRow>
    ));
  }

  renderContent = () => {
    const {
      data,
      loading,
      classes,
    } = this.props;

    switch (true) {
      case loading:
        return (
          <TableRow>
            <TableCell colSpan={5} padding="none" className={classes.loading}>
              <CircularProgress thickness={5} size={80} />
            </TableCell>
          </TableRow>
        );
      case data && !data.length:
        return (
          <TableRow>
            <TableCell colSpan={6} padding="none" className={classes.loading}>
              <Typography variant="display1">Sorry, seems there&apos;s no content to display</Typography>
            </TableCell>
          </TableRow>
        );
      default:
        return this.renderRows();
    }
  }

  renderTable = () => {
    const { classes } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>#</TableCell>
            <TableCell className={classes.tableHead}>Amount</TableCell>
            <TableCell className={classes.tableHead}>Created At</TableCell>
            <TableCell className={classes.tableHead}>Last Updated</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.renderContent()}
        </TableBody>
      </Table>
    );
  }

  render = () => {
    const {
      open,
      onClose,
      classes,
      data,
      user,
    } = this.props;

    return (
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={onClose}
      >
        <DialogTitle className={classes.modalTitle}>
          Payments:&nbsp;
          {user.holr}
          &nbsp;
          (
          {data && data.length}
          )
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <TableWrapper>
            {this.renderTable()}
          </TableWrapper>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={onClose}
            color="primary"
            variant="contained"
            className={classes.button}
          >
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const TableWrapper = styled.div`
  width: 900px;
  height: 400px;
  overflow-y: auto;
`;

export default withStyles(theme => ({
  loading: {
    textAlign: 'center',
    padding: `${theme.spacing.unit * 10}px !important`,
  },
  tableCell: {
    border: 'none',
  },
  tableHead: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  tableRow: {
    '&&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  dialogContent: {
    padding: 0,
    borderBottom: '1px solid #DCDCDC',
  },
  dialogActions: {
    padding: '8px 2px',
  },
  button: {
    padding: '8px 44px',
  },
  modalTitle: {
    borderBottom: '2px solid #DCDCDC',
  },
}))(PaymentsListModal);
