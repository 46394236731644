import React from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
} from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { withStyles } from '@material-ui/core/styles';
import PersonOutline from '@material-ui/icons/PersonOutline';
import ErrorIcon from '@material-ui/icons/Error';

const typeIcon = (type, classes) => {
  switch (type) {
    case 'Reported':
      return <ErrorIcon className={classes.errorIcon} />;
    case 'Requested':
      return <PersonOutline className={classes.accountIcon} />;
    default:
      return true;
  }
};

const DashboardNotificationCard = ({
  type, title, subtitle, onReviewClick, classes,
}) => (
  <Card className={classes.card}>
    <CardHeader
      title={type}
      subheader={typeIcon(type, classes)}
      classes={{
        content: classes.content,
        title: classes.type,
      }}
    />
    <CardContent>
      <Typography className={classes.title} variant="title">{title}</Typography>
      <Typography className={classes.subtitle} variant="subheading">{subtitle}</Typography>
    </CardContent>
    <CardActions className={classes.actions}>
      <Button variant="contained" color="primary" onClick={onReviewClick}>
        Review
      </Button>
    </CardActions>
  </Card>
);

DashboardNotificationCard.propTypes = {
  type: PropTypes.oneOf(['Reported', 'Requested']),
  title: PropTypes.string,
  subtitle: PropTypes.string,
  onReviewClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

DashboardNotificationCard.defaultProps = {
  type: 'Reported',
  title: '',
  subtitle: '',
  onReviewClick: () => {},
};

export default withStyles(theme => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    width: '23vw',
    height: '100%',
    margin: '0 auto',
  },
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  type: {
    textTransform: 'uppercase',
    fontSize: '0.9rem',
    letterSpacing: '2px',
  },
  subtitle: {
    marginRight: '20px',
    height: '30px',
    color: theme.palette.text.secondary,
  },
  accountIcon: {
    color: theme.palette.primary.main,
  },
  errorIcon: {
    color: theme.palette.error.main,
  },
  actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'top',
  },
}))(DashboardNotificationCard);
