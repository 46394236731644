import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Formik,
  Form,
} from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Typography,
  Button,
} from '@material-ui/core';
import { validate } from '../../../utils';

class LoginForm extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onForgotPasswordClick: PropTypes.func,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onChange: () => {},
    onSubmit: () => {},
    onForgotPasswordClick: () => {},
  };

  validation = (values) => {
    const { onChange } = this.props;

    const errors = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!validate.email(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.password) {
      errors.password = 'Required';
    }

    onChange(values);

    return errors;
  };

  handleSubmit = async (values, {
    setSubmitting,
    setValues,
    setTouched,
  }) => {
    const { onSubmit } = this.props;

    try {
      await onSubmit(values);
    } catch (error) {
      setValues({
        email: values.email,
        password: '',
      });

      setTouched({
        email: true,
        password: false,
      });
    } finally {
      setSubmitting(false);
    }
  };

  renderForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
  }) => {
    const {
      onForgotPasswordClick,
      classes,
    } = this.props;

    return (
      <Form>
        <FormControl
          fullWidth
          error={touched.email && !!errors.email}
          className={classes.formControl}
        >
          <InputLabel htmlFor="email">Email</InputLabel>
          <Input
            id="email"
            value={values.email}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="email"
          />
          <FormHelperText>{touched.email && errors.email}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          error={touched.password && !!errors.password}
          className={classes.formControl}
        >
          <InputLabel htmlFor="password">Password</InputLabel>
          <Input
            id="password"
            type="password"
            value={values.password}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="current-password"
          />
          <FormHelperText>{touched.password && errors.password}</FormHelperText>
        </FormControl>
        <ButtonContainer>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting || !isValid}
            className={classes.submitButton}
          >
            Login
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={onForgotPasswordClick}
          >
            Forgot Password
          </Button>
        </ButtonContainer>
      </Form>
    );
  };

  render() {
    const { classes } = this.props;

    return (
      <Container>
        <Typography
          align="center"
          variant="headline"
          className={classes.title}
        >
          Login
        </Typography>
        <Formik
          initialValues={{ email: '', password: '' }}
          onSubmit={this.handleSubmit}
          validate={this.validation}
        >
          {props => this.renderForm(props)}
        </Formik>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 400px;
  margin: 40px auto;
`;

const ButtonContainer = styled.div`
  width: 300px;
  margin: 2.5rem auto;
`;

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
}))(LoginForm);
