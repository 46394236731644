import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { config } from '../../config';
import auth from '../../services/auth';
import PasswordRecoveryScreen from '../../screens/PasswordRecoveryScreen';

export class PasswordRecoveryScreenConnector extends React.Component {
  static propTypes = {
    history: PropTypes.object.isRequired,
  };

  state = { open: false, message: '' };

  recoverPassword = async (data) => {
    const { history } = this.props;

    try {
      const payload = { ...data, redirect_url: config.REDIRECT_URL };

      const response = await auth.recover(payload);

      history.push('/login', {
        message: response.data.message,
      });

      return Promise.resolve();
    } catch (error) {
      this.setState({
        open: true,
        message: error.response.data.errors.length && error.response.data.errors.toString(),
      }, () => {
        setTimeout(() => this.setState({ open: false }), 3000);
      });

      return Promise.reject(error);
    }
  }

  render() {
    const { history } = this.props;
    const { open, message } = this.state;

    return (
      <PasswordRecoveryScreen
        formProps={{
          onSubmit: this.recoverPassword,
          onCancel: () => history.push('/login'),
        }}
        toastProps={{
          open,
          message,
        }}
      />
    );
  }
}

export default withRouter(PasswordRecoveryScreenConnector);
