import gql from 'graphql-tag';

export const FETCH_SUBSCRIPTION_PAYMENTS = gql`
  query FetchSubscriptionPayments(
    $first: Int
    $after: String
    $startDate: Date
    $endDate: Date
    $term: String
    $sortColumn: UserPaymentSortColumnsEnum
    $sortOrder: SortOrderEnum
  ) {
    userPayments(
      first: $first
      after: $after
      startDate: $startDate
      endDate: $endDate
      query: $term
      sortColumn: $sortColumn
      sortOrder: $sortOrder
    ) {
      edges {
        node {
          uuid
          user {
            uuid
            firstName
            lastName
            username
            streetAddress
            apartmentNumber
            poBox
            city
            state
            country
            zipCode
            phoneNumber
          }
          currentUserEmail
          createdAt
          payee {
            username
          }
          status
          checkNumber
          cancelReason
          amount
        }
        cursor
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const FETCH_PAYMENTS_DUE = gql`
  query FetchPaymentsDue (
    $first: Int
    $after: String
    $term: String
    $sortColumn: PaymentDueSortColumnEnum
    $sortOrder: SortOrderEnum
    $payUntil: Date!
  ) {
    paymentsDue(
      first: $first
      after: $after
      query: $term
      sortColumn: $sortColumn
      sortOrder: $sortOrder
      payUntil: $payUntil
    ) {
      edges {
        node {
          amount
          user {
            uuid
            username
            firstName
            lastName
            email
            duePayment
            streetAddress
            apartmentNumber
            poBox
            city
            state
            country
            zipCode
            phoneNumber
            lastPaymentDate
            createdAt
            userPayments(first: $first after: $after) {
              nodes {
                amount
                endDate
                createdAt
              }
            }
          }
        }
        cursor
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const CREATE_USER_PAYMENT = gql`
  mutation CreateUserPayment(
    $userUuid: ID!
    $endDate: Date!
    $paymentType: PaymentTypeEnum!
    $checkNumber: String
  ) {
    createUserPayment(
      userUuid: $userUuid
      endDate: $endDate
      paymentType: $paymentType
      checkNumber: $checkNumber
    ) {
      userPayments {
        uuid
      }
    }
  }
`;

export const CANCEL_USER_PAYMENT = gql`
  mutation CancelUserPayment($uuid: ID!, $cancelReason: String!) {
    cancelUserPayment(uuid: $uuid, cancelReason: $cancelReason) {
      payment {
        uuid
      }
    }
  }
`;
export const UPDATE_USER = gql`
  mutation UpdateUser(
    $uuid: ID!
    $streetAddress: String
    $apartmentNumber: String
    $poBox: String
    $city: String
    $state: String
    $country: String
    $zipCode: String
    $phoneNumber: String
  ) {
    updateUser(
      uuid: $uuid,
      streetAddress: $streetAddress
      apartmentNumber: $apartmentNumber,
      poBox: $poBox,
      city: $city,
      state: $state,
      country: $country,
      zipCode: $zipCode,
      phoneNumber: $phoneNumber,
    ) {
      user {
        uuid
      }
    }
  }
`;

export default {
  FETCH_SUBSCRIPTION_PAYMENTS,
  FETCH_PAYMENTS_DUE,
  CREATE_USER_PAYMENT,
  CANCEL_USER_PAYMENT,
};
