import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Query, Mutation } from '@apollo/react-components';
import { Adopt } from 'react-adopt';
import gql from 'graphql-tag';
import { date, notify, messages } from '../../utils';
import CategoryScreen from '../../screens/CategoryScreen';

export const FETCH_CATEGORY = gql`
  query FetchCategory($uuid: ID!) {
    category(uuid: $uuid) {
      uuid
      name
      avatar {
        url
      }
      coverPhoto {
        url
      }
      holrCount
      subscriberCount
      subcategories {
        nodes {
          name
          uuid
        }
      }
      lastMonthStats {
        holrCount
        subscriberCount
      }
      updatedAt
    }
  }
`;

export const UPDATE_CATEGORY = gql`
  mutation UpdateCategory(
    $uuid: ID!
    $name: String
    $avatar: Upload
    $coverPhoto: Upload
    $subcategories: [SubcategoriesAttributesUpdate!]
  ) {
    updateCategory(
      uuid: $uuid
      name: $name
      avatar: $avatar
      coverPhoto: $coverPhoto
      subcategories: $subcategories
    ) {
      category {
        uuid
        name
        avatar {
          url
        }
        coverPhoto {
          url
        }
        holrCount
        subscriberCount
        subcategories {
          nodes {
            name
            uuid
          }
        }
        lastMonthStats {
          holrCount
          subscriberCount
        }
        updatedAt
      }
    }
  }
`;

function mapCategory({ data, loading, error }) {
  if (loading || error) {
    return {
      categoryData: {},
      categoryLoading: loading,
      categoryError: error,
    };
  }

  try {
    return {
      categoryData: {
        uuid: data.category.uuid,
        name: data.category.name,
        avatar: data.category.avatar.url,
        coverPhoto: data.category.coverPhoto.url,
        subcategories: data.category.subcategories.nodes.map(node => ({
          uuid: node.uuid,
          name: node.name,
        })),
        holrs: data.category.holrCount,
        subscribers: data.category.subscriberCount,
        subscriberStats:
          data.category.lastMonthStats && data.category.lastMonthStats.subscriberCount,
        holrStats: data.category.lastMonthStats && data.category.lastMonthStats.holrCount,
        lastUpdated: date.lastUpdated(data.category.updatedAt),
      },
      categoryLoading: loading,
      categoryError: error,
    };
  } catch (mapError) {
    return {
      categoryData: {},
      categoryLoading: loading,
      categoryError: mapError,
    };
  }
}

export const CategoryScreenConnector = ({
  history,
  match: {
    params: { uuid },
  },
}) => {
  const mapper = {
    /* eslint react/prop-types: 0 */
    category: ({ render }) => (
      <Query
        query={FETCH_CATEGORY}
        variables={{ uuid }}
        onError={() => notify.error({ ...messages.FETCH_CATEGORY.error })}
        fetchPolicy="network-only"
      >
        {render}
      </Query>
    ),
    updateCategory: ({ render }) => (
      <Mutation
        mutation={UPDATE_CATEGORY}
        onCompleted={() => notify.success({ ...messages.UPDATE_CATEGORY.success })}
        onError={() => notify.error({ ...messages.UPDATE_CATEGORY.error })}
      >
        {(mutation, result) => render({ mutation, result })}
      </Mutation>
    ),
  };

  return (
    <Adopt mapper={mapper}>
      {({
        category,
        updateCategory: {
          mutation: updateCategory,
          result: { loading: updateCategoryLoading },
        },
      }) => {
        const mappedCategory = mapCategory(category);

        return (
          <CategoryScreen
            category={{ ...mappedCategory }}
            onUpdate={updateCategory}
            updateLoading={updateCategoryLoading}
            onReturnClick={() => history.push('/content')}
          />
        );
      }}
    </Adopt>
  );
};

CategoryScreenConnector.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(CategoryScreenConnector);
