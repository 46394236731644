import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import moment from 'moment';
import { ScreenContainer } from '../../components/layout';
import ReportedHolrsImage from '../../assets/images/ReportedHolrs.png';
import ReportedHolrbacksImage from '../../assets/images/ReportedHolrbacks.png';
import ReportedProblemsImage from '../../assets/images/ReportedProblems.png';
import ReportedUsersImage from '../../assets/images/ReportedUsers.png';

const renderSupportCard = (title, image, lastEdited, onClick, classes) => (
  <Card className={classes.card}>
    <CardContent>
      <Typography variant="h5" component="h3">
        {title}
      </Typography>
      <div>
        <img src={image} alt="report" className={classes.cardImage} />
      </div>
      <Typography className={classes.pos} color="textSecondary">
        {lastEdited ? `Last edited ${moment(lastEdited).format('MMM D, YYYY hh:mm A')}` : ''}
      </Typography>
    </CardContent>
    <CardActions>
      <Button onClick={onClick} className={classes.cardButton} size="large">View</Button>
    </CardActions>
  </Card>
);

const SupportScreen = ({
  classes,
  onClickReportedHolrs,
  onClickReportedHolrbacks,
  onClickReportedProblems,
  onClickReportedUsers,
  lastEditedReportedHolrs,
  lastEditedReportedHolrbacks,
  lastEditedReportedProblems,
  lastEditedReportedUsers,
}) => (
  <ScreenContainer>
    <div className={classes.cardContainer}>
      {renderSupportCard('Reported Holrs', ReportedHolrsImage, lastEditedReportedHolrs, onClickReportedHolrs, classes)}
      {renderSupportCard('Reported Holrbacks', ReportedHolrbacksImage, lastEditedReportedHolrbacks, onClickReportedHolrbacks, classes)}
      {renderSupportCard('Reported Problems', ReportedProblemsImage, lastEditedReportedProblems, onClickReportedProblems, classes)}
      {renderSupportCard('Reported Users', ReportedUsersImage, lastEditedReportedUsers, onClickReportedUsers, classes)}
    </div>
  </ScreenContainer>
);

SupportScreen.propTypes = {
  classes: PropTypes.object.isRequired,
  onClickReportedHolrs: PropTypes.func,
  onClickReportedHolrbacks: PropTypes.func,
  onClickReportedProblems: PropTypes.func,
  onClickReportedUsers: PropTypes.func,
  lastEditedReportedHolrs: PropTypes.string,
  lastEditedReportedHolrbacks: PropTypes.string,
  lastEditedReportedProblems: PropTypes.string,
  lastEditedReportedUsers: PropTypes.string,
};

SupportScreen.defaultProps = {
  onClickReportedHolrs: () => {},
  onClickReportedHolrbacks: () => {},
  onClickReportedProblems: () => {},
  onClickReportedUsers: () => {},
  lastEditedReportedHolrs: '',
  lastEditedReportedHolrbacks: '',
  lastEditedReportedProblems: '',
  lastEditedReportedUsers: '',
};

export default withStyles(() => ({
  cardContainer: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap',
  },
  card: {
    margin: '1rem',
    width: '33.33%',
    display: 'inline-block',
    minWidth: '340px',
  },
  cardImage: {
    display: 'flex',
    width: '50%',
    marginLeft: '25%',
    marginTop: '3rem',
    marginBottom: '3rem',
  },
  cardButton: {
    backgroundColor: '#FEF3DB',
    fontWeight: '800',
  },
}))(SupportScreen);
