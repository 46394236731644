import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { useQuery, useMutation } from '@apollo/react-hooks';
import qs from 'qs';
import { notify, messages, pagination } from '../../utils';
import {
  FETCH_REPORTED_HOLRS,
  FETCH_REPORTED_USERS,
  FETCH_REPORTED_PROBLEMS,
  FETCH_REPORTED_HOLRBACKS,
  RESOLVE_REPORTED_HOLR,
  RESOLVE_REPORTED_USER,
  RESOLVE_REPORTED_PROBLEM,
  UPDATE_BAN_USER,
  UPDATE_UNBAN_USER,
} from './queries';
import {
  mapReportedHolrs,
  mapReportedHolrbacks,
  mapReportedUsers,
  mapReportedProblems,
} from './mapping';
import ReportedContentScreen from '../../screens/ReportedContentScreen';

const options = {
  fetchPolicy: 'network-only',
  variables: { first: 10 },
};

export const ReportedContentScreenConnector = ({ location }) => {
  const reportedHolrs = useQuery(FETCH_REPORTED_HOLRS, options);
  const reportedHolrbacks = useQuery(FETCH_REPORTED_HOLRBACKS, options);
  const reportedProblems = useQuery(FETCH_REPORTED_PROBLEMS, options);
  const reportedUsers = useQuery(FETCH_REPORTED_USERS, options);

  const [resolveReportedProblem] = useMutation(RESOLVE_REPORTED_PROBLEM, {
    onCompleted: () => notify.success({ ...messages.RESOLVE_REPORTED_PROBLEM.success }),
    onError: () => notify.error({ ...messages.RESOLVE_REPORTED_PROBLEM.error }),
  });

  const [resolveReportedHolr] = useMutation(RESOLVE_REPORTED_HOLR, {
    onCompleted: () => notify.success({ ...messages.RESOLVE_REPORTED_HOLR.success }),
    onError: () => notify.error({ ...messages.RESOLVE_REPORTED_HOLR.error }),
  });

  const [resolveReportedUser] = useMutation(RESOLVE_REPORTED_USER, {
    onCompleted: () => notify.success({ ...messages.RESOLVE_REPORTED_USER.success }),
    onError: () => notify.error({ ...messages.RESOLVE_REPORTED_USER.error }),
  });

  const [updateBanUser] = useMutation(UPDATE_BAN_USER, {
    refetchQueries: [{ query: FETCH_REPORTED_USERS, variables: { first: 10 } }],
    onCompleted: () => notify.success({ ...messages.UPDATE_BAN_USER.success }),
    onError: () => notify.error({ ...messages.UPDATE_BAN_USER.error }),
  });

  const [updateUnbanUser] = useMutation(UPDATE_UNBAN_USER, {
    refetchQueries: [{ query: FETCH_REPORTED_USERS, variables: { first: 10 } }],
    onCompleted: () => notify.success({ ...messages.UPDATE_UNBAN_USER.success }),
    onError: () => notify.error({ ...messages.UPDATE_UNBAN_USER.error }),
  });

  const mappedReportedHolrs = mapReportedHolrs(reportedHolrs);
  const mappedReportedHolrbacks = mapReportedHolrbacks(reportedHolrbacks);
  const mappedReportedUsers = mapReportedUsers(reportedUsers);
  const mappedReportedProblems = mapReportedProblems(reportedProblems);

  const { tab } = qs.parse(location.search, { ignoreQueryPrefix: true });

  return (
    <ReportedContentScreen
      reportedHolrs={{ ...mappedReportedHolrs }}
      reportedHolrbacks={{ ...mappedReportedHolrbacks }}
      reportedUsers={{ ...mappedReportedUsers }}
      reportedProblems={{ ...mappedReportedProblems }}
      resolveReportedHolr={variables => resolveReportedHolr({ variables })}
      resolveReportedUser={variables => resolveReportedUser({ variables })}
      resolveReportedProblem={variables => resolveReportedProblem({ variables })}
      onReportedHolrsDateRangeUpdate={dates => reportedHolrs.refetch(dates)}
      onReportedHolrbacksDateRangeUpdate={dates => reportedHolrbacks.refetch(dates)}
      onReportedUsersDateRangeUpdate={dates => reportedUsers.refetch(dates)}
      onReportedProblemsDateRangeUpdate={dates => reportedProblems.refetch(dates)}
      onUpdateBanUser={variables => updateBanUser({ variables })}
      onUpdateUnbanUser={updateUnbanUser}
      loadMoreReportedHolrs={values => pagination.fetchNextPage(reportedHolrs, 'reportedHolrs', values)}
      loadMoreReportedProblems={values => pagination.fetchNextPage(reportedProblems, 'reportedProblems', values)}
      loadMoreReportedUsers={values => pagination.fetchNextPage(reportedUsers, 'reportedUsers', values)}
      loadMoreReportedHolrbacks={values => pagination.fetchNextPage(reportedHolrbacks, 'reportedHolrs', values)}
      totalCountHolrs={mappedReportedHolrs.totalCountHolrs}
      totalCountHolrbacks={mappedReportedHolrbacks.totalCountHolrbacks}
      totalCountUsers={mappedReportedUsers.totalCountUsers}
      totalCountProblems={mappedReportedProblems.totalCountProblems}
      initialTab={+tab || 0}
    />
  );
};

ReportedContentScreenConnector.propTypes = {
  location: PropTypes.object.isRequired,
};

export default withRouter(ReportedContentScreenConnector);
