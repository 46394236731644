import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { PageFooter } from '..';

const ScreenContainer = ({
  children, withFooter, footerProps,
}) => (
  <Fragment>
    <Content>{children}</Content>
    {withFooter && (
      <PageFooter {...footerProps} />
    )}
  </Fragment>
);

ScreenContainer.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]),
  withFooter: PropTypes.bool,
  footerProps: PropTypes.shape({
    ...PageFooter.propTypes,
  }),
};

ScreenContainer.defaultProps = {
  children: null,
  withFooter: false,
  footerProps: {},
};

const Content = styled.div`
  margin: 2rem;
  min-height: 100%;
  flex: 1;
  position: relative;
`;

export default ScreenContainer;
