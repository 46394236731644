import { get, set } from 'lodash';

export const fetchNextPage = async (response, resource, rows, first = 'first', after = 'after') => {
  if (!response || !resource) return;

  const resourceToEdit = get(response.data, resource, false);

  if (!response.fetchMore || !resource || !resourceToEdit || !resourceToEdit.pageInfo) return;

  const { fetchMore } = response;

  const { hasNextPage, endCursor } = resourceToEdit.pageInfo;

  if (!hasNextPage || !response.fetchMore) return;

  await fetchMore({
    variables: {
      [first]: rows,
      [after]: endCursor,
    },
    updateQuery: (prev, { fetchMoreResult }) => {
      if (!fetchMoreResult) return prev;
      set(fetchMoreResult, resource, {
        ...get(fetchMoreResult, resource),
        edges: [...get(prev, resource).edges, ...get(fetchMoreResult, resource).edges],
      });
      return fetchMoreResult;
    },
  });
};

export default { fetchNextPage };
