import React from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from '@material-ui/core';

const DiscardChangesConfirmModal = ({
  onConfirmClick,
  onCancelClick,
  open,
}) => (
  <Dialog
    open={open}
    onClose={onCancelClick}
  >
    <DialogTitle>Are you sure?</DialogTitle>
    <DialogContent>
      <DialogContentText>Are you sure you want to abandon all your changes?</DialogContentText>
    </DialogContent>
    <DialogActions>
      <Button onClick={onCancelClick} color="primary">
        Cancel
      </Button>
      <Button onClick={onConfirmClick} color="primary" variant="contained">
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

DiscardChangesConfirmModal.propTypes = {
  onConfirmClick: PropTypes.func,
  onCancelClick: PropTypes.func,
  open: PropTypes.bool,
};

DiscardChangesConfirmModal.defaultProps = {
  onConfirmClick: () => {},
  onCancelClick: () => {},
  open: false,
};

export default DiscardChangesConfirmModal;
