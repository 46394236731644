import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
} from 'formik';
import styled from 'styled-components';
import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Typography,
  Button,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';

class PasswordResetForm extends Component {
  static propTypes = {
    onChange: PropTypes.func,
    onSubmit: PropTypes.func,
    onCancelClick: PropTypes.func,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onChange: () => {},
    onSubmit: () => {},
    onCancelClick: () => {},
  };

  validate = (values) => {
    const { onChange } = this.props;
    const errors = {};

    if (!values.password) {
      errors.password = 'Required';
    }

    if (!values.confirmPassword) {
      errors.confirmPassword = 'Required';
    } else if (values.password !== values.confirmPassword) {
      errors.confirmPassword = 'Passwords must match';
    }

    onChange(values);
    return errors;
  };

  handleSubmit = (values) => {
    const { onSubmit } = this.props;

    onSubmit(values);
  };

  renderForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    isSubmitting,
    isValid,
  }) => {
    const {
      classes,
      onCancelClick,
    } = this.props;

    return (
      <Form>
        <FormControl
          fullWidth
          className={classes.formControl}
          error={touched.password && !!errors.password}
        >
          <InputLabel>New Password</InputLabel>
          <Input
            value={values.password}
            name="password"
            type="password"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="password"
          />
          <FormHelperText>{touched.password && errors.password}</FormHelperText>
        </FormControl>
        <FormControl
          fullWidth
          className={classes.formControl}
          error={touched.confirmPassword && !!errors.confirmPassword}
        >
          <InputLabel>Confirm New Password</InputLabel>
          <Input
            value={values.confirmPassword}
            name="confirmPassword"
            type="password"
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="new-password"
          />
          <FormHelperText>{touched.confirmPassword && errors.confirmPassword}</FormHelperText>
        </FormControl>
        <ButtonContainer>
          <Button
            className={classes.submitButton}
            variant="contained"
            color="primary"
            type="submit"
            fullWidth
            disabled={isSubmitting || !isValid}
          >
            Reset
          </Button>
          <Button
            variant="outlined"
            onClick={onCancelClick}
            fullWidth
          >
            Cancel
          </Button>
        </ButtonContainer>
      </Form>
    );
  }

  render() {
    const { classes } = this.props;

    return (
      <Container>
        <Typography
          align="center"
          variant="headline"
          className={classes.title}
        >
          Reset Password
        </Typography>
        <Formik
          onSubmit={this.handleSubmit}
          validate={this.validate}
          initialValues={{
            password: '',
            confirmPassword: '',
          }}
        >
          {props => this.renderForm(props)}
        </Formik>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 400px;
  margin: 40px auto;
`;

const ButtonContainer = styled.div`
  width: 300px;
  margin: 2.5rem auto;
  text-align: center;
`;

export default withStyles(theme => ({
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  title: {
    marginBottom: theme.spacing.unit * 3,
    color: theme.palette.common.gray,
  },
}))(PasswordResetForm);
