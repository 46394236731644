import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  Checkbox,
} from '@material-ui/core';
import moment from 'moment-timezone';
import SortFilterArrow from '../SortFilterArrow';
import { sortColumn } from '../../../utils/constants';

class PaymentsDueTable extends Component {
  static propTypes = {
    loading: PropTypes.bool,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        username: PropTypes.string,
        email: PropTypes.string,
        amount: PropTypes.string,
      }),
    ),
    loadMore: PropTypes.func,
    totalCount: PropTypes.number,
    classes: PropTypes.object.isRequired,
    onSelect: PropTypes.func,
    selected: PropTypes.array,
    onSort: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    loading: false,
    loadMore: () => { },
    onSelect: () => { },
    totalCount: 0,
    selected: [],
    onSort: () => {},
  };

  state = {
    page: 0,
    rowsPerPage: 10,
    rowsPageOptions: [],
  };

  handleChangePage = (e, page) => {
    const { loadMore, onSelect } = this.props;
    const { rowsPerPage } = this.state;

    this.setState({ page });
    loadMore(rowsPerPage);
    onSelect([]);
  };

  isSelected = (uuid) => {
    const {
      selected,
    } = this.props;

    const selectedItem = selected.find(item => item.uuid === uuid);

    return !!selectedItem;
  }

  toggleSelected = (payment) => {
    const {
      onSelect,
      selected,
    } = this.props;
    const isSelected = this.isSelected(payment.uuid);

    if (isSelected) {
      onSelect(selected.filter(item => item.uuid !== payment.uuid));

      return;
    }

    onSelect([...selected, payment]);
  }

  handleHeaderClick = (sortCol, sortOrder) => {
    const { onSort } = this.props;
    onSort(sortCol, sortOrder);
  }

  renderRows = () => {
    const {
      data,
      classes,
    } = this.props;

    const { page, rowsPerPage } = this.state;

    return data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(payment => (
        <TableRow
          hover
          key={payment.uuid}
          classes={{
            hover: classes.tableRow,
            root: this.isSelected(payment.uuid) && classes.tableRowSelected,
          }}
        >
          <TableCell
            className={classes.checkboxTableCell}
            onClick={() => this.toggleSelected(payment)}
          >
            <Checkbox
              checked={this.isSelected(payment.uuid)}
              value="unpaid"
              color="primary"
            />
            {`@${payment.username}`}

          </TableCell>
          <TableCell
            onClick={() => this.toggleSelected(payment)}
            className={classes.tableCell}
          >
            {payment.email}

          </TableCell>
          <TableCell
            onClick={() => this.toggleSelected(payment)}
            className={classes.tableCell}
          >
            {payment.amount}

          </TableCell>
          <TableCell
            onClick={() => this.toggleSelected(payment)}
            className={classes.tableCell}
          >
            {payment.lastPaymentDate && moment(payment.lastPaymentDate).format('MM/DD/YYYY hh:mm A')}
          </TableCell>
        </TableRow>
      ));
  };

  renderContent = () => {
    const { data, loading, classes } = this.props;

    switch (true) {
      case loading:
        return (
          <TableRow>
            <TableCell colSpan={4} padding="none" className={classes.loading}>
              <CircularProgress thickness={5} size={80} />
            </TableCell>
          </TableRow>
        );
      case !data.length:
        return (
          <TableRow>
            <TableCell colSpan={3} padding="none" className={classes.loading}>
              <Typography variant="display1">
                Sorry, seems there&apos;s no content to display
              </Typography>
            </TableCell>
          </TableRow>
        );
      default:
        return this.renderRows();
    }
  };

  toggleSelectAll = () => {
    const { selected, onSelect, data } = this.props;
    const { rowsPerPage, page } = this.state;

    const payments = data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    if (selected.length === payments.length) {
      onSelect([]);

      return;
    }

    onSelect(payments);
  }

  render() {
    const {
      totalCount, classes, selected,
      data,
    } = this.props;
    const { rowsPageOptions, rowsPerPage, page } = this.state;

    const dataLenghtOnPage = data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).length;

    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>
                <Checkbox
                  indeterminate={selected.length > 0 && selected.length < dataLenghtOnPage}
                  checked={dataLenghtOnPage > 0 && selected.length === dataLenghtOnPage}
                  onChange={() => this.toggleSelectAll()}
                  value="unpaid"
                  color="primary"
                />
                Influencer
                <SortFilterArrow
                  onClick={this.handleHeaderClick}
                  title={sortColumn.INFLUENCER_USERNAME}
                />
              </TableCell>
              <TableCell className={classes.tableHead}>
                Email
                <SortFilterArrow
                  onClick={this.handleHeaderClick}
                  title={sortColumn.INFLUENCER_EMAIL}
                />
              </TableCell>
              <TableCell className={classes.tableHead}>
                Amount
                <SortFilterArrow
                  onClick={this.handleHeaderClick}
                  title={sortColumn.AMOUNT}
                />
              </TableCell>
              <TableCell className={classes.tableHead}>
                Last Payment
                <SortFilterArrow
                  onClick={this.handleHeaderClick}
                  title={sortColumn.LAST_PAYMENT_DATE}
                />
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderContent()}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={4}
                rowsPerPageOptions={rowsPageOptions}
                rowsPerPage={rowsPerPage}
                page={page}
                count={totalCount}
                SelectProps={{ native: true }}
                onChangePage={this.handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(theme => ({
  loading: {
    textAlign: 'center',
    padding: `${theme.spacing.unit * 10}px !important`,
  },
  tableHead: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  tableCell: {
    border: 'none',
  },
  checkboxTableCell: {
    border: 'none',
    display: 'flex',
    alignItems: 'center',
  },
  tableRow: {
    '&&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  tableRowSelected: {
    backgroundColor: theme.palette.action.hover,
  },
}))(PaymentsDueTable);
