import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  withRouter,
  Switch,
  Route,
  Redirect,
} from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import withAuth from '../../contexts/AuthContext';
import { headers, users } from '../../utils';
import { graphql as client } from '../../config';
import auth from '../../services/auth';
import Dashboard from '../../components/layout/Dashboard';

// Connectors
import {
  CategoryScreenConnector,
  ContentScreenConnector,
  DashboardScreenConnector,
  InfluencerRequestsScreenConnector,
  LegalScreenConnector,
  PaymentsScreenConnector,
  UsersScreenConnector,
  UserScreenConnector,
  ProfileScreenConnector,
  PushNotificationsScreenConnector,
  ReportedContentScreenConnector,
  SubCategoriesScreenConnector,
  SupportScreenConnector,
  ReportsScreenConnector,
} from '..';
import HolrMediaScreen from '../../screens/HolrMediaScreen/HolrMediaScreen';
import HolrMediaListScreen from '../../screens/HolrMediaListScreen/HolrMediaListScreen';

export class DashboardConnector extends Component {
  static propTypes = {
    user: PropTypes.any.isRequired,
    setUser: PropTypes.func.isRequired,
    history: PropTypes.object.isRequired,
    location: PropTypes.object.isRequired,
  };

  setPageTitle = () => {
    const { location } = this.props;

    switch (location.pathname) {
      case '/profile':
        return 'Profile';
      case '/content':
        return 'Content';
      case location.pathname.startsWith('/categories') && location.pathname:
        return 'Categories';
      case location.pathname.startsWith('/subcategories') && location.pathname:
        return 'Subcategories';
      case location.pathname.startsWith('/users') && location.pathname:
        return 'Users';
      case '/payments':
        return 'Payments';
      case '/notifications':
        return 'Notifications';
      case '/support':
        return 'Support';
      case '/reported':
        return 'Reported';
      case '/requests':
        return 'Requests';
      case '/legal':
        return 'Legal';
      case '/reports':
        return 'Reports';
      case location.pathname.startsWith('/holr-media') && location.pathname:
        return 'holr Media';
      default:
        return 'Dashboard';
    }
  }

  logout = async () => {
    const {
      history,
      setUser,
    } = this.props;

    await auth.logout();

    headers.clear();

    users.remove();

    client.resetStore();

    setUser({}, () => history.push('/login'));
  };

  render() {
    const {
      user,
      history,
      location,
    } = this.props;

    if (isEmpty(user) && !headers.check()) {
      return <Redirect to="/login" />;
    }

    if (!isEmpty(user) && !user.adminRole && location.pathname !== `/holr-media/${user.uuid}`) {
      return <Redirect to={`/holr-media/${user.uuid}`} />;
    }

    return (
      <Dashboard
        isAdmin={!!user.adminRole}
        topPanelProps={{
          title: this.setPageTitle(),
          userName: user.firstName ? user.firstName : 'User',
          isAdmin: !!user.adminRole,
          onProfileClick: () => history.push('/profile'),
          onLogoutClick: this.logout,
        }}
        navigationPanelProps={{
          isAdmin: !!user.adminRole,
          onNavigationItemClick: key => history.push(key),
        }}
      >
        <Switch>
          <Route exact path="/" component={DashboardScreenConnector} />
          <Route path="/profile" component={ProfileScreenConnector} />
          <Route path="/content" component={ContentScreenConnector} />
          <Route path="/reported" component={ReportedContentScreenConnector} />
          <Route path="/categories/:uuid" component={CategoryScreenConnector} />
          <Route path="/subcategories/:uuid" component={SubCategoriesScreenConnector} />
          <Route path="/support" component={SupportScreenConnector} />
          <Route path="/reports" component={ReportsScreenConnector} />
          <Route path="/notifications" component={PushNotificationsScreenConnector} />
          <Route path="/legal" component={LegalScreenConnector} />
          <Route path="/requests" component={InfluencerRequestsScreenConnector} />
          <Route path="/payments" component={PaymentsScreenConnector} />
          <Route exact path="/users" component={UsersScreenConnector} />
          <Route path="/users/:uuid" component={UserScreenConnector} />
          <Route path="/holr-media/:uuid" component={HolrMediaListScreen} />
          <Route path="/holr-media" component={HolrMediaScreen} />
        </Switch>
      </Dashboard>
    );
  }
}

export default withRouter(withAuth(DashboardConnector));
