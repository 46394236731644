import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Button, RadioGroup, FormControlLabel, Radio, Checkbox,
} from '@material-ui/core';
import { Bar } from 'react-chartjs-2';
import CollapsableCard from '../../widgets/CollapsableCard';
import DateRangePicker from '../../forms/ReportDateRangePicker';
import NoDataPlaceholder from '../UsersReportCard/NoDataPlaceholder';

const dataTypes = ['CUMULATIVE', 'MONTHLY'];
const dataTypeLabels = {
  CUMULATIVE: 'Cumulative Data',
  MONTHLY: 'Monthly Data',
};
const checkboxesTypes = ['holr Rev', 'App Store Rev', 'Influencers Rev'];
const dataKeys = ['Apple', 'Google'];
const colors = {
  Apple: '#FDCD69',
  Google: '#E64F00',
};

const RevenuesReportCard = ({
  classes, onDownloadCsv, data, onChange,
}) => {
  const [dateRange, setDateRange] = useState({});
  const [dataType, setDataType] = useState('CUMULATIVE');
  const [checkboxTypes, setCheckboxTypes] = useState(checkboxesTypes);

  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
    onChange({ startDate, endDate }, dataType);
  };

  const handleDataType = (type) => {
    setDataType(type);
    onChange(dateRange, type);
  };

  const handleCheckboxSelection = (type) => {
    if (checkboxTypes.some(checkboxType => checkboxType === type)) {
      setCheckboxTypes(checkboxTypes.filter(checkboxType => checkboxType !== type));
    } else setCheckboxTypes([...checkboxTypes, type]);
  };

  const filterDataTypes = () => {
    if (!data) return {};
    return {
      ...data,
      datasets: data.datasets
        .filter(dataset => checkboxTypes.some(type => dataset.label.includes(type))),
    };
  };

  const isSelected = type => checkboxTypes.some(checkboxType => checkboxType === type);

  return (
    <CollapsableCard
      header={(
        <HeaderWrapper>
          <HeaderText>Revenue</HeaderText>
          <DateRangePicker
            onDateChange={handleDateChange}
          />
        </HeaderWrapper>
      )}
    >
      <ContentWrapper>
        <RadioGroupWrapper>
          <RadioGroup
            row
            value={dataType}
            onChange={(_, value) => handleDataType(value)}
          >
            {dataTypes.map(type => (
              <FormControlLabel
                value={type}
                control={<Radio color="primary" />}
                label={dataTypeLabels[type]}
                classes={{
                  root: classes.radioRoot,
                  label: classes.radioLabel,
                }}
              />
            ))}
          </RadioGroup>
        </RadioGroupWrapper>
        {data && data.labels.length ? (
          <ChartWrapper>
            <Bar
              data={filterDataTypes()}
              options={{
                maintainAspectRatio: false,
                legend: {
                  display: false,
                },
                scales: {
                  xAxes: [{
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                      maxRotation: 0,
                      minRotation: 0,
                      fontFamily: 'ArchivoNarrow-Bold',
                      fontSize: 18,
                      fontColor: 'rgb(113,113,113)',
                    },
                  }],
                  yAxes: [{
                    stacked: true,
                    ticks: {
                      fontFamily: 'ArchivoNarrow-Bold',
                      fontSize: 18,
                      fontColor: 'rgb(113,113,113)',
                    },
                  }],
                },
                tooltips: {
                  titleFontSize: 0,
                  backgroundColor: 'white',
                  bodyFontColor: 'black',
                  bodyFontFamily: 'Raleway-Regular',
                  borderColor: 'gray',
                  borderWidth: 1,
                },
              }}
            />
          </ChartWrapper>
        ) : (
          <NoDataPlaceholder />
        )}
        <OptionsLegendWrapper>
          <OptionsWrapper>
            {checkboxesTypes.map(type => (
              <FormControlLabel
                control={(
                  <Checkbox
                    color="primary"
                    onChange={() => handleCheckboxSelection(type)}
                    checked={isSelected(type)}
                  />
                )}
                label={type}
                classes={{
                  root: classes.checkboxLabelRoot,
                  label: classes.checkboxLabel,
                }}
              />
            ))}
          </OptionsWrapper>
          {data && data.labels.length > 0 && (
            <LegendWrapper>
              {dataKeys.map(key => (
                <LegendSection>
                  <LegendIcon style={{ backgroundColor: colors[key] }} />
                  <LegendText>{key}</LegendText>
                </LegendSection>
              ))}
            </LegendWrapper>
          )}
        </OptionsLegendWrapper>
        <BottomWrapper>
          <Button
            color="primary"
            variant="contained"
            onClick={onDownloadCsv}
            className={classes.downloadCsvButton}
            disabled={data && data.labels.length === 0}
          >
            Download Csv
          </Button>
        </BottomWrapper>
      </ContentWrapper>
    </CollapsableCard>
  );
};

const ContentWrapper = styled.div`
  padding-bottom: 13px;

  & > div > svg {
    overflow: visible !important;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 42px;
`;

const HeaderText = styled.h1`
  font-size: 31px;
  font-family: 'Raleway-Regular';
  margin: 0;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 67px 0 51px;
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LegendSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;

const LegendIcon = styled.div`
  height: 14px;
  width: 21px;
`;

const LegendText = styled.div`
  font-size: 14px;
  font-family: 'Raleway-Regular';
  color: rgb(0,0,0,0.38);
  margin-left: 7px;
`;

const RadioGroupWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 23px;
`;

const ChartWrapper = styled.div`
  height: 400px;
  padding: 0 100px 0 66px;
`;

const OptionsLegendWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  padding-right: 80px;
  margin: 39px 0 23px 0;
`;

const OptionsWrapper = styled.div`
  display: flex;
`;

RevenuesReportCard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.object,
  onDownloadCsv: PropTypes.func,
  onChange: PropTypes.func,
};

RevenuesReportCard.defaultProps = {
  data: {},
  onDownloadCsv: () => {},
  onChange: () => {},
};


export default withStyles(() => ({
  radioLabel: {
    fontFamily: 'Raleway-Regular',
    fontSize: '14px',
    color: 'rgb(0,0,0,0.38)',
  },
  radioRoot: {
    margin: '0 0 0 23px',
  },
  downloadCsvButton: {
    height: '36px',
    width: '220px',
    borderRadius: '2px',
    fontFamily: 'Raleway-SemiBold',
  },
  checkboxLabel: {
    fontFamily: 'Raleway-Regular',
    fontSize: '14px',
    color: 'rgb(0,0,0,0.38)',
  },
  checkboxLabelRoot: {
    margin: '0 0 0 23px',
  },
}))(RevenuesReportCard);
