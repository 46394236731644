import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  IconButton,
} from '@material-ui/core';
import Next from '@material-ui/icons/NavigateNext';

class ReportedContentListModal extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        reportedBy: PropTypes.string,
        reason: PropTypes.string,
        status: PropTypes.string,
        reportedDate: PropTypes.string,
      }),
    ),
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onItemClick: PropTypes.func,
    user: PropTypes.shape({
      holr: PropTypes.string,
    }),
    open: PropTypes.bool,
    loading: PropTypes.bool,
  };

  static defaultProps = {
    data: [],
    onClose: () => { },
    onCancel: () => { },
    onItemClick: () => { },
    user: { holr: '' },
    open: false,
    loading: false,
  };

  renderRows = () => {
    const {
      data,
      onItemClick,
      classes,
    } = this.props;

    return data.map(content => (
      <TableRow
        hover
        key={content.uuid}
        onClick={() => onItemClick(content)}
        classes={{
          hover: classes.tableRow,
        }}
      >
        <TableCell className={classes.tableCell}>{content.reportedBy}</TableCell>
        <TableCell className={classes.tableCell}>{content.reason}</TableCell>
        <TableCell className={classes.tableCell}>{content.status}</TableCell>
        <TableCell className={classes.tableCell}>{content.reportedDate}</TableCell>
        <TableCell className={classes.tableCell}>
          <IconButton>
            <Next />
          </IconButton>
        </TableCell>
      </TableRow>
    ));
  }

  renderContent = () => {
    const {
      data,
      loading,
      classes,
    } = this.props;

    switch (true) {
      case loading:
        return (
          <TableRow>
            <TableCell colSpan={5} padding="none" className={classes.loading}>
              <CircularProgress thickness={5} size={80} />
            </TableCell>
          </TableRow>
        );
      case data && !data.length:
        return (
          <TableRow>
            <TableCell colSpan={6} padding="none" className={classes.loading}>
              <Typography variant="display1">Sorry, seems there&apos;s no content to display</Typography>
            </TableCell>
          </TableRow>
        );
      default:
        return this.renderRows();
    }
  }

  renderTable = () => {
    const { classes } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>Reported By</TableCell>
            <TableCell className={classes.tableHead}>Reason</TableCell>
            <TableCell className={classes.tableHead}>Status</TableCell>
            <TableCell className={classes.tableHead}>Reported Date</TableCell>
            <TableCell className={classes.tableHead} />
          </TableRow>
        </TableHead>
        <TableBody>
          {this.renderContent()}
        </TableBody>
      </Table>
    );
  }

  render = () => {
    const {
      open,
      onCancel,
      onClose,
      classes,
      data,
      user,
    } = this.props;

    return (
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={onCancel}
      >
        <DialogTitle className={classes.modalTitle}>
          {`Reported Content: ${user.holr} (${data && data.length})`}
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <TableWrapper>
            {this.renderTable()}
          </TableWrapper>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button onClick={onClose} color="primary" variant="contained" className={classes.button}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const TableWrapper = styled.div`
  width: 900px;
  height: 400px;
  overflow-y: auto;
`;

export default withStyles(theme => ({
  loading: {
    textAlign: 'center',
    padding: `${theme.spacing.unit * 10}px !important`,
  },
  tableCell: {
    border: 'none',
  },
  tableHead: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  tableRow: {
    '&&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  dialogContent: {
    padding: 0,
    borderBottom: '1px solid #DCDCDC',
  },
  dialogActions: {
    padding: '8px 2px',
  },
  button: {
    padding: '8px 44px',
  },
  modalTitle: {
    borderBottom: '2px solid #DCDCDC',
  },
}))(ReportedContentListModal);
