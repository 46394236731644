import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TableSortLabel,
  TablePagination,
} from '@material-ui/core';
import { sortColumn, sortOrder } from '../../../utils/constants';

class CategoriesTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        name: PropTypes.string,
        subcategories: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        followers: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        holrs: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        lastUpdated: PropTypes.string,
      }),
    ),
    loading: PropTypes.bool,
    onItemClick: PropTypes.func,
    onCategoriesSort: PropTypes.func,
    classes: PropTypes.object.isRequired,
    loadMore: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    data: [],
    loading: false,
    onItemClick: () => {},
    onCategoriesSort: () => {},
  };

  state = {
    page: 0,
    rowsPerPage: 10,
    rowsPageOptions: [],
    order: 'asc',
  };

  handleChangePage = (e, page) => {
    const { loadMore } = this.props;
    const { rowsPerPage } = this.state;

    this.setState({ page });
    loadMore(rowsPerPage);
  };

  handleSort = (column) => {
    const { order } = this.state;
    const { onCategoriesSort } = this.props;

    switch (order) {
      case 'desc':
        onCategoriesSort(column, sortOrder.ASCENDING);
        this.setState({ order: 'asc' });
        break;
      case 'asc':
        onCategoriesSort(column, sortOrder.DESCENDING);
        this.setState({ order: 'desc' });
        break;
      default:
        break;
    }
  };

  renderRows = () => {
    const { data, onItemClick, classes } = this.props;

    const { page, rowsPerPage } = this.state;

    return data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map(category => (
      <TableRow
        hover
        key={category.uuid}
        onClick={() => onItemClick(category)}
        classes={{
          hover: classes.tableRow,
        }}
      >
        <TableCell className={classes.tableCell}>{category.name}</TableCell>
        <TableCell className={classes.tableCell}>{category.subcategories}</TableCell>
        <TableCell className={classes.tableCell}>{category.followers}</TableCell>
        <TableCell className={classes.tableCell}>{category.holrs}</TableCell>
        <TableCell className={classes.tableCell}>{category.lastUpdated}</TableCell>
      </TableRow>
    ));
  };

  renderContent = () => {
    const { data, loading, classes } = this.props;

    switch (true) {
      case loading:
        return (
          <TableRow>
            <TableCell colSpan={5} padding="none" className={classes.loading}>
              <CircularProgress thickness={5} size={80} />
            </TableCell>
          </TableRow>
        );
      case !data.length:
        return (
          <TableRow>
            <TableCell colSpan={5} padding="none" className={classes.loading}>
              <Typography variant="display1">
                Sorry, seems there&apos;s no content to display
              </Typography>
            </TableCell>
          </TableRow>
        );
      default:
        return this.renderRows();
    }
  };

  render() {
    const { totalCount, classes } = this.props;
    const {
      rowsPageOptions,
      rowsPerPage,
      page,
      order,
    } = this.state;

    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>Category</TableCell>
              <TableCell className={classes.tableHead}># of Sub-Categories</TableCell>
              <TableCell className={classes.tableHead}># of Followers</TableCell>
              <TableCell className={classes.tableHead}># of holrs</TableCell>
              <TableCell>
                <TableSortLabel
                  active
                  direction={order}
                  onClick={() => this.handleSort(sortColumn.UPDATED_AT)}
                  className={classes.tableHead}
                >
                  Last Updated
                </TableSortLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.renderContent()}</TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={5}
                rowsPerPageOptions={rowsPageOptions}
                rowsPerPage={rowsPerPage}
                page={page}
                count={totalCount}
                SelectProps={{ native: true }}
                onChangePage={this.handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(theme => ({
  loading: {
    textAlign: 'center',
    padding: `${theme.spacing.unit * 10}px !important`,
  },
  tableHead: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  tableCell: {
    border: 'none',
    '&&:nth-child(5)': {
      minWidth: 150,
    },
  },
  tableRow: {
    '&&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(CategoriesTable);
