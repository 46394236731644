import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardContent,
  Typography,
  FormControlLabel,
  Switch,
  Grid,
} from '@material-ui/core';

const InfoCard = ({
  type,
  title,
  subtitle,
  icon,
  subtitleIcon,
  showToggle,
  toggleLabel,
  toggled,
  onToggle,
  onCardClick,
  classes,
}) => (
  <Card onClick={onCardClick} className={classes.card}>
    <CardContent className={classes.cardContent}>
      <Grid container>
        <Grid item container direction="row">
          <Grid item container xs={10}>
            <Grid item container alignItems="center" justify="space-between">
              <Grid item xs={8}>
                <Typography
                  color="textSecondary"
                  variant="subheading"
                >
                  {type}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                {showToggle && (
                  <FormControlLabel
                    control={(
                      <Switch
                        onClick={(e) => {
                          e.stopPropagation();
                          onToggle(e);
                        }}
                        disableRipple
                        color="primary"
                        classes={{
                          switchBase: classes.toggle,
                        }}
                      />
                    )}
                    onClick={e => e.stopPropagation()}
                    checked={toggled}
                    label={toggleLabel}
                    labelPlacement="start"
                    classes={{
                      label: classes.toggleLabel,
                    }}
                  />
                )}
              </Grid>
            </Grid>
            <Grid item>
              <Typography
                variant="headline"
                component="h2"
                className={classes.title}
              >
                {title}
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs={2}>
            {icon}
          </Grid>
        </Grid>
        <Grid item container xs={12} alignItems="center">
          <Grid item>
            {subtitleIcon}
          </Grid>
          <Grid item>
            <Typography
              color="textSecondary"
              variant="subheading"
              className={classes.subtitle}
            >
              {subtitle}
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </CardContent>
  </Card>
);

InfoCard.propTypes = {
  type: PropTypes.string,
  title: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  subtitle: PropTypes.string,
  icon: PropTypes.object,
  subtitleIcon: PropTypes.object,
  showToggle: PropTypes.bool,
  toggleLabel: PropTypes.string,
  toggled: PropTypes.bool,
  onToggle: PropTypes.func,
  onCardClick: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

InfoCard.defaultProps = {
  type: '',
  title: '',
  subtitle: '',
  icon: null,
  subtitleIcon: null,
  showToggle: false,
  toggleLabel: '',
  toggled: false,
  onToggle: () => {},
  onCardClick: () => {},
};

export default withStyles(theme => ({
  card: {
    width: 400,
    cursor: 'pointer',
  },
  cardContent: {
    padding: '16px !important',
  },
  title: {
    fontSize: '2rem',
  },
  subtitle: {
    marginLeft: theme.spacing.unit,
  },
  toggle: {
    height: 'auto',
  },
  toggleLabel: {
    color: theme.palette.text.secondary,
    textTransform: 'uppercase',
  },
}))(InfoCard);
