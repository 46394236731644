export const get = () => ({
  accessToken: localStorage.getItem('accessToken'),
  uid: localStorage.getItem('uid'),
  client: localStorage.getItem('client'),
});

export const clear = () => {
  localStorage.removeItem('accessToken');
  localStorage.removeItem('uid');
  localStorage.removeItem('client');
};

export const store = ({ headers }) => {
  localStorage.setItem('accessToken', headers['access-token']);
  localStorage.setItem('uid', headers.uid);
  localStorage.setItem('client', headers.client);
};

export const check = () => {
  const keys = ['accessToken', 'uid', 'client'];
  let response = true;

  keys.forEach((key) => {
    if (!localStorage.getItem(key)) {
      response = false;
    }
  });

  return response;
};

export default {
  get,
  clear,
  store,
  check,
};
