import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { withRouter } from 'react-router-dom';
import { Grid } from '@material-ui/core';
import styled from 'styled-components';
import { Query } from '@apollo/react-components';
import gql from 'graphql-tag';
import { ScreenContainer } from '../../components/layout';
import { SearchBox } from '../../components/forms';
import { pagination } from '../../utils';
import HolrMediaUsersListTable from '../../components/tables/HolrMediaUsersListTable';

export const FETCH_USERS = gql`
  query FetchUsers(
    $query: String
    $first: Int
    $after: String
    $sortColumn: UserSortColumnsEnum
    $sortOrder: SortOrderEnum
  ) {
    fullUsers(
      appRole: INFLUENCER
      query: $query
      first: $first
      after: $after
      sortColumn: $sortColumn
      sortOrder: $sortOrder
    ) {
      edges {
        node {
          uuid
          username
          firstName
          fullName
          lastName
          email
          updatedAt
        }
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

function mapUsers({ error, data, loading }) {
  if (loading || error) {
    return { data: [], loading, error };
  }

  try {
    return {
      data: data.fullUsers.edges.map(edge => ({ ...edge.node })),
      loading,
      error,
      totalCount: data.fullUsers.totalCount,
    };
  } catch (mapError) {
    return { data: [], loading, error: mapError };
  }
}

const SearchBoxWrapper = styled.div`
  margin-top: 6px;
  margin-right: 2rem;
`;

const TableWrapper = styled.div`
  margin-top: 3rem;
`;


export const HolrMediaScreen = ({ history }) => (
  <Query
    query={FETCH_USERS}
    fetchPolicy="network-only"
  >
    {(users) => {
      const { data, totalCount } = mapUsers(users);


      return (
        <ScreenContainer>
          <Grid container>
            <Grid item xs={6}>
              <SearchBoxWrapper>
                <SearchBox
                  onSearch={query => users.refetch({ query })}
                  placeholder="Search influencers"
                />
              </SearchBoxWrapper>
            </Grid>
          </Grid>
          <TableWrapper>
            <HolrMediaUsersListTable
              totalCount={totalCount}
              data={data}
              onItemClick={uuid => history.push(`/holr-media/${uuid}`)}
              loading={users.loading}
              loadMore={values => pagination.fetchNextPage(users, 'fullUsers', values)}
              onRequestSort={(sortColumn, sortOrder) => {
                users.refetch({ sortColumn, sortOrder });
              }}
            />
          </TableWrapper>
        </ScreenContainer>
      );
    }}
  </Query>

);

HolrMediaScreen.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(withStyles({
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
})(HolrMediaScreen));
