import React from 'react';
import styled from 'styled-components';
import { ScreenContainer } from '../../components/layout';
import {
  UsersReportCard,
  SubscribersReportCard,
  TopInfluencersReportCard,
  RevenuesReportCard,
} from '../../components/charts';

export const ReportsScreen = () => (
  <ScreenContainer>
    <ChartContainer>
      <UsersReportCard />
    </ChartContainer>
    <ChartContainer>
      <SubscribersReportCard />
    </ChartContainer>
    <ChartContainer>
      <TopInfluencersReportCard />
    </ChartContainer>
    <ChartContainer>
      <RevenuesReportCard />
    </ChartContainer>
  </ScreenContainer>
);

const ChartContainer = styled.div`
  margin: 1rem;
`;

export default ReportsScreen;
