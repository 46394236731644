import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Snackbar,
  SnackbarContent,
} from '@material-ui/core';

class Toast extends Component {
  static propTypes = {
    open: PropTypes.bool,
    message: PropTypes.string,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    open: false,
    message: '',
  };

  constructor(props) {
    super(props);

    const { open } = this.props;

    this.state = { open };
  }

  componentDidUpdate = (prevProps) => {
    const { open } = this.props;

    if (prevProps.open !== open) {
      this.setState({ open });
    }
  };

  handleClose = () => this.setState({ open: false });

  render() {
    const { message, classes } = this.props;
    const { open } = this.state;

    return (
      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={open}
        onClick={this.handleClose}
        onClose={this.handleClose}
        autoHideDuration={3000}
        className={classes.snackbar}
      >
        <SnackbarContent
          className={classes.content}
          message={message}
        />
      </Snackbar>
    );
  }
}

export default withStyles(theme => ({
  snackbar: {
    margin: theme.spacing.unit * 2,
  },
  content: {
    backgroundColor: theme.palette.secondary.main,
    color: theme.palette.text.primary,
  },
}))(Toast);
