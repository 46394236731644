import gql from 'graphql-tag';

export const USER_STATS = gql`
  query UserStats(
    $startDate: Date!
    $endDate: Date!
  ) {
    userStats(
      startDate: $startDate
      endDate: $endDate
    ) {
      stats {
        date
        fansCount
        influencersCount
        usersCount
      }
    }
  }
`;

export default {
  USER_STATS,
};
