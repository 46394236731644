import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Button,
} from '@material-ui/core';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';
import uploadFilesImage from '../../../assets/images/uploadFiles.svg';
import uploadErrorImage from '../../../assets/images/uploadError.svg';
import uploadSuccessImage from '../../../assets/images/uploadSuccess.svg';
import uploadCancelActive from '../../../assets/images/uploadCancelActive.svg';
import uploadCancelInactive from '../../../assets/images/uploadCancelInactive.svg';
import steps from './steps';

const { FILE_SELECTION, UPLOAD_IN_PROGRESS } = steps;

const compressedFileFormats = ['zip', 'rar', 'gz'];

const UploadFileModal = ({
  open, onClose, classes, onClickNext, onFileSelection, selectedFiles, onSaveClose,
  isUploadCompleted, currentStep, onAddTag,
}) => {
  const selectFileInput = useRef(null);
  const isUploading = currentStep === UPLOAD_IN_PROGRESS;
  const hasSelectedFiles = selectedFiles && selectedFiles.length > 0;

  const hasFilesToUpload = () => (
    selectedFiles.some(selectedFile => selectedFile.importedMedia.length));

  const handleDrop = item => onFileSelection(item.files);
  // eslint-disable-next-line
  const [_, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: handleDrop,
  });

  const handleInputChange = (e) => {
    onFileSelection([...e.target.files]);
    if (selectFileInput && selectFileInput.current) selectFileInput.current.value = null;
  };

  const handleSaveClose = () => {
    onSaveClose();
    onClose();
  };

  const handleChooseFile = () => {
    if (selectFileInput && selectFileInput.current) selectFileInput.current.click();
  };

  const renderStatus = (error, progress = 100) => {
    if (error) {
      return (
        <StatusWrapper>
          <ErrorMessage>{error}</ErrorMessage>
          <StatusImage src={uploadErrorImage} />
        </StatusWrapper>
      );
    }
    if (progress < 100) {
      return (<StatusWrapper><StatusImage src={uploadCancelInactive} clickable /></StatusWrapper>);
    }
    if (progress === 100) {
      return (<StatusWrapper><StatusImage src={uploadSuccessImage} /></StatusWrapper>);
    }
    return (<StatusWrapper />);
  };

  const renderProgressBar = progress => (
    <ProgressBarBase>
      <ProgressBar progress={progress} />
    </ProgressBarBase>
  );

  const renderDialogActions = () => (isUploading ? (
    <DialogActions className={`${classes.selectionDialogActions} ${classes.uploadDialogOptions}`}>
      <Button onClick={handleSaveClose} color="primary" disabled={!isUploadCompleted} className={classes.dialogActionButton}>
        SAVE & CLOSE
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={onAddTag}
        className={classes.dialogActionButton}
        disabled={!isUploadCompleted || !hasFilesToUpload()}
      >
        ADD TAG
      </Button>
    </DialogActions>
  ) : (
    <DialogActions className={classes.selectionDialogActions}>
      <Button onClick={onClose} color="primary" className={classes.dialogActionButton}>
        Close
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={onClickNext}
        className={classes.dialogActionButton}
        disabled={!hasSelectedFiles}
      >
        Next
      </Button>
    </DialogActions>
  ));

  const renderDragDropContent = () => (
    <DragDropContainer ref={drop}>
      <UploadFilesImage
        src={uploadFilesImage}
      />
      <DragDropTitle>Drag & drop your files!</DragDropTitle>
      <Button
        variant="contained"
        color="primary"
        className={classes.chooseFilesButton}
        onClick={handleChooseFile}
      >
        CHOOSE FILES
      </Button>
      <HiddenInput type="file" accept=".gz,.rar,zip,application/octet-stream,application/zip,application/x-zip,application/x-zip-compressed,video/mp4,video/avi,application/x-matroska,video/quicktime,image/jpeg,image/png" ref={selectFileInput} onChange={handleInputChange} multiple />
    </DragDropContainer>
  );

  const renderFileList = () => (
    <SelectedFileWrapper>
      {selectedFiles.map(selectedFile => (
        <FileInfoWrapper key={selectedFile.file.name}>{selectedFile.file.name}</FileInfoWrapper>
      ))}
    </SelectedFileWrapper>
  );

  const renderSelectionContent = () => (hasSelectedFiles
    ? renderFileList() : renderDragDropContent());

  const renderUploadContent = () => (
    <SelectedFileWrapper>
      {selectedFiles.map(({
        file, progress, error, importedMedia, ignoredFiles,
      }) => {
        if (file && compressedFileFormats.includes(file.name.split('.').slice(-1)[0])) {
          return (
            <ZipWrapper>
              <FileInfoWrapper key={file && file.name} progress={progress}>
                <ProgressWrapper>
                  {file && file.name}
                  {renderProgressBar(progress)}
                </ProgressWrapper>
                {renderStatus(error, progress)}
              </FileInfoWrapper>
              {importedMedia.map(media => (
                <FileInfoWrapper key={media && media.name}>
                  <ZipMediaWrapper>
                    {media && media.name}
                  </ZipMediaWrapper>
                  {renderStatus()}
                </FileInfoWrapper>
              ))}
              {ignoredFiles.map(media => (
                <FileInfoWrapper key={media && media.name}>
                  <ZipMediaWrapper>
                    {media && media.fileName}
                  </ZipMediaWrapper>
                  {renderStatus(media.reason)}
                </FileInfoWrapper>
              ))}
            </ZipWrapper>
          );
        }
        return (
          <FileInfoWrapper key={file && file.name} progress={progress}>
            <ProgressWrapper>
              {file && file.name}
              {renderProgressBar(progress)}
            </ProgressWrapper>
            {renderStatus(error, progress)}
          </FileInfoWrapper>
        );
      })}
    </SelectedFileWrapper>
  );

  return (
    <Dialog
      maxWidth="md"
      open={open}
      onClose={onClose}
      disableBackdropClick
      disableEscapeKeyDown
    >
      {hasSelectedFiles
      && <DialogTitle className={classes.title} disableTypography>{isUploading ? 'Files Uploading' : 'Files to Upload'}</DialogTitle>}
      <DialogContent className={classes.dropContent}>
        {currentStep === UPLOAD_IN_PROGRESS ? renderUploadContent() : renderSelectionContent()}
      </DialogContent>
      {renderDialogActions()}
    </Dialog>
  );
};

UploadFileModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onClickNext: PropTypes.func,
  onClose: PropTypes.func,
  onFileSelection: PropTypes.func,
  onSaveClose: PropTypes.func,
  onAddTag: PropTypes.func,
  open: PropTypes.bool,
  isUploadCompleted: PropTypes.bool,
  selectedFiles: PropTypes.array,
  currentStep: PropTypes.string,
};

UploadFileModal.defaultProps = {
  onClickNext: () => {},
  onClose: () => {},
  onFileSelection: () => {},
  onSaveClose: () => {},
  onAddTag: () => {},
  open: false,
  isUploadCompleted: false,
  selectedFiles: [],
  currentStep: FILE_SELECTION,
};

const UploadFilesImage = styled.img`
  width: 177px;
`;

const DragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 520px;
`;

const DragDropTitle = styled.h1`
  font-size: 35px;
  font-family: 'ArchivoNarrow-Bold';
  color: ${colors.orange};
  margin: 27px 0 0 0;
  height: 47px;
`;

const HiddenInput = styled.input`
  display: none;
`;

const SelectedFileWrapper = styled.div`
  height: 439px;
  margin-left: 17px;
`;

const FileInfoWrapper = styled.div`
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'Raleway-Regular';
  font-size: 14px;
  margin-left: -41px;
  margin-right: -24px;
  padding-left: 41px;
  padding-right: 24px;

  &:hover {
    background-color: rgb(0,0,0,0.08);
    img {
      ${({ progress }) => progress < 100 && `content:url(${uploadCancelActive});`} 
    }
  }
`;

const StatusWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StatusImage = styled.img`
  height: 26px;
  width: 26px;
  ${props => props.clickable && 'cursor: pointer;'}
`;

const ProgressWrapper = styled.div``;

const ZipMediaWrapper = styled.div`
  margin-left: 40px;
`;

const ProgressBarBase = styled.div`
  height: 10px;
  width: 426px;
  margin-top: 5px;
  background-color: rgb(174,174,174);
`;

const ProgressBar = styled.div`
  height: 10px;
  width: ${props => props.progress}%;
  background-color: ${colors.orange};
`;

const ZipWrapper = styled.div`
`;

const ErrorMessage = styled.div`
  text-align: right;
  margin: 0 12px;
`;

export default withStyles(() => ({
  dropContent: {
    width: '746px',
  },
  title: {
    fontFamily: 'Raleway-SemiBold',
    fontSize: '20px',
    padding: '43px 41px 34px 41px',
    borderBottom: '2px solid rgb(235,235,235)',
  },
  chooseFilesButton: {
    marginTop: '13px',
    width: '170px',
    height: '37px',
    fontFamily: 'Raleway-SemiBold',
  },
  selectionDialogActions: {
    justifyContent: 'space-between',
    margin: '0',
    padding: '17px 25px',
    boxShadow: '0 -7px 5px -5px rgb(0,0,0, 0.16)',
  },
  uploadDialogOptions: {
    justifyContent: 'flex-end',
  },
  dialogActionButton: {
    height: '36px',
    width: '138px',
    borderRadius: '2px',
    fontFamily: 'Raleway-SemiBold',
  },
}))(UploadFileModal);
