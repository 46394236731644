import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Typography,
  // Button,
  FormControlLabel,
  Checkbox,
  CircularProgress,
} from '@material-ui/core';
import styled from 'styled-components';
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from 'recharts';
import theme from '../../../theme';
import { date } from '../../../utils';
import CollapsableCard from '../../widgets/CollapsableCard';
import DateRangePicker from '../../forms/DateRangePicker';

class RevenueChart extends React.Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        date: PropTypes.string,
        unpaid: PropTypes.number,
        paid: PropTypes.number,
      }),
    ),
    loading: PropTypes.bool,
    // onDownloadClick: PropTypes.func,
    onDateRangeUpdate: PropTypes.func,
    // classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    data: [],
    loading: false,
    // onDownloadClick: () => {},
    onDateRangeUpdate: () => {},
  };

  state = {
    unpaid: true,
    paid: true,
    startDate: new Date(date.firstDay()),
    endDate: new Date(date.lastDay()),
  };

  handleDateChange = (startDate, endDate) => {
    const { onDateRangeUpdate } = this.props;

    onDateRangeUpdate({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });

    this.setState({
      startDate: startDate.toDate(),
      endDate: endDate.toDate(),
    });
  };

  renderGraph = () => {
    const { data } = this.props;
    const { unpaid, paid } = this.state;

    const calculatedData = data.map((element) => {
      const result = {
        date: element.date,
        rate: 0,
      };

      if (unpaid) result.rate += element.unpaid;
      if (paid) result.rate += element.paid;

      return result;
    });

    return (
      <ResponsiveContainer id="revenue-chart" minWidth={200} minHeight={250}>
        <AreaChart data={calculatedData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="date" />
          <YAxis />
          <Tooltip />
          <Area
            type="monotone"
            dataKey="rate"
            stroke={theme.palette.common.graphOrange}
            fill={theme.palette.common.graphOrange}
          />
        </AreaChart>
      </ResponsiveContainer>
    );
  }

  renderContent = () => {
    const {
      data,
      loading,
    } = this.props;

    switch (true) {
      case loading:
        return (
          <CircularProgress thickness={5} size={80} />
        );
      case !data.length:
        return (
          <Typography variant="display1">Sorry, seems there&apos;s no content to display</Typography>
        );
      default:
        return this.renderGraph();
    }
  }

  render() {
    // const {
    //   data,
    //   loading,
    //   onDownloadClick,
    //   classes,
    // } = this.props;

    const {
      unpaid,
      paid,
      startDate,
      endDate,
    } = this.state;

    return (
      <CollapsableCard
        header={(
          <TitleContainer>
            <Typography variant="headline">Revenue</Typography>
            <DateRangePicker
              onUpdate={this.handleDateChange}
              startDate={startDate}
              endDate={endDate}
              label="Date Range"
            />
          </TitleContainer>
        )}
      >
        <GraphContainer>{this.renderContent()}</GraphContainer>
        <BottomContainer>
          {/* <Button
            color="primary"
            variant="contained"
            onClick={onDownloadClick}
            className={classes.button}
            disabled={!data.length || loading}
            hidden
          >
            DOWNLOAD .PDF
          </Button> */}
          <TagContainer>
            <FormControlLabel
              control={(
                <Checkbox
                  color="primary"
                  onChange={() => this.setState({ unpaid: !unpaid })}
                  checked={unpaid}
                  value="unpaid"
                />
              )}
              label="Unpaid Fans"
            />
            <FormControlLabel
              control={(
                <Checkbox
                  color="primary"
                  onChange={() => this.setState({ paid: !paid })}
                  checked={paid}
                  value="paid"
                />
              )}
              label="Paid Fans"
            />
          </TagContainer>
        </BottomContainer>
      </CollapsableCard>
    );
  }
}

const GraphContainer = styled.div`
  max-height: 237px;
  padding-top: 0.5rem;
  display: flex;
  justify-content: center;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BottomContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1rem;
`;

const TagContainer = styled.div`
  display: flex;
  align-items: center;
`;

export default withStyles({
  button: {
    padding: '8px 44px',
  },
})(RevenueChart);
