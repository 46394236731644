import React from 'react';
import { Adopt } from 'react-adopt';
import { Query, Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import {
  date,
  legalNoteTypes,
  notify,
  messages,
} from '../../utils';
import LegalScreen from '../../screens/LegalScreen';

export const FETCH_LEGAL_NOTE = gql`
  query FetchLegalNote($legalNote: NoteTypeEnum!) {
    legalNote(noteType: $legalNote) {
      content
      updatedAt
    }
  }
`;

export const UPDATE_LEGAL_NOTE = gql`
  mutation UpdateLegalNote(
    $noteType: NoteTypeEnum!,
    $content: String!,
  ) {
    upsertLegalNote(
      noteType: $noteType,
      content: $content
    ) {
      legalNote {
        content
        noteType
        updatedAt
      }
    }
  }
`;

function mapLegalNote({ data, loading, error }) {
  if (loading || error) {
    return {
      data: {
        content: null,
        lastUpdated: '',
        timeStamp: Date.now(),
      },
      loading,
      error,
    };
  }

  try {
    return {
      data: {
        content: JSON.parse(data.legalNote.content).rawState,
        lastUpdated: date.lastUpdated(data.legalNote.updatedAt),
        timeStamp: Date.now(),
      },
      loading,
      error,
    };
  } catch (mapError) {
    return {
      data: {
        content: null,
        lastUpdated: '',
        timeStamp: Date.now(),
      },
      loading,
      error: mapError,
    };
  }
}

export const LegalScreenConnector = () => {
  const mapper = {
    /* eslint react/prop-types: 0 */
    termsAndConditions: ({ render }) => (
      <Query
        query={FETCH_LEGAL_NOTE}
        variables={{ legalNote: legalNoteTypes[0] }}
        fetchPolicy="network-only"
      >
        {render}
      </Query>
    ),
    privacyPolicy: ({ render }) => (
      <Query
        query={FETCH_LEGAL_NOTE}
        variables={{ legalNote: legalNoteTypes[1] }}
        fetchPolicy="network-only"
      >
        {render}
      </Query>
    ),
    updateLegalNote: ({ render }) => (
      <Mutation
        mutation={UPDATE_LEGAL_NOTE}
        update={(cache, {
          data: {
            upsertLegalNote: { legalNote },
          },
        }) => (
          legalNote
          && cache.writeQuery({
            query: FETCH_LEGAL_NOTE,
            variables: { legalNote: legalNote.noteType },
            data: { legalNote },
          })
        )}
        onCompleted={() => notify.success({ ...messages.UPDATE_LEGAL_NOTE.success })}
        onError={() => notify.error({ ...messages.UPDATE_LEGAL_NOTE.error })}
      >
        {(mutation, result) => render({ mutation, result })}
      </Mutation>
    ),
  };

  return (
    <Adopt mapper={mapper}>
      {({
        termsAndConditions,
        privacyPolicy,
        updateLegalNote: {
          mutation: updateLegalNote,
          result: {
            loading: updateLoading,
          },
        },
      }) => {
        const mappedTermsAndConditions = mapLegalNote(termsAndConditions);
        const mappedPrivacyPolicy = mapLegalNote(privacyPolicy);

        return (
          <LegalScreen
            termsAndConditions={{ ...mappedTermsAndConditions }}
            privacyPolicy={{ ...mappedPrivacyPolicy }}
            updateLoading={updateLoading}
            onUpdate={updateLegalNote}
          />
        );
      }}
    </Adopt>
  );
};

export default LegalScreenConnector;
