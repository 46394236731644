import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import { ImagePicker } from '../../forms';
import { validate } from '../../../utils';

class AddAvatarModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    title: '',
    open: false,
    onSave: () => {},
    onCancel: () => {},
  }

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      isValid: false,
      isSubmitting: false,
    };
  }

  validation = (values) => {
    const errors = {};

    if (!values.image) {
      errors.image = 'Required';
    }

    this.setState({
      isValid: isEmpty(errors),
    });

    return errors;
  };

  handleSubmit = async (values) => {
    const { onSave } = this.props;

    try {
      this.setState({ isSubmitting: true });

      await onSave(values);
    } finally {
      this.setState({ isSubmitting: false });
    }
  }

  render() {
    const {
      isValid,
      isSubmitting,
    } = this.state;
    const {
      title,
      open,
      onCancel,
      classes,
    } = this.props;

    return (
      <Dialog
        maxWidth="md"
        open={open}
        onClose={onCancel}
      >
        <DialogTitle className={classes.modalTitle}>
          {title}
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Formik
            enableReinitialize
            initialValues={{
              image: '',
            }}
            onSubmit={this.handleSubmit}
            validate={this.validation}
            ref={this.form}
          >
            {({
              values,
              touched,
              errors,
              setFieldValue,
              setFieldTouched,
              setFieldError,
            }) => (
              <FormControl
                className={classes.formControl}
                error={touched.image && !!errors.image}
              >
                <ImagePicker
                  imageSrc={values.image}
                  title="Choose an image"
                  subtitle="(min 720 x 720 px)"
                  onChange={(file) => {
                    setFieldValue('image', file);
                    setFieldTouched('image', true);
                    validate.imageSize(file, 'cover', (result) => {
                      setFieldError('coverImageSrc', result);
                    });
                  }}
                />
                <FormHelperText>{touched.coverImageSrc && errors.coverImageSrc}</FormHelperText>
              </FormControl>
            )}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid || isSubmitting}
            onClick={() => this.form.current.submitForm()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  content: {
    paddingTop: '40px',
  },
  formControl: {
    width: 400,
  },
  modalTitle: {
    borderBottom: `1px solid ${theme.palette.common.gray}`,
  },
}))(AddAvatarModal);
