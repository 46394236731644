
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  Typography,
} from '@material-ui/core';
import { Formik } from 'formik';
import isEmpty from 'lodash/isEmpty';
import styled from 'styled-components';
import { validate } from '../../utils';
import ScreenContainer from '../../components/layout/ScreenContainer';
import { ProfileSettingsForm } from '../../components/forms';

class ProfileScreen extends Component {
  static propTypes = {
    formProps: PropTypes.object,
    onUpdate: PropTypes.func,
    updateProfileLoading: PropTypes.bool,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onUpdate: () => {},
    formProps: {
      onPasswordReset: () => {},
      values: {
        firstName: '',
        lastName: '',
        phone: ' ',
        email: '',
        role: 'Not Available',
        emailNotification: false,
        textMessageNotification: false,
      },
    },
    updateProfileLoading: false,
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      isValid: false,
    };
  }


  validation = (values) => {
    const errors = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!validate.email(values.email)) {
      errors.email = 'Invalid email address';
    }

    if (!values.firstName) {
      errors.firstName = 'Required';
    }

    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    if (!values.phone || values.phone === ' ' || values.phone === '   -   -    ') {
      errors.phone = 'Required';
    } else if (!validate.phone(values.phone)) {
      errors.phone = 'Invalid phone number';
    }

    this.setState({
      isValid: isEmpty(errors),
    });

    return errors;
  };

  handleSubmit = (values) => {
    const { onUpdate } = this.props;

    onUpdate({
      variables: {
        ...values,
        phone: values.phone.replace(/[^0-9]/g, ''),
      },
    });
  }

  render() {
    const {
      formProps: {
        values: {
          firstName,
          lastName,
          email,
          phone,
          role,
          emailNotification,
          textMessageNotification,
        },
        // onPasswordReset,
      },
      updateProfileLoading,
      classes,
    } = this.props;

    const { isValid } = this.state;

    return (
      <ScreenContainer
        withFooter
        footerProps={{
          rightButtons: [{
            variant: 'contained',
            label: 'Update',
            color: 'primary',
            onClick: () => this.form.current.submitForm(),
            disabled: !isValid || updateProfileLoading,
          }, {
            variant: 'outlined',
            label: 'Cancel',
            color: 'primary',
            onClick: () => this.form.current.resetForm(),
          }],
        }}
      >
        <Paper className={classes.paper}>
          <Container className={classes.form}>
            <Typography
              align="left"
              variant="headline"
              className={classes.title}
            >
              Profile Settings
            </Typography>
            <Formik
              enableReinitialize
              initialValues={{
                firstName: firstName || '',
                lastName: lastName || '',
                email: email || '',
                phone: phone || '',
                role: role || '',
                emailNotification: emailNotification || false,
                textMessageNotification: textMessageNotification || false,
              }}
              onSubmit={this.handleSubmit}
              validate={this.validation}
              ref={this.form}
            >
              {props => (
                <ProfileSettingsForm
                  {...props}
                  onPasswordResetButtonClick={() => {}}
                />
              )}
            </Formik>
          </Container>
        </Paper>
      </ScreenContainer>
    );
  }
}

const Container = styled.div`
  width: 60%;
  min-width: 20rem;
  margin: 1rem;
`;

export default withStyles(theme => ({
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
  },
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
}))(ProfileScreen);
