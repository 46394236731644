import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';

const AddTagModal = ({
  classes, open, onCancel, onConfirm, multiple,
}) => (
  <Dialog
    maxWidth="md"
    open={open}
    onClose={onCancel}
    disableBackdropClick
    disableEscapeKeyDown
  >
    <DialogTitle className={classes.modalTitle} disableTypography>
      Are you sure?
    </DialogTitle>
    <DialogContent className={classes.content}>
      {`Are you sure you want to delete ${multiple ? 'these files' : 'this file'}?`}
    </DialogContent>
    <DialogActions className={classes.dialogActions}>
      <Button
        onClick={onCancel}
        color="primary"
        className={classes.dialogButton}
      >
        Cancel
      </Button>
      <Button
        color="primary"
        variant="contained"
        onClick={onConfirm}
        className={classes.dialogButton}
      >
        Confirm
      </Button>
    </DialogActions>
  </Dialog>
);

AddTagModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool,
  multiple: PropTypes.bool,
};

AddTagModal.defaultProps = {
  onConfirm: () => {},
  onCancel: () => {},
  open: false,
  multiple: false,
};

export default withStyles(theme => ({
  modalTitle: {
    borderBottom: `1px solid ${theme.palette.common.gray}`,
    fontFamily: 'Raleway-SemiBold',
    fontSize: '20px',
  },
  content: {
    padding: '18px 24px',
    height: '245px',
    width: '680px',
    color: 'rgb(0,0,0, 0.6)',
    fontFamily: 'Raleway-SemiBold',
  },
  dialogActions: {
    margin: '0',
    padding: '17px 25px',
    boxShadow: '0 -7px 5px -5px rgb(0,0,0, 0.16)',
  },
  dialogButton: {
    height: '36px',
    width: '138px',
    borderRadius: '2px',
    fontFamily: 'Raleway-SemiBold',
  },
}))(AddTagModal);
