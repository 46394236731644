import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  Collapse,
  CardHeader,
  CardContent,
  IconButton,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ChevronRight from '@material-ui/icons/ChevronRight';

class CollapsableCard extends Component {
  static propTypes = {
    header: PropTypes.element,
    children: PropTypes.oneOfType([
      PropTypes.element,
      PropTypes.arrayOf(PropTypes.element),
    ]),
    classes: PropTypes.object.isRequired,
  }

  static defaultProps = {
    header: null,
    children: null,
  }

  state = {
    open: false,
  }

  handleClick = () => {
    const { open } = this.state;

    this.setState({ open: !open });
  }

  render() {
    const {
      header,
      children,
      classes,
    } = this.props;

    const { open } = this.state;

    return (
      <Card classes={{ root: classes.card }}>
        <CardHeader
          avatar={(
            <IconButton onClick={this.handleClick}>
              {open ? <ExpandMore /> : <ChevronRight />}
            </IconButton>
          )}
          title={header}
          className={classes.header}
        />
        <Collapse in={open}>
          <CardContent classes={{ root: classes.cardContent }}>
            {children}
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}

export default withStyles(() => ({
  card: {
    overflow: 'visible',
    paddingBottom: '30px',
  },
  header: {
    padding: '30px 30px 0 30px',
  },
  cardContent: {
    paddingBottom: '0 !important',
  },
}))(CollapsableCard);
