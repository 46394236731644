import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import { capitalize } from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Input,
  InputLabel,
  Typography,
  Grid,
  Select,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import Before from '@material-ui/icons/NavigateBefore';

const reasonOptions = [
  'Your reason was sufficient, we banned this user',
  'We are looking into this case',
  'This reason is insufficient',
];

class ReportedContentDetailsModal extends React.Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onNavigateBack: PropTypes.func,
    open: PropTypes.bool,
    reportedContent: PropTypes.shape({
      uuid: PropTypes.string,
      reportedBy: PropTypes.string,
      reportedDate: PropTypes.string,
      reason: PropTypes.string,
      response: PropTypes.string,
      adminNotes: PropTypes.string,
      status: PropTypes.string,
      length: PropTypes.number,
    }),
    user: PropTypes.shape({
      holr: PropTypes.string,
    }),
  };

  static defaultProps = {
    onSave: () => { },
    onCancel: () => { },
    onNavigateBack: () => { },
    open: false,
    reportedContent: {},
    user: { holr: '' },
  };

  isValid = ({
    reportedBy, reportedDate, reason, response, adminNotes, status,
  }) => (
    !!reportedBy && !!reportedDate && !!reason && !!response && !!adminNotes && !!status)

  onSave = async (data) => {
    const { onSave, reportedContent } = this.props;

    const variables = {
      uuid: reportedContent.uuid,
      feedback: data.adminNotes,
    };

    await onSave({ variables });
  }

  render = () => {
    const {
      open,
      onCancel,
      onNavigateBack,
      classes,
      reportedContent,
      user,
    } = this.props;

    return (
      <Formik initialValues={{ ...reportedContent, response: reportedContent.response ? reportedContent.response : '' }} enableReinitialize>
        {({
          values,
          handleChange,
          setFieldValue,
        }) => (
          <Dialog
            maxWidth="lg"
            open={open}
            onClose={onCancel}
          >
            <DialogTitle className={classes.modalTitle}>
              {`Reported Content: ${user.holr} (${reportedContent.length})`}
            </DialogTitle>

            <DialogContent className={classes.dialogContent}>
              <IconButton className={classes.navigateBefore} onClick={onNavigateBack}>
                <Before />
              </IconButton>
              <Form>
                <Grid container spacing={24}>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="reportedBy">
                        Reported By
                      </InputLabel>
                      <Input
                        disabled
                        name="reportedBy"
                        onChange={handleChange}
                        value={values.reportedBy}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="reportedDate">
                        Reported Date
                      </InputLabel>
                      <Input
                        disabled
                        name="reportedDate"
                        value={values.reportedDate}
                        onChange={handleChange}
                        autoComplete="reportedDate"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="reason">
                        Reason
                      </InputLabel>
                      <Input
                        disabled
                        name="reason"
                        value={values.reason}
                        onChange={handleChange}
                        autoComplete="reason"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="reason">
                        Response
                      </InputLabel>
                      <Select
                        name="response"
                        value={values.response}
                        onChange={handleChange}
                      >
                        {reasonOptions.map((reason, i) => (
                          <MenuItem value={reason} key={i}> {/* eslint-disable-line*/}
                            {reason}
                          </MenuItem>))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl
                      fullWidth
                      className={classes.formControl}
                    >
                      <InputLabel htmlFor="adminNotes">
                        Admin Notes
                      </InputLabel>
                      <Input
                        name="adminNotes"
                        value={values.adminNotes}
                        onChange={handleChange}
                        autoComplete="adminNotes"
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={7}>
                    <Button onClick={() => setFieldValue('banned', !values.banned)} variant="outlined">
                      {values.banned ? 'UN-BAN' : 'BAN'}
                    </Button>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="headline">
                      {`Status: ${capitalize(values.status)}`}
                    </Typography>
                  </Grid>
                </Grid>
              </Form>
            </DialogContent>
            <DialogActions className={classes.modalActions}>
              <Button onClick={onCancel} className={classes.button}>
                Cancel
              </Button>
              <Button onClick={() => this.onSave(values)} color="primary" className={classes.button} variant="contained">
                Save
              </Button>
            </DialogActions>
          </Dialog>
        )}
      </Formik>
    );
  }
}

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  statusText: {
    margin: '1rem 0rem',
    fontSize: '1.4rem',
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  modalActions: {
    borderTop: '2px solid #DCDCDC',
    padding: '8px 2px 2px',
  },
  modalTitle: {
    borderBottom: '1px solid #949494',
  },
  dialogContent: {
    width: '40rem',
    marginTop: '1rem',
    marginLeft: '2.5rem',
  },
  navigateBefore: {
    position: 'absolute',
    left: '0.5rem',
  },
  button: {
    padding: '8px 44px',
  },
}))(ReportedContentDetailsModal);
