import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  Divider,
} from '@material-ui/core';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ExpandLess from '@material-ui/icons/ExpandLess';
import navigationItems from '../../../config/navigationItems';
import themeStyle from '../../../theme';

class NavigationPanel extends Component {
  static propTypes = {
    logo: PropTypes.element,
    collapsed: PropTypes.bool,
    onNavigationItemClick: PropTypes.func,
    isAdmin: PropTypes.bool,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    logo: null,
    collapsed: false,
    isAdmin: false,
    onNavigationItemClick: () => { },
  };

  state = {
    selected: null,
    open: null,
  };

  handleNavigationItemClick = (key) => {
    const { onNavigationItemClick } = this.props;

    this.setState({
      selected: key,
    }, () => {
      onNavigationItemClick(key);
    });
  }

  renderNavigationSubItems = (key, items) => {
    const { classes } = this.props;
    const { open, selected } = this.state;

    return (
      <Collapse in={open === key} timeout="auto" unmountOnExit>
        <List disablePadding>
          {items.map(item => (
            <ListItem
              button
              key={item.key}
              selected={selected === item.key}
              onClick={() => this.handleNavigationItemClick(item.key)}
            >
              <ListItemText
                inset
                primary={item.label}
                classes={{ primary: classes.navigationText }}
              />
            </ListItem>
          ))}
        </List>
      </Collapse>
    );
  };

  renderNavigationItems = () => {
    const { collapsed, classes, isAdmin } = this.props;
    const { selected, open } = this.state;

    return navigationItems(isAdmin).map((item) => {
      const { label, key, items } = item;

      return (
        <Fragment key={key}>
          <ListItem
            button
            selected={selected === key}
            onClick={() => {
              this.setState({ open: key === open || collapsed ? null : key });

              this.handleNavigationItemClick(key);
            }}
          >
            <ListItemIcon>
              <item.icon
                className={classes.navigationIcon}
              />
            </ListItemIcon>
            {!collapsed && (
              <Fragment>
                <ListItemText primary={label} classes={{ primary: classes.navigationText }} />
                {items && (open === key
                  ? <ExpandLess color="secondary" />
                  : <ExpandMore color="secondary" />
                )}
              </Fragment>
            )}
          </ListItem>
          {items && !collapsed && this.renderNavigationSubItems(key, items)}
        </Fragment>
      );
    });
  };

  render() {
    const {
      logo,
      collapsed,
      classes,
    } = this.props;

    return (
      <Drawer
        variant="permanent"
        classes={{
          paper: classes.navigationPanel,
        }}
      >
        <LogoContainer>
          {logo}
        </LogoContainer>
        <Divider className={classes.divider} />
        <List className={collapsed ? '' : classes.navigationItems}>
          {this.renderNavigationItems()}
        </List>
      </Drawer>
    );
  }
}

const LogoContainer = styled.div`
  display: flex;
  justify-content: center;
  height: ${themeStyle.spacing.topPanelHeight}px;
  min-height: ${themeStyle.spacing.topPanelHeight}px;
`;


export default withStyles(theme => ({
  navigationPanel: {
    backgroundColor: theme.palette.primary.main,
    minWidth: '72px',
  },
  navigationItems: {
    minWidth: theme.spacing.drawerWidth,
  },
  navigationIcon: {
    marginRight: 0,
    color: theme.palette.secondary.main,
  },
  navigationText: {
    color: theme.palette.secondary.main,
  },
  divider: {
    height: '4px',
  },
}))(NavigationPanel);
