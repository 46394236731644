import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Formik,
  Form,
} from 'formik';
import {
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Typography,
  Button,
} from '@material-ui/core';
import { validate } from '../../../utils';

class PasswordRecoveryForm extends React.Component {
  static propTypes = {
    onSubmit: PropTypes.func,
    onChange: PropTypes.func,
    onCancel: PropTypes.func,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    onSubmit: () => {},
    onChange: () => {},
    onCancel: () => {},
  };

  validate = (values) => {
    const { onChange } = this.props;
    const errors = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!validate.email(values.email)) {
      errors.email = 'Invalid email address';
    }

    onChange(values);

    return errors;
  };

  handleSubmit = async (values, {
    setSubmitting,
    setValues,
    setTouched,
  }) => {
    const { onSubmit } = this.props;

    try {
      await onSubmit(values);
    } catch (error) {
      setValues({ email: '' });

      setTouched({ email: true });
    } finally {
      setSubmitting(false);
    }
  };

  renderForm = ({
    values,
    errors,
    touched,
    handleChange,
    isValid,
    isSubmitting,
  }) => {
    const {
      onCancel,
      classes,
    } = this.props;

    return (
      <Form>
        <FormControl
          fullWidth
          className={classes.formControl}
          error={touched.email && !!errors.email}
        >
          <InputLabel>Email</InputLabel>
          <Input
            type="email"
            name="email"
            onChange={handleChange}
            className={classes.input}
            value={values.email}
          />
          <FormHelperText>{errors.email}</FormHelperText>
        </FormControl>
        <ButtonContainer>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            fullWidth
            disabled={isSubmitting || !isValid}
            className={classes.submitButton}
          >
            Submit
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={onCancel}
          >
            Cancel
          </Button>
        </ButtonContainer>
      </Form>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <Container>
        <Typography
          className={classes.title}
          align="center"
          variant="headline"
        >
          Forgot Password
        </Typography>
        <Typography
          className={classes.subheading}
          variant="subheading"
          align="center"
        >
          Please enter your email address to receive a reset password link
        </Typography>

        <Formik
          initialValues={{ email: '' }}
          onSubmit={this.handleSubmit}
          validate={this.validate}
        >
          {props => this.renderForm(props)}
        </Formik>
      </Container>
    );
  }
}

const Container = styled.div`
  width: 400px;
  margin: 40px auto;
`;

const ButtonContainer = styled.div`
  width: 300px;
  margin: 2.5rem auto;
`;

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  subheading: {
    marginBottom: theme.spacing.unit * 3,
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
}))(PasswordRecoveryForm);
