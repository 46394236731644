import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import Paper from '@material-ui/core/Paper';
import { withStyles } from '@material-ui/core/styles';

class PageFooter extends Component {
  renderButtons = (buttons, group = 'left') => {
    if (!buttons || buttons.length === 0) {
      return null;
    }

    const { classes } = this.props;
    const buttonsToRender = [];
    buttons.forEach((button, index) => {
      const {
        variant, label, onClick, color, disabled,
      } = button;
      buttonsToRender.push(
        <Button
          key={`footer-${group}-button-${index + 1}`}
          data-test-id={`footer-${group}-button-${index + 1}`}
          variant={variant}
          onClick={onClick}
          color={color}
          disabled={disabled}
          className={classes.button}
        >
          {label}
        </Button>,
      );
    });
    return buttonsToRender;
  }

  render() {
    const {
      description: { label, onClick }, rightButtons,
      leftButtons,
      classes,
    } = this.props;
    return (
      <Paper elevation={1} square className={classes.paper}>
        <StyledToolbar>
          <FooterGroup
            data-test-id="footer-left-buttons"
            direction="row"
            style={{ position: 'absolute', left: '1rem' }}
          >
            {this.renderButtons(leftButtons, 'left')}
          </FooterGroup>
          <FooterGroup data-test-id="footer-description">
            {
              onClick
                ? (
                  <Button data-test-id="footer-description-button" onClick={onClick}>
                    <Description data-test-id="footer-description-label">
                      {label}
                    </Description>
                  </Button>
                )
                : (
                  <Description data-test-id="footer-description-label">
                    {label}
                  </Description>
                )
            }

          </FooterGroup>
          <FooterGroup
            data-test-id="footer-right-buttons"
            direction="row-reverse"
            style={{ position: 'absolute', right: '1rem' }}
          >
            {this.renderButtons(rightButtons, 'right')}
          </FooterGroup>
        </StyledToolbar>
      </Paper>
    );
  }
}

PageFooter.propTypes = {
  rightButtons: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.oneOf(['text', 'flat', 'outlined', 'contained', 'raised', 'fab', 'extendedFab']),
      label: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
    }),
  ),
  leftButtons: PropTypes.arrayOf(
    PropTypes.shape({
      variant: PropTypes.oneOf(['text', 'flat', 'outlined', 'contained', 'raised', 'fab', 'extendedFab']),
      label: PropTypes.string,
      onClick: PropTypes.func,
      disabled: PropTypes.bool,
      color: PropTypes.oneOf(['default', 'inherit', 'primary', 'secondary']),
    }),
  ),
  description: PropTypes.shape({
    label: PropTypes.string,
    onClick: PropTypes.func,
  }),
  classes: PropTypes.object.isRequired,
};

PageFooter.defaultProps = {
  rightButtons: [],
  leftButtons: [],
  description: {},
};


const Description = styled.div`
  font-family: 'Montserrat', sans-serif;
  opacity: 0.38;
  font-size: 0.875rem;
`;

const StyledToolbar = styled(Toolbar)`
  display: flex;
  justify-content: center;
  padding: 1rem 0;
`;

const FooterGroup = styled.div`
  width: ${props => props.width};
  display: flex;
  flex-direction: ${props => (props.direction ? props.direction : 'row')};
`;

export default withStyles(() => ({
  paper: {
    position: 'sticky',
    bottom: 0,
  },
  button: {
    padding: '8px 44px',
    margin: '0 1rem',
    lineHeight: '1.2em',
    minHeight: '30px',
  },
}))(PageFooter);
