import { get, startCase } from 'lodash';
import { date } from '../../utils';

export function mapReportedHolrs({ data, loading, error }) {
  if (loading || error) {
    return { data: [], loading, error };
  }

  try {
    return {
      data: data.reportedHolrs.edges.map(edge => ({
        uuid: edge.node.uuid,
        offender: get(edge, 'node.holr.user.username', ''),
        title: get(edge, 'node.holr.title', ''),
        caption: get(edge, 'node.holr.caption', ''),
        media: get(edge, 'node.holr.media', []).map(medium => ({
          uuid: medium.uuid,
          type: get(medium, 'original.contentType'),
          url: get(medium, 'original.url'),
        })),
        holrType: startCase(get(edge, 'node.holr.mediumType', '').toLowerCase()),
        reporter: get(edge, 'node.user.username'),
        reason: startCase(get(edge, 'node.reason', '').toLowerCase()),
        state: startCase(edge.node.state),
        createdAt: date.lastUpdated(edge.node.createdAt),
        banned: get(edge, 'node.holr.user.banned', false),
        flagged: edge.node.flagged,
      })),
      loading,
      error,
      totalCountHolrs: data.reportedHolrs.totalCount,
    };
  } catch (mapError) {
    return { data: [], loading, error: mapError };
  }
}

export function mapReportedHolrbacks({ data, loading, error }) {
  if (loading || error) {
    return { data: [], loading, error };
  }

  try {
    return {
      data: data.reportedHolrs.edges.map(edge => ({
        uuid: edge.node.uuid,
        offender: get(edge, 'node.holr.user.username', ''),
        title: get(edge, 'node.holr.title', ''),
        caption: get(edge, 'node.holr.caption', ''),
        media: get(edge, 'node.holr.media', []).map(medium => ({
          uuid: medium.uuid,
          type: get(medium, 'original.contentType'),
          url: get(medium, 'original.url'),
        })),
        originalAuthor: get(edge, 'node.holr.holr.user.username'),
        holrType: startCase(get(edge, 'node.holr.mediumType', '').toLowerCase()),
        reporter: get(edge, 'node.user.username'),
        reason: startCase(get(edge, 'node.reason', '').toLowerCase()),
        state: startCase(edge.node.state),
        createdAt: date.lastUpdated(edge.node.createdAt),
        banned: get(edge, 'node.holr.user.banned', false),
        flagged: edge.node.flagged,
      })),
      loading,
      error,
      totalCountHolrbacks: data.reportedHolrs.totalCount,
    };
  } catch (mapError) {
    return { data: [], loading, error: mapError };
  }
}

export function mapReportedUsers({ data, loading, error }) {
  if (loading || error) {
    return { data: [], loading, error };
  }

  try {
    return {
      data: data.reportedUsers.edges.map(edge => ({
        uuid: edge.node.uuid,
        offender: get(edge, 'node.user.username', ''),
        offenderUuid: get(edge, 'node.user.uuid', ''),
        reporter: get(edge, 'node.reporter.username', ''),
        reason: startCase(get(edge, 'node.reason', '').toLowerCase()),
        state: startCase(edge.node.state),
        createdAt: date.lastUpdated(edge.node.createdAt),
        banned: get(edge, 'node.user.banned', false),
      })),
      loading,
      error,
      totalCountUsers: data.reportedUsers.totalCount,
    };
  } catch (mapError) {
    return { data: [], loading, error: mapError };
  }
}

export function mapReportedProblems({ data, loading, error }) {
  if (loading || error) {
    return { data: [], loading, error };
  }

  try {
    return {
      data: data.reportedProblems.edges.map(edge => ({
        uuid: edge.node.uuid,
        feedback: edge.node.feedback,
        response: edge.node.response || '',
        reporter: get(edge, 'node.user.username', ''),
        state: startCase(edge.node.state),
        createdAt: date.lastUpdated(edge.node.createdAt),
      })),
      loading,
      error,
      totalCountProblems: data.reportedProblems.totalCount,
    };
  } catch (mapError) {
    return { data: [], loading, error: mapError };
  }
}
