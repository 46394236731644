import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
} from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Select,
  MenuItem,
  Grid,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import ImagePicker from '../../forms/ImagePicker';
import { validate } from '../../../utils';

class AddSubCategoryModal extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onUpdate: PropTypes.func,
    open: PropTypes.bool,
    categories: PropTypes.arrayOf(PropTypes.shape({
      uuid: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]),
      name: PropTypes.string,
    })),
  };

  static defaultProps = {
    onSave: () => {},
    onCancel: () => {},
    onUpdate: () => {},
    open: false,
    categories: [],
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      isValid: false,
      isSubmitting: false,
    };
  }

  validation = (values) => {
    const { onUpdate } = this.props;
    const errors = {};

    const checks = onUpdate(values);

    if (!values.title) {
      errors.title = 'Required';
    } else if (checks.title) {
      errors.title = 'This sub-category already exists';
    }

    if (!values.imageSrc) {
      errors.imageSrc = 'Required';
    }

    if (!values.parentCategory) {
      errors.parentCategory = 'Required';
    }

    this.setState({
      isValid: isEmpty(errors),
    });

    return errors;
  };

  handleSubmit = async (values) => {
    const { onSave } = this.props;

    try {
      this.setState({ isSubmitting: true });

      await onSave({
        variables: { ...values },
      });
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  renderForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  }) => {
    const {
      categories,
      classes,
    } = this.props;

    return (
      <Form>
        <Grid container spacing={24}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={touched.title && !!errors.title}
              className={classes.formControl}
            >
              <InputLabel htmlFor="title">
                Title*
              </InputLabel>
              <Input
                placeholder="Enter your title here"
                id="title"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="title"
              />
              <FormHelperText>{touched.title && errors.title}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              className={classes.formControl}
              error={touched.parentCategory && !!errors.parentCategory}
            >
              <InputLabel htmlFor="parentCategory">Parent Category*</InputLabel>
              <Select
                name="parentCategory"
                id="parentCategory"
                value={values.parentCategory}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {categories.map(category => (
                  <MenuItem
                    key={category.uuid}
                    value={category.uuid}
                  >
                    {category.name}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{touched.parentCategory && errors.parentCategory}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              className={classes.formControl}
              error={touched.imageSrc && !!errors.imageSrc}
              fullWidth
            >
              <ImagePicker
                imageSrc={values.imageSrc}
                title="Avatar*"
                subtitle="(min 720 x 720 px)"
                onChange={(file) => {
                  setFieldValue('imageSrc', file);
                  setFieldTouched('imageSrc', true);
                  validate.imageSize(file, 'avatar', (result) => {
                    setFieldError('imageSrc', result);
                  });
                }}
              />
            </FormControl>
          </Grid>
        </Grid>
      </Form>
    );
  }

  render() {
    const {
      isValid,
      isSubmitting,
    } = this.state;
    const {
      open,
      onCancel,
      classes,
    } = this.props;

    return (
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={onCancel}
      >
        <DialogTitle className={classes.modalTitle}>
          Add New Sub-Category
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Formik
            initialValues={{
              title: '',
              parentCategory: '',
              imageSrc: '',
            }}
            onSubmit={this.handleSubmit}
            validate={this.validation}
            ref={this.form}
          >
            {props => this.renderForm(props)}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid || isSubmitting}
            onClick={() => this.form.current.submitForm()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  content: {
    width: '40rem',
    paddingTop: '40px',
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  modalTitle: {
    borderBottom: `1px solid ${theme.palette.common.gray}`,
  },
}))(AddSubCategoryModal);
