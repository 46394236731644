export const map = user => ({
  uuid: user.uuid,
  firstName: user.first_name,
  lastName: user.last_name,
  fullName: user.full_name,
  email: user.email,
  avatarUrl: user.large_avatar_url,
  coverPhotoUrl: user.large_cover_photo_url,
  username: user.username,
  bio: user.bio,
  appRole: user.app_role,
  adminRole: user.admin_role,
});

export const set = user => localStorage.setItem('user', JSON.stringify(user));

export const get = () => JSON.parse(localStorage.getItem('user'));

export const remove = () => localStorage.removeItem('user');

export default {
  map,
  set,
  get,
  remove,
};
