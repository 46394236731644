import gql from 'graphql-tag';

export const USED_TAGS = gql`
  query UsedTags(
    $userUuid: ID
    $first: Int!
    $query: String
  ) {
    usedTags(
      userUuid: $userUuid
      first: $first
      query: $query
    ) {
      nodes {
        uuid
        name
      }
    }
  }
`;

export default {
  USED_TAGS,
};
