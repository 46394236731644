import gql from 'graphql-tag';

export const CREATE_IMPORTED_MEDIUM = gql`
  mutation CreateImportedMedium($userUuid: ID, $file: Upload!, $tags: [String!]) {
    createImportedMedium(userUuid: $userUuid, file: $file, tags: $tags) {
      ignoredFiles {
        fileName
        mimeType
        size
        reason
      }
      importedMedia {
        uuid
        name
        lowQualityThumbnail {
          url
        }
        original {
          url
          contentType
        }
        tags {
          name
          uuid
        }
      }
    }
  }
`;

export default {
  CREATE_IMPORTED_MEDIUM,
};
