import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Paper,
  Checkbox,
  Chip,
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import colors from '../../../utils/colors';
import uploadedVideoIcon from '../../../assets/images/uploadedVideoIcon.svg';

const videoFormats = ['video/mp4', 'video/avi', 'application/x-matroska', 'video/quicktime'];

const UploadedMediumCard = ({
  media, classes, onRemoveTag, onSelect,
}) => {
  const {
    uuid, name, lowQualityThumbnail, tags, isSelected, original,
  } = media;
  return (
    <Paper className={classes.paper} elevation={1}>
      <Thumbnail
        imageSrc={lowQualityThumbnail.url}
        className={isSelected && classes.thumbnailSelected}
        onClick={() => onSelect(media)}
      >
        <Checkbox onClick={() => onSelect(media)} color="primary" checked={isSelected} className={`${classes.checkbox} ${isSelected && classes.checkboxChecked}`} />
        {original && original.contentType
        && videoFormats.includes(original.contentType) && <VideoIcon src={uploadedVideoIcon} />}
      </Thumbnail>
      <MediaInfo>
        <MediaTitle>{name}</MediaTitle>
        <ChipWrapper>
          {tags.map(tag => (
            <Chip
              key={tag}
              label={tag}
              onDelete={() => onRemoveTag(uuid, tag)}
              className={classes.chips}
            />
          ))}
        </ChipWrapper>
      </MediaInfo>
    </Paper>
  );
};

UploadedMediumCard.propTypes = {
  media: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onRemoveTag: PropTypes.func,
  onSelect: PropTypes.func,
};

UploadedMediumCard.defaultProps = {
  onRemoveTag: () => {},
  onSelect: () => {},
};

const Thumbnail = styled.div`
  height: 118px;
  ${({ imageSrc }) => `background-image: url(${imageSrc});`}
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;
`;

const MediaInfo = styled.div`
  background-color: rgb(247, 247, 247);
  padding: 10px 9px;
  flex: 1;
`;

const MediaTitle = styled.div`
  font-family: 'Roboto', sans-serif;
  font-size: 10px;
  color: rgb(156,156,156);
`;

const ChipWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 4px;
`;

const VideoIcon = styled.img`
  width: 26px;
  height: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export default withStyles(() => ({
  paper: {
    width: '118px',
    display: 'flex',
    margin: '1px 12.3px 19px 0',
    flexDirection: 'column',
    '&&:hover $checkbox': {
      display: 'block',
    },
  },
  checkbox: {
    padding: 0,
    display: 'none',
    position: 'absolute',
    top: '-2px',
    right: '-2px',
  },
  checkboxChecked: {
    display: 'block',
    top: '-4px',
    right: '-4px',
  },
  thumbnailSelected: {
    border: `2px solid ${colors.orange}`,
  },
  chips: {
    margin: '0px 5px 5px 0',
    height: '22px',
    fontSize: '10px',
    '&& svg': {
      width: '15px',
      height: '15px',
    },
  },
}))(UploadedMediumCard);
