import React from 'react';
import PropTypes from 'prop-types';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import InlineRangeDatePicker from 'material-ui-pickers/DateRangePicker';

const DateRangePicker = ({
  startDate, endDate, onUpdate, label,
  minDate, maxDate, emptyLabel,
}) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <InlineRangeDatePicker
      label={label}
      startDate={startDate}
      endDate={endDate}
      format="MM/DD/YYYY"
      onChange={onUpdate}
      emptyLabel={emptyLabel}
      minDate={minDate}
      maxDate={maxDate}
    />
  </MuiPickersUtilsProvider>
);

DateRangePicker.propTypes = {
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
  onUpdate: PropTypes.func,
  label: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  emptyLabel: PropTypes.string,
};

DateRangePicker.defaultProps = {
  startDate: undefined,
  endDate: undefined,
  onUpdate: () => {},
  label: '',
  minDate: undefined,
  maxDate: undefined,
  emptyLabel: undefined,
};

export default DateRangePicker;
