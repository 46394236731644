import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import styled from 'styled-components';
import { union } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import Autocomplete from '../../forms/Autocomplete';
import addTagIcon from '../../../assets/images/addTagIcon.svg';
import { UploadedMediumCard } from '../../widgets';
import selectedTagIcon from '../../../assets/images/selectedTagIcon.svg';

const AddUploadedTagsModal = ({
  open, classes, onSave, usedTags, currentUploadedMedia, refetchUsedTags,
}) => {
  const [uploadedMedia, setUploadedMedia] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  useEffect(() => {
    if (currentUploadedMedia.length && currentUploadedMedia.some(
      currentMedia => !uploadedMedia.some(media => media.uuid === currentMedia.uuid),
    )) {
      setUploadedMedia(currentUploadedMedia.map(media => ({
        ...media,
        isSelected: false,
      })));
    }
  }, [currentUploadedMedia]);

  const isAnySelected = () => uploadedMedia.some(media => media.isSelected);

  const isAllSelected = () => !uploadedMedia.some(media => !media.isSelected);

  const selectedTags = () => {
    if (!isAnySelected) return [];
    const selectedMedia = uploadedMedia.filter(media => media.isSelected);
    let currentSelectedTags = [];
    selectedMedia.forEach((selected) => {
      if (selected.tags) currentSelectedTags = union(currentSelectedTags, selected.tags);
    });
    return currentSelectedTags;
  };

  const onAddTag = () => {
    if (!selectedTag) return;
    if (isAnySelected()) {
      setUploadedMedia(uploadedMedia.map((media) => {
        if (!media.isSelected) return media;
        if (media.tags.includes(selectedTag && selectedTag.label)) return media;
        return ({
          ...media,
          tags: [
            ...media.tags,
            selectedTag && selectedTag.label,
          ],
        });
      }));
    }
    setSelectedTag(null);
  };

  const handleSelectMedia = (selectedMedia) => {
    setUploadedMedia(uploadedMedia.map((media) => {
      if (media.uuid !== selectedMedia.uuid) return media;
      return ({
        ...media,
        isSelected: !media.isSelected,
      });
    }));
  };

  const handleSelectAllFiles = () => {
    if (isAllSelected()) {
      setUploadedMedia(uploadedMedia.map(media => ({
        ...media,
        isSelected: false,
      })));
    } else {
      setUploadedMedia(uploadedMedia.map(media => ({
        ...media,
        isSelected: true,
      })));
    }
  };

  const handleRemoveTag = (mediaUuid, tag) => setUploadedMedia(uploadedMedia.map((media) => {
    if (media.uuid !== mediaUuid) return media;
    return ({
      ...media,
      tags: media.tags.filter(mediaTag => mediaTag !== tag),
    });
  }));

  const handleRemoveSelectedTag = tag => setUploadedMedia(uploadedMedia.map((media) => {
    if (!media.isSelected) return media;
    return ({
      ...media,
      tags: media.tags.filter(mediaTag => mediaTag !== tag),
    });
  }));

  const handleInputChange = (value) => {
    if (value) setSelectedTag({ value, label: value });
    refetchUsedTags({ query: value });
  };

  const handleSave = async () => {
    await onSave(uploadedMedia);
    setUploadedMedia([]);
    setSelectedTag(null);
  };

  const renderCards = () => (
    <MediaCardWrapper>
      {uploadedMedia.map(media => (
        <UploadedMediumCard
          media={media}
          onSelect={handleSelectMedia}
          onRemoveTag={handleRemoveTag}
          key={media.uuid}
        />
      ))}
    </MediaCardWrapper>
  );

  const renderSelectedTags = () => selectedTags().map(tag => (
    <SelectedTag key={tag}>
      <SelectedTagName>{tag}</SelectedTagName>
      <RemoveSelectedTag
        src={selectedTagIcon}
        onClick={() => handleRemoveSelectedTag(tag)}
      />
    </SelectedTag>
  ));

  return (
    <Dialog
      maxWidth="lg"
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <ContentWrapper>
        <AddTagSection>
          <DialogTitle className={classes.title} disableTypography>Add Tag</DialogTitle>
          <AddTagWrapper>
            <AutocompleteWrapper>
              <Autocomplete
                options={usedTags}
                value={selectedTag}
                onChange={setSelectedTag}
                onInputChange={value => handleInputChange(value)}
                textFieldProps={{
                  label: 'Search tag',
                  InputLabelProps: {
                    shrink: true,
                  },
                }}
                placeholder=""
                className={classes.autocomplete}
                isClearable
              />
            </AutocompleteWrapper>
            <AddTagImage
              src={addTagIcon}
              onClick={onAddTag}
            />
          </AddTagWrapper>
          <SelectedTagsWrapper>
            {renderSelectedTags()}
          </SelectedTagsWrapper>
        </AddTagSection>
        <FileSection>
          <FileSectionHeader>
            <DialogTitle
              className={classes.title}
              disableTypography
            >
                Select files to add tags
            </DialogTitle>
            <Button
              variant="contained"
              className={classes.selectAllFilesButton}
              onClick={handleSelectAllFiles}
            >
              {isAllSelected() ? 'Unselect all files' : 'Select all Files'}
            </Button>
          </FileSectionHeader>
          <DialogContent className={classes.fileSectionContent}>
            {uploadedMedia && renderCards()}
          </DialogContent>
          <DialogActions className={classes.dialogActions}>
            <Button
              color="primary"
              variant="contained"
              className={classes.saveButton}
              onClick={handleSave}
            >
              Save
            </Button>
          </DialogActions>
        </FileSection>
      </ContentWrapper>
    </Dialog>
  );
};

const ContentWrapper = styled.div`
  display: flex;
`;

const AddTagSection = styled.div`
  background-color: rgb(236,236,236);
  width: 253px;
  padding: 33px 22px;
`;

const FileSection = styled.div`
`;

const FileSectionHeader = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 29px 25px 38px 25px;
  align-items: center;
`;

const AddTagWrapper = styled.div`
  display: flex;
  margin-top: 42px;
  align-items: flex-end;
`;

const AutocompleteWrapper = styled.div`
  width: 183px;
`;

const AddTagImage = styled.img`
  height: 22px;
  width: 22px;
  cursor: pointer;
  margin-left: 11px;
  padding: 5px;
`;

const MediaCardWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;

const SelectedTag = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
`;

const SelectedTagsWrapper = styled.div`
  margin-top: 83px;
  height: 392px;
  overflow-y: auto;
`;

const SelectedTagName = styled.div`
  font-family: 'Raleway-SemiBold';
  font-size: 16px;
`;

const RemoveSelectedTag = styled.img`
  height: 19px;
  width: 19px;
  cursor: pointer;
  padding: 5px;
`;

AddUploadedTagsModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onSave: PropTypes.func,
  refetchUsedTags: PropTypes.func,
  open: PropTypes.bool,
  currentUploadedMedia: PropTypes.array,
  usedTags: PropTypes.array,
};

AddUploadedTagsModal.defaultProps = {
  onSave: () => {},
  refetchUsedTags: () => {},
  open: false,
  currentUploadedMedia: [],
  usedTags: [],
};

export default withStyles(() => ({
  selectAllFilesButton: {
    backgroundColor: 'white',
    fontFamily: 'Raleway-SemiBold',
    borderRadius: '2px',
    height: '36px',
    width: '186px',
  },
  title: {
    fontFamily: 'Raleway-SemiBold',
    fontSize: '20px',
    padding: '0',
  },
  fileSectionContent: {
    height: '501px',
    width: '670px',
    padding: '0 0 0 25px',
  },
  saveButton: {
    height: '36px',
    width: '138px',
    borderRadius: '2px',
    fontFamily: 'Raleway-SemiBold',
  },
  dialogActions: {
    margin: '0',
    padding: '17px 25px',
    boxShadow: '0 -7px 5px -5px rgb(0,0,0, 0.16)',
  },
}))(AddUploadedTagsModal);
