import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import { Grid, Tab, Tabs } from '@material-ui/core';
import ScreenContainer from '../../components/layout/ScreenContainer';
import DateRangePicker from '../../components/forms/DateRangePicker';
import {
  ReportedHolrsTable,
  ReportedHolrbacksTable,
  ReportedUsersTable,
  ReportedProblemsTable,
} from '../../components/tables';
import {
  ReportedHolrModal,
  ReportedUserModal,
  ReportedProblemModal,
  BanUserModal,
} from '../../components/modals';

class ReportedContentScreen extends Component {
  static propTypes = {
    reportedHolrs: PropTypes.shape({
      data: PropTypes.array,
      loading: PropTypes.bool,
    }),
    reportedHolrbacks: PropTypes.shape({
      data: PropTypes.array,
      loading: PropTypes.bool,
    }),
    reportedUsers: PropTypes.shape({
      data: PropTypes.array,
      loading: PropTypes.bool,
    }),
    reportedProblems: PropTypes.shape({
      data: PropTypes.array,
      loading: PropTypes.bool,
    }),
    resolveReportedHolr: PropTypes.func,
    resolveReportedUser: PropTypes.func,
    resolveReportedProblem: PropTypes.func,
    initialTab: PropTypes.number,
    onReportedHolrsDateRangeUpdate: PropTypes.func,
    onReportedHolrbacksDateRangeUpdate: PropTypes.func,
    onReportedUsersDateRangeUpdate: PropTypes.func,
    onReportedProblemsDateRangeUpdate: PropTypes.func,
    onUpdateBanUser: PropTypes.func,
    onUpdateUnbanUser: PropTypes.func,
    classes: PropTypes.object.isRequired,
    loadMoreReportedHolrs: PropTypes.func,
    loadMoreReportedProblems: PropTypes.func,
    loadMoreReportedUsers: PropTypes.func,
    loadMoreReportedHolrbacks: PropTypes.func,
    totalCountHolrs: PropTypes.number,
    totalCountHolrbacks: PropTypes.number,
    totalCountUsers: PropTypes.number,
    totalCountProblems: PropTypes.number,
  };

  static defaultProps = {
    reportedHolrs: {
      data: [],
      loading: false,
    },
    reportedHolrbacks: {
      data: [],
      loading: false,
    },
    reportedUsers: {
      data: [],
      loading: false,
    },
    reportedProblems: {
      data: [],
      loading: false,
    },
    resolveReportedHolr: () => {},
    resolveReportedUser: () => {},
    resolveReportedProblem: () => {},
    onReportedHolrsDateRangeUpdate: () => {},
    onReportedHolrbacksDateRangeUpdate: () => {},
    onReportedUsersDateRangeUpdate: () => {},
    onReportedProblemsDateRangeUpdate: () => {},
    onUpdateBanUser: () => {},
    onUpdateUnbanUser: () => {},
    initialTab: 0,
    loadMoreReportedHolrs: () => {},
    loadMoreReportedProblems: () => {},
    loadMoreReportedUsers: () => {},
    loadMoreReportedHolrbacks: () => {},
    totalCountHolrs: 0,
    totalCountHolrbacks: 0,
    totalCountUsers: 0,
    totalCountProblems: 0,
  };

  state = {
    tab: 0,
    initialized: false,
    startDate: undefined,
    endDate: undefined,
    holrModalOpen: false,
    holrbackModalOpen: false,
    userModalOpen: false,
    problemModalOpen: false,
    banUserModalOpen: false,
    holrModalData: {},
    holrbackModalData: {},
    userModalData: {},
    problemModalData: {},
  };

  componentDidMount = () => {
    const { initialTab } = this.props;
    this.setState({ tab: initialTab });
  };

  updateDateRange = () => {
    const { tab, startDate, endDate } = this.state;
    const {
      onReportedHolrsDateRangeUpdate,
      onReportedHolrbacksDateRangeUpdate,
      onReportedUsersDateRangeUpdate,
      onReportedProblemsDateRangeUpdate,
    } = this.props;

    switch (tab) {
      case 0:
        onReportedHolrsDateRangeUpdate({ startDate, endDate });
        break;
      case 1:
        onReportedHolrbacksDateRangeUpdate({ startDate, endDate });
        break;
      case 2:
        onReportedUsersDateRangeUpdate({ startDate, endDate });
        break;
      case 3:
        onReportedProblemsDateRangeUpdate({ startDate, endDate });
        break;
      default:
        break;
    }
  }

  handleTab = (event, tab) => {
    this.setState({ tab }, this.updateDateRange);
  };

  handleDate = (startDate, endDate) => {
    this.setState({
      startDate: startDate.toDate(),
      endDate: endDate ? endDate.toDate() : startDate.toDate(),
    }, this.updateDateRange);
  };

  onTableClick = (modal, object) => {
    this.toggleModal(`${modal}Open`);
    this.setState({ [`${modal}Data`]: object });
  };

  renderTable = () => {
    const { tab } = this.state;
    const {
      reportedHolrs,
      reportedHolrbacks,
      reportedUsers,
      reportedProblems,
      loadMoreReportedHolrs,
      loadMoreReportedProblems,
      loadMoreReportedUsers,
      loadMoreReportedHolrbacks,
      totalCountHolrs,
      totalCountHolrbacks,
      totalCountUsers,
      totalCountProblems,
    } = this.props;

    switch (tab) {
      case 0:
        return (
          <ReportedHolrsTable
            data={reportedHolrs.data}
            loading={reportedHolrs.loading}
            onItemClick={object => this.onTableClick('holrModal', object)}
            loadMore={loadMoreReportedHolrs}
            totalCount={totalCountHolrs}
          />
        );
      case 1:
        return (
          <ReportedHolrbacksTable
            data={reportedHolrbacks.data}
            loading={reportedHolrbacks.loading}
            onItemClick={object => this.onTableClick('holrbackModal', object)}
            loadMore={loadMoreReportedHolrbacks}
            totalCount={totalCountHolrbacks}
          />
        );
      case 2:
        return (
          <ReportedUsersTable
            data={reportedUsers.data}
            loading={reportedUsers.loading}
            onItemClick={object => this.onTableClick('userModal', object)}
            loadMore={loadMoreReportedUsers}
            totalCount={totalCountUsers}
          />
        );
      case 3:
        return (
          <ReportedProblemsTable
            data={reportedProblems.data}
            loading={reportedProblems.loading}
            onItemClick={object => this.onTableClick('problemModal', object)}
            loadMore={loadMoreReportedProblems}
            totalCount={totalCountProblems}
          />
        );
      default:
        return null;
    }
  };

  toggleModal = (modal) => {
    this.setState(prevState => ({ [modal]: !prevState[modal] }));
  };

  handleResolve = (modal) => {
    this.toggleModal(`${modal}Open`);
  };

  render = () => {
    const {
      tab,
      startDate,
      endDate,
      holrModalOpen,
      holrbackModalOpen,
      userModalOpen,
      problemModalOpen,
      banUserModalOpen,
      holrModalData,
      holrbackModalData,
      userModalData,
      problemModalData,
    } = this.state;
    const {
      resolveReportedHolr,
      resolveReportedUser,
      resolveReportedProblem,
      onUpdateBanUser,
      onUpdateUnbanUser,
      classes,
    } = this.props;

    return (
      <ScreenContainer>
        <Grid container spacing={8}>
          <Grid item xs={9}>
            <Tabs value={tab} onChange={this.handleTab} indicatorColor="primary" textColor="primary">
              <Tab label="HOLRS" />
              <Tab label="HOLRBACKS" />
              <Tab label="USERS" />
              <Tab label="PROBLEMS" />
            </Tabs>
          </Grid>
          <Grid item xs={3} className={classes.filters}>
            <DateRangePicker
              onUpdate={this.handleDate}
              startDate={startDate}
              endDate={endDate}
              label="Date Range"
            />
          </Grid>
        </Grid>
        <TableWrapper>{this.renderTable()}</TableWrapper>
        <ReportedHolrModal
          onResolve={req => this.handleResolve('holrModal', resolveReportedHolr(req))}
          onCancel={() => this.toggleModal('holrModalOpen')}
          open={holrModalOpen}
          data={holrModalData}
        />
        <ReportedHolrModal
          onResolve={req => this.handleResolve('holrbackModal', resolveReportedHolr(req))}
          onCancel={() => this.toggleModal('holrbackModalOpen')}
          open={holrbackModalOpen}
          data={holrbackModalData}
          holrback
        />
        <ReportedUserModal
          onBanButtonClick={() => this.toggleModal('banUserModalOpen')}
          onUnbanButtonClick={(values) => {
            onUpdateUnbanUser(values);
            this.toggleModal('userModalOpen');
          }}
          onResolve={req => this.handleResolve('userModal', resolveReportedUser(req))}
          onCancel={() => this.toggleModal('userModalOpen')}
          open={userModalOpen}
          data={userModalData}
        />
        <ReportedProblemModal
          onResolve={req => this.handleResolve('problemModal', resolveReportedProblem(req))}
          onCancel={() => this.toggleModal('problemModalOpen')}
          open={problemModalOpen}
          data={problemModalData}
        />
        <BanUserModal
          open={banUserModalOpen}
          onCancel={() => this.toggleModal('banUserModalOpen')}
          user={{
            ...userModalData,
            username: userModalData.offender,
            uuid: userModalData.offenderUuid,
          }}
          onSave={(values) => {
            onUpdateBanUser(values);
            this.toggleModal('banUserModalOpen');
            this.toggleModal('userModalOpen');
          }}
        />
      </ScreenContainer>
    );
  };
}

const TableWrapper = styled.div`
  padding-top: 2rem;
`;

export default withStyles({
  filters: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'end',
  },
})(ReportedContentScreen);
