import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Query, Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import auth from '../../services/auth';
import withAuth from '../../contexts/AuthContext';
import { notify, messages } from '../../utils';
import ProfileScreen from '../../screens/ProfileScreen';

export const FETCH_PROFILE = gql`
  query FetchProfile($uuid: ID!) {
    fullUser(uuid: $uuid) {
      uuid
      firstName
      lastName
      phoneNumber
      email
      adminRole
      emailNotifications
      textNotifications
    }
  }
`;

export const UPDATE_PROFILE = gql`
  mutation UpdateProfile(
    $firstName: String!
    $lastName: String!
    $phone: String!
    $email: String!
    $emailNotification: Boolean!
    $textMessageNotification: Boolean!
  ) {
    updateCurrentUser(
      firstName: $firstName
      lastName: $lastName
      phoneNumber: $phone
      email: $email
      emailNotifications: $emailNotification
      textNotifications: $textMessageNotification
    ) {
      user {
        uuid
        firstName
        lastName
        phoneNumber
        email
        adminRole
        emailNotifications
        textNotifications
      }
    }
  }
`;

export class ProfileScreenConnector extends Component {
  static propTypes = {
    user: PropTypes.object.isRequired,
  };

  parseProfileData = (data) => {
    if (!data || !data.fullUser) {
      return {};
    }

    const {
      fullUser: {
        firstName,
        lastName,
        phoneNumber,
        email,
        adminRole,
        emailNotifications,
        textNotifications,
      },
    } = data;

    return {
      firstName,
      lastName,
      email,
      phone: phoneNumber,
      role: adminRole,
      emailNotification: emailNotifications,
      textMessageNotification: textNotifications,
    };
  };

  handlePasswordReset = async (payload) => {
    try {
      await auth.update(payload);

      return Promise.resolve();
    } catch (error) {
      return Promise.reject(error);
    }
  };

  render() {
    const {
      user: { uuid },
    } = this.props;

    if (!uuid) {
      return null;
    }

    return (
      <Query
        query={FETCH_PROFILE}
        variables={{ uuid }}
        fetchPolicy="network-only"
      >
        {({
          loading: fetchProfileLoading,
          error: fetchProfileError,
          data: fetchProfileData,
        }) => (
          <Mutation
            mutation={UPDATE_PROFILE}
            awaitRefetchQueries
            refetchQueries={[{
              query: FETCH_PROFILE,
              variables: { uuid },
            }]}
            onCompleted={() => notify.success({ ...messages.UPDATE_PROFILE.success })}
            onError={() => notify.error({ ...messages.UPDATE_PROFILE.error })}
          >
            {(updateProfile, {
              loading: updateProfileLoading,
              error: updateProfileError,
            }) => (
              <ProfileScreen
                fetchProfileLoading={fetchProfileLoading}
                updateProfileLoading={updateProfileLoading}
                fetchProfileError={fetchProfileError}
                updateProfileError={updateProfileError}
                onPasswordReset={this.handlePasswordReset}
                onUpdate={updateProfile}
                formProps={{
                  values: this.parseProfileData(fetchProfileData),
                }}
              />
            )}
          </Mutation>
        )}
      </Query>
    );
  }
}

export default withAuth(ProfileScreenConnector);
