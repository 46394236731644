import { createMuiTheme } from '@material-ui/core/styles';
import RalewayTTF from './assets/fonts/Raleway-Regular.ttf';

const raleway = {
  fontFamily: 'Raleway-Regular',
  fontStyle: 'normal',
  fontDisplay: 'swap',
  fontWeight: 400,
  src: `
    local('Raleway-Regular'),
    url(${RalewayTTF}) format('ttf')
  `,
};

const theme = createMuiTheme({
  typography: {
    fontFamily: 'Roboto, Raleway-Regular',
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#F5A827',
      light: '#FCCD68',
      dark: '#C9891F',
    },
    secondary: {
      main: '#FFFFFF',
    },
    common: {
      gray: '#A9A9A9',
      lightGray: '#F7F7F7A8',
      graphRed: '#E17156CC',
      graphYellow: '#FCCD68CC',
      graphOrange: '#EAAA47CC',
    },
    action: {
      hover: '#FCCD6855',
      selected: '#C9891FDD',
    },
  },
  spacing: {
    unit: 8,
    drawerWidth: 270,
    topPanelHeight: 64,
    footerHeight: 64,
  },
  overrides: {
    MuiCssBaseline: {
      '@global': {
        '@font-face': [raleway],
      },
    },
  },
});

export default theme;
