import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from '@material-ui/core';
import isEqual from 'lodash/isEqual';
import SearchBox from '../../forms/SearchBox';
import FollowersTable from '../../tables/FollowersTable';

class FollowersListModal extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        username: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        type: PropTypes.string,
        adminPrivileges: PropTypes.string,
      }),
    ),
    classes: PropTypes.object.isRequired,
    loading: PropTypes.bool,
    onItemClick: PropTypes.func,
    onClose: PropTypes.func,
    open: PropTypes.bool,
    loadMoreFollowers: PropTypes.func,
    totalCountFollowers: PropTypes.number,
  };

  static defaultProps = {
    onClose: () => {},
    onItemClick: () => {},
    loading: false,
    open: false,
    data: [],
    loadMoreFollowers: () => {},
    totalCountFollowers: 0,
  };

  state = {
    filteredData: [],
  };

  componentDidMount = () => {
    const { data } = this.props;

    this.setState({ filteredData: data });
  }

  componentDidUpdate = (prevProps) => {
    const { data } = this.props;

    if (!isEqual(data, prevProps.data)) {
      this.setState({ filteredData: data });
    }
  }

  onSearch = (value) => {
    const { data } = this.props;

    if (!value) {
      this.setState({ filteredData: data });
    } else {
      const filteredData = data
        .filter(item => item.name.toLowerCase().startsWith(value.toLowerCase()));
      this.setState({ filteredData });
    }
  };

  render() {
    const {
      open,
      onClose,
      classes,
      data,
      onItemClick,
      loading,
      loadMoreFollowers,
      totalCountFollowers,
    } = this.props;

    const { filteredData } = this.state;

    return (
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={onClose}
      >
        <DialogTitle className={classes.modalTitle}>
          {`Followers: ${data.length}`}
        </DialogTitle>

        <DialogContent>
          <Container>
            <SearchBoxWrapper>
              <SearchBox onSearch={this.onSearch} />
            </SearchBoxWrapper>
            <FollowersTable
              data={filteredData}
              onItemClick={onItemClick}
              loading={loading}
              loadMore={loadMoreFollowers}
              totalCount={totalCountFollowers}
            />
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const Container = styled.div`
  width: 900px;
  margin: 40px auto;
`;

const SearchBoxWrapper = styled.div`
  margin: 1rem 0 1rem 2rem;
`;

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  modalTitle: {
    borderBottom: '1px solid #949494',
  },
}))(FollowersListModal);
