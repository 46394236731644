import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import {
  Card,
  CardHeader,
  CardContent,
  CircularProgress,
} from '@material-ui/core';
import Before from '@material-ui/icons/NavigateBefore';
import Next from '@material-ui/icons/NavigateNext';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './style.css';

// eslint-disable-next-line
const SliderButtonPrev = ({ onClick }) => (<div><StyledBefore onClick={onClick} className="slick-prev" /></div>);
// eslint-disable-next-line
const SliderButtonNext = ({ onClick }) => (<div><StyledNext onClick={onClick} className="slick-next" /></div>);

const DashboardCarouselCard = ({
  title,
  loading,
  children,
}) => (
  <Card>
    <CardHeader title={title} />
    <CardContent>
      { loading
        ? (
          <LoadingContainer>
            <CircularProgress thickness={5} size={80} />
          </LoadingContainer>
        )
        : (
          <SliderWrapper>
            <Slider
              infinite
              speed={500}
              slidesToShow={3}
              slidesToScroll={1}
              prevArrow={<SliderButtonPrev />}
              nextArrow={<SliderButtonNext />}
            >
              {React.Children.map(children, child => <ChildrenWrapper>{child}</ChildrenWrapper>)}
            </Slider>
          </SliderWrapper>
        )
      }
    </CardContent>
  </Card>
);

DashboardCarouselCard.propTypes = {
  title: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.array]),
};

DashboardCarouselCard.defaultProps = {
  title: '',
  loading: false,
  children: null,
};

const LoadingContainer = styled.div`
  text-align: center;
`;

const SliderWrapper = styled.div`
  min-width: 920px;
  padding: 0 1rem;
`;

const ChildrenWrapper = styled.div`
  margin: 10px 0;
  outline: none;
  height: 230px;
`;

const StyledBefore = styled(Before)`
  color: black !important;
  font-size: 50px !important;
  z-index: 1;
`;

const StyledNext = styled(Next)`
  color: black !important;
  font-size: 50px !important;
  z-index: 1;
`;

export default DashboardCarouselCard;
