import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import SubscribersReportCard from './SubscribersReportCard';
import { SUBSCRIBER_STATS } from './queries';
import { downloadCsv } from '../../../utils/csv';

const initialDates = { startDate: moment().subtract(1, 'years'), endDate: moment() };

export const connect = Component => (props) => {
  // eslint-disable-next-line
  const { data, refetch } = useQuery(SUBSCRIBER_STATS, {
    variables: { startDate: initialDates.startDate, endDate: initialDates.endDate, groupingType: 'CUMULATIVE' },
  });

  const handleChange = (dateRange, dataType) => refetch({ ...dateRange, groupingType: dataType });

  const parseData = () => {
    if (!(data && data.subscriberStats && data.subscriberStats.stats)) return [];
    return data.subscriberStats.stats.map(({
      statsDate, paidSubscriptions, unpaidSubscriptions,
    }) => ({
      date: statsDate,
      Paid: paidSubscriptions,
      Unpaid: unpaidSubscriptions,
    }));
  };

  const headers = [{
    statsDate: 'Date', paidSubscriptions: 'Paid', unpaidSubscriptions: 'Unpaid',
  }];

  return (
    <Component
      onChange={handleChange}
      data={parseData()}
      onDownloadCsv={() => downloadCsv(data.subscriberStats.stats, headers, 'Subscribers')}
      {...props}
    />
  );
};

export default connect(SubscribersReportCard);
