import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Query, Mutation } from '@apollo/react-components';
import { Adopt } from 'react-adopt';
import gql from 'graphql-tag';
import { date, notify, messages } from '../../utils';
import SubCategoryScreen from '../../screens/SubCategoryScreen';

export const FETCH_SUBCATEGORY = gql`
  query FetchSubcategory($uuid: ID!) {
    subcategory(uuid: $uuid){
      uuid
      name
      avatar {
        url
      }
      category {
        uuid
        name
      }
      holrCount,
      subscriberCount,
      lastMonthStats{
        holrCount
        subscriberCount
      }
      updatedAt
    }
  }
`;

export const FETCH_CATEGORIES = gql`
  query FetchCategories{
    categories {
      nodes {
        uuid
        name
      }
    }
  }
`;

export const UPDATE_SUBCATEGORY = gql`
  mutation UpdateSubCategory(
    $uuid: ID!,
    $name: String,
    $avatar: Upload,
    $parentCategory: ID,
  ) {
    updateSubcategory(
      uuid: $uuid,
      name: $name,
      avatar: $avatar,
      categoryUuid: $parentCategory
    ) {
      subcategory {
        uuid
        name
        avatar {
          url
        }
        category {
          uuid
          name
        }
        holrCount,
        subscriberCount,
        lastMonthStats{
          holrCount
          subscriberCount
        }
        updatedAt
      }
    }
  }
`;

export const DELETE_SUBCATEGORY = gql`
  mutation DeleteSubCategory($uuid: ID!) {
    deleteSubcategory(uuid: $uuid) {
      success
    }
  }
`;

function mapSubCategory({ data, loading, error }) {
  if (loading || error) {
    return {
      subCategoryData: {},
      subCategoryLoading: loading,
      subCategoryError: error,
    };
  }

  try {
    return {
      subCategoryData: {
        uuid: data.subcategory.uuid,
        name: data.subcategory.name,
        avatar: data.subcategory.avatar.url,
        parentCategory: {
          uuid: data.subcategory.category.uuid,
          name: data.subcategory.category.name,
        },
        holrs: data.subcategory.holrCount,
        subscribers: data.subcategory.subscriberCount,
        subscriberStats: data.subcategory.lastMonthStats
          && data.subcategory.lastMonthStats.subscriberCount,
        holrStats: data.subcategory.lastMonthStats
          && data.subcategory.lastMonthStats.holrCount,
        lastUpdated: date.lastUpdated(data.subcategory.updatedAt),
      },
    };
  } catch (mapError) {
    return {
      subCategoryData: [],
      subCategoryLoading: loading,
      subCategoryError: mapError,
    };
  }
}

function mapCategories({ data, loading, error }) {
  if (loading || error) {
    return {
      categoriesData: [],
      categoriesLoading: loading,
      categoriesError: error,
    };
  }

  try {
    return {
      categoriesData: data.categories.nodes.map(node => ({
        uuid: node.uuid,
        name: node.name,
      })),
      categoriesLoading: loading,
      categoriesError: error,
    };
  } catch (mapError) {
    return {
      categoriesData: [],
      categoriesLoading: loading,
      categoriesError: mapError,
    };
  }
}

export const SubCategoryScreenConnector = ({
  history,
  match: {
    params: { uuid },
  },
}) => {
  const mapper = {
    /* eslint react/prop-types: 0 */
    subCategory: ({ render }) => (
      <Query
        query={FETCH_SUBCATEGORY}
        variables={{ uuid }}
        onError={() => notify.error({ ...messages.FETCH_SUBCATEGORY.error })}
        fetchPolicy="network-only"
      >
        {render}
      </Query>
    ),
    categories: ({ render }) => (
      <Query
        query={FETCH_CATEGORIES}
        onError={() => notify.error({ ...messages.FETCH_CATEGORIES.error })}
        fetchPolicy="network-only"
      >
        {render}
      </Query>
    ),
    updateSubCategory: ({ render }) => (
      <Mutation
        mutation={UPDATE_SUBCATEGORY}
        onCompleted={() => notify.success({ ...messages.UPDATE_SUBCATEGORY.success })}
        onError={() => notify.error({ ...messages.UPDATE_SUBCATEGORY.error })}
      >
        {(mutation, result) => render({ mutation, result })}
      </Mutation>
    ),
    deleteSubCategory: ({ render }) => (
      <Mutation
        mutation={DELETE_SUBCATEGORY}
        variables={{ uuid }}
        onCompleted={() => {
          history.push('/content');
          notify.success({ ...messages.DELETE_SUBCATEGORY.success });
        }}
        onError={() => notify.error({ ...messages.DELETE_SUBCATEGORY.error })}
      >
        {(mutation, result) => render({ mutation, result })}
      </Mutation>
    ),
  };

  return (
    <Adopt mapper={mapper}>
      {({
        subCategory,
        categories,
        updateSubCategory: {
          mutation: updateSubCategory,
          result: {
            loading: updateSubCategoryLoading,
          },
        },
        deleteSubCategory: {
          mutation: deleteSubCategory,
        },
      }) => {
        const mappedSubCategory = mapSubCategory(subCategory);
        const mappedCategories = mapCategories(categories);

        return (
          <SubCategoryScreen
            subCategory={{ ...mappedSubCategory }}
            categories={{ ...mappedCategories }}
            onUpdate={updateSubCategory}
            onDelete={deleteSubCategory}
            updateLoading={updateSubCategoryLoading}
            onReturnClick={() => history.push('/content')}
          />
        );
      }}
    </Adopt>
  );
};

SubCategoryScreenConnector.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
};

export default withRouter(SubCategoryScreenConnector);
