import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
} from '@material-ui/core';
import gql from 'graphql-tag';
import { Query } from '@apollo/react-components';
import styled from 'styled-components';
import Autocomplete from '../../forms/Autocomplete';
import addTagIcon from '../../../assets/images/addTagIcon.svg';
import { CustomChip } from '../../forms';

export const FETCH_USER_TAG = gql`
  query GetTags($uuid: ID! $first: Int! $query: String) {
    usedTags(userUuid: $uuid first: $first query:$query){
      edges {
        node {
          name,
          uuid
        }
      }
    }
  }
`;

class AddTagModal extends Component {
  static propTypes = {
    title: PropTypes.string,
    open: PropTypes.bool,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    classes: PropTypes.object.isRequired,
    uuid: PropTypes.string.isRequired,
  }

  static defaultProps = {
    title: '',
    open: false,
    onSave: () => { },
    onCancel: () => { },
  }

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      query: '',
      isSubmitting: false,
      selectedTag: null,
      tags: [],
    };
  }

  handleInputChange = value => value && this.setState({
    selectedTag: { value, label: value },
    query: value,
  })

  handleCancel = () => {
    const { onCancel } = this.props;
    onCancel();
    this.setState({ selectedTag: null, tags: [], query: '' });
  }

  handleDeleteTag = (currentTag) => {
    const { tags } = this.state;
    this.setState({
      tags: tags.filter(tag => tag.label !== currentTag.label),
    });
  }

  parseUsedTags = tags => tags.map(tag => ({ value: tag.name, label: tag.name }));

  onAddTag = () => {
    const { selectedTag, tags } = this.state;
    if (!selectedTag) return;
    if (!tags.some(tag => tag.label === selectedTag.label)) {
      this.setState({
        tags: [...tags, selectedTag],
      });
    }
    this.setState({ selectedTag: null });
  }

  renderForm = () => {
    const { uuid, classes } = this.props;
    const { query, selectedTag, tags } = this.state;

    return (
      <Query
        query={FETCH_USER_TAG}
        variables={{ first: 5, uuid, query }}
        fetchPolicy="network-only"
      >
        {(userTags) => {
          const { data } = this.mapTags(userTags);
          return (
            <FormControl
              className={classes.formControl}
            >
              <AddTagWrapper>
                <AutocompleteWrapper>
                  <Autocomplete
                    options={this.parseUsedTags(data)}
                    value={selectedTag}
                    onChange={tag => this.setState({ selectedTag: tag })}
                    onInputChange={value => this.handleInputChange(value)}
                    placeholder="Search Tags"
                    classes={{
                      placeholder: classes.placeholder,
                      input: classes.input,
                    }}
                    isClearable
                  />
                </AutocompleteWrapper>
                <AddTagImage
                  src={addTagIcon}
                  onClick={this.onAddTag}
                />
              </AddTagWrapper>
              <ChipWrapper>
                {tags.map(tag => (
                  <CustomChip
                    key={tag.label}
                    label={tag.label}
                    onDelete={() => this.handleDeleteTag(tag)}
                  />
                ))}
              </ChipWrapper>
            </FormControl>
          );
        }}
      </Query>
    );
  }

  mapTags = ({ error, data, loading }) => {
    if (loading || error) {
      return { data: [], loading, error };
    }

    try {
      return {
        data: data.usedTags.edges.map(edge => ({ ...edge.node })),
        loading,
        error,
      };
    } catch (mapError) {
      return { data: [], loading, error: mapError };
    }
  }

  handleSave = async () => {
    const { onSave } = this.props;
    const { tags } = this.state;

    try {
      this.setState({ isSubmitting: true });

      await onSave(tags.map(tag => ({ name: tag.label })));
    } finally {
      this.setState({ isSubmitting: false });
    }
    this.setState({ selectedTag: null, tags: [], query: '' });
  }

  render() {
    const {
      isSubmitting,
    } = this.state;
    const {
      title,
      open,
      classes,
    } = this.props;

    return (
      <Dialog
        maxWidth="md"
        open={open}
        onClose={this.handleCancel}
        classes={{ paper: classes.dialog }}
      >
        <DialogTitle className={classes.modalTitle}>
          {title}
        </DialogTitle>
        <DialogContent className={classes.content}>
          {this.renderForm()}
        </DialogContent>
        <DialogActions className={classes.dialogActions}>
          <Button
            onClick={this.handleCancel}
            color="primary"
            className={classes.dialogButton}
          >
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={isSubmitting}
            onClick={this.handleSave}
            className={classes.dialogButton}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const AutocompleteWrapper = styled.div`
  width: 417px;
`;

const AddTagWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-end;
`;

const AddTagImage = styled.img`
  height: 22px;
  width: 22px;
  cursor: pointer;
  margin-left: 11px;
  padding: 5px;
`;

const ChipWrapper = styled.div`
  display: flex;
  padding: 20px 20px;
`;

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  dialog: {
    overflow: 'visible',
  },
  content: {
    padding: '40px 0 13px 0',
    overflow: 'visible',
  },
  formControl: {
    padding: '0 61px',
  },
  modalTitle: {
    borderBottom: `1px solid ${theme.palette.common.gray}`,
  },
  placeholder: {
    left: '16px',
    fontSize: '16px',
    fontFamily: 'Raleway-SemiBold',
    color: 'black',
  },
  input: {
    paddingLeft: '13px',
  },
  dialogActions: {
    margin: '0',
    padding: '17px 25px',
    boxShadow: '0 -7px 5px -5px rgb(0,0,0, 0.16)',
  },
  dialogButton: {
    height: '36px',
    width: '138px',
    borderRadius: '2px',
    fontFamily: 'Raleway-SemiBold',
  },
}))(AddTagModal);
