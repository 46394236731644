import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Formik } from 'formik';
import { isEmpty, isEqual } from 'lodash';
import { withStyles } from '@material-ui/core/styles';
import FavoriteIcon from '@material-ui/icons/Favorite';
import NavigateBefore from '@material-ui/icons/NavigateBefore';
import {
  Paper,
  Typography,
  Grid,
  IconButton,
  Button,
  Select,
  MenuItem,
  CircularProgress,
  FormControl,
  FormLabel,
} from '@material-ui/core/';
import ScreenContainer from '../../components/layout/ScreenContainer';
import { UserDetailsForm } from '../../components/forms';
import { InfoCard, Logo } from '../../components/widgets';
import {
  DiscardChangesConfirmModal,
  FollowersListModal,
  PaymentsListModal,
  FollowingListModal,
  HolrsListModal,
  HolrDetailsModal,
  ReportedContentListModal,
  ReportedContentDetailsModal,
  BanUserModal,
} from '../../components/modals';
import { validate } from '../../utils';

class UserScreen extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    userData: PropTypes.shape({
      uuid: PropTypes.string,
      username: PropTypes.string,
      name: PropTypes.string,
      firstName: PropTypes.string,
      lastName: PropTypes.string,
      fullName: PropTypes.string,
      bio: PropTypes.string,
      email: PropTypes.string,
      facebook: PropTypes.string,
      instagram: PropTypes.string,
      followerCount: PropTypes.number,
      followingCount: PropTypes.number,
      avatar: PropTypes.any,
      cover: PropTypes.any,
      categories: PropTypes.arrayOf(PropTypes.object),
      subcategories: PropTypes.array,
      userType: PropTypes.string,
      adminRole: PropTypes.string,
      banned: PropTypes.bool,
    }),
    holrsData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        type: PropTypes.string,
        likes: PropTypes.number,
        views: PropTypes.number,
        holrbacks: PropTypes.number,
        postDate: PropTypes.string,
      }),
    ),
    paymentsData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        billingStart: PropTypes.string,
        billingEnd: PropTypes.string,
        amount: PropTypes.string,
        admin: PropTypes.string,
        transactionDate: PropTypes.string,
      }),
    ),
    followersData: PropTypes.arrayOf(
      PropTypes.shape({
        username: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        type: PropTypes.string,
        adminPrivileges: PropTypes.string,
      }),
    ),
    followingData: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        follower: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        type: PropTypes.string,
        adminPrivileges: PropTypes.string,
      }),
    ),
    reportedContentData: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        reportedBy: PropTypes.string,
        reason: PropTypes.string,
        status: PropTypes.string,
        reportedDate: PropTypes.string,
      }),
    ),
    onUpdateUserData: PropTypes.func,
    updateProfileLoading: PropTypes.bool,
    onUpdateReportedContent: PropTypes.func,
    onUpdateHolr: PropTypes.func,
    onUpdatePayment: PropTypes.func,
    onUpdateUserType: PropTypes.func,
    onUpdateAdminPrivileges: PropTypes.func,
    onUpdateBanUser: PropTypes.func,
    onUpdateFlagHolr: PropTypes.func,
    onUpdateBanAuthorHolr: PropTypes.func,
    isActiveUser: PropTypes.bool,
    activeUser: PropTypes.object,
    totalCountFollowers: PropTypes.number,
    totalCountFollowing: PropTypes.number,
    totalCountPayments: PropTypes.number,
    onReturnClick: PropTypes.func,
    loadMoreFollowers: PropTypes.func,
    loadMoreFollowing: PropTypes.func,
    onUpdateUnbanUser: PropTypes.func,
    onCancel: PropTypes.func,
    categories: PropTypes.array,
  };

  static defaultProps = {
    onUpdateUserData: () => {},
    updateProfileLoading: false,
    userData: {
      username: '',
      name: '',
      bio: '',
      email: '',
      facebook: '',
      instagram: '',
      avatar: '',
      cover: '',
      categories: [],
      subcategories: [],
      userType: '',
      adminRole: '',
    },
    holrsData: [],
    paymentsData: [],
    followersData: [],
    followingData: [],
    reportedContentData: [],
    onUpdateReportedContent: () => {},
    onUpdateHolr: () => {},
    onUpdatePayment: () => {},
    onUpdateUserType: () => {},
    onUpdateAdminPrivileges: () => {},
    onUpdateFlagHolr: () => {},
    onUpdateBanAuthorHolr: () => {},
    onUpdateBanUser: () => {},
    isActiveUser: false,
    activeUser: {},
    totalCountFollowers: 0,
    totalCountFollowing: 0,
    totalCountPayments: 0,
    onReturnClick: () => {},
    loadMoreFollowers: () => {},
    loadMoreFollowing: () => {},
    onUpdateUnbanUser: () => {},
    onCancel: () => {},
    categories: [],
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      paymentsModalOpen: false,
      followersModalOpen: false,
      followingModalOpen: false,
      holrsModalOpen: false,
      banUserModalOpen: false,
      paymentDetailsModalOpen: false,
      holrsDetailsModalOpen: false,
      reportedContentModalOpen: false,
      reportedContentDetailsModalOpen: false,
      discardChangesConfirmModalOpen: false,
      isValid: false,
      hasChanged: false,
      currentHolr: {},
      currentPayment: {},
      currentReported: {},
      userType: props.userData.userType,
      adminRole: props.userData.adminRole,
    };
  }

  toggleModal = (modal) => {
    const { state } = this;
    state[modal] = !state[modal];
    this.setState(state);
  }

  validation = (values) => {
    const { userData } = this.props;
    const errors = {};

    if (!values.username) {
      errors.username = 'Required';
    }

    if (!values.firstName) {
      errors.firstName = 'Required';
    }

    if (!values.lastName) {
      errors.lastName = 'Required';
    }

    if (!values.bio) {
      errors.bio = 'Required';
    }

    if (!values.avatar) {
      errors.avatar = 'Required';
    }

    if (!values.cover) {
      errors.cover = 'Required';
    }

    if (!values.email) {
      errors.email = 'Required';
    } else if (!validate.email(values.email)) {
      errors.email = 'Invalid email address';
    }

    const hasChanged = !Object.keys(values).every(key => isEqual(values[key], userData[key]));

    this.setState({
      isValid: isEmpty(errors),
      hasChanged,
    });

    return errors;
  };

  handleSubmit = async (values) => {
    const { onUpdateUserData, userData } = this.props;

    const variables = { uuid: userData.uuid };

    Object.keys(values).forEach((key) => {
      if (!isEqual(values[key], userData[key])) {
        variables[key] = values[key];
      }
    });

    variables.categories = values.categories.map(category => category.uuid);
    variables.subcategories = values.subcategories.map(subcategory => subcategory.uuid);

    await onUpdateUserData({ variables });
  };

  handleSetCurrentHolr = (holr) => {
    this.setState({ currentHolr: holr }, () => this.toggleModal('holrsDetailsModalOpen'));
  }

  handleSetCurrentPayment = (payment) => {
    this.setState({ currentPayment: payment }, () => false);
  }

  handleSetCurrentReportedContent = (reportedContent) => {
    this.setState({ currentReported: reportedContent }, () => this.toggleModal('reportedContentDetailsModalOpen'));
  }

  handleUserTypeChange = (event) => {
    const { onUpdateUserType, userData } = this.props;
    const userType = event.target.value === 'NONE' ? null : event.target.value;

    this.setState({ userType });

    onUpdateUserType({ variables: { uuid: userData.uuid, appRole: userType } });
  };

  handleAdminPrivilegeChange = (event) => {
    const { onUpdateAdminPrivileges, userData } = this.props;
    const adminRole = event.target.value === 'NONE' ? null : event.target.value;

    this.setState({ adminRole });

    onUpdateAdminPrivileges({ variables: { uuid: userData.uuid, adminRole } });
  };

  renderTopOptions = () => {
    const {
      classes,
      reportedContentData,
      userData: { fullName, userType, adminRole },
      onReturnClick,
      activeUser,
      isActiveUser,
    } = this.props;

    return (
      <Grid className={classes.topOptions} container>
        <Grid item xs={3}>
          <TitleContainer>
            <IconButton onClick={onReturnClick}>
              <NavigateBefore />
            </IconButton>
            <Typography align="left" variant="headline" className={classes.title}>
              {fullName}
            </Typography>
          </TitleContainer>
        </Grid>
        <Grid item xs={9} container alignItems="center" justify="flex-end">
          {!isActiveUser && (
            <Fragment>
              <Grid item className={classes.rightAligned} xs={3}>
                <FormControl className={classes.formControl}>
                  <FormLabel htmlFor="user-type">User Type</FormLabel>
                  <Select
                    className={classes.topSelectOptions}
                    value={userType}
                    onChange={this.handleUserTypeChange}
                    inputProps={{
                      id: 'user-type',
                      name: 'user-type',
                    }}
                  >
                    <MenuItem value="NONE">None</MenuItem>
                    <MenuItem value="FAN" disabled={userType === 'INFLUENCER'}>Fan</MenuItem>
                    <MenuItem value="INFLUENCER">Influencer</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
              <Grid item className={classes.rightAligned} xs={3}>
                {activeUser.adminRole === 'super_admin' && (
                  <FormControl className={classes.formControl}>
                    <FormLabel htmlFor="admin-privilege">Admin Privileges</FormLabel>
                    <Select
                      className={classes.topSelectOptions}
                      value={adminRole}
                      onChange={this.handleAdminPrivilegeChange}
                      inputProps={{
                        id: 'user-type',
                        name: 'user-type',
                      }}
                    >
                      <MenuItem value="NONE">None</MenuItem>
                      <MenuItem value="ADMIN">Admin</MenuItem>
                      <MenuItem value="SUPER_ADMIN">
                        Super Admin
                      </MenuItem>
                    </Select>
                  </FormControl>
                )}
              </Grid>
            </Fragment>
          )}
          <Grid item className={classes.rightAligned} xs={3}>
            <Button
              style={{ width: '60%' }}
              color={reportedContentData.length ? 'primary' : 'default'}
              variant={reportedContentData.length ? 'contained' : 'outlined'}
              onClick={() => this.toggleModal('reportedContentModalOpen')}
            >
              {`Reports (${reportedContentData.length})`}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  }

  renderLoading = () => (
    <LoadingContainer>
      <CircularProgress thickness={5} size={80} />
    </LoadingContainer>
  );

  renderModal = () => {
    const {
      userData,
      holrsData,
      paymentsData,
      followersData,
      followingData,
      reportedContentData,
      onUpdateReportedContent,
      onUpdateHolr,
      onUpdateBanUser,
      onUpdateFlagHolr,
      onUpdateBanAuthorHolr,
      loadMoreFollowers,
      totalCountFollowers,
    } = this.props;

    const {
      followersModalOpen,
      paymentsModalOpen,
      holrsModalOpen,
      followingModalOpen,
      holrsDetailsModalOpen,
      reportedContentDetailsModalOpen,
      discardChangesConfirmModalOpen,
      reportedContentModalOpen,
      banUserModalOpen,
      currentHolr,
      currentReported,
    } = this.state;

    return (
      <Fragment>
        <PaymentsListModal
          open={paymentsModalOpen}
          data={paymentsData}
          onItemClick={this.handleSetCurrentPayment}
          onClose={() => this.toggleModal('paymentsModalOpen')}
        />
        <FollowersListModal
          open={followersModalOpen}
          data={followersData}
          loadMoreFollowers={loadMoreFollowers}
          totalCountFollowers={totalCountFollowers}
          onClose={() => this.toggleModal('followersModalOpen')}
        />
        <FollowingListModal
          open={followingModalOpen}
          data={followingData}
          onClose={() => this.toggleModal('followingModalOpen')}
        />
        <HolrsListModal
          open={holrsModalOpen}
          data={holrsData}
          onItemClick={this.handleSetCurrentHolr}
          onClose={() => this.toggleModal('holrsModalOpen')}
        />
        <ReportedContentListModal
          data={reportedContentData}
          open={reportedContentModalOpen}
          onItemClick={this.handleSetCurrentReportedContent}
          onClose={() => this.toggleModal('reportedContentModalOpen')}
        />
        <BanUserModal
          user={userData}
          open={banUserModalOpen}
          onCancel={() => this.toggleModal('banUserModalOpen')}
          onSave={(values) => {
            onUpdateBanUser(values);
            this.toggleModal('banUserModalOpen');
          }}
        />
        <HolrDetailsModal
          holr={currentHolr}
          open={holrsDetailsModalOpen}
          onCancel={() => this.toggleModal('holrsDetailsModalOpen')}
          onSave={onUpdateHolr}
          onFlag={onUpdateFlagHolr}
          onBan={onUpdateBanAuthorHolr}
        />
        <ReportedContentDetailsModal
          reportedContent={currentReported}
          user={{ holr: userData.username }}
          open={reportedContentDetailsModalOpen}
          onCancel={() => this.toggleModal('reportedContentDetailsModalOpen')}
          onSave={onUpdateReportedContent}
        />
        <DiscardChangesConfirmModal
          open={discardChangesConfirmModalOpen}
          onCancelClick={() => this.toggleModal('discardChangesConfirmModalOpen')}
          onConfirmClick={() => {
            this.form.current.resetForm();
            this.toggleModal('discardChangesConfirmModalOpen');
          }}
        />
      </Fragment>
    );
  }

  render() {
    const {
      updateProfileLoading,
      classes,
      userData,
      holrsData,
      onUpdateUnbanUser,
      onCancel,
      isActiveUser,
      categories,
      totalCountFollowers,
      totalCountFollowing,
      totalCountPayments,
    } = this.props;

    const {
      isValid,
      hasChanged,
    } = this.state;

    if (updateProfileLoading) return this.renderLoading();

    return (
      <ScreenContainer
        withFooter
        footerProps={{
          rightButtons: [{
            variant: 'contained',
            label: 'Update',
            color: 'primary',
            onClick: () => this.form.current.submitForm(),
            disabled: !isValid || updateProfileLoading,
          }, {
            variant: 'outlined',
            label: 'Cancel',
            onClick: hasChanged ? () => this.toggleModal('discardChangesConfirmModalOpen') : onCancel,
          }],
          leftButtons: isActiveUser ? [] : [{
            variant: 'outlined',
            label: userData.banned ? 'Unban User' : 'Ban User',
            onClick: userData.banned
              ? () => onUpdateUnbanUser({ variables: { uuid: userData.uuid } })
              : () => this.toggleModal('banUserModalOpen'),
          }],
        }}
      >
        <Grid container>
          {this.renderTopOptions()}
          <Grid item xs={12}>
            <Paper className={classes.paper}>
              <div className={classes.form}>
                <Formik
                  enableReinitialize
                  initialValues={{
                    username: userData.username || '',
                    firstName: userData.firstName || '',
                    lastName: userData.lastName || '',
                    bio: userData.bio || '',
                    email: userData.email || '',
                    facebook: userData.facebook || '',
                    instagram: userData.instagram || '',
                    avatar: userData.avatar || '',
                    cover: userData.cover || '',
                    categories: userData.categories || [],
                    subcategories: userData.subcategories || [],
                  }}
                  onSubmit={this.handleSubmit}
                  validate={this.validation}
                  ref={this.form}
                >
                  {props => (
                    <UserDetailsForm
                      {...props}
                      categories={categories}
                    />
                  )}
                </Formik>
                <Grid item xs={12}>
                  <div className={classes.cardContainer}>
                    <InfoCard
                      type="Followers"
                      title={totalCountFollowers}
                      subtitle="View & moderate all followers"
                      onCardClick={() => this.toggleModal('followersModalOpen')}
                      subtitleIcon={<FavoriteIcon />}
                      icon={<Logo style={{ width: 50, fill: '#d8d8d8' }} />}
                    />
                  </div>
                  <div className={classes.cardContainer}>
                    <InfoCard
                      type="Following"
                      title={totalCountFollowing}
                      showToggle
                      toggleLabel="Public"
                      toggled
                      subtitle="View list of users following"
                      onCardClick={() => this.toggleModal('followingModalOpen')}
                      subtitleIcon={<FavoriteIcon />}
                      icon={<Logo style={{ width: 50, fill: '#d8d8d8' }} />}
                    />
                  </div>
                </Grid>
                <Grid item xs={12}>
                  <div className={classes.cardContainer}>
                    <InfoCard
                      type="Payments"
                      title={totalCountPayments}
                      subtitle="View all payments"
                      onCardClick={() => this.toggleModal('paymentsModalOpen')}
                      subtitleIcon={<FavoriteIcon />}
                      icon={<Logo style={{ width: 50, fill: '#d8d8d8' }} />}
                    />
                  </div>
                  <div className={classes.cardContainer}>
                    <InfoCard
                      type="Holrs"
                      title={holrsData.length}
                      subtitle="View & moderate all users holrs"
                      onCardClick={() => this.toggleModal('holrsModalOpen')}
                      subtitleIcon={<FavoriteIcon />}
                      icon={<Logo style={{ width: 50, fill: '#d8d8d8' }} />}
                    />
                  </div>
                </Grid>
              </div>
            </Paper>
          </Grid>
        </Grid>
        { this.renderModal() }
      </ScreenContainer>
    );
  }
}

const LoadingContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export default withStyles(theme => ({
  form: {
    width: '95%',
    minWidth: '20rem',
    margin: '1rem',
  },
  paper: {
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 4}px`,
  },
  title: {
    marginLeft: theme.spacing.unit,
  },
  formControl: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  cardContainer: {
    display: 'inline-block',
    marginRight: '2rem',
    marginTop: '2rem',
  },
  rightAligned: {
    textAlign: 'right',
  },
  topSelectOptions: {
    marginLeft: '1rem',
    marginTop: '0px !important',
  },
  topOptions: {
    marginBottom: '1rem',
  },
}))(UserScreen);
