import React from 'react';
import PropTypes from 'prop-types';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import MaterialDatePicker from 'material-ui-pickers/DatePicker';

const DatePicker = ({
  date, label, onChange,
}) => (
  <MuiPickersUtilsProvider utils={MomentUtils}>
    <MaterialDatePicker
      onChange={onChange}
      value={date}
      label={label}
      format="MM/DD/YYYY"
    />
  </MuiPickersUtilsProvider>
);

DatePicker.propTypes = {
  date: PropTypes.instanceOf(Date),
  onChange: PropTypes.func,
  label: PropTypes.string,
};

DatePicker.defaultProps = {
  date: undefined,
  onChange: () => {},
  label: '',
};

export default DatePicker;
