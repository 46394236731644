import React from 'react';
import PropTypes from 'prop-types';

const getPathTop = (x, y, width, height) => (
  `M${x} ${y}
  C${x + width / 4} ${y - 10},${x + width * 3 / 4} ${y - 10}, ${x + width} ${y}
  V${y + height}
  C${x + width * 3 / 4} ${y + height - 10},${x + width / 4} ${y + height - 10}, ${x} ${y + height}
  Z`
);

const getPathBottom = (x, y, width, height) => (
  `M${x} ${y}
  C${x + width / 4} ${y - 10},${x + width * 3 / 4} ${y - 10}, ${x + width} ${y}
  V${y + height - 5}
  C${x + width * 3 / 4} ${y + height},${x + width / 4} ${y + height}, ${x} ${y + height - 5}
  Z`
);

const BarShape = (props) => {
  const {
    fill, x, y, width, height, top,
  } = props;

  return top ? <path d={getPathTop(x, y, width, height)} stroke="none" fill={fill} />
    : <path d={getPathBottom(x, y, width, height)} stroke="none" fill={fill} />;
};

BarShape.propTypes = {
  fill: PropTypes.string,
  x: PropTypes.number,
  y: PropTypes.number,
  width: PropTypes.number,
  height: PropTypes.number,
  top: PropTypes.bool,
};

BarShape.defaultProps = {
  fill: '',
  x: 0,
  y: 0,
  width: 0,
  height: 0,
  top: false,
};

export default BarShape;
