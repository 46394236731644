import { date } from '../../utils';

export function mapCategories({ data, loading, error }) {
  if (loading || error) {
    return { data: [], loading, error };
  }

  try {
    return {
      data: data.categories.nodes.map(node => ({ ...node })),
      loading,
      error,
    };
  } catch (mapError) {
    return { data: [], loading, error: mapError };
  }
}

export function mapUser({ data, loading, error }) {
  if (loading || error) {
    return {
      user: {},
      error,
    };
  }

  try {
    const { fullUser: user } = data;

    const {
      holrs, followers, following, reportedProblems, userPayments,
    } = user;

    const paymentsData = userPayments.edges.map(({ node }) => ({
      ...node,
      amount: `$${node.amount}`,
      createdAt: date.lastUpdated(node.createdAt),
      lastUpdated: date.lastUpdated(node.lastUpdated),
    }));

    const totalCountPayments = userPayments.totalCount;

    const holrsData = holrs.edges
      .map(({ node }) => ({ ...node }))
      .map(holr => ({
        uuid: holr.uuid,
        username: user.username,
        name: holr.title,
        body: holr.caption,
        type: holr.type,
        likes: holr.likeCount,
        views: holr.viewCount,
        holrbacks: holr.holrbackCount,
        postDate: date.lastUpdated(holr.createdAt),
        mediumType: holr.mediumType,
        thumbnails: holr.thumbnails,
        videoStreamingUrl: holr.videoStreamingUrl,
      }));
    const followersData = followers.edges
      .map(({ node }) => ({ ...node }))
      .map(follower => ({
        uuid: follower.uuid,
        username: follower.username,
        name: follower.fullName,
        email: follower.email,
        type: follower.appRole,
        adminPrivileges: follower.adminRole,
      }));

    const totalCountFollowers = followers.totalCount;

    const followingData = following.edges
      .map(({ node }) => ({ ...node }))
      .map(follow => ({
        uuid: follow.uuid,
        follower: follow.follower || '',
        name: follow.username,
        email: follow.email,
        type: follow.type,
        adminPrivileges: follow.adminRole,
      }));

    const totalCountFollowing = following.totalCount;

    const reportedContent = reportedProblems.map(report => ({
      uuid: report.uuid,
      reason: report.feedback,
      response: report.response,
      status: report.state,
      reportedBy: report.user.fullName,
      reportedDate: date.lastUpdated(report.createdAt),
    }));

    const userData = {
      ...user,
      avatar: user.avatar.url,
      cover: user.coverPhoto.url,
      categories: [...user.followedCategories] || [],
      subcategories: [...user.subcategories] || [],
      userType: user.appRole || 'NONE',
      adminRole: user.adminRole || 'NONE',
    };

    return {
      reportedContent,
      paymentsData,
      followersData,
      followingData,
      holrsData,
      userData,
      error,
      totalCountFollowers,
      totalCountFollowing,
      totalCountPayments,
    };
  } catch (mapErrors) {
    return {
      error: mapErrors,
    };
  }
}

export default {
  mapUser,
  mapCategories,
};
