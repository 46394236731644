import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import ScreenContainer from '../../components/layout/ScreenContainer';
import UsersListTable from '../../components/tables/UsersListTable';
import SearchBox from '../../components/forms/SearchBox';

const UsersScreen = ({
  users,
  onUserClick,
  onSearch,
  loadMoreUsers,
  totalCount,
}) => (
  <ScreenContainer>
    <SearchBoxWrapper>
      <SearchBox onSearch={onSearch} />
    </SearchBoxWrapper>
    <UsersListTable
      totalCount={totalCount}
      data={users.data}
      onItemClick={onUserClick}
      loading={users.loading}
      loadMore={loadMoreUsers}
    />
  </ScreenContainer>
);

UsersScreen.propTypes = {
  users: PropTypes.shape({
    data: PropTypes.array,
    loading: PropTypes.bool,
  }),
  onSearch: PropTypes.func,
  onUserClick: PropTypes.func,
  loadMoreUsers: PropTypes.func,
  totalCount: PropTypes.number,
};

UsersScreen.defaultProps = {
  users: {
    data: [],
    loading: false,
  },
  onSearch: () => {},
  onUserClick: () => {},
  loadMoreUsers: () => {},
  totalCount: 0,
};

const SearchBoxWrapper = styled.div`
  margin-bottom: 1rem;
`;

export default UsersScreen;
