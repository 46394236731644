import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  FormHelperText,
  Input,
  InputLabel,
  Typography,
  Grid,
  Select,
  MenuItem,
} from '@material-ui/core';
import AccessTime from '@material-ui/icons/AccessTime';
import MuiPickersUtilsProvider from 'material-ui-pickers/utils/MuiPickersUtilsProvider';
import MomentUtils from 'material-ui-pickers/utils/moment-utils';
import { InlineDatePicker, InlineTimePicker } from 'material-ui-pickers';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { combineDateAndTime } from '../../../utils/date';

const audienceOptions = ['Influencers', 'Fans', 'Everyone'];

class PushNotificationDetailsModal extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onDelete: PropTypes.oneOfType([
      PropTypes.func,
      PropTypes.bool,
    ]),
    open: PropTypes.bool,
    title: PropTypes.string,
    pushNotification: PropTypes.shape({
      title: PropTypes.string,
      deliveredDate: PropTypes.instanceOf(moment),
      deliveredTime: PropTypes.instanceOf(moment),
      body: PropTypes.string,
      screenToLinkTo: PropTypes.string,
      state: PropTypes.string,
      audience: PropTypes.string,
    }),
  };

  static defaultProps = {
    onSave: () => {},
    onCancel: () => {},
    onDelete: false,
    open: false,
    pushNotification: {},
    title: '',
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      isValid: false,
    };
  }

  validation = (values) => {
    const errors = {};

    if (!values.title) {
      errors.title = 'Required';
    }

    if (!values.deliveredDate) {
      errors.deliveredDate = 'Required';
    }

    if (!values.body) {
      errors.body = 'Required';
    }

    if (!values.deliveredTime) {
      errors.deliveredTime = 'Required';
    }

    if (!values.screenToLinkTo) {
      errors.screenToLinkTo = 'Required';
    }

    if (!values.audience) {
      errors.audience = 'Required';
    }

    this.setState({
      isValid: isEmpty(errors),
    });

    return errors;
  };

  handleSubmit = (values) => {
    const { onSave } = this.props;

    const data = values;

    if (data) {
      data.deliverAt = combineDateAndTime(data.deliveredDate, data.deliveredTime);
    }

    return onSave(data);
  };

  renderForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
  }) => {
    const { classes } = this.props;

    return (
      <Form>
        <Grid container spacing={24}>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={touched.title && !!errors.title}
              className={classes.formControl}
            >
              <InputLabel htmlFor="title">Push Notification Title*</InputLabel>
              <Input
                fullWidth
                id="title"
                placeholder="Enter your title here"
                value={values.title}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="title"
              />
              <FormHelperText>{touched.title && errors.title}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              error={touched.deliveredDate && !!errors.deliveredDate}
              className={classes.formControl}
              fullWidth
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <InlineDatePicker
                  keyboard
                  id="deliveredDate"
                  label="Deliver Date*"
                  value={values.deliveredDate}
                  onChange={(e) => {
                    setFieldValue('deliveredDate', e);
                    setFieldTouched('deliveredDate', true);
                  }}
                  onBlur={handleBlur}
                />
              </MuiPickersUtilsProvider>
              <FormHelperText>{touched.deliveredDate && errors.deliveredDate}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              fullWidth
              error={touched.body && !!errors.body}
              className={classes.formControl}
            >
              <InputLabel htmlFor="body">Body Text*</InputLabel>
              <Input
                fullWidth
                placeholder="Enter your body text here"
                id="body"
                value={values.body}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="body"
                multiline
              />
              <FormHelperText>{touched.body && errors.body}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl
              error={touched.deliveredTime && !!errors.deliveredTime}
              className={classes.formControl}
              fullWidth
            >
              <MuiPickersUtilsProvider utils={MomentUtils}>
                <InlineTimePicker
                  id="deliveredTime"
                  fullWidth
                  keyboard
                  label="Deliver Time*"
                  value={values.deliveredTime}
                  onChange={(e) => {
                    setFieldValue('deliveredTime', e);
                    setFieldTouched('deliveredTime', true);
                  }}
                  onBlur={handleBlur}
                  keyboardIcon={<AccessTime />}
                />
              </MuiPickersUtilsProvider>
              <FormHelperText>{touched.deliveredTime && errors.deliveredTime}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              error={touched.screenToLinkTo && !!errors.screenToLinkTo}
              className={classes.formControl}
            >
              <InputLabel htmlFor="screenToLinkTo">Screen*</InputLabel>
              <Input
                fullWidth
                placeholder="Screen to link to"
                id="screenToLinkTo"
                value={values.screenToLinkTo}
                onChange={handleChange}
                onBlur={handleBlur}
                autoComplete="screenToLinkTo"
              />
              <FormHelperText>{touched.screenToLinkTo && errors.screenToLinkTo}</FormHelperText>
            </FormControl>
            <FormControl
              fullWidth
              error={touched.audience && !!errors.audience}
              className={classes.formControl}
            >
              <InputLabel htmlFor="audience">Audience*</InputLabel>
              <Select
                id="audience"
                name="audience"
                value={values.audience}
                onChange={handleChange}
                onBlur={handleBlur}
              >
                {audienceOptions.map(option => (
                  <MenuItem key={option} value={option.toUpperCase()}>
                    {option}
                  </MenuItem>
                ))}
              </Select>
              <FormHelperText>{touched.audience && errors.audience}</FormHelperText>
            </FormControl>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="headline">{`Status: ${values.state}`}</Typography>
          </Grid>
        </Grid>
      </Form>
    );
  };

  render() {
    const {
      pushNotification,
      title,
      open,
      onCancel,
      onDelete,
      classes,
    } = this.props;

    const { isValid } = this.state;

    return (
      <Dialog maxWidth="lg" open={open} onClose={onCancel}>
        <DialogTitle className={classes.modalTitle}>{title}</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Formik
            enableReinitialize
            initialValues={{
              title: pushNotification.title || '',
              deliveredDate: pushNotification.deliveredDate || null,
              deliveredTime: pushNotification.deliveredTime || null,
              body: pushNotification.body || '',
              screenToLinkTo: pushNotification.screenToLinkTo || '',
              state: pushNotification.state || '',
              audience: pushNotification.audience || '',
            }}
            onSubmit={this.handleSubmit}
            validate={this.validation}
            ref={this.form}
          >
            {props => this.renderForm(props)}
          </Formik>
        </DialogContent>
        <DialogActions className={classes.modalActions}>
          {!!onDelete && pushNotification.state !== 'sent' && (
            <Button onClick={onDelete} className={classes.deleteButton}>
              Delete
            </Button>
          )}
          <Button onClick={onCancel} className={classes.button}>
            {pushNotification.state !== 'sent' ? 'Cancel' : 'close'}
          </Button>
          {pushNotification.state !== 'sent' && (
            <Button
              color="primary"
              className={classes.button}
              variant="contained"
              disabled={!isValid}
              onClick={() => this.form.current.submitForm()}
            >
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  statusText: {
    margin: '1rem 0rem',
    fontSize: '1.4rem',
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  modalActions: {
    borderTop: '2px solid #DCDCDC',
    padding: '8px 2px 2px',
  },
  modalTitle: {
    borderBottom: '1px solid #949494',
  },
  dialogContent: {
    width: '40rem',
    marginTop: '1rem',
  },
  navigateBefore: {
    position: 'absolute',
    left: '0.5rem',
  },
  button: {
    padding: '8px 44px',
  },
  deleteButton: {
    padding: '8px 44px',
    position: 'absolute',
    left: '4px',
  },
}))(PushNotificationDetailsModal);
