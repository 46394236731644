import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import moment from 'moment-timezone';
import styled from 'styled-components';
import {
  Grid,
  Typography,
  Chip,
  Checkbox,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import uploadedVideoIcon from '../../../assets/images/uploadedVideoIcon.svg';
import { keyboard } from '../../../utils';

const MediumCard = ({
  classes,
  onRemoveTag,
  data,
  selected,
  onSelect,
  onItemPressed,
  onItemDeletePressed,
}) => {
  const {
    uuid,
    name,
    createdAt,
    tags,
    lowQualityThumbnail,
    original: { contentType },
  } = data;

  const shallowMedium = { uuid, name, tags };

  return (
    <Grid container className={`${selected ? classes.selected : classes.unselected} ${classes.container}`}>
      <Grid className={classes.relative}>
        <Checkbox onClick={() => onSelect(shallowMedium)} color="primary" checked={selected} className={classes.checkbox} />
        <div
          role="button"
          tabIndex={0}
          onKeyDown={keyboard.actionKeyPressed(onItemPressed)}
          onClick={onItemPressed}
          className={classes.thumbnail}
          style={{ backgroundImage: `url(${lowQualityThumbnail.url})` }}
        />
        {contentType.split('/')[0] === 'video' && <PlayArrowIcon src={uploadedVideoIcon} className={classes.playIcon} />}
        <DeleteIcon className={classes.delete} onClick={() => onItemDeletePressed(uuid)} />
      </Grid>
      <Grid onClick={onItemPressed} container classes={{ container: classes.infoContainer }}>
        <Typography className={classes.text} variant="body2">{name}</Typography>
        <Typography className={`${classes.text} ${classes.textSeparation}`} variant="body2">
          {`${createdAt && moment(createdAt).format('MMMM DD, YYYY')}   -   ${contentType && contentType.split('/').slice(-1)[0].toUpperCase()}`}
        </Typography>
        <Grid item xs={12}>
          {tags.map(tag => (
            <Chip
              key={tag.uuid}
              label={tag.name}
              onDelete={() => onRemoveTag(shallowMedium, tag.uuid)}
              className={classes.chips}
              classes={{
                label: classes.chipLabel,
              }}
            />
          ))}
        </Grid>
      </Grid>
    </Grid>
  );
};

const PlayArrowIcon = styled.img`
  width: 38px;
  height: 36px;
`;

MediumCard.propTypes = {
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired,
  onRemoveTag: PropTypes.func,
  onSelect: PropTypes.func,
  onItemPressed: PropTypes.func,
  onItemDeletePressed: PropTypes.func,
  selected: PropTypes.bool,
};

MediumCard.defaultProps = {
  onRemoveTag: () => { },
  onSelect: () => { },
  onItemPressed: () => { },
  onItemDeletePressed: () => { },
  selected: false,
};

export default withStyles(theme => ({
  checkbox: {
    display: 'none',
    position: 'absolute',
    top: 0,
    right: 0,
    padding: 0,
  },
  playIcon: {
    margin: 'auto',
    position: 'absolute',
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    fontSize: '50px',
    color: theme.palette.common.white,
    opacity: '0.8',
  },
  thumbnail: {
    width: '100%',
    height: '176px',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
  },
  unselected: {
    marginTop: '2px',
    border: '2px solid transparent',

    '&&:hover': {
      border: `2px solid ${theme.palette.common.gray}`,
    },

    '&&:hover $relative $delete': {
      display: 'block',
    },

    '&&:hover $relative $checkbox': {
      display: 'block',
    },
  },

  selected: {
    marginTop: '2px',
    border: `2px solid ${theme.palette.primary.main}`,

    '&&:hover': {
      border: `2px solid ${theme.palette.primary.main}`,
    },

    '&& $relative $checkbox': {
      display: 'block',
    },
  },
  relative: {
    position: 'relative',
    width: '100%',
  },
  container: {
    position: 'relative',
    backgroundColor: theme.palette.common.lightGray,
    boxShadow: '0 3px 6px rgba(0,0,0,0.16), 0 3px 6px rgba(0,0,0,0.23)',
    transition: 'all 300ms ease',
    cursor: 'pointer',
    display: 'block',
  },
  text: {
    color: theme.palette.common.gray,
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  },
  textSeparation: {
    marginBottom: '7px',
  },
  chips: {
    height: '20px',
    fontSize: '9px',
    margin: '0 7px 8px 0',
    backgroundColor: 'white',
    border: '1px solid rgb(205, 205, 205)',
    '&& svg': {
      width: '12px',
      height: '12px',
    },
  },
  chipLabel: {
    paddingLeft: '5px',
  },
  delete: {
    display: 'none',
    position: 'absolute',
    bottom: '10px',
    right: 0,
    border: 'none',
    cursor: 'pointer',
    color: theme.palette.common.gray,
    '&&:hover': {
      color: theme.palette.primary.main,
    },
  },
  infoContainer: {
    padding: '16px 13px',
    display: 'block',
  },
}))(MediumCard);
