import React from 'react';
import PropTypes from 'prop-types';
import { Formik, Form } from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Grid,
  Typography,
  TextField,
} from '@material-ui/core';
import MediaBox from '../../widgets/MediaBox';

const ReportedHolrModal = ({
  open,
  onResolve,
  onCancel,
  data,
  holrback,
  classes,
}) => (
  <Formik
    initialValues={{ ...data }}
    enableReinitialize
  >
    {({
      values,
      handleChange,
      handleBlur,
      setFieldValue,
    }) => (
      <Dialog maxWidth="lg" open={open} onClose={onCancel}>
        <DialogTitle className={classes.title}>
          Reported holr
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Form>
            <Grid container spacing={16}>
              <Grid container item spacing={16}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="holr Author (offender)"
                    name="offender"
                    value={`@${values.offender}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.formControl}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="Reason"
                    name="reason"
                    value={values.reason}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.formControl}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={16}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="holr Title"
                    name="title"
                    value={values.title}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.formControl}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="Type of holr"
                    name="holrType"
                    value={values.holrType}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.formControl}
                  />
                </Grid>
              </Grid>
              <Grid container item spacing={16}>
                <Grid item xs={6}>
                  <MediaBox
                    style={{ marginBottom: '1rem' }}
                    media={values.media}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    disabled
                    label="Reported By"
                    name="reporter"
                    value={`@${values.reporter}`}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    style={{ marginBottom: '1.5rem' }}
                  />
                  {holrback && (
                    <TextField
                      fullWidth
                      disabled
                      label="Original holr Author"
                      name="originalAuthor"
                      value={`@${values.originalAuthor}`}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      style={{ marginBottom: '1.5rem' }}
                    />
                  )}
                  <TextField
                    fullWidth
                    disabled
                    label="Reported on"
                    name="createdAt"
                    value={values.createdAt}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    className={classes.formControl}
                  />
                </Grid>
              </Grid>
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  disabled
                  label="Caption"
                  name="caption"
                  value={values.caption}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  className={classes.formControl}
                />
              </Grid>
              <Grid item xs={12}>
                <Button
                  onClick={() => setFieldValue('flagged', !values.flagged)}
                  variant="outlined"
                  color={values.flagged ? 'primary' : 'default'}
                  className={classes.button}
                >
                  {values.flagged ? 'Un-Flag Holr' : 'Flag Holr'}
                </Button>
                <Button
                  onClick={() => {}}
                  variant="outlined"
                  color={values.banned ? 'primary' : 'default'}
                  className={classes.button}
                >
                  {values.banned ? 'Un-Ban User' : 'Ban User'}
                </Button>
              </Grid>
              <Grid item xs={6}>
                <Typography className={classes.statusText} variant="subheading">
                  {`Status: ${values.state}`}
                </Typography>
              </Grid>
            </Grid>
          </Form>
        </DialogContent>
        <DialogActions className={classes.actions}>
          <Button onClick={onCancel} color="primary">
            Back
          </Button>
          {values.state !== 'Resolved' && (
            <Button
              color="primary"
              variant="contained"
              onClick={() => onResolve(values)}
            >
              Resolve
            </Button>
          )}
        </DialogActions>
      </Dialog>
    )}
  </Formik>
);

ReportedHolrModal.propTypes = {
  classes: PropTypes.object.isRequired,
  onResolve: PropTypes.func,
  onCancel: PropTypes.func,
  open: PropTypes.bool,
  data: PropTypes.shape({
    uuid: PropTypes.string,
    offender: PropTypes.string,
    title: PropTypes.string,
    caption: PropTypes.string,
    media: PropTypes.arrayOf(PropTypes.object),
    holrType: PropTypes.string,
    reporter: PropTypes.string,
    originalAuthor: PropTypes.string,
    reason: PropTypes.string,
    flagged: PropTypes.bool,
    createdAt: PropTypes.string,
  }),
  holrback: PropTypes.bool,
};

ReportedHolrModal.defaultProps = {
  onResolve: () => {},
  onCancel: () => {},
  open: false,
  data: {},
  holrback: false,
};

export default withStyles(theme => ({
  title: {
    borderBottom: '1px solid #949494',
  },
  content: {
    width: '40rem',
    marginTop: '1rem',
  },
  actions: {
    borderTop: '1px solid #949494',
    paddingTop: '1rem',
  },
  statusText: {
    fontSize: '1.4rem',
  },
  formControl: {
    marginBottom: theme.spacing.unit,
  },
  button: {
    margin: theme.spacing.unit,
  },
}))(ReportedHolrModal);
