import React, { Component } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import { withStyles } from '@material-ui/core/styles';
import styled from 'styled-components';
import {
  Paper,
  CircularProgress,
  Table,
  TableHead,
  TableSortLabel,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
  Checkbox,
  Grid,
  Button,
} from '@material-ui/core';
import { isEmpty } from 'lodash';
import DeleteIcon from '@material-ui/icons/Delete';
import { useDrop } from 'react-dnd';
import { NativeTypes } from 'react-dnd-html5-backend';
import colors from '../../../utils/colors';
import uploadFilesImage from '../../../assets/images/uploadFiles.svg';
import { CustomChip } from '../../forms';
import { MediumCard } from '../../widgets';
import mediaTableDeleteIcon from '../../../assets/images/mediaTableDeleteIcon.svg';
import addTagIcon from '../../../assets/images/addTagIcon.svg';
import mediaNoMatch from '../../../assets/images/mediaNoMatch.svg';

const DropZone = ({ classes, onUploadFileClick, onFileDrop }) => {
  const handleDrop = item => onFileDrop(item.files);
  // eslint-disable-next-line
  const [_, drop] = useDrop({
    accept: [NativeTypes.FILE],
    drop: handleDrop,
  });

  return (
    <DragDropContainer ref={drop}>
      <DragDropTitle>Looks like you do not have any media in your library yet!</DragDropTitle>
      <UploadFilesImage
        src={uploadFilesImage}
      />
      <Button
        variant="contained"
        color="primary"
        className={classes.chooseFilesButton}
        onClick={onUploadFileClick}
      >
        UPLOAD FILES
      </Button>
    </DragDropContainer>
  );
};

DropZone.propTypes = {
  classes: PropTypes.object.isRequired,
  onUploadFileClick: PropTypes.func,
  onFileDrop: PropTypes.func,
};

DropZone.defaultProps = {
  onUploadFileClick: () => {},
  onFileDrop: () => {},
};

class HolrMediaTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        firstName: PropTypes.string,
        lastName: PropTypes.string,
        username: PropTypes.string,
        email: PropTypes.string,
        updatedAt: PropTypes.string,
      }),
    ),
    loading: PropTypes.bool,
    onItemPressed: PropTypes.func,
    onItemDeletePressed: PropTypes.func,
    classes: PropTypes.object,
    loadMore: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
    onDeletePressed: PropTypes.func,
    onAddTagPressed: PropTypes.func,
    selected: PropTypes.arrayOf(PropTypes.object),
    onSelectChange: PropTypes.func,
    onRemoveTag: PropTypes.func,
    onRequestSort: PropTypes.func,
    onUploadFileClick: PropTypes.func,
    onFileDrop: PropTypes.func,
    tableMode: PropTypes.string,
    latestMediaTime: PropTypes.string,
    search: PropTypes.string,
    user: PropTypes.object,
  }

  static defaultProps = {
    data: [],
    classes: {},
    onItemPressed: () => { },
    onItemDeletePressed: () => { },
    onDeletePressed: () => { },
    onAddTagPressed: () => { },
    loading: false,
    selected: [],
    onSelectChange: () => { },
    onRemoveTag: () => { },
    onRequestSort: () => { },
    onUploadFileClick: () => { },
    onFileDrop: () => { },
    tableMode: 'LIST',
    latestMediaTime: '',
    search: '',
    user: {},
  }

  state = {
    page: 0,
    rowsPerPage: 12,
    rowsPageOptions: [],
    order: 'asc',
    orderBy: 'name',
  };

  handleChangePage = (e, page) => {
    const { loadMore } = this.props;
    const { rowsPerPage } = this.state;

    this.setState({ page });
    loadMore(rowsPerPage);
  }

  mediaType = contentType => contentType.split('/')[1].toUpperCase()

  renderGrid = () => {
    const {
      data, onItemPressed, onItemDeletePressed, selected,
      onRemoveTag,
    } = this.props;

    const { page, rowsPerPage } = this.state;

    const list = data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

    return (
      <TableRow>
        <TableCell colSpan={6}>
          <Grid container spacing={16}>
            {list.map((medium, index) => {
              const isSelected = !!selected.find(item => medium.uuid === item.uuid);
              return (
                <Grid
                  direction="row"
                  justify="center"
                  container
                  item
                  key={medium.uuid || index}
                  xs={2}
                >
                  <MediumCard
                    selected={isSelected}
                    onSelect={shallowMedium => this.handleSelect(shallowMedium)}
                    onRemoveTag={onRemoveTag}
                    onItemDeletePressed={() => onItemDeletePressed(medium.uuid)}
                    onItemPressed={() => onItemPressed(medium.uuid)}
                    data={medium}
                  />
                </Grid>
              );
            })}
          </Grid>
        </TableCell>
      </TableRow>
    );
  }

  renderRows = () => {
    const {
      data, onItemPressed, classes, onItemDeletePressed, selected,
      onRemoveTag,
    } = this.props;
    const { page, rowsPerPage } = this.state;

    return data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map((importedMedia, index) => {
        const {
          uuid,
          name,
          createdAt,
          original: { contentType },
          tags,
        } = importedMedia;


        const isSelected = !!selected.find(item => uuid === item.uuid);

        return (
          <TableRow
            hover
            key={uuid || index}
            classes={{ hover: classes.tableRow }}
          >
            <TableCell onClick={() => this.handleSelect({ uuid, name, tags })} classes={{ root: `${classes.checkboxCell} ${classes.tableCell}` }} padding="checkbox">
              <Checkbox
                color="primary"
                checked={isSelected}
                inputProps={{ 'aria-label': 'select all media' }}
              />
            </TableCell>
            <TableCell
              onClick={() => onItemPressed(uuid)}
              className={classes.tableCell}
            >
              {name}
            </TableCell>
            <TableCell
              onClick={() => onItemPressed(uuid)}
              className={classes.tableCell}
            >
              {createdAt ? moment(createdAt).format('MM/DD/YYYY hh:mm A') : '-'}
            </TableCell>
            <TableCell
              onClick={() => onItemPressed(uuid)}
              className={classes.tableCell}
            >
              {this.mediaType(contentType)}
            </TableCell>
            <TableCell
              onClick={() => onItemPressed(uuid)}
              className={classes.tableCell}
            >
              {tags.map(tag => (
                <CustomChip
                  key={tag.uuid}
                  label={tag.name}
                  onDelete={() => onRemoveTag({ uuid, name, tags }, tag.uuid)}
                />
              ))}
            </TableCell>
            <TableCell
              className={`${classes.tableCell} ${classes.right}`}
            >
              <DeleteIcon className={classes.delete} onClick={() => onItemDeletePressed(uuid)} />
            </TableCell>
          </TableRow>
        );
      });
  }

  renderContent = () => {
    const {
      data,
      loading,
      classes,
      tableMode,
      onUploadFileClick,
      onFileDrop,
      search,
    } = this.props;

    switch (true) {
      case loading:
        return (
          <TableRow>
            <TableCell colSpan={6} padding="none" className={classes.loading}>
              <CircularProgress thickness={5} size={80} />
            </TableCell>
          </TableRow>
        );
      case !data.length:
        return search.length ? (
          <TableRow>
            <TableCell colSpan={6} padding="none">
              <DragDropContainer>
                <DragDropTitle style={{ marginBottom: '32px' }}>
                  There are no files matching.
                  <br />
                  Try again with a different word!
                </DragDropTitle>
                <UploadFilesImage
                  src={mediaNoMatch}
                />
              </DragDropContainer>
            </TableCell>
          </TableRow>
        ) : (
          <TableRow>
            <TableCell colSpan={6} padding="none">
              <DropZone
                classes={classes}
                onUploadFileClick={onUploadFileClick}
                onFileDrop={onFileDrop}
              />
            </TableCell>
          </TableRow>
        );
      case tableMode === 'GRID':
        return this.renderGrid();
      default:
        return this.renderRows();
    }
  }

  EnhancedTableHead = (props) => {
    const {
      classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort,
    } = props;
    const createSortHandler = property => (event) => {
      onRequestSort(event, property);
    };

    return (
      <TableHead>
        <TableRow>
          <TableCell padding="checkbox" classes={{ root: classes.checkboxCell }}>
            <Checkbox
              color="primary"
              indeterminate={numSelected > 0 && numSelected < rowCount}
              checked={rowCount > 0 && numSelected === rowCount}
              onChange={onSelectAllClick}
              inputProps={{ 'aria-label': 'select all media' }}
            />
          </TableCell>
          <TableCell
            key="NAME"
            sortDirection={false}
            className={classes.tableHead}
          >
            <TableSortLabel
              active={orderBy === 'NAME'}
              direction={orderBy === 'NAME' ? order : 'asc'}
              onClick={createSortHandler('NAME')}
            >
              Name
              {orderBy === 'NAME' ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          <TableCell
            key="CREATED_AT"
            sortDirection={false}
            className={classes.tableHead}
          >
            <TableSortLabel
              active={orderBy === 'CREATED_AT'}
              direction={orderBy === 'CREATED_AT' ? order : 'asc'}
              onClick={createSortHandler('CREATED_AT')}
            >
              Date
              {orderBy === 'CREATED_AT' ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          <TableCell
            key="MIME_TYPE"
            sortDirection={false}
            className={classes.tableHead}
          >
            <TableSortLabel
              active={orderBy === 'MIME_TYPE'}
              direction={orderBy === 'MIME_TYPE' ? order : 'asc'}
              onClick={createSortHandler('MIME_TYPE')}
            >
              Type
              {orderBy === 'MIME_TYPE' ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          <TableCell
            key="TAG"
            sortDirection={false}
            className={classes.tableHead}
          >
            <TableSortLabel
              active={orderBy === 'TAG'}
              direction={orderBy === 'TAG' ? order : 'asc'}
              onClick={createSortHandler('TAG')}
            >
              Tag
              {orderBy === 'TAG' ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
          <TableCell
            key="actions"
            className={classes.tableHead}
          />
        </TableRow>
      </TableHead>
    );
  }

  handleRequestSort = (_, property) => {
    const {
      order, orderBy,
    } = this.state;
    const { onRequestSort } = this.props;
    const isAsc = orderBy === property && order === 'asc';

    this.setState(state => ({
      ...state,
      order: isAsc ? 'desc' : 'asc',
      orderBy: property,
    }));

    onRequestSort(property, isAsc ? 'ASCENDING' : 'DESCENDING');
  };

  handleSelectAll = () => {
    const { data, selected, onSelectChange } = this.props;

    onSelectChange(selected.length < data.length
      ? data.map(({ uuid, name, tags }) => ({ uuid, name, tags })) : []);
  }

  handleSelect = (item) => {
    const { selected, onSelectChange } = this.props;
    const isSelected = !!selected.find(({ uuid }) => uuid === item.uuid);
    onSelectChange(isSelected
      ? selected.filter(({ uuid }) => uuid !== item.uuid) : [...selected, { ...item }]);
  }

  render() {
    const {
      totalCount, classes, data, onAddTagPressed, onDeletePressed, selected, user, latestMediaTime,
    } = this.props;
    const {
      page, rowsPerPage, rowsPageOptions, order, orderBy,
    } = this.state;

    return (
      <Paper>
        <Grid container classes={{ container: classes.header }}>
          <Grid item container classes={{ container: classes.actionsTitle }} alignItems="center" justify="space-between">
            <UserInfo>
              <Name>{user && user.fullName}</Name>
              {!isEmpty(user) && <UserName>{`@${user.username}`}</UserName>}
            </UserInfo>
            {latestMediaTime && <LatestMediaTime>{`Last Updated: ${moment.tz(latestMediaTime, 'America/New_York').format('MM/DD/YYYY hh:mm A z')}`}</LatestMediaTime>}
          </Grid>
          {selected.length > 0 && (
            <div className={classes.actions}>
              <button onClick={onDeletePressed} disabled={selected.length <= 0} className={classes.actionButton} type="button">
                <DeleteButtonIcon src={mediaTableDeleteIcon} />
                <ButtonLabel>Delete</ButtonLabel>
              </button>
              <button onClick={onAddTagPressed} disabled={selected.length <= 0} className={`${classes.actionButton} ${classes.primary}`} type="button">
                <AddTagButtonIcon src={addTagIcon} />
                <ButtonLabel>Add Tag</ButtonLabel>
              </button>
            </div>
          )}
        </Grid>
        <Table>
          <this.EnhancedTableHead
            numSelected={selected.length}
            rowCount={data.length}
            order={order}
            orderBy={orderBy}
            classes={classes}
            onSelectAllClick={this.handleSelectAll}
            onRequestSort={this.handleRequestSort}
          />
          <TableBody>
            {this.renderContent()}
          </TableBody>
          {data.length > 0 && (
            <TableFooter>
              <TableRow>
                <TablePagination
                  colSpan={6}
                  rowsPerPageOptions={rowsPageOptions}
                  rowsPerPage={rowsPerPage}
                  page={page}
                  count={totalCount}
                  SelectProps={{ native: true }}
                  onChangePage={this.handleChangePage}
                />
              </TableRow>
            </TableFooter>
          )}
        </Table>
      </Paper>
    );
  }
}

const DragDropContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 572px;
`;

const DragDropTitle = styled.h1`
  font-size: 24px;
  font-family: 'ArchivoNarrow-Bold';
  color: ${colors.orange};
  margin: 0 0 48px 0;
  max-width: 370px;
  text-align: center;
`;

const UploadFilesImage = styled.img`
  width: 286px;
`;

const Name = styled.div`
  font-size: 34px;
  font-family: 'Raleway-Regular';
  margin-right: 15px;
`;

const UserName = styled.div`
  font-size: 20px;
  font-family: 'Raleway-Regular';
  padding-bottom: 4px;
`;

const LatestMediaTime = styled.div`
  font-size: 12px;
  font-family: 'Raleway-Regular';
  color: rgb(174, 174, 174);
`;

const UserInfo = styled.div`
  display: flex;
  align-items: flex-end;
`;

const ButtonLabel = styled.div`
  font-size: 16px;
  font-family: 'ArchivoNarrow-Bold';
`;

const DeleteButtonIcon = styled.img`
  width: 12px;
  height: 14px;
  margin-right: 5px;
`;

const AddTagButtonIcon = styled.img`
  width: 11px;
  height: 11px;
  margin: 2px 3px 0 0;
`;

export default withStyles(theme => ({
  loading: {
    textAlign: 'center',
    padding: `${theme.spacing.unit * 10}px !important`,
  },
  tableHead: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  tableCell: {
    border: 'none',
  },
  right: {
    textAlign: 'right',
  },
  actions: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
    alignContent: 'center',
    cursor: 'pointer',
    padding: '10px 25px 0 0',
    position: 'absolute',
    top: 'calc(100% + 5px)',
    right: 0,
  },
  actionsTitle: {
    padding: '19px 19px 0 24px',
  },
  actionButton: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'transparent',
    border: '3px solid rgb(113, 113, 113)',
    height: '27px',
    color: 'rgb(113, 113, 113)',
    cursor: 'pointer',
    padding: '3px 14px 5px',
    borderRadius: '27px',
    marginLeft: '20px',
    outline: 'none',
  },
  primary: {
    color: theme.palette.primary.main,
    border: `3px solid ${theme.palette.primary.main}`,
    padding: '3px 20px 5px',
  },
  tableRow: {
    '&&:hover': {
      backgroundColor: theme.palette.action.hover,
      cursor: 'pointer',
    },

    '&&:hover $delete': {
      opacity: 1,
    },
  },
  delete: {
    opacity: 0,
    border: 'none',
    cursor: 'pointer',
    color: theme.palette.common.gray,
    '&&:hover': {
      color: theme.palette.primary.main,
    },
  },
  chips: { margin: '0px 5px 10px' },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  chooseFilesButton: {
    marginTop: '44px',
    width: '170px',
    height: '36px',
    fontFamily: 'Raleway-SemiBold',
  },
  header: {
    paddingBottom: '19px',
    display: 'block',
    position: 'relative',
  },
  checkboxCell: {
    width: '50px',
  },
}))(HolrMediaTable);
