import React from 'react';
import { Query, Mutation } from '@apollo/react-components';
import gql from 'graphql-tag';
import { adopt } from 'react-adopt';
import { notify, messages } from '../../utils';
import PushNotificationsScreen from '../../screens/PushNotificationsScreen';

export const FETCH_PUSH_NOTIFICATIONS = gql`
  query {
    pushNotifications {
      nodes {
        title
        state
        body
        audience
        createdAt
        deliverAt
        screenToLinkTo
        uuid
      }
    }
  }
`;

export const CREATE_NOTIFICATION = gql`
  mutation createNotification(
    $title: String!
    $body: String!
    $screenToLinkTo: String
    $audience: AudienceEnum!
    $deliverAt: IsoTime!
  ) {
    createPushNotification(
      title: $title
      body: $body
      screenToLinkTo: $screenToLinkTo
      audience: $audience
      deliverAt: $deliverAt
    ) {
      pushNotification {
        uuid
        audience
        body
        createdAt
        deliverAt
        title
        state
      }
    }
  }
`;

export const UPDATE_NOTIFICATION = gql`
  mutation updateNotification(
    $uuid: ID!
    $title: String
    $body: String
    $screenToLinkTo: String
    $audience: AudienceEnum
    $deliverAt: IsoTime
  ) {
    updatePushNotification(
      uuid: $uuid
      title: $title
      body: $body
      screenToLinkTo: $screenToLinkTo
      audience: $audience
      deliverAt: $deliverAt
    ) {
      pushNotification {
        title
        state
        body
        audience
        deliverAt
        uuid
      }
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($uuid: ID!) {
    deletePushNotification(uuid: $uuid) {
      success
    }
  }
`;

/* eslint-disable react/prop-types */
const Composed = adopt({
  fetchNotifications: ({ render }) => (
    <Query
      query={FETCH_PUSH_NOTIFICATIONS}
      fetchPolicy="network-only"
    >
      {render}
    </Query>
  ),
  createNotification: ({ render }) => (
    <Mutation
      mutation={CREATE_NOTIFICATION}
      awaitRefetchQueries
      refetchQueries={[{
        query: FETCH_PUSH_NOTIFICATIONS,
      }]}
      onCompleted={() => notify.success({ ...messages.CREATE_NOTIFICATION.success })}
      onError={() => notify.error({ ...messages.CREATE_NOTIFICATION.error })}
    >
      {(mutation, result) => render({ mutation, result })}
    </Mutation>
  ),
  deleteNotification: ({ render }) => (
    <Mutation
      mutation={DELETE_NOTIFICATION}
      awaitRefetchQueries
      refetchQueries={[{
        query: FETCH_PUSH_NOTIFICATIONS,
      }]}
      onCompleted={() => notify.success({ ...messages.DELETE_NOTIFICATION.success })}
      onError={() => notify.error({ ...messages.DELETE_NOTIFICATION.error })}
    >
      {(mutation, result) => render({ mutation, result })}
    </Mutation>
  ),
  updateNotification: ({ render }) => (
    <Mutation
      mutation={UPDATE_NOTIFICATION}
      awaitRefetchQueries
      refetchQueries={[{
        query: FETCH_PUSH_NOTIFICATIONS,
      }]}
      onCompleted={() => notify.success({ ...messages.UPDATE_NOTIFICATION.success })}
      onError={() => notify.error({ ...messages.UPDATE_NOTIFICATION.error })}
    >
      {(mutation, result) => render({ mutation, result })}
    </Mutation>
  ),
});
/* eslint-enable react/prop-types */

export const PushNotificationsScreenConnector = () => (
  <Composed>
    {({
      fetchNotifications: { data, loading },
      createNotification: { mutation: createNotification },
      updateNotification: { mutation: updateNotification },
      deleteNotification: { mutation: deleteNotification },
    }) => {
      const pushNotifications = [];

      if (!loading) {
        pushNotifications.push(...data.pushNotifications.nodes);
      }

      return (
        <PushNotificationsScreen
          onCreateNotification={variables => createNotification({ variables })}
          onUpdateNotification={variables => updateNotification({ variables })}
          onDeleteNotification={variables => deleteNotification({ variables })}
          notificationList={{
            notificationsListData: pushNotifications,
            notificationsListLoading: loading,
          }}
        />
      );
    }}
  </Composed>
);

export default PushNotificationsScreenConnector;
