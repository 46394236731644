import validator from 'validator';
import { config } from '../config';

/**
 * Check if the provided string is an email
 * @param {string} value - Email address
 * @returns {boolean} Returns true if string is an email
 */
export function email(value) {
  return validator.isEmail(value);
}

/**
 * Check if the provided string is a phone number
 * @param {string} value - Phone number
 * @returns {boolean} Returns true if string is a phone number
 */
export function phone(value) {
  return validator.isMobilePhone(value);
}

/**
 * Check if the provided string is a valid url
 * @param {string} value - URL
 * @returns {boolean} Returns true if string is a valid URL
 */
export function url(value) {
  try {
    // eslint-disable-next-line
    const url = new URL(value);
    return true;
  } catch (_) {
    return false;
  }
}

/**
 * Check if the provided image has a valid size and resolution
 * @param {object} file - The image file
 * @param {string} type - The image type, either 'cover' or 'avatar'. Defaults to 'avatar'
 * @param {function} callback - Callback function to be called after checking the image
 */
export function imageSize(file, type, callback) {
  const restrictions = {
    avatar: {
      width: config.MINIMUM_AVATAR_WIDTH,
      height: config.MINIMUM_AVATAR_HEIGHT,
      size: config.MAXIMUM_IMAGE_SIZE,
    },
    cover: {
      width: config.MINIMUM_COVER_WIDTH,
      height: config.MINIMUM_COVER_HEIGHT,
      size: config.MAXIMUM_IMAGE_SIZE,
    },
  };

  const image = new Image();

  image.src = URL.createObjectURL(file);

  image.onload = function checkImage() {
    const minWidth = `${type === 'avatar' ? config.MINIMUM_AVATAR_WIDTH : config.MINIMUM_COVER_WIDTH}px`;
    const minHeight = `${type === 'avatar' ? config.MINIMUM_AVATAR_HEIGHT : config.MINIMUM_COVER_HEIGHT}px`;

    if (this.width < restrictions[type].width) {
      callback(`Image width is too small. Minimum width: ${minWidth}`);
    } else if (this.height < restrictions[type].height) {
      callback(`Image height is too small. Minimum height: ${minHeight}`);
    } else if (file.size > restrictions[type].size) {
      callback('Image size is too large. Maximum size: 20 MB');
    } else {
      callback(false);
    }
  };
}

export default {
  email,
  phone,
  url,
  imageSize,
};
