import React, { Component, Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  Formik,
  Form,
  FieldArray,
} from 'formik';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  FormControl,
  Input,
  InputLabel,
  FormHelperText,
  Typography,
} from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';
import {
  ChipsBox,
  ImagePicker,
} from '../../forms';
import { AddAvatarModal } from '..';
import { validate } from '../../../utils';

class AddCategoryModal extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    onSave: PropTypes.func,
    onCancel: PropTypes.func,
    onUpdate: PropTypes.func,
    open: PropTypes.bool,
  };

  static defaultProps = {
    onSave: () => {},
    onCancel: () => {},
    onUpdate: () => {},
    open: false,
  };

  constructor(props) {
    super(props);

    this.form = React.createRef();

    this.state = {
      isValid: false,
      isSubmitting: false,
      avatarModal: false,
      subcategory: null,
    };
  }

  validation = (values) => {
    const { onUpdate } = this.props;
    const errors = {};

    const checks = onUpdate(values);

    if (!values.title) {
      errors.title = 'Required';
    } else if (checks.title) {
      errors.title = 'This category already exists';
    }

    if (!values.imageSrc) {
      errors.imageSrc = 'Required';
    }

    if (!values.coverImageSrc) {
      errors.coverImageSrc = 'Required';
    }

    this.setState({
      isValid: isEmpty(errors),
    });

    return errors;
  };

  handleSubmit = async (values) => {
    const { onSave } = this.props;

    try {
      this.setState({ isSubmitting: true });

      await onSave({
        variables: { ...values },
      });
    } finally {
      this.setState({ isSubmitting: false });
    }
  };

  renderForm = ({
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    setFieldValue,
    setFieldTouched,
    setFieldError,
  }) => {
    const { classes } = this.props;
    const {
      avatarModal,
      subcategory,
    } = this.state;

    return (
      <Form>
        <FormControl
          error={touched.title && !!errors.title}
          className={`${classes.formControl} ${classes.inputFormControl}`}
        >
          <InputLabel htmlFor="title">Title*</InputLabel>
          <Input
            placeholder="Enter your title here"
            id="title"
            value={values.title}
            onChange={handleChange}
            onBlur={handleBlur}
            autoComplete="title"
          />
          <FormHelperText>{touched.title && errors.title}</FormHelperText>
        </FormControl>
        <FormControl
          className={classes.formControl}
          error={touched.imageSrc && !!errors.imageSrc}
          fullWidth
        >
          <ImagePicker
            imageSrc={values.imageSrc}
            title="Avatar*"
            subtitle="(min 720 x 720 px)"
            onChange={(file) => {
              setFieldValue('imageSrc', file);
              setFieldTouched('imageSrc', true);
              validate.imageSize(file, 'avatar', (result) => {
                setFieldError('imageSrc', result);
              });
            }}
          />
          <FormHelperText>{touched.imageSrc && errors.imageSrc}</FormHelperText>
        </FormControl>
        <FormControl
          className={classes.formControl}
          error={touched.coverImageSrc && !!errors.coverImageSrc}
          fullWidth
        >
          <ImagePicker
            large
            imageSrc={values.coverImageSrc}
            title="Cover Photo*"
            subtitle="(min 1200 x 900 px)"
            onChange={(file) => {
              setFieldValue('coverImageSrc', file);
              setFieldTouched('coverImageSrc', true);
              validate.imageSize(file, 'cover', (result) => {
                setFieldError('coverImageSrc', result);
              });
            }}
          />
          <FormHelperText>{touched.coverImageSrc && errors.coverImageSrc}</FormHelperText>
        </FormControl>
        <FormControl
          className={`${classes.formControl} ${classes.inputFormControl}`}
        >
          <Typography variant="subheading" className={classes.subcategoriesTitle}>
            Sub-Categories
          </Typography>
          <FieldArray name="subcategories">
            {({ push, remove }) => (
              <Fragment>
                <ChipsBox
                  addNew
                  selectedItems={values.subcategories}
                  onSelect={item => this.setState({
                    subcategory: item,
                    avatarModal: true,
                  })}
                  onRemove={index => remove(index)}
                  itemLabel="name"
                  itemKey="uuid"
                  placeholder="Add a new subcategory"
                />
                <AddAvatarModal
                  open={avatarModal}
                  title={`Add "${subcategory && subcategory.name}" avatar`}
                  onSave={({ image: avatar }) => {
                    push({ ...subcategory, avatar });

                    this.setState({
                      subcategory: null,
                      avatarModal: false,
                    });
                  }}
                  onCancel={() => this.setState({ avatarModal: false })}
                />
              </Fragment>
            )}
          </FieldArray>
        </FormControl>
      </Form>
    );
  };

  render() {
    const {
      isValid,
      isSubmitting,
    } = this.state;
    const {
      open,
      onCancel,
      classes,
    } = this.props;

    return (
      <Dialog
        maxWidth="md"
        open={open}
        onClose={onCancel}
      >
        <DialogTitle className={classes.modalTitle}>
          Add New Category
        </DialogTitle>
        <DialogContent className={classes.content}>
          <Formik
            enableReinitialize
            initialValues={{
              title: '',
              imageSrc: '',
              coverImageSrc: '',
              subcategories: [],
            }}
            onSubmit={this.handleSubmit}
            validate={this.validation}
            ref={this.form}
          >
            {props => this.renderForm(props)}
          </Formik>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel} color="primary">
            Cancel
          </Button>
          <Button
            color="primary"
            variant="contained"
            disabled={!isValid || isSubmitting}
            onClick={() => this.form.current.submitForm()}
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

export default withStyles(theme => ({
  title: {
    marginBottom: theme.spacing.unit * 3,
  },
  content: {
    width: '40rem',
    paddingTop: '40px',
  },
  submitButton: {
    marginBottom: theme.spacing.unit * 1.5,
  },
  formControl: {
    marginBottom: theme.spacing.unit * 5,
  },
  inputFormControl: {
    width: '50%',
  },
  modalTitle: {
    borderBottom: `1px solid ${theme.palette.common.gray}`,
  },
  subcategoriesTitle: {
    marginBottom: '0.5rem',
  },
}))(AddCategoryModal);
