import moment from 'moment-timezone';

const parseDateValue = value => (
  value < 10 ? `0${value}` : value
);

/**
 * Gets the date of 30 days prior to the current day
 * @returns { string } The date in the format yyyy-mm-dd
 */
export function firstDay() {
  return moment().subtract(30, 'days').format('YYYY-MM-DD');
}

/**
 * Gets the current day
 * @returns { string } The date in the format yyyy-mm-dd
 */
export function lastDay() {
  return moment().format('YYYY-MM-DD');
}
/**
 * Return a date with format to display on graphs (e.g. JAN 1)
 * @param { Date } value - A date value
 * @returns { string } The date in the format MMM D
 */
export function graphDisplay(value) {
  return moment(value).format('MMM D').toUpperCase();
}

/**
 * Return a date with format to display on tables (e.g. 12/31/1999 11:59 PM)
 * @param { Date } value - A date value
 * @returns { string } The date in the format MM/DD/YYYY hh:mm A
 */
export function lastUpdated(value) {
  return moment(value).format('MM/DD/YYYY hh:mm A');
}

/**
 * @description Combine Date and time from two different times,
 * Useful for datePicker and timePicker.
 * @param {string} date
 * @param {string} time
 */
export const combineDateAndTime = (date, time) => {
  const tempDate = new Date(date);
  const tempTime = new Date(time);

  const parsedDate = `${tempDate.getFullYear()}-${parseDateValue(tempDate.getMonth() + 1)}-${parseDateValue(tempDate.getDate())}`;
  const parsedTime = `${parseDateValue(tempTime.getHours())}:${parseDateValue(tempTime.getMinutes())}`;


  const parsedDateTimeWithTimezone = moment(`${parsedDate} ${parsedTime}`).tz(moment.tz.guess(true));

  return parsedDateTimeWithTimezone.format();
};

export default {
  firstDay,
  lastDay,
  graphDisplay,
  lastUpdated,
};
