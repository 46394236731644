import Noty from 'noty';

const notify = (type, title, message) => {
  const text = `
    ${title && `<strong>${title}</strong><br>`}
    ${message}
  `;

  const notification = new Noty({
    text,
    type,
    theme: 'mint',
    timeout: 3000,
  });

  notification.show();
};

/**
 * Show a success message notification
 * @param {string} title The title of your notification
 * @param {string} message The message you want to display
 */
const success = ({ title = '', message }) => {
  notify('success', title, message);
};

const error = ({ title = '', message }) => {
  notify('error', title, message);
};

const warning = ({ title = '', message }) => {
  notify('warning', title, message);
};

export default {
  success,
  error,
  warning,
};
