import React from 'react';
import { useQuery } from '@apollo/react-hooks';
import moment from 'moment';
import TopInfluencersReportCard from './TopInfluencersReportCard';
import { SUBSCRIBER_STATS, TOP_INFLUENCERS } from './queries';
import { downloadCsv } from '../../../utils/csv';

const initialDates = { startDate: moment().subtract(1, 'years'), endDate: moment() };

export const connect = Component => (props) => {
  const { data: topInfluencers } = useQuery(TOP_INFLUENCERS, {
    variables: { first: 10 },
  });

  // eslint-disable-next-line
  const { data, refetch } = useQuery(SUBSCRIBER_STATS, {
    variables: {
      startDate: initialDates.startDate,
      endDate: initialDates.endDate,
      groupingType: 'CUMULATIVE',
      influencerUuid: topInfluencers ? topInfluencers.topInfluencers.nodes[0].uuid : null,
    },
  });

  const handleChange = (influencerUuid,
    dateRange, dataType) => refetch({ influencerUuid, ...dateRange, groupingType: dataType });

  const headers = influencerUuid => ([
    {
      statsDate: 'Influencer:', paidSubscriptions: topInfluencers && topInfluencers.topInfluencers.nodes && topInfluencers.topInfluencers.nodes.find(influencer => influencer.uuid === influencerUuid).username, unpaidSubscriptions: '',
    },
    {
      statsDate: 'Date', paidSubscriptions: 'Paid', unpaidSubscriptions: 'Unpaid',
    },
  ]);

  const parseData = () => {
    if (!(data && data.subscriberStats && data.subscriberStats.stats)) return [];
    return data.subscriberStats.stats.map(({
      statsDate, paidSubscriptions, unpaidSubscriptions,
    }) => ({
      date: statsDate,
      Paid: paidSubscriptions,
      Unpaid: unpaidSubscriptions,
    }));
  };

  const handleDownloadCsv = (influencerUuid) => {
    if (!influencerUuid) return;
    downloadCsv(data.subscriberStats.stats, headers(influencerUuid), 'TopInfluencers');
  };

  return (
    <Component
      onChange={handleChange}
      onDownloadCsv={handleDownloadCsv}
      topInfluencers={topInfluencers && topInfluencers.topInfluencers.nodes}
      data={parseData()}
      {...props}
    />
  );
};

export default connect(TopInfluencersReportCard);
