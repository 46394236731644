import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
} from '@material-ui/core';
import { filter, lowerCase } from 'lodash';
import SearchBox from '../../forms/SearchBox';

class FollowingListModal extends Component {
  static propTypes = {
    classes: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        follower: PropTypes.string,
        name: PropTypes.string,
        email: PropTypes.string,
        type: PropTypes.string,
        adminPrivileges: PropTypes.string,
      }),
    ),
    onClose: PropTypes.func,
    onCancel: PropTypes.func,
    onItemClick: PropTypes.func,
    open: PropTypes.bool,
    loading: PropTypes.bool,
  };

  state = { searchFilter: '' }

  static defaultProps = {
    data: [],
    onClose: () => { },
    onCancel: () => { },
    onItemClick: () => { },
    open: false,
    loading: false,
  };

  renderRows = () => {
    const {
      onItemClick,
      classes,
    } = this.props;

    return this.filteredData().map(following => (
      <TableRow
        hover
        key={following.uuid}
        onClick={() => onItemClick(following)}
        classes={{
          hover: classes.tableRow,
        }}
      >
        <TableCell className={classes.tableCell}>{following.follower}</TableCell>
        <TableCell className={classes.tableCell}>{following.name}</TableCell>
        <TableCell className={classes.tableCell}>{following.email}</TableCell>
        <TableCell className={classes.tableCell}>{following.type}</TableCell>
        <TableCell className={classes.tableCell}>{following.adminPrivileges}</TableCell>
      </TableRow>
    ));
  }

  renderContent = () => {
    const {
      data,
      loading,
      classes,
    } = this.props;

    switch (true) {
      case loading:
        return (
          <TableRow>
            <TableCell colSpan={5} padding="none" className={classes.loading}>
              <CircularProgress thickness={5} size={80} />
            </TableCell>
          </TableRow>
        );
      case data && !this.filteredData().length:
        return (
          <TableRow>
            <TableCell colSpan={5} padding="none" className={classes.loading}>
              <Typography variant="display1">Sorry, seems there&apos;s no content to display</Typography>
            </TableCell>
          </TableRow>
        );
      default:
        return this.renderRows();
    }
  }

  renderTable = () => {
    const { classes } = this.props;
    return (
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableHead}>Follower</TableCell>
            <TableCell className={classes.tableHead}>Name</TableCell>
            <TableCell className={classes.tableHead}>Email</TableCell>
            <TableCell className={classes.tableHead}>Type</TableCell>
            <TableCell className={classes.tableHead}>Admin Privileges</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {this.renderContent()}
        </TableBody>
      </Table>
    );
  }

  handleSearch = (searchFilter) => {
    this.setState({ searchFilter });
  }

  filteredData = () => {
    const { data } = this.props;
    const { searchFilter } = this.state;
    const filteredData = filter(data,
      following => lowerCase(following.name).includes(lowerCase(searchFilter))
        || following.follower.includes(lowerCase(searchFilter)));
    return filteredData;
  }

  render = () => {
    const {
      open,
      onCancel,
      onClose,
      classes,
      data,
    } = this.props;

    return (
      <Dialog
        maxWidth="lg"
        open={open}
        onClose={onCancel}
      >
        <DialogTitle className={classes.modalTitle}>
          Following:&nbsp;
          {data && this.filteredData().length}
        </DialogTitle>

        <DialogContent className={classes.dialogContent}>
          <SearchBoxWrapper>
            <SearchBox onSearch={this.handleSearch} />
          </SearchBoxWrapper>
          <TableWrapper>
            {this.renderTable()}
          </TableWrapper>
        </DialogContent>

        <DialogActions className={classes.dialogActions}>
          <Button onClick={onClose} color="primary" variant="contained" className={classes.button}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

const SearchBoxWrapper = styled.div`
  margin: 1rem 0 1rem 2rem;
`;

const TableWrapper = styled.div`
  width: 900px;
  height: 400px;
  overflow-y: auto;
`;

export default withStyles(theme => ({
  loading: {
    textAlign: 'center',
    padding: `${theme.spacing.unit * 10}px !important`,
  },
  tableCell: {
    border: 'none',
  },
  tableHead: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  tableRow: {
    '&&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
  dialogContent: {
    padding: 0,
    borderBottom: '1px solid #DCDCDC',
  },
  dialogActions: {
    padding: '8px 2px',
  },
  button: {
    padding: '8px 44px',
  },
  modalTitle: {
    borderBottom: '2px solid #DCDCDC',
  },
}))(FollowingListModal);
