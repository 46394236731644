import React, { useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Button, RadioGroup, FormControlLabel, Radio,
} from '@material-ui/core';
import moment from 'moment';
import { ResponsiveBar } from '@nivo/bar';
import CollapsableCard from '../../widgets/CollapsableCard';
import DateRangePicker from '../../forms/ReportDateRangePicker';
import NoDataPlaceholder from '../UsersReportCard/NoDataPlaceholder';

const dataKeys = ['Paid', 'Unpaid'];
const dataTypes = ['CUMULATIVE', 'MONTHLY', 'DAILY'];
const dataTypeLabels = {
  CUMULATIVE: 'Cumulative Data',
  MONTHLY: 'Monthly Data',
  DAILY: 'Daily Data',
};

const colors = {
  Paid: '#FDCD69',
  Unpaid: '#E64F00',
};

const SubscribersReportCard = ({
  classes, onDownloadCsv, data, onChange,
}) => {
  const [dateRange, setDateRange] = useState({});
  const [dataType, setDataType] = useState('CUMULATIVE');

  const handleDateChange = ({ startDate, endDate }) => {
    setDateRange({ startDate, endDate });
    onChange({ startDate, endDate }, dataType);
  };

  const handleDataType = (type) => {
    setDataType(type);
    onChange(dateRange, type);
  };

  return (
    <CollapsableCard
      header={(
        <HeaderWrapper>
          <HeaderText>Subscribers</HeaderText>
          <DateRangePicker
            onDateChange={handleDateChange}
          />
        </HeaderWrapper>
      )}
    >
      <ContentWrapper>
        <RadioGroupWrapper>
          <RadioGroup
            row
            value={dataType}
            onChange={(_, value) => handleDataType(value)}
          >
            {dataTypes.map(type => (
              <FormControlLabel
                value={type}
                control={<Radio color="primary" />}
                label={dataTypeLabels[type]}
                classes={{
                  root: classes.radioRoot,
                  label: classes.radioLabel,
                }}
              />
            ))}
          </RadioGroup>
        </RadioGroupWrapper>
        {data.length ? (
          <OverflowWrapper>
            <ChartWrapper style={{ minWidth: data.length * 39 }}>
              <ResponsiveBar
                margin={{
                  top: 20, right: 20, bottom: 60, left: 80,
                }}
                axisBottom={{
                  format: value => (dataType === 'MONTHLY' ? moment(value).format('MMM') : value),
                  tickRotation: dataType === 'DAILY' ? 35 : 0,
                }}
                keys={dataKeys}
                indexBy="date"
                animate
                motionStiffness={120}
                data={data}
                colors={value => colors[value.id]}
                axisLeft={{
                  format: e => Math.floor(e) === e && e,
                }}
                theme={{
                  axis: {
                    ticks: {
                      text: {
                        fontSize: dataType === 'DAILY' ? 14 : 18,
                        fontFamily: 'ArchivoNarrow-Bold',
                        fill: 'rgb(113,113,113)',
                      },
                    },
                  },
                  tooltip: {
                    container: {
                      fontSize: 14,
                      fontFamily: 'Raleway-Regular',
                    },
                  },
                }}
                enableLabel={false}
              />
            </ChartWrapper>
          </OverflowWrapper>
        ) : (
          <NoDataPlaceholder />
        )}
        <BottomWrapper>
          <Button
            color="primary"
            variant="contained"
            onClick={onDownloadCsv}
            className={classes.downloadCsvButton}
            disabled={data.length === 0}
          >
            Download Csv
          </Button>
          {data.length > 0 && (
            <LegendWrapper>
              {dataKeys.map(key => (
                <LegendSection>
                  <LegendIcon style={{ backgroundColor: colors[key] }} />
                  <LegendText>{key}</LegendText>
                </LegendSection>
              ))}
            </LegendWrapper>
          )}
        </BottomWrapper>
      </ContentWrapper>
    </CollapsableCard>
  );
};

const ContentWrapper = styled.div`
  padding-bottom: 13px;
  padding-right: 30px;

  & > div > svg {
    overflow: visible !important;
  }
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 42px;
`;

const HeaderText = styled.h1`
  font-size: 31px;
  font-family: 'Raleway-Regular';
  margin: 0;
`;

const BottomWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 67px 0 51px;
`;

const LegendWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const LegendSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;

const LegendIcon = styled.div`
  height: 14px;
  width: 21px;
`;

const LegendText = styled.div`
  font-size: 14px;
  font-family: 'Raleway-Regular';
  color: rgb(0,0,0,0.38);
  margin-left: 7px;
`;

const RadioGroupWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  padding: 0 23px;
`;

const ChartWrapper = styled.div`
  height: 400px;
`;

const OverflowWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: 100px;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

SubscribersReportCard.propTypes = {
  classes: PropTypes.object.isRequired,
  data: PropTypes.array,
  onDownloadCsv: PropTypes.func,
  onChange: PropTypes.func,
};

SubscribersReportCard.defaultProps = {
  data: [],
  onDownloadCsv: () => {},
  onChange: () => {},
};


export default withStyles(() => ({
  radioLabel: {
    fontFamily: 'Raleway-Regular',
    fontSize: '14px',
    color: 'rgb(0,0,0,0.38)',
  },
  radioRoot: {
    margin: '0 0 0 23px',
  },
  downloadCsvButton: {
    height: '36px',
    width: '220px',
    borderRadius: '2px',
    fontFamily: 'Raleway-SemiBold',
  },
}))(SubscribersReportCard);
