import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Card,
  CardHeader,
  CircularProgress,
  Typography,
} from '@material-ui/core';
import { ResponsiveBar } from '@nivo/bar';

const dataKeys = ['Paid', 'Unpaid'];
const colors = {
  Paid: '#FDCD69',
  Unpaid: '#E64F00',
};

const DashboardSubscribersChart = ({
  data,
  loading,
  classes,
}) => {
  const renderContent = () => {
    switch (true) {
      case loading:
        return (
          <LoadingContainer>
            <CircularProgress thickness={5} size={80} />
          </LoadingContainer>
        );
      case !data.length:
        return (
          <NoDataContainer>
            <Typography variant="display1">Sorry, seems there&apos;s no content to display</Typography>
          </NoDataContainer>
        );
      default:
        return (
          <Fragment>
            <OverflowWrapper>
              <ChartWrapper style={{ minWidth: data.length * 39 }}>
                <ResponsiveBar
                  margin={{
                    top: 20, right: 20, bottom: 60, left: 80,
                  }}
                  axisBottom={{
                    format: value => (value),
                    tickRotation: 35,
                  }}
                  keys={dataKeys}
                  indexBy="date"
                  animate
                  motionStiffness={120}
                  data={data}
                  colors={value => colors[value.id]}
                  axisLeft={{
                    format: e => Math.floor(e) === e && e,
                  }}
                  theme={{
                    axis: {
                      ticks: {
                        text: {
                          fontSize: 14,
                          fontFamily: 'ArchivoNarrow-Bold',
                          fill: 'rgb(113,113,113)',
                        },
                      },
                    },
                    tooltip: {
                      container: {
                        fontSize: 14,
                        fontFamily: 'Raleway-Regular',
                      },
                    },
                  }}
                  enableLabel={false}
                />
              </ChartWrapper>
            </OverflowWrapper>
            <LegendWrapper>
              {dataKeys.map(key => (
                <LegendSection>
                  <LegendIcon style={{ backgroundColor: colors[key] }} />
                  <LegendText>{key}</LegendText>
                </LegendSection>
              ))}
            </LegendWrapper>
          </Fragment>
        );
    }
  };

  return (
    <Card>
      <StyledHeader
        title="Subscribers"
        classes={{
          content: classes.content,
          subheader: classes.subheader,
        }}
      />
      <CustomCardContent>
        {renderContent()}
      </CustomCardContent>
    </Card>
  );
};

DashboardSubscribersChart.propTypes = {
  data: PropTypes.array,
  loading: PropTypes.bool,
  classes: PropTypes.object.isRequired,
};

DashboardSubscribersChart.defaultProps = {
  data: [],
  loading: false,
};

const StyledHeader = styled(CardHeader)`
  padding-bottom: 0px !important;
`;

const CustomCardContent = styled.div`
  padding: 0 16px 16px;
`;

const LoadingContainer = styled.div`
  text-align: center;
`;

const OverflowWrapper = styled.div`
  overflow-x: auto;
  overflow-y: hidden;
  padding-right: 100px;
  padding-bottom: 10px;
  margin-bottom: 20px;
`;

const ChartWrapper = styled.div`
  height: 243px;
`;

const LegendWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`;

const LegendSection = styled.div`
  display: flex;
  align-items: center;
  margin-left: 30px;
`;

const LegendIcon = styled.div`
  height: 14px;
  width: 21px;
`;

const LegendText = styled.div`
  font-size: 14px;
  font-family: 'Raleway-Regular';
  color: rgb(0,0,0,0.38);
  margin-left: 7px;
`;

const NoDataContainer = styled.div`
  height: 62px;
  margin-top: 50px;
  text-align: center;
`;

export default withStyles(theme => ({
  content: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  subheader: {
    width: '60%',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '0',
  },
  installed: {
    color: theme.palette.common.graphRed,
    fontWeight: 'bold',
  },
  subscribed: {
    color: theme.palette.common.graphYellow,
    fontWeight: 'bold',
  },
  thisMonth: {
    color: theme.palette.common.gray,
    fontWeight: 'bold',
  },
}))(DashboardSubscribersChart);
