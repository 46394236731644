import gql from 'graphql-tag';

export const FETCH_INFLUENCERS_REQUEST = gql`
  query InfluencerUpgradeRequests(
    $first: Int
    $after: String
    $startDate: IsoTime
    $endDate: IsoTime
    $state: StateEnum
  ) {
    influencerUpgradeRequests(
      first: $first
      after: $after
      startDate: $startDate
      endDate: $endDate
      state: $state
    ) {
      edges {
        node {
          uuid
          reason
          state
          user {
            username
            phoneNumber
            email
            streetAddress
            apartmentNumber
            poBox
            city
            state
            country
            zipCode
          }
          createdAt
        }
        cursor
      }
      totalCount
      pageInfo {
        startCursor
        endCursor
        hasPreviousPage
        hasNextPage
      }
    }
  }
`;

export const RESOLVE_INFLUENCER_REQUEST = gql`
  mutation ResolveInfluencerUpgradeRequest($uuid: ID!, $acceptanceStatus: Boolean!) {
    resolveInfluencerUpgradeRequest(uuid: $uuid, acceptanceStatus: $acceptanceStatus) {
      influencerUpgradeRequest {
        uuid
        reason
        state
        user {
          username
          phoneNumber
          email
          streetAddress
          apartmentNumber
          poBox
          city
          state
          country
          zipCode
        }
        createdAt
      }
    }
  }
`;

export default {
  FETCH_INFLUENCERS_REQUEST,
  RESOLVE_INFLUENCER_REQUEST,
};
