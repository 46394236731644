import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from '@material-ui/core';

class PushNotificationsTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.oneOfType([
          PropTypes.string,
          PropTypes.number,
        ]),
        title: PropTypes.string,
        audience: PropTypes.string,
        state: PropTypes.string,
        deliverAt: PropTypes.string,
      }),
    ),
    loading: PropTypes.bool,
    onItemClick: PropTypes.func,
    classes: PropTypes.object.isRequired,
    delivered: PropTypes.bool,
    loadMore: PropTypes.func,
  };

  static defaultProps = {
    data: [],
    loading: false,
    onItemClick: () => {},
    delivered: false,
    loadMore: () => {},
  };

  state = {
    page: 0,
    rowsPerPage: 5,
    rowsPageOptions: [],
  };

  handleChangePage = (e, page) => {
    const { loadMore } = this.props;
    const { rowsPerPage } = this.state;

    this.setState({ page });
    loadMore(rowsPerPage);
  }

  renderRows = () => {
    const {
      data,
      onItemClick,
      classes,
    } = this.props;

    const { page, rowsPerPage } = this.state;

    return data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(pushNotification => (
        <TableRow
          hover
          key={pushNotification.uuid}
          onClick={() => onItemClick(pushNotification)}
          classes={{
            hover: classes.tableRow,
          }}
        >
          <TableCell className={classes.tableCell}>{pushNotification.title}</TableCell>
          <TableCell className={classes.tableCell}>{pushNotification.audience}</TableCell>
          <TableCell className={classes.tableCell}>{pushNotification.state}</TableCell>
          <TableCell className={classes.tableCell}>
            {new Date(pushNotification.deliverAt).toDateString()}
          </TableCell>
        </TableRow>
      ));
  }

  renderContent = () => {
    const {
      data,
      loading,
      classes,
    } = this.props;

    switch (true) {
      case loading:
        return (
          <TableRow>
            <TableCell colSpan={5} padding="none" className={classes.loading}>
              <CircularProgress thickness={5} size={80} />
            </TableCell>
          </TableRow>
        );
      case !data.length:
        return (
          <TableRow>
            <TableCell colSpan={5} padding="none" className={classes.loading}>
              <Typography variant="display1">Sorry, seems there&apos;s no content to display</Typography>
            </TableCell>
          </TableRow>
        );
      default:
        return this.renderRows();
    }
  }

  render() {
    const { data, classes, delivered } = this.props;
    const { page, rowsPerPage, rowsPageOptions } = this.state;

    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>Title</TableCell>
              <TableCell className={classes.tableHead}>Audience</TableCell>
              <TableCell className={classes.tableHead}>state</TableCell>
              <TableCell className={classes.tableHead}>
                {delivered ? 'Delivered' : 'Deilvery Date'}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderContent()}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={4}
                rowsPerPageOptions={rowsPageOptions}
                rowsPerPage={rowsPerPage}
                page={page}
                count={data.length}
                SelectProps={{ native: true }}
                onChangePage={this.handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(theme => ({
  loading: {
    textAlign: 'center',
    padding: `${theme.spacing.unit * 10}px !important`,
  },
  tableHead: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  tableCell: {
    border: 'none',
  },
  tableRow: {
    '&&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(PushNotificationsTable);
