import gql from 'graphql-tag';

export const TOP_INFLUENCERS = gql`
  query TopInfluencers(
    $first: Int
  ) {
    topInfluencers(
      first: $first
    ) {
      nodes {
        uuid
        username
        followerCount
      }
    }
  }
`;


export const SUBSCRIBER_STATS = gql`
  query SubscriberStats(
    $startDate: Date!
    $endDate: Date!
    $groupingType: SubscriberStatsGroupingEnum!
    $influencerUuid: ID
  ) {
    subscriberStats(
      startDate: $startDate
      endDate: $endDate
      groupingType: $groupingType
      influencerUuid: $influencerUuid
    ) {
      stats {
        statsDate
        paidSubscriptions
        unpaidSubscriptions
      }
    }
  }
`;

export default {
  TOP_INFLUENCERS,
  SUBSCRIBER_STATS,
};
