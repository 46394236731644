import React, { Component } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import {
  Grid,
  Tabs,
  Tab,
  Paper,
  Typography,
  Button,
} from '@material-ui/core';
import MaterialTextEditor from '../../components/widgets/MaterialTextEditor';
import { ScreenContainer } from '../../components/layout';
import { DiscardChangesConfirmModal } from '../../components/modals';
import { legalNoteTypes } from '../../utils';
import 'draft-js/dist/Draft.css';

export class LegalScreen extends Component {
  static propTypes = {
    privacyPolicy: PropTypes.shape({
      data: PropTypes.shape({
        content: PropTypes.object,
        lastUpdated: PropTypes.string,
        timeStamp: PropTypes.number,
        rawState: PropTypes.object,
      }),
      timeStamp: PropTypes.number,
      rawState: PropTypes.object,
      loading: PropTypes.bool,
    }),
    termsAndConditions: PropTypes.shape({
      data: PropTypes.PropTypes.shape({
        content: PropTypes.object,
        lastUpdated: PropTypes.string,
        timeStamp: PropTypes.number,
        rawState: PropTypes.object,
      }),
      timeStamp: PropTypes.number,
      rawState: PropTypes.object,
      loading: PropTypes.bool,
    }),
    updateLoading: PropTypes.bool,
    onUpdate: PropTypes.func,
    classes: PropTypes.object.isRequired,
  };

  static defaultProps = {
    privacyPolicy: {
      data: {
        content: null,
        lastUpdated: '',
      },
      loading: false,
    },
    termsAndConditions: {
      data: {
        content: null,
        lastUpdated: '',
      },
      loading: false,
    },
    updateLoading: false,
    onUpdate: () => {},
  };

  state = {
    activeTab: 0,
    discardModal: false,
    changed: false,
    privacyPolicy: {
      rawState: null,
      html: null,
      timeStamp: null,
    },
    termsAndConditions: {
      rawState: null,
      html: null,
      timeStamp: null,
    },
  };

  componentDidMount = () => {
    this.loadContent();
  };

  componentDidUpdate = (prevProps) => {
    const {
      privacyPolicy: {
        data: { timeStamp: prevPrivacyPolicyTimeStamp },
      },
      termsAndConditions: {
        data: { timeStamp: prevTermsAndConditionsTimeStamp },
      },
    } = prevProps;

    const {
      privacyPolicy: {
        data: { timeStamp: privacyPolicyTimeStamp },
      },
      termsAndConditions: {
        data: { timeStamp: termsAndConditionsTimeStamp },
      },
      updateLoading,
    } = this.props;

    if (
      (prevPrivacyPolicyTimeStamp !== privacyPolicyTimeStamp
        || prevTermsAndConditionsTimeStamp !== termsAndConditionsTimeStamp)
        && !updateLoading
    ) {
      this.loadContent();
    }
  };

  loadContent = () => {
    const { termsAndConditions, privacyPolicy } = this.props;

    this.setState({
      termsAndConditions: {
        rawState: termsAndConditions.data.content,
        timeStamp: Date.now(),
        html: null,
      },
      privacyPolicy: {
        rawState: privacyPolicy.data.content,
        timeStamp: Date.now(),
        html: null,
      },
    });
  };

  toggleDiscardChangesModal = () => {
    const { discardModal } = this.state;

    this.setState({ discardModal: !discardModal });
  };

  handleTabChange = (event, activeTab) => {
    this.setState({ activeTab });
  };

  handleChange = ({ rawState, html }) => {
    const { activeTab } = this.state;

    switch (activeTab) {
      case 0:
        this.setState({
          termsAndConditions: { rawState, html },
          changed: true,
        });
        break;
      case 1:
        this.setState({
          privacyPolicy: { rawState, html },
          changed: true,
        });
        break;
      default:
        break;
    }
  };

  handleUpdate = () => {
    const { onUpdate } = this.props;
    const {
      termsAndConditions: {
        rawState: termsAndConditionsRawState,
        html: termsAndConditionsHtml,
      },
      privacyPolicy: {
        rawState: privacyPolicyRawState,
        html: privacyPolicyHtml,
      },
      activeTab,
    } = this.state;

    let rawState;
    let html;

    switch (activeTab) {
      case 0:
        rawState = termsAndConditionsRawState;
        html = termsAndConditionsHtml;
        break;
      case 1:
        rawState = privacyPolicyRawState;
        html = privacyPolicyHtml;
        break;
      default:
        break;
    }

    const variables = {
      content: JSON.stringify({ rawState, html }),
      noteType: legalNoteTypes[activeTab],
    };

    onUpdate({ variables });

    this.setState({ changed: false });
  };

  handleCancel = () => {
    this.loadContent();

    this.setState({ discardModal: false });
  };

  getLastUpdated = () => {
    const { activeTab } = this.state;

    const { termsAndConditions, privacyPolicy } = this.props;

    switch (activeTab) {
      case 0:
        return termsAndConditions.data.lastUpdated;
      case 1:
        return privacyPolicy.data.lastUpdated;
      default:
        return null;
    }
  };

  render() {
    const {
      termsAndConditions: termsAndConditionsProps,
      privacyPolicy: privacyPolicyProps,
      updateLoading,
      classes,
    } = this.props;

    const {
      activeTab,
      termsAndConditions,
      privacyPolicy,
      discardModal,
      changed,
    } = this.state;

    const lastUpdated = this.getLastUpdated();

    return (
      <ScreenContainer>
        <Grid container>
          <Grid item xs={12} sm={6}>
            <Tabs indicatorColor="primary" value={activeTab} onChange={this.handleTabChange}>
              <Tab label="Terms & Conditions" />
              <Tab label="Privacy Policy" />
            </Tabs>
          </Grid>
          <Grid item sm={12} className={classes.editorContainer}>
            <Paper className={classes.container}>
              {activeTab === 0 && (
                <MaterialTextEditor
                  onCancel={this.toggleDiscardChangesModal}
                  onChange={content => this.handleChange(content)}
                  lastUpdated={termsAndConditionsProps.data.lastUpdated}
                  loading={termsAndConditionsProps.loading}
                  editorContent={termsAndConditions.rawState}
                  timeStamp={termsAndConditions.timeStamp}
                />
              )}
              {activeTab === 1 && (
                <MaterialTextEditor
                  onCancel={this.toggleDiscardChangesModal}
                  onChange={content => this.handleChange(content)}
                  lastUpdated={privacyPolicyProps.data.lastUpdated}
                  loading={privacyPolicyProps.loading}
                  editorContent={privacyPolicy.rawState}
                  timeStamp={privacyPolicy.timeStamp}
                />
              )}
              <FooterContainer>
                <Typography className={classes.lastUpdated} color="textSecondary">
                  {lastUpdated && `Last updated at ${lastUpdated}`}
                </Typography>
                <div>
                  <Button
                    variant="outlined"
                    className={classes.cancel}
                    onClick={this.toggleDiscardChangesModal}
                    disabled={!changed}
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={this.handleUpdate}
                    disabled={updateLoading}
                  >
                    Update
                  </Button>
                </div>
              </FooterContainer>
            </Paper>
          </Grid>
        </Grid>
        <DiscardChangesConfirmModal
          onConfirmClick={this.handleCancel}
          onCancelClick={this.toggleDiscardChangesModal}
          open={discardModal}
        />
      </ScreenContainer>
    );
  }
}

const FooterContainer = styled.div`
  width: 90%;
  margin: 0 5%;
  height: 5rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export default withStyles({
  container: {
    marginTop: '2rem',
    minWidth: 600,
  },
  cancel: {
    marginRight: '2rem',
  },
})(LegalScreen);
