import { http } from '../config';

const auth = {
  register: payload => http.post('/auth', payload),
  login: payload => http.post('/auth/sign_in', payload),
  recover: payload => http.post('/auth/password', payload),
  update: payload => http.put('/auth/password', payload),
  logout: () => http.delete('auth/sign_out'),
};

export default auth;
