import React from 'react';

export const AuthContext = React.createContext({
  user: {},
  setUser: () => {},
});

export default function withAuth(Component) {
  return function AuthComponent(props) {
    return (
      <AuthContext.Consumer>
        {({ user, setUser }) => <Component {...props} user={user} setUser={setUser} />}
      </AuthContext.Consumer>
    );
  };
}
