import { stringify } from 'csv';

export const downloadCsv = (data, headers, fileName = 'users') => {
  if (!data) return;
  stringify([...headers, ...data], (err, output) => {
    if (window.navigator.msSaveOrOpenBlob) {
      const blob = new Blob([output]);
      window.navigator.msSaveOrOpenBlob(blob, `${fileName}.csv`);
    } else {
      const uriContent = URL.createObjectURL(new Blob([`\uFEFF${output}`], { type: 'text/csv; charset=utf-8;' }));
      const link = document.createElement('a');
      link.setAttribute('href', uriContent);
      link.setAttribute('download', `${fileName}.csv`);
      const event = new MouseEvent('click');
      link.dispatchEvent(event);
    }
  });
};

export default {
  downloadCsv,
};
