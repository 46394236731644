import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import {
  Paper,
  CircularProgress,
  Typography,
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
  TablePagination,
} from '@material-ui/core';

import getReporterValue from '../../../utils/reporterValue';

class ReportedHolrsTable extends Component {
  static propTypes = {
    data: PropTypes.arrayOf(
      PropTypes.shape({
        uuid: PropTypes.string,
        offender: PropTypes.string,
        holrType: PropTypes.string,
        reporter: PropTypes.string,
        reason: PropTypes.string,
        state: PropTypes.string,
        createdAt: PropTypes.string,
      }),
    ),
    loading: PropTypes.bool,
    classes: PropTypes.object.isRequired,
    onItemClick: PropTypes.func,
    loadMore: PropTypes.func.isRequired,
    totalCount: PropTypes.number.isRequired,
  };

  static defaultProps = {
    data: [],
    loading: false,
    onItemClick: () => { },
  };

  state = {
    page: 0,
    rowsPerPage: 10,
    rowsPageOptions: [],
  };

  handleChangePage = (e, page) => {
    const { loadMore } = this.props;
    const { rowsPerPage } = this.state;

    this.setState({ page });
    loadMore(rowsPerPage);
  }

  renderRows = () => {
    const {
      data,
      classes,
      onItemClick,
    } = this.props;

    const { page, rowsPerPage } = this.state;

    return data
      .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
      .map(reportedHolr => (
        <TableRow
          hover
          key={reportedHolr.uuid}
          onClick={() => onItemClick(reportedHolr)}
          classes={{
            hover: classes.tableRow,
          }}
        >
          <TableCell className={classes.tableCell}>{`@${reportedHolr.offender}`}</TableCell>
          <TableCell className={classes.tableCell}>{reportedHolr.holrType}</TableCell>
          <TableCell className={classes.tableCell}>{getReporterValue(reportedHolr)}</TableCell>
          <TableCell className={classes.tableCell}>{reportedHolr.reason}</TableCell>
          <TableCell className={classes.tableCell}>{reportedHolr.state}</TableCell>
          <TableCell className={classes.tableCell}>{reportedHolr.createdAt}</TableCell>
        </TableRow>
      ));
  }

  renderContent = () => {
    const {
      data,
      loading,
      classes,
    } = this.props;

    switch (true) {
      case loading:
        return (
          <TableRow>
            <TableCell colSpan={6} padding="none" className={classes.loading}>
              <CircularProgress thickness={5} size={80} />
            </TableCell>
          </TableRow>
        );
      case !data.length:
        return (
          <TableRow>
            <TableCell colSpan={6} padding="none" className={classes.loading}>
              <Typography variant="display1">Sorry, seems there&apos;s no content to display</Typography>
            </TableCell>
          </TableRow>
        );
      default:
        return this.renderRows();
    }
  }

  render() {
    const { totalCount, classes } = this.props;
    const { page, rowsPerPage, rowsPageOptions } = this.state;

    return (
      <Paper>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell className={classes.tableHead}>Offender</TableCell>
              <TableCell className={classes.tableHead}>Type of holr</TableCell>
              <TableCell className={classes.tableHead}>Reported by</TableCell>
              <TableCell className={classes.tableHead}>Reason</TableCell>
              <TableCell className={classes.tableHead}>Status</TableCell>
              <TableCell className={classes.tableHead}>Reported Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.renderContent()}
          </TableBody>
          <TableFooter>
            <TableRow>
              <TablePagination
                colSpan={6}
                rowsPerPageOptions={rowsPageOptions}
                rowsPerPage={rowsPerPage}
                page={page}
                count={totalCount}
                SelectProps={{ native: true }}
                onChangePage={this.handleChangePage}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </Paper>
    );
  }
}

export default withStyles(theme => ({
  loading: {
    textAlign: 'center',
    padding: `${theme.spacing.unit * 10}px !important`,
  },
  tableHead: {
    fontWeight: 'bold',
    color: theme.palette.primary.main,
  },
  tableCell: {
    border: 'none',
  },
  tableRow: {
    '&&:hover': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(ReportedHolrsTable);
